import logo from "../../../logo.svg";
import "./style.css";
import PageLocation from "../../../components/PageLocation";
import prospektusDasar from "../../../assets/prospektusDasar.pdf";
import termSheet from "../../../assets/termSheet.pdf";
import faqArrow from "../../../assets/faqArrow.png";
import nilaiSw from "../../../assets/nilaiSw.png";
import swCode from "../../../assets/swCode.png";
import swCalendar from "../../../assets/swCalendar.png";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { NotFound } from "../../NotFound";
import { setActiveMenu } from "../../../store/action";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { server } from "../../../config/server";
import { storageLocation } from "../../../config/server";
export default function Pengumuman({ category }) {
  const locationObject = useParams();
  const page = locationObject["*"];

  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(category);
    let allSubPath = document.getElementsByClassName("subPathDokumen");
    // console.log(allSubPath);
    for (let i = 0; i < allSubPath.length; i++) {
      const element = allSubPath[i];
      console.log(element.id);
      if (element.id == category.title) {
        element.classList.add("subPathActive");
      } else {
        element.classList.remove("subPathActive");
      }
    }
    // const data = dummyPemberitahuan.filter((item) => item.category == category);
    let temp = dummyPemberitahuan.filter((item) => item.category == category);
    let x = 10 - temp.length;
    if (temp.length < 10) {
      for (let i = 0; i < x; i++) {
        temp.push([]);
      }
    }
  }, [category]);

  const ContentSwitcher = () => {
    console.log("kont", category);
    switch (category.category_id) {
      case 6:
        return <ProspektusDasar />;
      case 1:
        return <TermSheet />;
      case 8:
        return <AksiKorporasi />;

      default:
        return <NotFound />;
    }
  };

  const navigate = useNavigate();
  const openInNewTab = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };

  const dummyPemberitahuan = [
    {
      date: "05-11-2024",
      title:
        "Informasi Penerbitan Waran Terstruktur di Bursa Efek Indonesia pada tanggal 26 Februari 2024",
      content: `<div data-v-06b38075="" class="col-md pl-md-2 mt-1 p-0 shout-right-bar"><div data-v-06b38075="" class="functionBoxmax" style="padding: 10px 0px;"</div> <div data-v-06b38075=""><div data-v-06b38075="" id="rightText" class="font-size14 footer-h font-weight-bold">Informasi Penerbitan  Waran Terstruktur di Bursa Efek Indonesia pada tanggal 26 Februari 2024</div> <div data-v-06b38075="" id="rightContent" class="mt-5 shout-text"><p>Kami informasikan bahwa berikut ini adalah detail penerbitan Waran Terstruktur ke-18 yang dicatatkan di Bursa Efek Indonesia pada tanggal 26 Februari 2024&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;</p>
      
      <p>Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;ADRODRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 28/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 2650/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;500,000,000<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;AMRTDRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 50/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 2600/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;500,000,000<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;ANTMDRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 24/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 1500/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;500,000,000<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;ASIIDRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 33/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 5500/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;500,000,000<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;BBCADRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 95/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 9500/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;200,000,000<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;BBNIDRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 58/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 6100/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;200,000,000<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;BUKADRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 15/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 160/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;1,000,000,000<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;INDFDRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 31/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 6500/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; :&nbsp; &nbsp; &nbsp;500,000,000<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;ITMGDRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 90/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 28000/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;200,000,000<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;KLBFDRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 38/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 1500/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;500,000,000<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;MEDCDRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 25/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 1350/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;500,000,000<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;PGASDRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 19/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 1400/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;500,000,000<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;UNVRDRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 36/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 2800/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;300,000,000<br>
      &nbsp;</p>
      </div></div></div>`,
      category: "penerbitan",
    },
    {
      date: "05-11-2024",
      content: `<div data-v-06b38075="" id="rightContent" class="mt-5 shout-text"><p>Berikut adalah pengumuman untuk waran terstruktur AKRADRCH4A, ARTODRCH4A,BBRIDRCH4A, BMRIDRCH4A, BRPTDRCH4A, CPINDRCH4A, EMTKDRCH4A, GOTODRCH4A, HRUMDRCH4A, INCODRCH4A, MDKADRCH4A, PTBADRCH4A, SMGRDRCH4A, TLKMDRCH4A dan UNTRDRCH4A mengenai periode perdagangan terbaru bertepatan dengan Cuti Bersama Hari Suci Nyepi Tahun Baru Saka 1946.</p>

      <p>Periode perdagangan&nbsp;</p>
      
      <table border="1" cellpadding="1" cellspacing="0" style="width: 500px; border-collapse: collapse; border-spacing: 0px;">
        <tbody>
          <tr>
            <td style="padding: 1px; text-align: center;">Kegiatan</td>
            <td style="padding: 1px; text-align: center;">Jadwal Awal</td>
            <td style="padding: 1px; text-align: center;">Jadwal Perubahan</td>
          </tr>
          <tr>
            <td style="padding: 1px; text-align: center;">Periode Perdagangan</td>
            <td style="padding: 1px; text-align: center;">14 Nov 2023 - 11 Mar 2024</td>
            <td style="padding: 1px; text-align: center;">14 Nov 2023 - 7 Mar 2024</td>
          </tr>
        </tbody>
      </table>
      
      <p>&nbsp;</p>
      
      <p>Term Sheet Terbaru&nbsp;<a href="https://www.warrantindonesia.co.id/id/Document" target="_blank">https://www.warrantindonesia.co.id/id/Document</a></p>
      
      <p>&nbsp;</p>
      </div>`,
      title:
        "Perubahan Tanggal Perdagangan Terakhir pada Waran Terstruktur Penerbitan ke-15",
      category: "perubahan",
    },
    {
      date: "05-11-2024",
      content: `<div data-v-06b38075="" id="rightContent" class="mt-5 shout-text"><p>Berikut adalah pengumuman untuk waran terstruktur AKRADRCH4A, ARTODRCH4A,BBRIDRCH4A, BMRIDRCH4A, BRPTDRCH4A, CPINDRCH4A, EMTKDRCH4A, GOTODRCH4A, HRUMDRCH4A, INCODRCH4A, MDKADRCH4A, PTBADRCH4A, SMGRDRCH4A, TLKMDRCH4A dan UNTRDRCH4A mengenai periode perdagangan terbaru bertepatan dengan Cuti Bersama Hari Suci Nyepi Tahun Baru Saka 1946.</p>

      <p>Periode perdagangan&nbsp;</p>
      
      <table border="1" cellpadding="1" cellspacing="0" style="width: 500px; border-collapse: collapse; border-spacing: 0px;">
        <tbody>
          <tr>
            <td style="padding: 1px; text-align: center;">Kegiatan</td>
            <td style="padding: 1px; text-align: center;">Jadwal Awal</td>
            <td style="padding: 1px; text-align: center;">Jadwal Perubahan</td>
          </tr>
          <tr>
            <td style="padding: 1px; text-align: center;">Periode Perdagangan</td>
            <td style="padding: 1px; text-align: center;">14 Nov 2023 - 11 Mar 2024</td>
            <td style="padding: 1px; text-align: center;">14 Nov 2023 - 7 Mar 2024</td>
          </tr>
        </tbody>
      </table>
      
      <p>&nbsp;</p>
      
      <p>Term Sheet Terbaru&nbsp;<a href="https://www.warrantindonesia.co.id/id/Document" target="_blank">https://www.warrantindonesia.co.id/id/Document</a></p>
      
      <p>&nbsp;</p>
      </div>`,
      title:
        "Tanggal Kadaluwarsa Perdagangan Terakhir pada Waran Terstruktur Penerbitan ke-15",
      category: "kadaluwarsa",
    },
    {
      date: "05-11-2024",
      content: `<div data-v-06b38075="" id="rightContent" class="mt-5 shout-text"><p>Berikut adalah pengumuman untuk waran terstruktur AKRADRCH4A, ARTODRCH4A,BBRIDRCH4A, BMRIDRCH4A, BRPTDRCH4A, CPINDRCH4A, EMTKDRCH4A, GOTODRCH4A, HRUMDRCH4A, INCODRCH4A, MDKADRCH4A, PTBADRCH4A, SMGRDRCH4A, TLKMDRCH4A dan UNTRDRCH4A mengenai periode perdagangan terbaru bertepatan dengan Cuti Bersama Hari Suci Nyepi Tahun Baru Saka 1946.</p>

      <p>Periode perdagangan&nbsp;</p>
      
      <table border="1" cellpadding="1" cellspacing="0" style="width: 500px; border-collapse: collapse; border-spacing: 0px;">
        <tbody>
          <tr>
            <td style="padding: 1px; text-align: center;">Kegiatan</td>
            <td style="padding: 1px; text-align: center;">Jadwal Awal</td>
            <td style="padding: 1px; text-align: center;">Jadwal Perubahan</td>
          </tr>
          <tr>
            <td style="padding: 1px; text-align: center;">Periode Perdagangan</td>
            <td style="padding: 1px; text-align: center;">14 Nov 2023 - 11 Mar 2024</td>
            <td style="padding: 1px; text-align: center;">14 Nov 2023 - 7 Mar 2024</td>
          </tr>
        </tbody>
      </table>
      
      <p>&nbsp;</p>
      
      <p>Term Sheet Terbaru&nbsp;<a href="https://www.warrantindonesia.co.id/id/Document" target="_blank">https://www.warrantindonesia.co.id/id/Document</a></p>
      
      <p>&nbsp;</p>
      </div>`,
      title:
        "Perubahan Tanggal Penyelesaian Perdagangan Terakhir pada Waran Terstruktur Penerbitan ke-15",
      category: "penyelesaian",
    },
    {
      date: "05-11-2024",
      content: `<div data-v-06b38075="" id="rightContent" class="mt-5 shout-text"><p>Berikut adalah pengumuman untuk waran terstruktur AKRADRCH4A, ARTODRCH4A,BBRIDRCH4A, BMRIDRCH4A, BRPTDRCH4A, CPINDRCH4A, EMTKDRCH4A, GOTODRCH4A, HRUMDRCH4A, INCODRCH4A, MDKADRCH4A, PTBADRCH4A, SMGRDRCH4A, TLKMDRCH4A dan UNTRDRCH4A mengenai periode perdagangan terbaru bertepatan dengan Cuti Bersama Hari Suci Nyepi Tahun Baru Saka 1946.</p>

      <p>Periode perdagangan&nbsp;</p>
      
      <table border="1" cellpadding="1" cellspacing="0" style="width: 500px; border-collapse: collapse; border-spacing: 0px;">
        <tbody>
          <tr>
            <td style="padding: 1px; text-align: center;">Kegiatan</td>
            <td style="padding: 1px; text-align: center;">Jadwal Awal</td>
            <td style="padding: 1px; text-align: center;">Jadwal Perubahan</td>
          </tr>
          <tr>
            <td style="padding: 1px; text-align: center;">Periode Perdagangan</td>
            <td style="padding: 1px; text-align: center;">14 Nov 2023 - 11 Mar 2024</td>
            <td style="padding: 1px; text-align: center;">14 Nov 2023 - 7 Mar 2024</td>
          </tr>
        </tbody>
      </table>
      
      <p>&nbsp;</p>
      
      <p>Term Sheet Terbaru&nbsp;<a href="https://www.warrantindonesia.co.id/id/Document" target="_blank">https://www.warrantindonesia.co.id/id/Document</a></p>
      
      <p>&nbsp;</p>
      </div>`,
      title: "Server Maintenance",
      category: "etc",
    },
  ];
  const ProspektusDasar = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
      // const data = dummyPemberitahuan.filter((item) => item.category == category);
      // let temp = dummyPemberitahuan.filter((item) => item.category == category);

      getData();

      // let x = 10 - temp.length;
      // if (temp.length < 10) {
      //   for (let i = 0; i < x; i++) {
      //     temp.push([]);
      //   }
      // }
      // setData(temp);
    }, []);
    const getData = () => {
      fetch(`${server}/api/v2/swari/shout-out/1/${category.category_id}`, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        // mode: "cors", // no-cors, *cors, same-origin
        // body: JSON.stringify(file),
        headers: {
          "Content-Type": "application/json",
          // access_token: localStorage.getItem("access_token"),
        },
      })
        .then((res) => res.json())
        .then(async (data) => {
          console.log(data, "promox");
          if (data.status) {
            data.data.push([]);
            setData(data.data.filter((item) => item.active == 1));
          } else {
            throw data;
          }
        })

        .catch((err) => {
          console.log(err);
        });
    };
    const dummy = [
      {
        name: "Prospektus Dasar untuk penerbitan Waran Terstruktur oleh PT Korea Investment And Sekuritas Indonesia",
        src: prospektusDasar,
      },
    ];
    return (
      <>
        {" "}
        <div
          class="produkMobileContainer  text-center monsterratExtraBold mt-5 pt-3 mx-auto  f275r  blue-text-1 mw90768"
          style={{ textTransform: "capitalize" }}
        >
          {category.title}
        </div>
        <div
          class="w80 py-4 mx-auto flex  f1r "
          style={{ marginBottom: "6rem" }}
        >
          {/* <div class="pengumumanTabelWaranList">
      {dummyJatuhTempo.map((item, i) => {
        return (
          <div
            id={`pengumumanList${i}`}
            class="tabelPengumumanItem monsterratMedium f1r w100 py-2 pointer"
            onClick={(e) => {
              setSelected(i);
            }}
          >
            Tanggal Pencatatan : {moment(item.date).format("DD-MMM-YYYY")}
          </div>
        );
      })}
    </div> */}
          <div class="pengumumanTabelWaran">
            {" "}
            <div class="w90 mx-auto mb-5 pb-5 " style={{ overflow: "auto" }}>
              <table
                id="searchForm"
                className="table  table-striped table-warrant table-pengumuman "
                style={{
                  textAlign: "center",
                  fontSize: 12,
                }}
              >
                <thead style={{ fontSize: 12 }}>
                  <tr className="h2 tableWarranFontContent" style={{}}>
                    <th
                      className="py-4 white text-center mx-auto  blue-border monsterratMedium  fixedTableCol"
                      style={{
                        backgroundColor: "#0271bf",
                        width: "10%",
                        borderTopLeftRadius: 20,
                        fontSize: 12,
                      }}
                    >
                      No
                    </th>{" "}
                    <th
                      className="py-4 white  mx-auto  blue-border monsterratMedium  fixedTableCol"
                      style={{
                        backgroundColor: "#0271bf",
                        width: "60%",
                        borderLeft: "1px solid white",
                        borderRight: "1px solid white",
                        fontSize: 12,
                      }}
                    >
                      Deskripsi
                    </th>{" "}
                    <th
                      className="py-4 white text-center mx-auto   blue-border monsterratMedium  fixedTableCol"
                      style={{
                        backgroundColor: "#0271bf",

                        borderRight: "1px solid white",
                        fontSize: 12,
                      }}
                    >
                      Tanggal
                    </th>{" "}
                    <th
                      className="py-4 white text-center mx-auto   blue-border monsterratMedium  fixedTableCol"
                      style={{
                        backgroundColor: "#0271bf",

                        borderTopRightRadius: 20,
                        fontSize: 12,
                      }}
                    >
                      File
                    </th>{" "}
                  </tr>
                </thead>
                <tbody id="formData tableWarrantFontContent">
                  {data.map((data, i) => {
                    return (
                      <tr
                        className="form_c"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        <td
                          className="tableWarranFontContent monsterratMedium search-td-h noLeftBorder blue-text-1 fixedTableCol "
                          style={{
                            borderRight: "1px solid transparent ",
                            borderLeft: "1px solid white ",
                            color: "black",
                            fontSize: 12,
                          }}
                        >
                          {data.shout_out_id ? (
                            <> {i + 1}</>
                          ) : (
                            <span class="hidden px-2 ">|</span>
                          )}
                        </td>
                        <td className="  monsterratMedium tdRhb noLeftBorder text-left px-5 ">
                          {" "}
                          {data.shout_out_id ? (
                            <div>
                              <div>{data.title}</div>
                            </div>
                          ) : (
                            <span class="hidden">|</span>
                          )}
                        </td>{" "}
                        <td
                          className="  monsterratMedium tdRhb text-left px-5 "
                          style={{
                            borderLeft: "1px solid white",
                          }}
                        >
                          {" "}
                          {data.shout_out_id ? (
                            <div>
                              <div>{moment(data.shout_out_date).format("DD-MMM-YYYY")}</div>
                            </div>
                          ) : (
                            <span class="hidden">|</span>
                          )}
                        </td>
                        <td
                          className=" monsterratMedium c_one_1 tdRhb  text-center"
                          style={{
                            borderLeft: "1px solid white",
                            verticalAlign: "middle",
                          }}
                        >
                          {data.shout_out_id ? (
                            <div class="rounded pill mx-auto center-vertical w100">
                              {" "}
                              <button
                                id="faqBackButton"
                                class=" monsterratMedium blue-btn rounded-pill flex  grey   mx-auto px-2  center-vertical "
                                onClick={(e) => {
                                  openInNewTab(
                                    `${server}${storageLocation}${data.file}`
                                  );
                                }}
                                style={{
                                  backgroundColor: "transparent",
                                  border: "1px solid #787878",
                                  transition: "0s",
                                }}
                              >
                                <span
                                  style={{ marginRight: "0.3rem" }}
                                  class="mr-1"
                                >
                                  Baca
                                </span>{" "}
                                <img
                                  style={{
                                    marginRight: "0rem",
                                    transform: "rotate(270deg)",
                                  }}
                                  src={faqArrow}
                                  class="faqBackArrow center-vertical"
                                ></img>
                              </button>
                            </div>
                          ) : (
                            <span class="hidden px-2 ">|</span>
                          )}
                        </td>
                        {/* Repeat the same structure for other table data cells */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  };
  const AksiKorporasi = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
      // const data = dummyPemberitahuan.filter((item) => item.category == category);
      // let temp = dummyPemberitahuan.filter((item) => item.category == category);

      getData();

      // let x = 10 - temp.length;
      // if (temp.length < 10) {
      //   for (let i = 0; i < x; i++) {
      //     temp.push([]);
      //   }
      // }
      // setData(temp);
    }, []);
    const getData = () => {
      fetch(`${server}/api/v2/swari/shout-out/1/${category.category_id}`, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        // mode: "cors", // no-cors, *cors, same-origin
        // body: JSON.stringify(file),
        headers: {
          "Content-Type": "application/json",
          // access_token: localStorage.getItem("access_token"),
        },
      })
        .then((res) => res.json())
        .then(async (data) => {
          console.log(data, "promoxxc");
          if (data.status) {
            data.data.push([]);
            setData(data.data.filter((item) => item.active == 1));
          } else {
            throw data;
          }
        })

        .catch((err) => {
          console.log(err);
        });
    };
    const dummy = [
      {
        name: "Prospektus Dasar untuk penerbitan Waran Terstruktur oleh PT Korea Investment And Sekuritas Indonesia",
        src: prospektusDasar,
      },
    ];
    return (
      <>
        {" "}
        <div
          class="produkMobileContainer  text-center monsterratExtraBold mt-5 pt-3 mx-auto  f275r  blue-text-1 mw90768"
          style={{ textTransform: "capitalize" }}
        >
          {category.title}
        </div>
        <div
          class="w80 py-4 mx-auto flex  f1r "
          style={{ marginBottom: "6rem" }}
        >
          {/* <div class="pengumumanTabelWaranList">
      {dummyJatuhTempo.map((item, i) => {
        return (
          <div
            id={`pengumumanList${i}`}
            class="tabelPengumumanItem monsterratMedium f1r w100 py-2 pointer"
            onClick={(e) => {
              setSelected(i);
            }}
          >
            Tanggal Pencatatan : {moment(item.date).format("DD-MMM-YYYY")}
          </div>
        );
      })}
    </div> */}
          <div class="pengumumanTabelWaran">
            {" "}
            <div class="w90 mx-auto mb-5 pb-5 " style={{ overflow: "auto" }}>
            <table
                id="searchForm"
                className="table  table-striped table-warrant table-pengumuman "
                style={{
                  textAlign: "center",
                  fontSize: 12,
                }}
              >
                <thead style={{ fontSize: 12 }}>
                  <tr className="h2 tableWarranFontContent" style={{}}>
                    <th
                      className="py-4 white text-center mx-auto  blue-border monsterratMedium  fixedTableCol"
                      style={{
                        backgroundColor: "#0271bf",
                        width: "10%",
                        borderTopLeftRadius: 20,
                        fontSize: 12,
                      }}
                    >
                      No
                    </th>{" "}
                    <th
                      className="py-4 white  mx-auto  blue-border monsterratMedium  fixedTableCol"
                      style={{
                        backgroundColor: "#0271bf",
                        width: "60%",
                        borderLeft: "1px solid white",
                        borderRight: "1px solid white",
                        fontSize: 12,
                      }}
                    >
                      Deskripsi
                    </th>{" "}
                    <th
                      className="py-4 white text-center mx-auto   blue-border monsterratMedium  fixedTableCol"
                      style={{
                        backgroundColor: "#0271bf",

                        borderRight: "1px solid white",
                        fontSize: 12,
                      }}
                    >
                      Tanggal
                    </th>{" "}
                    <th
                      className="py-4 white text-center mx-auto   blue-border monsterratMedium  fixedTableCol"
                      style={{
                        backgroundColor: "#0271bf",

                        borderTopRightRadius: 20,
                        fontSize: 12,
                      }}
                    >
                      File
                    </th>{" "}
                  </tr>
                </thead>
                <tbody id="formData tableWarrantFontContent">
                  {data.map((data, i) => {
                    return (
                      <tr
                        className="form_c"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        <td
                          className="tableWarranFontContent monsterratMedium search-td-h noLeftBorder blue-text-1 fixedTableCol "
                          style={{
                            borderRight: "1px solid transparent ",
                            borderLeft: "1px solid white ",
                            color: "black",
                            fontSize: 12,
                          }}
                        >
                          {data.shout_out_id ? (
                            <> {i + 1}</>
                          ) : (
                            <span class="hidden px-2 ">|</span>
                          )}
                        </td>
                        <td className="  monsterratMedium tdRhb noLeftBorder text-left px-5 ">
                          {" "}
                          {data.shout_out_id ? (
                            <div>
                              <div>{data.title}</div>
                            </div>
                          ) : (
                            <span class="hidden">|</span>
                          )}
                        </td>{" "}
                        <td
                          className="  monsterratMedium tdRhb text-left px-5 "
                          style={{
                            borderLeft: "1px solid white",
                          }}
                        >
                          {" "}
                          {data.shout_out_id ? (
                            <div>
                              <div>{moment().format("DD-MMM-YYYY")}</div>
                            </div>
                          ) : (
                            <span class="hidden">|</span>
                          )}
                        </td>
                        <td
                          className=" monsterratMedium c_one_1 tdRhb  text-center"
                          style={{
                            borderLeft: "1px solid white",
                            verticalAlign: "middle",
                          }}
                        >
                          {data.shout_out_id ? (
                            <div class="rounded pill mx-auto center-vertical w100">
                              {" "}
                              <button
                                id="faqBackButton"
                                class=" monsterratMedium blue-btn rounded-pill flex  grey   mx-auto px-2  center-vertical "
                                onClick={(e) => {
                                  openInNewTab(
                                    `${server}${storageLocation}${data.file}`
                                  );
                                }}
                                style={{
                                  backgroundColor: "transparent",
                                  border: "1px solid #787878",
                                  transition: "0s",
                                }}
                              >
                                <span
                                  style={{ marginRight: "0.3rem" }}
                                  class="mr-1"
                                >
                                  Baca
                                </span>{" "}
                                <img
                                  style={{
                                    marginRight: "0rem",
                                    transform: "rotate(270deg)",
                                  }}
                                  src={faqArrow}
                                  class="faqBackArrow center-vertical"
                                ></img>
                              </button>
                            </div>
                          ) : (
                            <span class="hidden px-2 ">|</span>
                          )}
                        </td>
                        {/* Repeat the same structure for other table data cells */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  };
 
  const TermSheet = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
      // const data = dummyPemberitahuan.filter((item) => item.category == category);
      // let temp = dummyPemberitahuan.filter((item) => item.category == category);

      getData();

      // let x = 10 - temp.length;
      // if (temp.length < 10) {
      //   for (let i = 0; i < x; i++) {
      //     temp.push([]);
      //   }
      // }
      // setData(temp);
    }, []);
    const getData = () => {
      fetch(`${server}/api/v2/swari/termSheet/ALL_INTERNAL`, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        // mode: "cors", // no-cors, *cors, same-origin
        // body: JSON.stringify(file),
        headers: {
          "Content-Type": "application/json",
          // access_token: localStorage.getItem("access_token"),
        },
      })
        .then((res) => res.json())
        .then(async (data) => {
          console.log(data, "promox");
          if (data.status) {
            data.data.push([]);
            setData(data.data.filter((item) => item.active == 1));
          } else {
            throw data;
          }
        })

        .catch((err) => {
          console.log(err);
        });
    };
    const dummy = [
      {
        name: "BRPTBQCX4A",
        src: termSheet,
        underlying: "BRPT",
        exp_date: "11-4-2024",
        exercise_price: 955,
      },
    ];
    return (
      <>
        {" "}
        <div
          class="produkMobileContainer  text-center monsterratExtraBold mt-5 pt-3 mx-auto  f275r  blue-text-1 mw90768"
          style={{ textTransform: "capitalize" }}
        >
          Term Sheet
        </div>
        <div
          class="w80 py-4 mx-auto flex  f1r "
          style={{ marginBottom: "6rem" }}
        >
          <div class="pengumumanTabelWaran">
            {" "}
            <div class="w90 mx-auto mb-5 pb-5 " style={{ overflow: "auto" }}>
              <table
                id="searchForm"
                className="table  table-striped table-warrant table-pengumuman "
                style={{
                  textAlign: "center",
                  fontSize: 12,
                }}
              >
                <thead style={{}}>
                  <tr className="h2 " style={{ fontSize: 12 }}>
                    <th
                      className="py-4 white text-center mx-auto  blue-border monsterratMedium  fixedTableCol"
                      style={{
                        backgroundColor: "#0271bf",

                        borderTopLeftRadius: 20,
                        fontSize: 12,
                      }}
                    >
                      Kode Waran Dasar
                    </th>
                    <th
                      className="py-4 white  mx-auto  blue-border monsterratMedium  fixedTableCol"
                      style={{
                        backgroundColor: "#0271bf",

                        borderLeft: "1px solid white",
                        borderRight: "1px solid white",
                      }}
                    >
                      Kode Waran
                    </th>
                    <th
                      className="py-4 white  mx-auto  blue-border monsterratMedium  fixedTableCol"
                      style={{
                        backgroundColor: "#0271bf",

                        borderLeft: "1px solid white",
                        borderRight: "1px solid white",
                      }}
                    >
                      Tanggal Kadaluwarsa
                    </th>
                    <th
                      className="py-4 white  mx-auto  blue-border monsterratMedium  fixedTableCol"
                      style={{
                        backgroundColor: "#0271bf",

                        borderLeft: "1px solid white",
                        borderRight: "1px solid white",
                      }}
                    >
                      Harga Pelakasanaan
                    </th>

                    <th
                      className="py-4 white text-center mx-auto   blue-border monsterratMedium  fixedTableCol"
                      style={{
                        backgroundColor: "#0271bf",

                        borderTopRightRadius: 20,
                      }}
                    >
                      Dokumen Pencatatan
                    </th>
                  </tr>
                </thead>
                <tbody
                  id="formData tableWarrantFontContent"
                  style={{
                    fontSize: 12,
                  }}
                >
                  {data.map((data, i) => {
                    return (
                      <tr
                        className="form_c"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        <td
                          className=" monsterratMedium search-td-h noLeftBorder blue-text-1 fixedTableCol "
                          style={{
                            borderRight: "1px solid transparent ",
                            borderLeft: "1px solid white ",
                            color: "black",
                            fontSize: 12,
                          }}
                        >
                          {data.warrant_id ? (
                            <> {data.base_stock_code}</>
                          ) : (
                            <span class="hidden px-2 ">|</span>
                          )}
                        </td>
                        <td
                          className="  text-center monsterratMedium tdRhb noLeftBorder text-left px-5 "
                          style={{
                            borderRight: "1px solid transparent ",
                            borderLeft: "1px solid white ",
                            color: "black",
                          }}
                        >
                          {" "}
                          {data.warrant_id ? (
                            <div>{data.warrant_code}</div>
                          ) : (
                            <span class="hidden">|</span>
                          )}
                        </td>
                        <td
                          className=" monsterratMedium search-td-h noLeftBorder blue-text-1 fixedTableCol "
                          style={{
                            borderRight: "1px solid transparent ",
                            borderLeft: "1px solid white ",
                            color: "black",
                          }}
                        >
                          {data.warrant_id ? (
                            <> {moment(data.expiry_date).format("DD-MMM-YYYY")}</>
                          ) : (
                            <span class="hidden px-2 ">|</span>
                          )}
                        </td>
                        <td
                          className=" monsterratMedium search-td-h noLeftBorder blue-text-1 fixedTableCol "
                          style={{
                            borderRight: "1px solid transparent ",
                            borderLeft: "1px solid white ",
                            color: "black",
                          }}
                        >
                          {data.warrant_id ? (
                            <>IDR {data.exercise_price}</>
                          ) : (
                            <span class="hidden px-2 ">|</span>
                          )}
                        </td>

                        <td
                          className=" monsterratMedium c_one_1 tdRhb center-vertical text-center"
                          style={{
                            borderLeft: "1px solid white",
                          }}
                        >
                          {data.warrant_id ? (
                            <div class="rounded pill center-vertical mx-auto">
                              {" "}
                              <button
                                id="faqBackButton"
                                class=" monsterratMedium blue-btn rounded-pill flex  grey   px-2  center-vertical "
                                onClick={(e) => {
                                  openInNewTab(
                                    `${server}${storageLocation}${data.file}`
                                  );
                                }}
                                style={{
                                  backgroundColor: "transparent",
                                  border: "1px solid #787878",
                                  transition: "0s",
                                }}
                              >
                                <span
                                  style={{ marginRight: "0.3rem" }}
                                  class="mr-1"
                                >
                                  Baca
                                </span>{" "}
                                <img
                                  style={{
                                    marginRight: "0rem",
                                    transform: "rotate(270deg)",
                                  }}
                                  src={faqArrow}
                                  class="faqBackArrow center-vertical"
                                ></img>
                              </button>
                            </div>
                          ) : (
                            <span class="hidden px-2 ">|</span>
                          )}
                        </td>

                        {/* Repeat the same structure for other table data cells */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <button
        id="faqBackButton"
        class=" monsterratMedium blue-btn rounded-pill grey flex  f1r px-4 mb-3 center-vertical "
        onClick={(e) => {
          navigate("/produk/waran-terstruktur", {
            replace: true,
            state: { toAnnouncement: true },
          });
        }}
        style={{
          backgroundColor: "transparent",
          border: "2px solid #787878",
          transition: "0s",
        }}
      >
        <img src={faqArrow} class="faqBackArrow center-vertical"></img>Back
      </button>
      <div class="w100">
        <div id="subPath" className=" productSubPath ">
          <div className="subPathContainer justify-content-center">
            <div
              className="subPathDokumen subPathProduk monsterratMedium rounded-pill mx-2"
              style={{
                width: "14%",
                fontSize: 12,
              }}
              id="Prospektus Dasar"
              onClick={() =>
                navigate("/produk/waran-terstruktur/dokumen/prospektus-dasar")
              }
            >
              Prospektus Dasar
            </div>{" "}
            <div
              className="subPathDokumen subPathProduk monsterratMedium rounded-pill mx-2"
              style={{
                width: "14%",
                fontSize: 12,
              }}
              id="Term Sheet"
              onClick={() =>
                navigate("/produk/waran-terstruktur/dokumen/term-sheet")
              }
            >
              Term Sheet
            </div>{" "}
            <div
              className="subPathDokumen subPathProduk monsterratMedium rounded-pill mx-2"
              style={{
                width: "14%",
                fontSize: 12,
              }}
              id="Aksi Korporasi"
              onClick={() =>
                navigate("/produk/waran-terstruktur/dokumen/aksi-korporasi")
              }
            >
              Aksi Korporasi
            </div>
          </div>
        </div>
        {ContentSwitcher()}
      </div>
    </>
  );
}

function BackButton() {
  const [offset, setOffset] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    // console.log(page.substring(1, page.length), page);
    // document.getElementById(page).className +=
    //   " subPathActive";
    console.log(offset, document.documentElement.offsetHeight);

    // if (offset >= document.documentElement.offsetHeight - 1300) {
    //   document.getElementById("faqBackButton").style.position = "static";
    // } else {
    //   document.getElementById("faqBackButton").style.position = "sticky";
    // }
    // if (offset > 0) {
    //   document.getElementById("faqBackButton").style.top = "12rem";

    // }
    // if (offset == 0) {
    //   if (window.innerWidth > 768) {
    //     document.getElementById("faqBackButton").style.top = "17rem";
    //   }

    // }

    // if (offset >= document.documentElement.offsetHeight - 1900) {
    //   document.getElementById("faqBackButton").style.position = "block";
    // } else {
    //   document.getElementById("faqBackButton").style.position = "fixed";
    // }

    // if (offset >= document.documentElement.offsetHeight - 1000) {
    //   document.getElementById("faqBackButton").style.top = "130px";
    // } else {
    //   document.getElementById("faqBackButton").style.top = "315px";
    // }
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, [offset]);
  return (
    <>
      <button
        id="faqBackButton"
        class=" monsterratMedium blue-btn rounded-pill flex  f1r px-4 mb-3 "
        onClick={(e) => {
          navigate("/produk/waran_terstruktur");
        }}
      >
        Keterangan :
      </button>
    </>
  );
}
