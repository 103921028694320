import logo from "../../logo.svg";
import "./style.css";
import React, { useEffect, useState } from "react";
import PageLocation from "../../components/PageLocation";
import laporan2020 from "../../assets/laporan2020.pdf";
import strukturOrganisasiDetail from "../../assets/strukturOrganisasiDetail.jpg";
export function LaporanTahunan() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div class="laporanTahunanWrapper">
        <div class="laporanTahunanSelector">
          <div class="btn-group laporanDropdown">
            <button type="button" class="btn btn-secondary">
              Laporan Tahun 2020
            </button>
            <button
              type="button"
              class="btn btn-secondary dropdown-toggle dropdown-toggle-split"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span class="visually-hidden">Toggle Dropdown</span>
            </button>
          </div>
        </div>
        <div class="laporanTahunanContainer">
          <object
            data={laporan2020}
            type="application/pdf"
          
            class="laporanTahunanReader"
          >
            <p>
              Alternative text - include a link{" "}
              <a href={laporan2020}>
                to the PDF!
              </a>
            </p>
          </object>
        </div>
      </div>
    </div>
  );
}
