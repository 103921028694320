import logo from "../../../logo.svg";
import "./style.css";
import PageLocation from "../../../components/PageLocation";
import arrowUp from "../../../assets/arrowUp.png";
import arrowDown from "../../../assets/arrowDown.png";
import faqArrow from "../../../assets/faqArrow.png";
import nilaiSw from "../../../assets/nilaiSw.png";
import swCode from "../../../assets/swCode.png";
import swCalendar from "../../../assets/swCalendar.png";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { NotFound } from "../../NotFound";
import { setActiveMenu } from "../../../store/action";
import { useDispatch, useSelector } from "react-redux";
export default function FaqWarrant() {
  const locationObject = useParams();
  const page = locationObject["*"];

  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(page);
    // console.log(page.substring(1, page.length), page);
    // document.getElementById(page).className +=
    //   " subPathActive";
    // dispatch(setActiveMenu("Produk"));
    // if (offset >= document.documentElement.offsetHeight - 1000) {
    //   document.getElementById("waBtn").style.bottom = "130px";
    // } else {
    //   document.getElementById("waBtn").style.bottom = "15px";
    // }
    // const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    // window.removeEventListener("scroll", onScroll);
    // window.addEventListener("scroll", onScroll, { passive: true });
    // return () => window.removeEventListener("scroll", onScroll);
  }, [page]);

  const faqSwitcher = () => {
    switch (page) {
      case "q1":
        return <Q1 />;
      case "q2":
        return <Q2 />;
      case "q3":
        return <Q3 />;
      case "q4":
        return <Q4 />;
      case "q5":
        return <Q5 />;
      case "q6":
        return <Q6 />;
      default:
        return <NotFound />;
    }
  };

  const navigate = useNavigate();
  const openInNewTab = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };

  const Q1 = () => {
    return (
      <>
        <div class="textCenter   pb-5 ">
          {/* <div class="monsterratBold grey f2r"></div> */}
          <div class="produkMobileContainer monsterratExtraBold mt-3 mx-auto  f275r  blue-text-1 mw90768">
            Apa itu Waran Terstruktur?
          </div>

          <div class="monsterratMedium grey jenisAkunText  f1r mw60 mw90768">
            Waran Terstruktur merupakan instrumen keuangan dan instrumen
            investasi yang diterbitkan oleh perusahaan sekuritas serta dapat
            diperjualbelikan di Bursa Efek Indonesia melalui aplikasi online
            trading masing-masing sekuritas seperti aplikasi KOINS yang dimiliki
            oleh PT. Korea Investment And Sekuritas Indonesia.
          </div>
        </div>
        <div class="bg-biru-muda sahamTextContainer putvscall pt-5 pb-5 text-center rounded-corner mt-5 flex  justify-content-center">
          <div
            class="mx-auto1000"
            style={{ borderRadius: "20px 20px 10px 10px", maxWidth: "24rem" }}
          >
            <div
              class="text-center f2r white monsterratSemiBold"
              style={{
                backgroundColor: "#ca0a09",
                borderRadius: "20px 20px 0px 0px",
              }}
            >
              Put
            </div>

            <div
              style={{
                border: "3px solid #ca0a09",
                borderRadius: "0px 0px 10px 10px",
              }}
            >
              <div class="px-5 f1r grey monsterratSemiBold pt-4 mt-1">
                Right to <span class="fw-800">sell </span> underlying asset at
                future date at <span class="fw-800">predetermined</span> price
              </div>
              <div class="px-5 f09r grey monsterratMedium py-4">
                Increase in value when underlying asset goes <b>down</b>
              </div>
            </div>
          </div>
          <div class="f5r center-vertical block1000 mx-auto1000 monsterratExtraBold blue-text-1 px-5">
            VS
          </div>
          <div
            class="mx-auto1000"
            style={{ borderRadius: "20px 20px 10px 10px", maxWidth: "24rem" }}
          >
            <div
              class="text-center f2r white monsterratSemiBold"
              style={{
                backgroundColor: "#57CB0A",
                borderRadius: "20px 20px 0px 0px",
              }}
            >
              Call
            </div>

            <div
              style={{
                border: "3px solid #57CB0A",
                borderRadius: "0px 0px 10px 10px",
              }}
            >
              <div class="px-5 f1r grey monsterratSemiBold pt-4 mt-1">
                Right to <span class="fw-800">buy </span> underlying asset at
                future date at <span class="fw-800">predetermined</span> price
              </div>
              <div class="px-5 f09r grey monsterratMedium py-4">
                Increase in value when underlying asset goes <b>up</b>
              </div>
            </div>
          </div>
        </div>
        <div class="textCenter   pb-5 ">
          {/* <div class="monsterratBold grey f2r"></div> */}
          <div class="monsterratExtraBold mt-3 mx-auto  f275r  blue-text-1 mw90768">
            Perbedaan
          </div>

          <div class="monsterratMedium grey jenisAkunText  f1r mw60 mw90768">
            <b>Waran Terstruktur</b> dan <b>Waran Perusahaan</b> dari sisi fitur
            produk
          </div>
          <div class="mw80 mw901000 mx-auto">
            <table
              style={{
                border: "2px solid #0271bf",
                verticalAlign: "middle",
                tableLayout: "fixed",
              }}
              class="table f12r table-bordered textCenter monsterratMedium lh25 tableFaqWarrant mt-5"
            >
              <thead class="white" style={{ backgroundColor: "#0271bf" }}>
                <tr>
                  <th class="py-3" style={{ width: "25%" }} scope="col"></th>
                  <th class="py-3" style={{ width: "37.5%" }} scope="col">
                    WARAN TERSTRUKTUR
                  </th>
                  <th class="py-3" style={{ width: "37.5%" }} scope="col">
                    WARAN PERUSAHAAN{" "}
                  </th>
                </tr>
              </thead>
              <tbody
                class="grey monsterratMedium f1r"
                style={{ border: "2px solid #0271bf" }}
              >
                <tr style={{ border: "2px solid #0271bf" }} class="py-3">
                  <td class="py-4">Diterbitkan Oleh</td>
                  <td class="fw700 py-4">Perusahaan Sekuritas (Pihak ke-3)</td>
                  <td class="py-4">Perusahaan Tercatat (Emiten Perusahaan)</td>
                </tr>
                <tr style={{ border: "2px solid #0271bf" }} class="py-3">
                  <td class="py-4">Tujuan</td>
                  <td class="fw700 py-4">
                    Leverage ataupun lindung nilai (hedging) bagi investor
                  </td>
                  <td class="py-4">
                    Pengumpulan dana bagi perusahaan ataupun menjadi bonus bagi
                    investor yang membeli pada saat proses IPO
                  </td>
                </tr>
                <tr style={{ border: "2px solid #0271bf" }} class="py-3">
                  <td class="py-4">
                    Saham Underlying<br></br> (Saham dasar)
                  </td>
                  <td class="fw700 py-4">
                    Saham perusahaan lain, tidak terkait dengan penerbit
                  </td>
                  <td class="py-4">
                    Saham yang dikeluarkan oleh perusahaan tercatat
                  </td>
                </tr>
                <tr style={{ border: "2px solid #0271bf" }} class="py-3">
                  <td class="py-4">Efek Dilusi*</td>
                  <td class="fw700 py-4">Tidak ada</td>
                  <td class="py-4">Menyebabkan dilusi</td>
                </tr>
                <tr style={{ border: "2px solid #0271bf" }} class="py-3">
                  <td class="py-4">Jatuh Tempo</td>
                  <td class="fw700 py-4">2 - 24 Bulan</td>
                  <td class="py-4">
                    Umumnya lebih Panjang, 6 bulan hingga 5 tahun
                  </td>
                </tr>
                <tr style={{ border: "2px solid #0271bf" }} class="py-3">
                  <td class="py-4">Metode Penyelesian</td>
                  <td class="fw700 py-4">Tunai**</td>
                  <td class="py-4">Saham fisik</td>
                </tr>
              </tbody>
            </table>
            <div class="my-5 pb-5">
              <button
                class=" monsterratMedium blue-btn rounded-pill flex  f1r px-4 mb-3"
                style={{ pointerEvents: "none" }}
              >
                Keterangan :
              </button>
              <div
                style={{ maxWidth: 600, textAlign: "left" }}
                class="pt-3 monsterratMedium f1r grey"
              >
                <b>*Efek dilusi</b> adalah berkurangnya porsi kepemilikan saham
                (dalam persentase), karena penambahan lembar saham baru.
              </div>
              <div
                style={{ maxWidth: 600, textAlign: "left" }}
                class="pt-3 monsterratMedium f1r grey"
              >
                <b>**Penyelesaian tunai</b> adalah pada saat tanggal kedaluarsa,
                penyelesaian Waran Terstruktur dalam bentuk tunai (bukan
                penyerahan saham fisik)
              </div>
            </div>
            <div class=" pb-5 ">
              {/* <div class="monsterratBold grey f2r"></div> */}
              <div class="text-center monsterratExtraBold mt-3 mx-auto  f275r  blue-text-1 mw90768">
                Penamaan Waran Terstruktur
              </div>

              <div class="monsterratMedium grey text-center jenisAkunText  f1r mw60 mw90768">
                Dari sisi penamaan, Waran Terstruktur berbeda dengan Waran
                Perusahan dimana pada Waran Terstruktur ada tambahan beberapa
                kode khusus sehingga total kodenya menjadi 10 digit.
              </div>
              <div class="bg-biru-muda sahamTextContainer pt-5 pb-5  rounded-corner mt-5 flex  justify-content-center">
                <div class="px-5 monsterratMedium grey f1r text-left">
                  Sebagai contoh kita gunakan saham{" "}
                  <span class="fw-800">BBCA</span> untuk memahami perbedaan dari
                  sisi penamaan:
                  <div class="my-4"></div>
                  <div class="flex">
                    <div style={{ paddingRight: "20%" }}>
                      <div class="nowrap text-left">
                        Saham dasar (underlying)
                      </div>
                      <div class="nowrap text-left">Waran Perusahaan</div>
                      <div class="nowrap text-left">Waran Terstruktur </div>
                    </div>
                    <div>
                      <div class="nowrap text-left">
                        : BBCA - Bank Central Asia Tbk
                      </div>
                      <div class="nowrap text-left">: BBCA - W</div>
                      <div class="nowrap text-left">: BBCABQCV3A</div>
                    </div>
                  </div>
                  <div class="my-4"></div>
                  Waran Perusahan, biasanya memiliki kode huruf W dibelakang
                  kode sahamnya, sebagai contoh BBCA-W, artinya Waran Perusahaan
                  dari <span class="fw-800">emiten BBCA</span>
                </div>
              </div>
              <div class="sahamTextContainer  grey monsterratMedium f1r">
                Sementara Waran Terstruktur BBCA memiliki 10-digit kode, yaitu
                <span class="fw-800"> BBCABQCV3A</span>, yang terdiri:
                <div>
                  <img class="w90 my-5" src={swCode}></img>
                </div>
              </div>
              <div
                class="flex mw70 mw1001000 monsterratMedium grey mx-auto justify-content-center f12768"
                style={{ fontSize: 20 }}
              >
                <div
                  class="text-left pr1768"
                  style={{
                    paddingRight: "3rem",
                  }}
                >
                  Berikut disamping macam-macam <b>kode waktu</b> Waran
                  Terstruktur:
                  <div class="my-4"></div>
                  Lalu, yang dimaksud dengan angka 3 setelah kode bulan
                  merupakan angka tahun / angka terakhir pada tahun periode
                  Waran Terstruktur berakhir.
                  <div class="my-4"></div> Contoh diatas kode waktunya adalah{" "}
                  <b>V3</b> artinya periodenya akan berakhir pada bulan Oktober
                  20<b>23</b>
                  <div class="my-4"></div>
                  <b>Kode Unik</b> sendiri merupakan satu huruf bebas yang
                  diberikan oleh penerbit Waran Terstruktur tersebut.
                </div>
                <div>
                  <table
                    style={{
                      border: "2px solid #0271bf",
                      maxWidth: 600,
                      tableLayout: "fixed",
                    }}
                    class="table f1r table-bordered textCenter monsterratMedium  tableFaqWarrant w100"
                  >
                    <thead class="white" style={{ backgroundColor: "#0271bf" }}>
                      <tr class="w100">
                        <th class="" style={{ width: "50%" }} scope="col">
                          Bulan
                        </th>
                        <th class="" style={{ width: "50%" }} scope="col">
                          Kode
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      class="grey monsterratMedium f09r"
                      style={{ border: "2px solid #0271bf" }}
                    >
                      <tr style={{ border: "2px solid #0271bf" }} class="">
                        <td class="text-left px-4 pl05768">Januari</td>
                        <td class="">F</td>
                      </tr>
                      <tr style={{ border: "2px solid #0271bf" }} class="">
                        <td class="text-left px-4 pl05768">Februari</td>
                        <td class="">G</td>
                      </tr>
                      <tr style={{ border: "2px solid #0271bf" }} class="">
                        <td class="text-left px-4 pl05768">Maret</td>
                        <td class="">H</td>
                      </tr>
                      <tr style={{ border: "2px solid #0271bf" }} class="">
                        <td class="text-left px-4 pl05768">April</td>
                        <td class="">J</td>
                      </tr>
                      <tr style={{ border: "2px solid #0271bf" }} class="">
                        <td class="text-left px-4 pl05768">Mei</td>
                        <td class="">K</td>
                      </tr>
                      <tr style={{ border: "2px solid #0271bf" }} class="">
                        <td class="text-left px-4 pl05768">Juni</td>
                        <td class="">M</td>
                      </tr>
                      <tr style={{ border: "2px solid #0271bf" }} class="">
                        <td class="text-left px-4 pl05768">Juli</td>
                        <td class="">N</td>
                      </tr>
                      <tr style={{ border: "2px solid #0271bf" }} class="">
                        <td class="text-left px-4 pl05768">Agustus</td>
                        <td class="">Q</td>
                      </tr>
                      <tr style={{ border: "2px solid #0271bf" }} class="">
                        <td class="text-left px-4 pl05768">September</td>
                        <td class="">U</td>
                      </tr>
                      <tr style={{ border: "2px solid #0271bf" }} class="">
                        <td class="text-left px-4 pl05768">Oktober</td>
                        <td class="">V</td>
                      </tr>
                      <tr style={{ border: "2px solid #0271bf" }} class="">
                        <td class="text-left px-4 pl05768">November</td>
                        <td class="">X</td>
                      </tr>
                      <tr style={{ border: "2px solid #0271bf" }} class="">
                        <td class="text-left px-4 pl05768">Desember</td>
                        <td class="">Z</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const Q2 = () => {
    return (
      <div
        class="disclaimerPageContainer py-4"
        style={{ marginBottom: "6rem" }}
      >
        <div class="textCenter   pb-3 ">
          {/* <div class="monsterratBold grey f2r"></div> */}
          <div class="produkMobileContainer monsterratExtraBold mt-3 mx-auto  f275r  blue-text-1 mw90768">
            Kenapa Transaksi Waran Terstruktur
          </div>

          <div class="monsterratMedium grey jenisAkunText  f1r  mw90768">
            Keuntungan berinvestasi Waran Terstruktur
          </div>
        </div>
        <div class="disclaimerContainer rounded-corner bg-biru-muda px-5 py-4 mx-auto mt-5  grey monsterratMedium f1r">
          <br></br>
          <div class="eIpoText">
            <ul>
              <li>
                <b>Memiliki tingkat Likuiditas yang Tinggi</b>, hal ini
                dikarenakan Penerbit Waran Terstruktur berlaku sebagai penyedia
                likuiditas (liquidity provider/LP) sehingga investor dapat
                melakukan jual/beli. Akan tetapi, jika status Waran Terstruktur
                sudah low-on-inventory, maka LP hanya dapat menyediakan Bid saja
                karena penerbit sudah tidak memiliki unit waran yang dapat
                dijual.
              </li>
              <br></br>
              <li>
                <b>Memiliki fitur Efek Pengungkit</b>, dengan adanya fitur ini
                dalam instrument Waran Terstruktur maka akan meningkatkan
                keuntungan (return) dan kerugian (loss) dalam berinvestasi.
              </li>
              <br></br>
              <li>
                <b>Dapat dibeli dengan harga yang lebih murah</b>, Waran
                Terstruktur biasanya dihargai lebih murah daripada saham
                dasarnya karena yang dibeli investor hanyalah “hak” untuk
                melakukan jual/beli saham dasar di tanggal kedaluarsanya.
              </li>
              <br></br>
              <li>
                <b>Tidak ada Batasan Auto Reject</b>, dengan tidak adanya
                Batasan pergerakan harga ini maka dengan modal yang relatif
                lebih rendah maka hasil yang bisa diperoleh investor pada saat
                jatuh tempo pun dapat tidak terbatas.
              </li>
              <br></br>
              <li>
                <b>Potensi Keuntungan Tidak Terbatas</b>, Potensi keuntungan
                yang akan diperoleh investor tidak terbatas, tergantung dari
                pergerakan harga underlying sahamnya. Sedangkan maksimum potensi
                kerugian yang dialami hanya sebatas harga Waran Terstruktur.
              </li>
              <br></br>
            </ul>
          </div>
        </div>
        {/* <div class="syariahButtonWrapper my-5 pt-5">
        <div class="f25r  mb-4 blue-text-1 monsterratExtraBold text-center">
        Manual Book
        </div>
        <div class="monsterratMedium grey block1000 f1r flex text-center pt-1">
          <div class="flex mx-auto block1000">
     
            <div>
            <button
                class="syariahPdfButton appendixPdfButton grey f1r px-4 mx-2"
                onClick={() => openInNewTab(manualPendaftaranEIpo)}
              >
                Manual Book Pendaftaran e-IPO
              </button>
              <button
                class="syariahPdfButton appendixPdfButton grey f1r px-4 mx-2"
                onClick={() => openInNewTab(manual_order_e_ipo)}
              >
                Manual Book Order e-IPO
              </button>
       
            </div>
          </div>
        </div>
      </div> */}
      </div>
    );
  };
  const Q3 = () => {
    return (
      <>
        <div class="textCenter   ">
          {/* <div class="monsterratBold grey f2r"></div> */}
          <div class="produkMobileContainer monsterratExtraBold mt-3  mb-5 mx-auto  f275r  blue-text-1 mw90768">
            Bagaimana Harga Waran Terstruktur dibentuk?
          </div>

          <div class="monsterratMedium grey jenisAkunText  f1r mw60 mw90768">
            Harga dari suatu Waran Terstruktur dibentuk oleh 6 faktor
          </div>
          <div class="mw80 mw901400 mw951000 mx-auto">
            <table
              style={{
                border: "2px solid #0271bf",
                verticalAlign: "middle",
                tableLayout: "fixed",
              }}
              class="table f12r table-bordered textCenter monsterratMedium lh2 tableFaqWarrant mt-5  mb-5 pb-5 tableFaqWarrant3"
            >
              <thead
                class="white fw-500"
                style={{ backgroundColor: "#0271bf" }}
              >
                <tr>
                  <th class="py-3 fw-500" style={{ width: "20%" }} scope="col">
                    Dampak kenaikan Faktor
                  </th>
                  <th
                    class="py-3 fw-500 w101000"
                    style={{ width: "20%" }}
                    scope="col"
                  >
                    Waran Terstruktur <i>Call</i>
                  </th>
                  <th
                    class="py-3 fw-500 w101000"
                    style={{ width: "20%" }}
                    scope="col"
                  >
                    Waran Terstruktur <i>Put</i>
                  </th>
                  <th class="py-3 fw-500" style={{ width: "40%" }} scope="col">
                    Keterangan
                  </th>
                </tr>
              </thead>
              <tbody
                class="grey monsterratMedium f1r"
                style={{ border: "2px solid #0271bf" }}
              >
                <tr style={{ border: "2px solid #0271bf" }} class="py-3">
                  <td class="py-4">Saham Dasar</td>
                  <td class="fw700 py-4">
                    <img style={{ maxWidth: "30%" }} src={arrowUp} />
                  </td>
                  <td class="fw700 py-4">
                    <img style={{ maxWidth: "30%" }} src={arrowDown} />
                  </td>
                  <td class="px-5 py-3 f09r" style={{ textAlign: "justify" }}>
                    Ketika Harga Saham mengalami kenaikan maka WT Call akan
                    mengalami kenaikan sebaliknya Ketika Saham Dasarnya melemah
                    maka WT Put akan mengalami penurunan nilai.
                  </td>
                </tr>
                <tr style={{ border: "2px solid #0271bf" }} class="py-3">
                  <td class="py-4">Harga Penyelesaian</td>
                  <td class="fw700 py-4">
                    <img style={{ maxWidth: "30%" }} src={arrowUp} />
                  </td>
                  <td class="fw700 py-4">
                    <img style={{ maxWidth: "30%" }} src={arrowDown} />
                  </td>
                  <td class="px-5 py-3 f09r" style={{ textAlign: "justify" }}>
                    Pada saat mendekati masa Waktu kedaluarsa dan harga
                    penyelesaianya semakin tinggi diatas Harga pelaksanannya
                    maka WT Call akan mengalami kenaikan karena tingkat
                    keuntungan semakin besar sementara WT Put akan melemah
                  </td>
                </tr>{" "}
                <tr style={{ border: "2px solid #0271bf" }} class="py-3">
                  <td class="py-4">Masa Kadaluarsa</td>
                  <td class="fw700 py-4">
                    <img style={{ maxWidth: "30%" }} src={arrowUp} />
                  </td>
                  <td class="fw700 py-4">
                    <img style={{ maxWidth: "30%" }} src={arrowDown} />
                  </td>
                  <td class="px-5 py-3 f09r" style={{ textAlign: "justify" }}>
                    Semakin lama masa kedaluarsa suatu Waran Terstruktur maka
                    semakin tinggi nilai suatu Waran Terstruktur baik itu jenis
                    Call atau Put karena dengan semakin lamanyaperiode maka
                    probabilitas tingkat keuntungan semakin besar.
                  </td>
                </tr>{" "}
                <tr style={{ border: "2px solid #0271bf" }} class="py-3">
                  <td class="py-4">Volatilitas</td>
                  <td class="fw700 py-4">
                    <img style={{ maxWidth: "30%" }} src={arrowUp} />
                  </td>
                  <td class="fw700 py-4">
                    <img style={{ maxWidth: "30%" }} src={arrowDown} />
                  </td>
                  <td class="px-5 py-3 f09r" style={{ textAlign: "justify" }}>
                    Volatilitas menunjukan tingkat ketidakpastian akan performa
                    sebuah saham Dimasa depan maka dari itu semakin tinggi
                    tingkat volatilitas dapat menyebabkan kenaikan pada nilai
                    Waran Terstruktur karena pergerakan harga yang bergerak
                    signifikan.
                  </td>
                </tr>{" "}
                <tr style={{ border: "2px solid #0271bf" }} class="py-3">
                  <td class="py-4">Dividend</td>
                  <td class="fw700 py-4">
                    <img style={{ maxWidth: "30%" }} src={arrowUp} />
                  </td>
                  <td class="fw700 py-4">
                    <img style={{ maxWidth: "30%" }} src={arrowDown} />
                  </td>
                  <td class="px-5 py-3 f09r" style={{ textAlign: "justify" }}>
                    Ketika tingkat pembayaran Dividen mengalami kenaikan dan
                    pada saat Ex-Date harga saham mengalami penurunan lebih dari
                    nilai Dividennya maka akan membuat nilai dari WT Call
                    mengalami penurunan sebaliknya nilai dari WT Put akan
                    meningkat.
                  </td>
                </tr>{" "}
                <tr style={{ border: "2px solid #0271bf" }} class="py-3">
                  <td class="py-4">Suku Bunga</td>
                  <td class="fw700 py-4">
                    <img style={{ maxWidth: "30%" }} src={arrowUp} />
                  </td>
                  <td class="fw700 py-4">
                    <img style={{ maxWidth: "30%" }} src={arrowDown} />
                  </td>
                  <td class="px-5 py-3 f09r" style={{ textAlign: "justify" }}>
                    Semakin tinggi tingkat kenaikan pada Suku Bunga acuan makan
                    akan membuat nilai dari WT Call meningkat hal ini disebabkan
                    oleh naiknya biaya pinjaman sebaliknya nilai dari WT Put
                    akan turun.
                  </td>
                </tr>
              </tbody>
            </table>

            <div class=" py-5 ">
              {/* <div class="monsterratBold grey f2r"></div> */}
              <div class=" text-center monsterratExtraBold mt-3 mx-auto  f275r  blue-text-1 mw90768">
                Nilai dari Waran Terstruktur
              </div>

              <div class="monsterratMedium grey text-center jenisAkunText  f1r mw60 mw90768">
                Nilai dari Waran Terstruktur terbentuk dari{" "}
                <b>Nilai Intrinsik</b> dan Nilai waktu
              </div>

              <div class="flex py-5 my-4 block1000">
                <div class="px-5 monsterratMedium grey f1r text-left mb-4">
                  <b>Nilai Intrinsik</b> merupakan selisih antara Harga Saham
                  Saat ini dan Harga Pelaksanaan Waran Terstruktur.
                  <div class="my-4"></div>
                  <div class="flex">
                    <div
                      class="pl10768"
                      style={{ paddingRight: "20%", paddingLeft: 50 }}
                    >
                      <div class="nowrap text-left">
                        {" "}
                        Perhatikan ilustrasi Waran Terstruktur Call dibawah ini:
                      </div>

                      <div class="nowrap text-left">Harga Saham Saat ini</div>
                      <div class="nowrap text-left">Harga Pelaksanaan WT</div>
                      <div class="nowrap text-left">Harga WT Saat ini </div>
                      <div class="nowrap text-left">
                        Rasio Waran Terstruktur{" "}
                      </div>
                    </div>
                    <div>
                      <div
                        class="nowrap text-left"
                        style={{ marginLeft: "-200px" }}
                      >
                        &nbsp;
                      </div>
                      <div
                        class="nowrap text-left"
                        style={{ marginLeft: "-200px" }}
                      >
                        : Rp 755
                      </div>
                      <div
                        class="nowrap text-left"
                        style={{ marginLeft: "-200px" }}
                      >
                        : Rp 505
                      </div>
                      <div
                        class="nowrap text-left"
                        style={{ marginLeft: "-200px" }}
                      >
                        : Rp 412
                      </div>
                      <div
                        class="nowrap text-left"
                        style={{ marginLeft: "-200px" }}
                      >
                        : 1 (satu) banding 1 (satu)
                      </div>
                    </div>
                  </div>
                  <div class="my-4"></div>
                  Dari data diatas dapat diketahui bahwa Nilai Intrinsik dari WT
                  adalah sebesar Rp250 (Rp755 - Rp505) lalu dibagi dengan
                  1(rasio).
                  <div class="my-4"></div>
                  Nilai Waktu adalah selisih antara Harga WT saat ini dan Nilai
                  Intrinsiknya, seperti contoh ilustrasi diatas diketahui bahwa
                  Nilai Waktu WT Call adalah Rp162 (Rp412 – Rp250).
                  <div class="my-4"></div>
                  Sebuah Waran Terstruktur biasanya memang bernilai lebih dari
                  Nilai Intrinsiknya seperti pada contoh diatas.
                </div>
                <img
                  class="mw100768"
                  style={{ maxWidth: "500px" }}
                  src={nilaiSw}
                />
              </div>
              <div class=" monsterratExtraBold mt-5 pt-5   mx-auto  f275r  blue-text-1 mw90768">
                Skenario Call and Put Waran
              </div>

              <div class="monsterratMedium grey mx-auto pt-2 f1r mw60 mw90768">
                Perbedaan transaksi antara Call and Put Waran
              </div>
              <div class="mw75 mx-auto mw95768">
                <table
                  style={{
                    border: "2px solid #0271bf",
                    verticalAlign: "middle",
                    tableLayout: "fixed",
                  }}
                  class="table f12r table-bordered textCenter monsterratMedium lh2 tableFaqWarrant mt-5  mb-5 pb-5 tableFaqWarrant3"
                >
                  <thead
                    class="white fw-500"
                    style={{ backgroundColor: "#0271bf" }}
                  >
                    <tr>
                      <th
                        class="py-3 fw-500"
                        style={{ width: "32.5%" }}
                        scope="col"
                      >
                        Waran Terstruktur <i>Call</i>
                      </th>
                      <th
                        class="py-3 fw-500"
                        style={{ width: "22.5" }}
                        scope="col"
                      >
                        Skenario 1
                      </th>
                      <th
                        class="py-3 fw-500"
                        style={{ width: "22.5" }}
                        scope="col"
                      >
                        Skenario 2
                      </th>
                      <th
                        class="py-3 fw-500"
                        style={{ width: "22.5" }}
                        scope="col"
                      >
                        Skenario 3
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    class="grey monsterratMedium f1r"
                    style={{ border: "2px solid #0271bf" }}
                  >
                    <tr style={{ border: "2px solid #0271bf" }}>
                      <td class="text-left px-4 py-3">Harga Penyelesaian</td>
                      <td class="text-left px-4 py-3">Rp 12,000</td>
                      <td class="text-left px-4 py-3">Rp 11,000</td>
                      <td class="text-left px-4  py-3 f09r">Rp 10,000</td>
                    </tr>
                    <tr style={{ border: "2px solid #0271bf" }}>
                      <td class="text-left px-4 py-3">Harga Pelaksanaan</td>
                      <td class="text-left px-4 py-3">Rp 11,000</td>
                      <td class="text-left px-4 py-3">Rp 11,000</td>
                      <td class="text-left px-4  py-3 f09r">Rp 11,000</td>
                    </tr>
                    <tr style={{ border: "2px solid #0271bf" }}>
                      <td class="text-left px-4 py-3">
                        Nilai Waran Terstruktur
                      </td>
                      <td class="text-left px-4 py-3">
                        <i>In-The-Money</i>
                      </td>
                      <td class="text-left px-4 py-3">
                        <i>At-The-Money</i>
                      </td>
                      <td class="text-left px-4  py-3 f09r">
                        <i>Out-The-Money</i>
                      </td>
                    </tr>
                    <tr style={{ border: "2px solid #0271bf" }}>
                      <td class="text-left px-4 py-3">
                        Penerimaan pembayaran (Penyelesaian tunai)
                      </td>
                      <td class="text-left px-4 py-3">
                        Iya, mendapat penyelesaian tunai
                      </td>
                      <td class="text-left px-4 py-3">Tidak dapat</td>
                      <td class="text-left px-4  py-3 f09r">Tidak dapat</td>
                    </tr>
                  </tbody>
                </table>
                <div class="my-5 pb-5">
                  <button class=" monsterratMedium blue-btn rounded-pill flex  f1r px-4 mb-4">
                    Istilah dalam tabel Waran Terstruktur Call :
                  </button>

                  <div class="flex monsterratMedium f1r grey">
                    <div style={{ paddingRight: "5%" }}>
                      <div class="nowrap text-left py-1">
                        <b>
                          <i>In-The-Money</i>
                        </b>
                      </div>
                      <div class="nowrap text-left py-1">
                        <b>
                          <i>At-The-Money</i>
                        </b>
                      </div>
                      <div class="nowrap text-left py-1">
                        <b>
                          <i>Out-The-Money</i>
                        </b>{" "}
                      </div>
                    </div>
                    <div>
                      <div class="nowrap text-left py-1">
                        =&nbsp;&nbsp; Harga Penyelesaian {">"} Harga Pelaksanaan
                      </div>
                      <div class="nowrap text-left py-1">
                        =&nbsp;&nbsp; Harga Penyelesaian = Harga Pelaksanaan
                      </div>
                      <div class="nowrap text-left py-1">
                        =&nbsp;&nbsp; Harga Penyelesaian {"<"} Harga Pelaksanaan
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ maxWidth: 600, textAlign: "left" }}
                    class="pt-3 monsterratMedium f1r grey lh2"
                  >
                    Waran Terstruktur akan dilaksanakan (exercise) secara tunai
                    dan otomatis pada akhir periodenya, dimana KPEI adalah pihak
                    yang akan melakukan perhitungan dan KSEI sebagai pihak yang
                    akan mendistribusikan nilai tunainya jika posisinya{" "}
                    <b>
                      <i>In-The-Money</i>
                    </b>
                    .
                  </div>
                </div>
                <table
                  style={{
                    border: "2px solid #0271bf",
                    verticalAlign: "middle",
                    tableLayout: "fixed",
                  }}
                  class="table f12r table-bordered textCenter monsterratMedium lh2 tableFaqWarrant mt-5  mb-5 pb-5 tableFaqWarrant3"
                >
                  <thead
                    class="white fw-500"
                    style={{ backgroundColor: "#0271bf" }}
                  >
                    <tr>
                      <th
                        class="py-3 fw-500"
                        style={{ width: "32.5%" }}
                        scope="col"
                      >
                        Waran Terstruktur <i>Put</i>
                      </th>
                      <th
                        class="py-3 fw-500"
                        style={{ width: "22.5" }}
                        scope="col"
                      >
                        Skenario 1
                      </th>
                      <th
                        class="py-3 fw-500"
                        style={{ width: "22.5" }}
                        scope="col"
                      >
                        Skenario 2
                      </th>
                      <th
                        class="py-3 fw-500"
                        style={{ width: "22.5" }}
                        scope="col"
                      >
                        Skenario 3
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    class="grey monsterratMedium f1r"
                    style={{ border: "2px solid #0271bf" }}
                  >
                    <tr style={{ border: "2px solid #0271bf" }}>
                      <td class="text-left px-4 py-3">Harga Penyelesaian</td>
                      <td class="text-left px-4 py-3">Rp 12,000</td>
                      <td class="text-left px-4 py-3">Rp 11,000</td>
                      <td class="text-left px-4  py-3 f09r">Rp 10,000</td>
                    </tr>
                    <tr style={{ border: "2px solid #0271bf" }}>
                      <td class="text-left px-4 py-3">Harga Pelaksanaan</td>
                      <td class="text-left px-4 py-3">Rp 11,000</td>
                      <td class="text-left px-4 py-3">Rp 11,000</td>
                      <td class="text-left px-4  py-3 f09r">Rp 11,000</td>
                    </tr>
                    <tr style={{ border: "2px solid #0271bf" }}>
                      <td class="text-left px-4 py-3">
                        Nilai Waran Terstruktur
                      </td>
                      <td class="text-left px-4 py-3">
                        <i>Out-The-Money</i>
                      </td>
                      <td class="text-left px-4 py-3">
                        <i>At-The-Money</i>
                      </td>
                      <td class="text-left px-4  py-3 f09r">
                        <i>In-The-Money</i>
                      </td>
                    </tr>
                    <tr style={{ border: "2px solid #0271bf" }}>
                      <td class="text-left px-4 py-3">
                        Penerimaan pembayaran (Penyelesaian tunai)
                      </td>
                      <td class="text-left px-4 py-3">Tidak dapat</td>
                      <td class="text-left px-4 py-3">Tidak dapat</td>
                      <td class="text-left px-4  py-3 f09r">
                        {" "}
                        Iya, mendapat penyelesaian tunai
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="my-5 ">
                  <button class=" monsterratMedium blue-btn rounded-pill flex  f1r px-4 mb-4">
                    Istilah dalam tabel Waran Terstruktur Put :
                  </button>

                  <div class="flex monsterratMedium f1r grey">
                    <div style={{ paddingRight: "5%" }}>
                      <div class="nowrap text-left py-1">
                        <b>
                          <i>In-The-Money</i>
                        </b>
                      </div>
                      <div class="nowrap text-left py-1">
                        <b>
                          <i>At-The-Money</i>
                        </b>
                      </div>
                      <div class="nowrap text-left py-1">
                        <b>
                          <i>Out-The-Money</i>
                        </b>{" "}
                      </div>
                    </div>
                    <div>
                      <div class="nowrap text-left py-1">
                        =&nbsp;&nbsp; Harga Penyelesaian {"<"} Harga Pelaksanaan
                      </div>
                      <div class="nowrap text-left py-1">
                        =&nbsp;&nbsp; Harga Penyelesaian = Harga Pelaksanaan
                      </div>
                      <div class="nowrap text-left py-1">
                        =&nbsp;&nbsp; Harga Penyelesaian {">"} Harga Pelaksanaan
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class=" monsterratExtraBold   mx-auto  f275r  blue-text-1 mw90768">
            Penyelesaian Akhir atau Proses Pelakasanaan
          </div>

          <div class="monsterratMedium grey mx-auto pt-4 f1r mw75 mw90768">
            <b>Harga penyelesaian</b> ditentukan dari rata-rata harga penutupan
            5 hari perdagangan saham dasar atau dengan contoh sebelumnya yaitu
            saham BBCA. Dalam hal ini, rata-rata harga penutupan saham dasar
            yaitu pada periode 19 September - 26 September.
          </div>
          <div class="mw60 mw95768 mx-auto mt-5">
            <img class="w100" src={swCalendar} />
            <div class="f1r monsterratMedium grey mt-5 pt-3">
              <b>Waktu Pembayaran</b>, pembayaran akan dilakukan melalui KSEI
              (Kustodian Sentral Efek Indonesia) dalam waktu tiga (3) hari bursa
              setelah tanggal kedaluarsa.
            </div>
          </div>
        </div>
      </>
    );
  };
  const Q4 = () => {
    return (
      <div
        class="disclaimerPageContainer py-4"
        style={{ marginBottom: "6rem" }}
      >
        <div class="textCenter   pb-3 ">
          {/* <div class="monsterratBold grey f2r"></div> */}
          <div class="produkMobileContainer monsterratExtraBold mt-3 mx-auto  f275r  blue-text-1 mw90768">
            Pengertian dalam Waran Terstruktur{" "}
          </div>

          <div class="monsterratMedium grey jenisAkunText  f1r  mw90768">
            Ringkasan istilah dalam Waran Terstruktur{" "}
          </div>
        </div>
        <div class="disclaimerContainer rounded-corner bg-biru-muda px-5 py-4 mx-auto mt-5  grey monsterratMedium f1r">
          <br></br>
          <div class="eIpoText">
            <ul>
              <li>
                <b>
                  <i>Effective gearing</i>
                </b>
                , berarti perkiraan persentase perubahan harga waran terstruktur
                relatif terhadap perubahan 1% harga saham dasar.
              </li>
              <br></br>
              <li>
                <b>
                  <i>Ex-Date</i>
                </b>
                , berarti tanggal perdagangan pada (dan setelahnya) di mana hak
                atas suatu aksi korporasi tidak akan didapat oleh pembeli baru
                saham.
              </li>
              <br></br>
              <li>
                <b>Fraksi Harga</b>, berarti satuan perubahan harga yang
                digunakan dalam melakukan penawaran jual atau permintaan beli.
              </li>
              <br></br>
              <li>
                <b>
                  <i>Gearing</i>
                </b>
                , berarti suatu rasio yang mengukur eksposur terhadap Underlying
                Waran Terstruktur di mana eksposur tersebut diperoleh dengan
                membeli 1 (satu) Waran Terstruktur Jenis Call atau Put.
              </li>
              <br></br>
              <li>
                <b>Harga Pelaksanaan</b>, berarti harga penukaran Waran
                Terstruktur dengan Underlying Waran Terstruktur dan/atau dana
                pada Tanggal Pelaksanaan.
              </li>
              <br></br>
              <li>
                <b>Harga Penutupan</b>, harga yang terbentuk pada saat sesi
                pra-penutupan atau harga perdagangan terakhir jika tidak
                terdapat harga yang terbentuk pada saat sesi pra-penutupan.
              </li>
              <br></br>
              <li>
                <b>Harga Penyelesaian</b>, berarti harga yang menjadi acuan pada
                saat pelaksanaan hak Waran Terstruktur.
              </li>
              <br></br>
              <li>
                <b>Masa Berlaku</b>, berarti periode di mana Waran Terstruktur
                dapat diperdagangkan di pasar sekunder sampai dengan Tanggal
                Pelaksanaan.
              </li>
              <br></br>

              <li>
                <b>Masa Penawaran</b>, berarti jangka waktu bagi Masyarakat
                untuk dapat mengajukan pemesanan Waran Terstruktur sebagaimana
                diatur dalam Prospektus dan Formulir Pemesanan Pembelian Waran
                Terstruktur.
              </li>
              <br></br>
              <li>
                <b>
                  <i>Underlying</i> Waran Terstruktur
                </b>
                , berarti Efek yang menjadi dasar diterbitkannya Waran
                Terstruktur sebagaimana diatur dalam Pasal 1 angka 7 POJK No.
                8/2021.
              </li>
              <br></br>
              <li>
                <b>
                  Waran Terstruktur Jenis <i>Call (Call Warrant)</i>
                </b>
                , berarti Efek yang memberikan hak kepada pemegangnya untuk
                membeli Underlying Waran Terstruktur pada Harga Pelaksanaan dan
                Tanggal Pelaksanaan yang telah ditetapkan pada awal penerbitan.
              </li>
              <br></br>
              <li>
                <b>Nilai Intrinsik</b> adalah selisih antara Harga Waran dan
                Nilai Waktu serta Waran Terstruktur yang statusnya
                ITM(in-the-money) yang memiliki nilai intrinsik.
              </li>
              <br></br>
              <li>
                <b>Nilai Waktu</b> adalah selisih antara Harga Waran dengan
                Nilai Intrinsik.
              </li>
              <br></br>
            </ul>
          </div>
        </div>
        {/* <div class="syariahButtonWrapper my-5 pt-5">
        <div class="f25r  mb-4 blue-text-1 monsterratExtraBold text-center">
        Manual Book
        </div>
        <div class="monsterratMedium grey block1000 f1r flex text-center pt-1">
          <div class="flex mx-auto block1000">
     
            <div>
            <button
                class="syariahPdfButton appendixPdfButton grey f1r px-4 mx-2"
                onClick={() => openInNewTab(manualPendaftaranEIpo)}
              >
                Manual Book Pendaftaran e-IPO
              </button>
              <button
                class="syariahPdfButton appendixPdfButton grey f1r px-4 mx-2"
                onClick={() => openInNewTab(manual_order_e_ipo)}
              >
                Manual Book Order e-IPO
              </button>
       
            </div>
          </div>
        </div>
      </div> */}
      </div>
    );
  };

  const Q5 = () => {
    return (
      <div
        class="disclaimerPageContainer py-4"
        style={{ marginBottom: "6rem" }}
      >
        <div class="textCenter   pb-3 ">
          {/* <div class="monsterratBold grey f2r"></div> */}
          <div class="produkMobileContainer monsterratExtraBold mt-3 mx-auto  f275r  blue-text-1 mw90768">
            Risiko Waran Terstruktur
          </div>

          <div class="monsterratMedium grey jenisAkunText  f1r  mw90768">
            Risiko yang dialami oleh Waran Tersturktur
          </div>
        </div>
        <div class="disclaimerContainer rounded-corner bg-biru-muda px-5 py-4 mx-auto mt-5  grey monsterratMedium f1r">
          <br></br>
          <div class="eIpoText">
            <ul>
              <li>
                <b>Risiko Perubahan Harga Saham Dasar</b>, jika dalam kondisi
                tertentu terdapat katalis negatif atau positif terkait suatu
                Emiten maka nilai dari Saham akan terpengaruh sehingga nilai
                dari Waran Terstruktur ikut berubah.
              </li>
              <br></br>
              <li>
                <b>Risiko Efek Pengungkit</b>, dengan adanya fitur ini juga maka
                investor dapat mengalami penurunan yang berlipat ganda jika
                Saham Dasar dan Waran Terstruktur tidak bergerak sesuai yang
                diharapkan.
              </li>
              <br></br>
              <li>
                <b>Risiko Kredit</b>, yaitu risiko kerugian yang timbul dari
                kegagalan penerbit dalam memenuhi kewajiban keuangan dan
                kontraktual sesuai dengan ketentuan yang telah disepakati.
              </li>
              <br></br>
              <li>
                <b>Memiliki Masa Waktu</b>, Waran Terstruktur sendiri memiliki
                tanggal kedaluarsa. Semakin dekat tanggal kedaluarsa, maka
                nilainya semakin berkurang. Kondisi tersebut dikenal dengan
                istilah Peluruhan Waktu atau Time Decay of Option.
              </li>
              <br></br>
              <li>
                <b>Risiko Delisting</b>, semisal saham dasarnya dihapus dari
                bursa (delisting), maka investor bisa saja kehilangan nilai
                waktu yang tersisa. Maka dari itu Bursa Efek Indonesia
                menentukan hanya saham yang masuk kedalam Index IDX30 yang dapat
                dijadikan saham dasar.
              </li>
              <br></br>
            </ul>
          </div>
        </div>
        {/* <div class="syariahButtonWrapper my-5 pt-5">
        <div class="f25r  mb-4 blue-text-1 monsterratExtraBold text-center">
        Manual Book
        </div>
        <div class="monsterratMedium grey block1000 f1r flex text-center pt-1">
          <div class="flex mx-auto block1000">
     
            <div>
            <button
                class="syariahPdfButton appendixPdfButton grey f1r px-4 mx-2"
                onClick={() => openInNewTab(manualPendaftaranEIpo)}
              >
                Manual Book Pendaftaran e-IPO
              </button>
              <button
                class="syariahPdfButton appendixPdfButton grey f1r px-4 mx-2"
                onClick={() => openInNewTab(manual_order_e_ipo)}
              >
                Manual Book Order e-IPO
              </button>
       
            </div>
          </div>
        </div>
      </div> */}
      </div>
    );
  };
  const Q6 = () => {
    return (
      <div
        class="disclaimerPageContainer py-4"
        style={{ marginBottom: "6rem" }}
      >
        <div class="textCenter   pb-3 ">
          {/* <div class="monsterratBold grey f2r"></div> */}
          <div class="produkMobileContainer monsterratExtraBold mt-3 mx-auto  f275r  blue-text-1 mw90768">
            Regulasi Waran Tersruktur{" "}
          </div>

          <div class="monsterratMedium grey jenisAkunText  f1r  mw90768">
            Peraturan terkait Waran Terstruktur di Indonesia{" "}
          </div>
        </div>
        <div class="disclaimerContainer rounded-corner bg-biru-muda px-5 py-4 mx-auto mt-5  grey monsterratMedium f1r">
          <br></br>
          <div class="eIpoText">
            <ul>
              <li>
                Dari Otoritas Jasa Keuangan terakait Waran Terstruktur yaitu
                Peraturan Otoritas Jasa Keuangan Nomor 8/POJK.04/2021 Tentang
                Waran Terstruktur.
                <div class="my-2"></div>
                <span>
                  <a
                    style={{ color: "#0071bf" }}
                    onClick={(e) => {
                      e.preventDefault();
                      openInNewTab(
                        "https://www.ojk.go.id/id/regulasi/Documents/Pages/Waran-Terstruktur/pojk%208-2021.pdf"
                      );
                    }}
                    href="https://www.ojk.go.id/id/regulasi/Documents/Pages/Waran-Terstruktur/pojk%208-2021.pdf"
                  >
                    https://www.ojk.go.id/id/regulasi/Documents/Pages/Waran-Terstruktur/pojk%208-2021.pdf
                  </a>
                </span>
              </li>
              <br></br>
              <li>
                Keputusan Direksi PT Bursa Efek Indonesia Nomor
                Kep-00018/BEI/04-2022 tanggal 11 April 2022 tentang Pencatatan
                Waran Terstruktur Di Bursa.
                <div class="my-2"></div>
                <span>
                  <a
                    style={{ color: "#0071bf" }}
                    onClick={(e) => {
                      e.preventDefault();
                      openInNewTab(
                        "https://www.idx.co.id/Media/10972/peraturan_i_p_pencatatan_waran_terstruktur_di_bursa-2.pdf"
                      );
                    }}
                    href="https://www.idx.co.id/Media/10972/peraturan_i_p_pencatatan_waran_terstruktur_di_bursa-2.pdf"
                  >
                    https://www.idx.co.id/Media/10972/peraturan_i_p_pencatatan_waran_terstruktur_di_bursa-2.pdf
                  </a>
                </span>
              </li>
              <br></br>
              <li>
                Keputusan Direksi PT Bursa Efek Indonesia Nomor
                Kep-00019/BEI/04-2022 tanggal 11 April 2022 tentang Perdagangan
                Waran Terstruktur Di Bursa
                <div class="my-2"></div>
                <span>
                  <a
                    style={{ color: "#0071bf" }}
                    onClick={(e) => {
                      e.preventDefault();
                      openInNewTab(
                        "https://www.idx.co.id/media/20221047/rule_ii-p_trading_of_structured_warrants_on_the_exchange.pdf"
                      );
                    }}
                    href="https://www.idx.co.id/media/20221047/rule_ii-p_trading_of_structured_warrants_on_the_exchange.pdf"
                  >
                    https://www.idx.co.id/media/20221047/rule_ii-p_trading_of_structured_warrants_on_the_exchange.pdf
                  </a>
                </span>
              </li>
              <br></br>
              <li>
                Keputusan Direksi PT Bursa Efek Indonesia Nomor
                Kep-00286/BEI/08-2023 tanggal 28 Agustus 2023 tentang Pencatatan
                Waran Terstruktur Tambahan untuk Memfasilitasi Aktivitas
                Liquidity Provider Waran Terstruktur.
                <div class="my-2"></div>
                <span>
                  <a
                    style={{ color: "#0071bf" }}
                    onClick={(e) => {
                      e.preventDefault();
                      openInNewTab(
                        "https://www.idx.co.id/Media/2hboagk5/sk_pencatatan_waran_terstruktur_tambahan_untuk_memfasiltiasi_lp.pdf"
                      );
                    }}
                    href="https://www.idx.co.id/Media/2hboagk5/sk_pencatatan_waran_terstruktur_tambahan_untuk_memfasiltiasi_lp.pdf"
                  >
                    https://www.idx.co.id/Media/2hboagk5/sk_pencatatan_waran_terstruktur_tambahan_untuk_memfasiltiasi_lp.pdf
                  </a>
                </span>
              </li>
              <br></br>
              <li>
                Keputusan Bersama Direksi PT Bursa Efek Indonesia, PT Kliring
                Penjaminan Efek Indonesia, dan PT Kustodian Sentral Efek
                Indonesia tentang Kebijakan Biaya Bagi Liquidity Provider Waran
                Terstruktur.
                <div class="my-2"></div>
                <span>
                  <a
                    style={{ color: "#0071bf" }}
                    onClick={(e) => {
                      e.preventDefault();
                      openInNewTab(
                        "https://www.idx.co.id/Media/uvwmce5t/sk_kebijakan_biaya_bagi_liquidity_provider_waran_terstruktur.pdf"
                      );
                    }}
                    href="https://www.idx.co.id/Media/uvwmce5t/sk_kebijakan_biaya_bagi_liquidity_provider_waran_terstruktur.pdf"
                  >
                    https://www.idx.co.id/Media/uvwmce5t/sk_kebijakan_biaya_bagi_liquidity_provider_waran_terstruktur.pdf
                  </a>
                </span>
              </li>
              <br></br>
            </ul>
          </div>
        </div>
        {/* <div class="syariahButtonWrapper my-5 pt-5">
        <div class="f25r  mb-4 blue-text-1 monsterratExtraBold text-center">
        Manual Book
        </div>
        <div class="monsterratMedium grey block1000 f1r flex text-center pt-1">
          <div class="flex mx-auto block1000">
     
            <div>
            <button
                class="syariahPdfButton appendixPdfButton grey f1r px-4 mx-2"
                onClick={() => openInNewTab(manualPendaftaranEIpo)}
              >
                Manual Book Pendaftaran e-IPO
              </button>
              <button
                class="syariahPdfButton appendixPdfButton grey f1r px-4 mx-2"
                onClick={() => openInNewTab(manual_order_e_ipo)}
              >
                Manual Book Order e-IPO
              </button>
       
            </div>
          </div>
        </div>
      </div> */}
      </div>
    );
  };
  return (
    <>
      <button
        id="faqBackButton"
        class=" monsterratMedium blue-btn rounded-pill flex  f1r px-4 mb-3 center-vertical "
        onClick={(e) => {
          navigate("/produk/waran-terstruktur");
        }}
      >
        <img src={faqArrow} class="faqBackArrow center-vertical"></img>Back
      </button>
      <div style={{ marginBottom: "5rem" }}>{faqSwitcher()}</div>
    </>
  );
}

function BackButton() {
  const [offset, setOffset] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    // console.log(page.substring(1, page.length), page);
    // document.getElementById(page).className +=
    //   " subPathActive";
    console.log(offset, document.documentElement.offsetHeight);

    // if (offset >= document.documentElement.offsetHeight - 1300) {
    //   document.getElementById("faqBackButton").style.position = "static";
    // } else {
    //   document.getElementById("faqBackButton").style.position = "sticky";
    // }
    // if (offset > 0) {
    //   document.getElementById("faqBackButton").style.top = "12rem";

    // }
    // if (offset == 0) {
    //   if (window.innerWidth > 768) {
    //     document.getElementById("faqBackButton").style.top = "17rem";
    //   }

    // }

    // if (offset >= document.documentElement.offsetHeight - 1900) {
    //   document.getElementById("faqBackButton").style.position = "block";
    // } else {
    //   document.getElementById("faqBackButton").style.position = "fixed";
    // }

    // if (offset >= document.documentElement.offsetHeight - 1000) {
    //   document.getElementById("faqBackButton").style.top = "130px";
    // } else {
    //   document.getElementById("faqBackButton").style.top = "315px";
    // }
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, [offset]);
  return (
    <>
      <button
        id="faqBackButton"
        class=" monsterratMedium blue-btn rounded-pill flex  f1r px-4 mb-3 "
        onClick={(e) => {
          navigate("/produk/waran-terstruktur");
        }}
      >
        Keterangan :
      </button>
    </>
  );
}
