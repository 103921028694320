import "./style.css";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setActiveMenu, setArticlePage, setRnaMenu } from "../../store/action";
export default function BlogMenu(props) {
  const { page } = useParams();
  const [offset, setOffset] = useState(0);
  const rnaMenu = useSelector((state) => state.rnaMenu);
  const dispatch = useDispatch();
  // const [rnaMenu,setRnaMenu] =  useState('daily')
  useEffect(() => {
    // if (page !== undefined && page !== "") {
    // console.log(document.getElementsByClassName("subPath"));
    //   let test;
    //   // console.log(document.getElementById(page), test);
    //   test = document.getElementById(page);
    //   document.getElementById(page).className += " subPathActive";
    // }
    if (page == "artikel" || page == "rna" || page == "promo") {
      let allSubPath = document.getElementsByClassName("subPath");
      for (let i = 0; i < allSubPath.length; i++) {
        const element = allSubPath[i];
        // console.log(element.className);
        if (element.className !== page) {
          element.className = "subPath monsterratMedium rounded-pill mx-4 ";
        }
      }
      document.getElementById(page).className += " subPathActive";
    }

    if (page == "rna") {
      // console.log(document.getElementsByClassName("rnaSubPath"));
      let allSubPath = document.getElementsByClassName("rnaSubPath");
      for (let i = 0; i < allSubPath.length; i++) {
        const element = allSubPath[i];
        // console.log(element.className);
        element.className = "subPath monsterratMedium rounded-pill mx-4 ";
      }
      document.getElementById(rnaMenu).className += " subPathActive";
    }
    // console.log(        document.getElementById("rnaSubPath"));
    // document.getElementById('syariah').className =
    //   "subPath monsterratMedium rounded-pill ";
    if (offset > 0) {
      document.getElementById("subPath").style.paddingTop = "0rem";
      document.getElementById("subPath").style.paddingBottom = "1rem";
      document.getElementById("navBar").style.boxShadow = "none";
      document.getElementById("subPath").style.boxShadow =
        "0px 4px 14px rgb(0 0 0 / 7%)";
      // document.getElementById("rnaPath").style.paddingTop = "1.6rem";
      // if(document.getElementById("rnaSubPath")        ){
      //   document.getElementById("rnaSubPath").style.paddingTop = "1.5rem";
      // }
      // document.getElementById('blogSpacer').style.height="100px"
    }
    if (offset == 0) {
      if (window.innerWidth > 768) {
        document.getElementById("subPath").style.paddingTop = "5rem";
      }
      document.getElementById("subPath").style.boxShadow = "none";
      document.getElementById("navBar").style.boxShadow =
        "0px 4px 14px rgb(0 0 0 / 7%)";
      document.getElementById("subPath").style.paddingBottom = "0rem";
      if (document.getElementById("rnaPath")) {
        document.getElementById("rnaPath").style.paddingTop = "0rem";
      }

      // document.getElementById('blogSpacer').style.height="60px"
      if (document.getElementById("rnaSubPath")) {
        document.getElementById("rnaSubPath").style.paddingTop = "0rem";
      }
    }
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, [page, offset, rnaMenu]);
  // console.log(offset);
  const navigate = useNavigate();

  return (
    <div>
      <div class="pageLocationWrapper ">
        {props.leftPath ? (
          <div class="path monsterratMedium mt-3 px-4 mx-5">
            <div
              class="leftSide"
              onClick={() => {
                navigate(props.leftPath);
              }}
            >
              {props.left}
            </div>
            <div class="divider" style={{ fontWeight: "900" }}>
              &nbsp;&nbsp;|&nbsp;&nbsp;
            </div>
            <div class="rightSide">{props.right}</div>
          </div>
        ) : null}
      </div>
      <div id="subPath" class="subPathWrapper  fixed-top">
        <div class="subPathContainer blogSubPath justify-content-center">
          <div
            class="subPath mx-4 monsterratMedium rounded-pill "
            id="promo"
            onClick={() => navigate("/blog/promo")}
          >
            Promo
          </div>
          {props.from == "artikelById" ? (
            <div
              class="subPath mx-4 monsterratMedium rounded-pill "
              id="artikel"
              onClick={() =>
                navigate("/blog/artikel", { state: { from: "artikelById" } })
              }
            >
              Berita Pasar
            </div>
          ) : (
            <div
              class="subPath mx-4 monsterratMedium rounded-pill "
              id="artikel"
              onClick={() => navigate("/blog/artikel")}
            >
              Berita Pasar
            </div>
          )}

          <div
            class="subPath mx-2 monsterratMedium rounded-pill "
            id="rna"
            onClick={() => navigate("/blog/rna")}
          >
            Riset dan Analisis
          </div>
        </div>
      </div>
      <div id="blogSpacer" className="subPathSpacer"></div>
    </div>
  );
}
