import { useNavigate } from "react-router-dom";
import "./style.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getNews,
  setSortedNews,
  getNewsById,
  setNewsDetail,
} from "../../../store/action";
import moment from "moment";
import faqArrow from "../../../assets/faqArrow.png";
import BlogMenu from "../../../components/BlogMenu";
import loading from "../../../assets/loading.gif";
import { useParams, useLocation } from "react-router-dom";
import fbLogo from "../../../assets/fb.ico";
import instagramLogo from "../../../assets/instagram.ico";
import twitterLogo from "../../../assets/twitter.ico";
import linkedinLogo from "../../../assets/linkedin.ico";
import tiktokLogo from "../../../assets/tiktok.ico";
import { server, storageLocation } from "../../../config/server";
export default function ArtikelById() {
  const { state } = useLocation();
  let from = "";
  console.log(state);
  if (state) {
    from = state.from;
  }
  let { id, category } = useParams();
  const navigate = useNavigate();
  const allowedExtensions = ["jpg", "png", "jpeg","gif"];
  const dispatch = useDispatch();
  const sortedNews = useSelector((state) => state.sortedNews);
  const [news, setNews] = useState({});
  const [imageLoaded, setImageLoaded] = useState(false);
  // const news = useSelector((state) => state.news);
  // const newsDetail = useSelector((state) => state.newsDetail);
  const [localNewsDetail, setLocalNewsDetail] = useState({});
  // const newsDetail = useSelector((state) => state.newsDetail);
  useEffect(() => {
    window.scrollTo(0, 1);
    console.log(id, "xyz", category);
    getData();
    // document.getElementById("navBar").style.boxShadow =
    // "0px 4px 14px rgb(0 0 0 / 7%)";
    const selected = dummyPemberitahuan.filter((item) => item.id == id);
    if (selected.length > 0) {
      setNews(selected[0]);
    }
  }, [id]);

  const getData = () => {
    fetch(`${server}/api/v2/swari/shout-out/1/${category}/${id}`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      // body: JSON.stringify(file),
      headers: {
        "Content-Type": "application/json",
        // access_token: localStorage.getItem("access_token"),
      },
    })
      .then((res) => res.json())
      .then(async (data) => {
        console.log(data, "promox");
        if (data.status) {
          setNews(data.data[0]);
        } else {
          throw data;
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };

  function openInNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }
  const loadPage = () => {
    dispatch(setNewsDetail({}));

    if (sortedNews.length === 0) {
      // console.log("masuk if no news");
      dispatch(getNews())
        .then((news) => {
          var sortedNews = [].concat(news);
          sortedNews.sort((a, b) => {
            return b.views - a.views;
          });
          dispatch(setSortedNews(sortedNews));
        })
        .catch((error) => {
          console.log(error);
        });
    }
    let isArticleAvailable = false;
    // for (let i = 0; i < news.length; i++) {
    //   if (news[i].id === Number(id)) {
    //     dispatch(setNewsDetail(news[i]));
    //     setLocalNewsDetail(news[i]);
    //   }
    // }
  };

  const goToArticle = (id) => {
    console.log("masuk");

    loadPage();
    navigate(`/artikel/${id}`);
  };
  const dummyPemberitahuan = [
    {
      date: "05-11-2024",
      id: 1,
      title:
        "Informasi Penerbitan Waran Terstruktur di Bursa Efek Indonesia pada tanggal 26 Februari 2024",
      content: `<div data-v-06b38075="" class="col-md pl-md-2 mt-1 p-0 shout-right-bar"><div data-v-06b38075="" class="functionBoxmax" style="padding: 10px 0px;"</div> <div data-v-06b38075=""><div data-v-06b38075="" id="rightText" class="font-size14 footer-h font-weight-bold">Informasi Penerbitan  Waran Terstruktur di Bursa Efek Indonesia pada tanggal 26 Februari 2024</div> <div data-v-06b38075="" id="rightContent" class="mt-5 shout-text"><p>Kami informasikan bahwa berikut ini adalah detail penerbitan Waran Terstruktur ke-18 yang dicatatkan di Bursa Efek Indonesia pada tanggal 26 Februari 2024&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;</p>
      
      <p>Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;ADRODRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 28/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 2650/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;500,000,000<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;AMRTDRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 50/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 2600/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;500,000,000<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;ANTMDRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 24/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 1500/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;500,000,000<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;ASIIDRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 33/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 5500/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;500,000,000<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;BBCADRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 95/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 9500/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;200,000,000<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;BBNIDRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 58/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 6100/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;200,000,000<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;BUKADRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 15/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 160/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;1,000,000,000<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;INDFDRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 31/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 6500/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; :&nbsp; &nbsp; &nbsp;500,000,000<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;ITMGDRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 90/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 28000/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;200,000,000<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;KLBFDRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 38/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 1500/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;500,000,000<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;MEDCDRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 25/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 1350/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;500,000,000<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;PGASDRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 19/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 1400/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;500,000,000<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;UNVRDRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 36/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 2800/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;300,000,000<br>
      &nbsp;</p>
      </div></div></div>`,
      category: "penerbitan",
    },
    {
      date: "05-11-2024",
      id: 2,
      content: `<div data-v-06b38075="" id="rightContent" class="mt-5 shout-text"><p>Berikut adalah pengumuman untuk waran terstruktur AKRADRCH4A, ARTODRCH4A,BBRIDRCH4A, BMRIDRCH4A, BRPTDRCH4A, CPINDRCH4A, EMTKDRCH4A, GOTODRCH4A, HRUMDRCH4A, INCODRCH4A, MDKADRCH4A, PTBADRCH4A, SMGRDRCH4A, TLKMDRCH4A dan UNTRDRCH4A mengenai periode perdagangan terbaru bertepatan dengan Cuti Bersama Hari Suci Nyepi Tahun Baru Saka 1946.</p>

      <p>Periode perdagangan&nbsp;</p>
      
      <table border="1" cellpadding="1" cellspacing="0" style="width: 500px; border-collapse: collapse; border-spacing: 0px;">
        <tbody>
          <tr>
            <td style="padding: 1px; text-align: center;">Kegiatan</td>
            <td style="padding: 1px; text-align: center;">Jadwal Awal</td>
            <td style="padding: 1px; text-align: center;">Jadwal Perubahan</td>
          </tr>
          <tr>
            <td style="padding: 1px; text-align: center;">Periode Perdagangan</td>
            <td style="padding: 1px; text-align: center;">14 Nov 2023 - 11 Mar 2024</td>
            <td style="padding: 1px; text-align: center;">14 Nov 2023 - 7 Mar 2024</td>
          </tr>
        </tbody>
      </table>
      
      <p>&nbsp;</p>
      
      <p>Term Sheet Terbaru&nbsp;<a href="https://www.warrantindonesia.co.id/id/Document" target="_blank">https://www.warrantindonesia.co.id/id/Document</a></p>
      
      <p>&nbsp;</p>
      </div>`,
      title:
        "Perubahan Tanggal Perdagangan Terakhir pada Waran Terstruktur Penerbitan ke-15",
      category: "perubahan",
    },
    {
      date: "05-11-2024",
      id: 3,
      content: `<div data-v-06b38075="" id="rightContent" class="mt-5 shout-text"><p>Berikut adalah pengumuman untuk waran terstruktur AKRADRCH4A, ARTODRCH4A,BBRIDRCH4A, BMRIDRCH4A, BRPTDRCH4A, CPINDRCH4A, EMTKDRCH4A, GOTODRCH4A, HRUMDRCH4A, INCODRCH4A, MDKADRCH4A, PTBADRCH4A, SMGRDRCH4A, TLKMDRCH4A dan UNTRDRCH4A mengenai periode perdagangan terbaru bertepatan dengan Cuti Bersama Hari Suci Nyepi Tahun Baru Saka 1946.</p>

      <p>Periode perdagangan&nbsp;</p>
      
      <table border="1" cellpadding="1" cellspacing="0" style="width: 500px; border-collapse: collapse; border-spacing: 0px;">
        <tbody>
          <tr>
            <td style="padding: 1px; text-align: center;">Kegiatan</td>
            <td style="padding: 1px; text-align: center;">Jadwal Awal</td>
            <td style="padding: 1px; text-align: center;">Jadwal Perubahan</td>
          </tr>
          <tr>
            <td style="padding: 1px; text-align: center;">Periode Perdagangan</td>
            <td style="padding: 1px; text-align: center;">14 Nov 2023 - 11 Mar 2024</td>
            <td style="padding: 1px; text-align: center;">14 Nov 2023 - 7 Mar 2024</td>
          </tr>
        </tbody>
      </table>
      
      <p>&nbsp;</p>
      
      <p>Term Sheet Terbaru&nbsp;<a href="https://www.warrantindonesia.co.id/id/Document" target="_blank">https://www.warrantindonesia.co.id/id/Document</a></p>
      
      <p>&nbsp;</p>
      </div>`,
      title:
        "Tanggal Kadaluwarsa Perdagangan Terakhir pada Waran Terstruktur Penerbitan ke-15",
      category: "kadaluwarsa",
    },
    {
      date: "05-11-2024",
      id: 4,
      content: `<div data-v-06b38075="" id="rightContent" class="mt-5 shout-text"><p>Berikut adalah pengumuman untuk waran terstruktur AKRADRCH4A, ARTODRCH4A,BBRIDRCH4A, BMRIDRCH4A, BRPTDRCH4A, CPINDRCH4A, EMTKDRCH4A, GOTODRCH4A, HRUMDRCH4A, INCODRCH4A, MDKADRCH4A, PTBADRCH4A, SMGRDRCH4A, TLKMDRCH4A dan UNTRDRCH4A mengenai periode perdagangan terbaru bertepatan dengan Cuti Bersama Hari Suci Nyepi Tahun Baru Saka 1946.</p>

      <p>Periode perdagangan&nbsp;</p>
      
      <table border="1" cellpadding="1" cellspacing="0" style="width: 500px; border-collapse: collapse; border-spacing: 0px;">
        <tbody>
          <tr>
            <td style="padding: 1px; text-align: center;">Kegiatan</td>
            <td style="padding: 1px; text-align: center;">Jadwal Awal</td>
            <td style="padding: 1px; text-align: center;">Jadwal Perubahan</td>
          </tr>
          <tr>
            <td style="padding: 1px; text-align: center;">Periode Perdagangan</td>
            <td style="padding: 1px; text-align: center;">14 Nov 2023 - 11 Mar 2024</td>
            <td style="padding: 1px; text-align: center;">14 Nov 2023 - 7 Mar 2024</td>
          </tr>
        </tbody>
      </table>
      
      <p>&nbsp;</p>
      
      <p>Term Sheet Terbaru&nbsp;<a href="https://www.warrantindonesia.co.id/id/Document" target="_blank">https://www.warrantindonesia.co.id/id/Document</a></p>
      
      <p>&nbsp;</p>
      </div>`,
      title:
        "Perubahan Tanggal Penyelesaian Perdagangan Terakhir pada Waran Terstruktur Penerbitan ke-15",
      category: "penyelesaian",
    },
    {
      date: "05-11-2024",
      id: 5,
      content: `<div data-v-06b38075="" id="rightContent" class="mt-5 shout-text"><p>Berikut adalah pengumuman untuk waran terstruktur AKRADRCH4A, ARTODRCH4A,BBRIDRCH4A, BMRIDRCH4A, BRPTDRCH4A, CPINDRCH4A, EMTKDRCH4A, GOTODRCH4A, HRUMDRCH4A, INCODRCH4A, MDKADRCH4A, PTBADRCH4A, SMGRDRCH4A, TLKMDRCH4A dan UNTRDRCH4A mengenai periode perdagangan terbaru bertepatan dengan Cuti Bersama Hari Suci Nyepi Tahun Baru Saka 1946.</p>

      <p>Periode perdagangan&nbsp;</p>
      
      <table border="1" cellpadding="1" cellspacing="0" style="width: 500px; border-collapse: collapse; border-spacing: 0px;">
        <tbody>
          <tr>
            <td style="padding: 1px; text-align: center;">Kegiatan</td>
            <td style="padding: 1px; text-align: center;">Jadwal Awal</td>
            <td style="padding: 1px; text-align: center;">Jadwal Perubahan</td>
          </tr>
          <tr>
            <td style="padding: 1px; text-align: center;">Periode Perdagangan</td>
            <td style="padding: 1px; text-align: center;">14 Nov 2023 - 11 Mar 2024</td>
            <td style="padding: 1px; text-align: center;">14 Nov 2023 - 7 Mar 2024</td>
          </tr>
        </tbody>
      </table>
      
      <p>&nbsp;</p>
      
      <p>Term Sheet Terbaru&nbsp;<a href="https://www.warrantindonesia.co.id/id/Document" target="_blank">https://www.warrantindonesia.co.id/id/Document</a></p>
      
      <p>&nbsp;</p>
      </div>`,
      title: "Server Maintenance",
      category: "etc",
    },
  ];
  function isValidImageFormat(filename) {
    const regex = /\.(jpg|jpeg|png|gif)$/i;
    return regex.test(filename);
  }
  const MainArticle = () => {
    return (
      <div class="newsByIdDiv">
        {/* {JSON.stringify(news)} */}
        <div class="monsterratExtraBold blue-text-1 mb-5 f25r newsDetailTitle">
          {news.title}
        </div>
        {/* <div
            class="mainArticleContainer2 flex"
            style={{
              backgroundImage: `url('${server}${storageLocation}${news.file}')`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div style={{ alignSelf: "flex-end" }}>
              <div class="flex monsterratRegular white mainArticleSourceDate">
                <div>{news.published_at}</div>
              </div>
            </div>
          </div> */}
{/* {JSON.stringify(isValidImageFormat(news.file))}
{'sf'} */}
          {isValidImageFormat(news.file)? <div class="mw70 mw951000 mx-auto">
          <img
            onLoad={() => {
              setImageLoaded(true);
            }}
            class="w100  mb-5"
            src={`${server}${storageLocation}${news.file}`}
          ></img>
        </div>:null}
        {/* <div class="mw70 mw951000 mx-auto">
          <img
            onLoad={() => {
              setImageLoaded(true);
            }}
            class="w100  mb-5"
            src={`${server}${storageLocation}${news.file}`}
          ></img>
        </div> */}

        {/* <div class="newsDetailSource monsterratRegular grey">
            By {localNewsDetail.source}
          </div> */}
      </div>
    );
    // } else {
    //   return (
    //     <div
    //       class="mainArticleContainer flex"
    //       style={{
    //         backgroundImage: `url(${loading})`,
    //         backgroundRepeat: "no-repeat",
    //         backgroundSize: "auto",
    //       }}
    //     ></div>
    //   );
    // }
  };

  const ArticleContent = () => {
    return (
      <>
        <div class="mb-4 monsterratMedium f08r  mw951000">
          {moment(news.shout_out_date).format("DD-MMM-YYYY")}
        </div>{" "}
        <div class="newsContentContainer f1r  monsterratRegular ">
          <pre
            dangerouslySetInnerHTML={{ __html: news.content }}
            class="formattedArticleContent monsterratMedium"
          ></pre>
        </div>
      </>
    );
  };
  return (
    <div>
      <button
        id="faqBackButton"
        class=" monsterratMedium blue-btn rounded-pill grey flex  f1r px-4 mb-3 center-vertical "
        onClick={(e) => {
          navigate(`/produk/waran-terstruktur`, {
            replace: true,
            state: { toAnnouncement: true },
          });
          // if(from){

          // }else{
          //   navigate(`/produk/waran-terstruktur/`, {
          //     replace: true,
          //     state: { toAnnouncement: true },
          //   });
          // }
        }}
        style={{
          backgroundColor: "transparent",
          border: "2px solid #787878",
          transition: "0s",
        }}
      >
        <img src={faqArrow} class="faqBackArrow center-vertical"></img>Back
      </button>
      <MainArticle />
      <div class="artikelSpacer my-2"></div>
      <div class="articleContentContainer w901000">
        <div class="articleContentWrapper">
          <ArticleContent />
        </div>
      </div>
    </div>
  );
}
