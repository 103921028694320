import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";

import testi1 from "../../assets/testi1.png";
import testi2 from "../../assets/testi2.png";
import testi3 from "../../assets/testi3.png";
import testi4 from "../../assets/testi4.png";
import testi5 from "../../assets/testi5.png";
import arrow from "../../assets/arrow.png";
import "./style.css";
gsap.registerPlugin(Draggable);

const pictures = [
  {
    source: testi1,
    content: {
      date: "04.29.2020",
      desc: "Behind the leaves. ",
    },
  },
  {
    source: testi2,
    content: {
      date: "04.28.2020",
      desc: "Minimal eucalyptus leaves",
    },
  },
  {
    source: testi3,
    content: {
      date: "04.28.2020",
      desc: "Rubber Plant",
    },
  },
  {
    source: testi4,
    content: {
      date: "04.27.2020",
      desc: "Person holding leaf plant",
    },
  },
  {
    source: testi5,
    content: {
      date: "04.23.2020",
      desc: "Green leafed plant photography",
    },
  },

];

const Slide = ({ imageSource, content, index }) => {
  return (
    <div className="slide" id={"testiSlide" + index}>
      <div className="preview">
        <img
          src={imageSource}
          alt="The Plant"
          draggable="false"
          class="testiSlideImg"
        />
      </div>
      <div className="infos">
        {/* {JSON.stringify(index)} */}
        {/* <h3>{content.date}</h3>
        <h2>{content.desc}</h2> */}
      </div>
    </div>
  );
};

export const TestiSlider = () => {
  const sliderRef = useRef(null);

  useEffect(() => {
    console.log(sliderRef.current.clientWidth, sliderRef.current.innerWidth);
    Draggable.create(sliderRef.current, {
      type: "x",
      bounds: {
        minX: -sliderRef.current.clientWidth + window.innerWidth * -0.05,
        maxX: 100,
      },
    });
  }, []);

  return (
    <div class="appSliderContainer ">
<div class="appSpacerLeft  center-vertical ">
        <img class="sliderArrow arrowLeft" src={arrow}></img>
      </div>
    <div class="appSliderWrapper">
      {" "}
      <div id="slider" className="slider" ref={sliderRef}>
      {pictures.map((item, index) => {
        return (
          <Slide
            key={index}
            imageSource={item.source}
            content={item.content}
            index={index}
          />
        );
      })}
    </div>
    </div>
    <div class="appSpacerRight center-vertical  appSpacerHeight">
        {" "}
        <img class="sliderArrow arrowRight" src={arrow}></img>
      </div>
  </div>
    
  );
};
