import comingSoon from "../../assets/comingSoon.png";
import React, { useEffect, useState } from "react";
export function DisclaimerMargin() {
    useEffect(() => {
        console.log("asw");
        window.scrollTo(0, 0);
      }, []);
  return (
    <div class="promoPageContainer">
      <div class="promoPageWrapper">
        <div class="CIMBPageContainer">
          <div class="CIMBPageWrapper">
            <div class="CIMBTextWrapper">
              <div class="f25r  monsterratExtraBold my-3 text-center blue-text-1 mb-5 pb-4">
                Pernyataan Untuk Calon Nasabah Margin
              </div>
              {/* <div class="my-5 f25r nowrap blue-text-1 monsterratExtraBold text-center">
      Syarat & Ketentuan menjadi nasabah ETF
      </div> */}
              <div class="etfContent f1r monsterratMedium grey">
                <p>{/* <b>Syarat dan Ketentuan:</b> */}</p>

                <ol>
                  <li>
                    Nasabah telah memiliki Rekening Efek Reguler untuk menampung
                    transaksi Efek yang tidak dibiayai dengan fasilitas marjin.
                  </li>
                  <li>
                    Nasabah telah menyetor Jaminan Awal berupa <i>cash</i> atau
                    saham margin yang nilai setelah haricut, sekurang-kurangnya
                    sebesar Rp. 200.000.000,- (dua ratus juta Rupiah).
                  </li>
                  <li>
                    Transaksi Margin hanya diperuntukan untuk{" "}
                    <b>Pembelian Saham Margin</b>, bukan untuk pembiayaan
                    lainnya.
                  </li>
                  <li>Resiko Fluktuasi Harga Saham di Pasar Modal.</li>
                  <li>Perusahaan dapat menetapkan haircut saham margin.</li>
                  <li>
                    Perusahaan dapat menetapkan daftar saham margin yang kurang
                    dari yang ditetapkan Bursa.
                  </li>
                  <li>
                    Ratio Margin adalah perbandingan antara nilai outstanding
                    dibagi dengan <b>nilai saham jaminan setelah haircut</b>.
                  </li>
                  <li>
                    Perusahaan dapat menetapkan Maksimal Trading Limit untuk
                    Nasabah. Transaksi Nasabah akan dibatasi dengan parameter
                    Ratio Margin versus Trading Limit,{" "}
                    <b>mana yang lebih kecil</b>.
                  </li>
                  <li>
                    Bila Ratio Margin mencapai 65% atau lebih, Perusahaan akan
                    menerbitkan <i>Margin Call Letter</i>, Nasabah diminta top
                    up cash dan/atau saham dan/atau jual saham jaminan, dalam
                    waktu yang ditetapkan dalam surat.
                  </li>
                  <li>
                    Bila permintaan margin call tidak dipenuhi dalam waktu yang
                    ditetapkan, Perusahaan dapat melakukan <i>force sell</i>.
                  </li>
                  <li>
                    Bila Ratio Margin mencapai 80%, Perusahaan dapat melakukan{" "}
                    <i>force sell</i>.
                  </li>
                  <li>
                    <i>Force Sell</i> berdasarkan diskresi Perusahaan, Nasabah
                    tidak dapat memilih Saham yang akan dijual.
                  </li>
                  <li>
                    Bila <i>Force Sell</i> tidak dapat terlaksana karena force
                    majeur atau kondisi pasar yang turun tajam, Nasabah tetap
                    bertanggung jawab atas <i>outstanding</i>-nya.
                  </li>
                  <li>
                    Nasabah tidak dapat menarik dana, bila masih ada{" "}
                    <i>outstanding</i> di Rekening Efek Margin.
                  </li>
                  <li>
                    Transaksi yang sudah terjadi di Rekening Efek Reguler tidak
                    dapat dipindahkan ke Rekening Efek Margin, demikian
                    sebaliknya.
                  </li>
                  <li>
                    Saham yang keluar dari List Margin oleh Bursa, akan
                    dikeluarkan dari Rekening Efek Margin. Nasabah diminta untuk
                    melunasi atau top up bila Ratio Margin akan melebihi Ratio
                    yang ditentukan.
                  </li>
                  <li>
                    Atas <i>outstanding</i> Nasabah dikenakan bunga yang
                    ditetapkan dan dapat berubah sesuai kebijakan Perusahaan.
                    Biaya bunga diperhitungkan berdasarkan saldo debit (hutang)
                    harian, dibagi 360 hari dikalikan prosentase bunga.
                  </li>
                </ol>
            
             
               
              </div>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
