import logo from "../../../logo.svg";
import "./style.css";
import PageLocation from "../../../components/PageLocation";
import arrowUp from "../../../assets/arrowUp.png";
import arrowDown from "../../../assets/arrowDown.png";
import faqArrow from "../../../assets/faqArrow.png";
import nilaiSw from "../../../assets/nilaiSw.png";
import swCode from "../../../assets/swCode.png";
import swCalendar from "../../../assets/swCalendar.png";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { NotFound } from "../../NotFound";
import { setActiveMenu } from "../../../store/action";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { server } from "../../../config/server";
export default function Pengumuman({ category }) {
  const locationObject = useParams();
  const page = locationObject["*"];
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(page);
    // const data = dummyPemberitahuan.filter((item) => item.category == category);
    // let temp = dummyPemberitahuan.filter((item) => item.category == category);

    getData();

    // let x = 10 - temp.length;
    // if (temp.length < 10) {
    //   for (let i = 0; i < x; i++) {
    //     temp.push([]);
    //   }
    // }
    // setData(temp);
  }, [page]);
  const getData = () => {
    fetch(`${server}/api/v2/swari/shout-out/1/${category.category_id}`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      // body: JSON.stringify(file),
      headers: {
        "Content-Type": "application/json",
        // access_token: localStorage.getItem("access_token"),
      },
    })
      .then((res) => res.json())
      .then(async (data) => {
        console.log(data, "promox");
        if(data.status){
          data.data.push([])
          setData(data.data);
        }else{
          throw data
        }
      
      })

      .catch((err) => {
        console.log(err);
      });
  };
  const faqSwitcher = () => {
    switch (page) {
      case "pemberitahuan":
        return <Pemberitahuan />;
      case "waran-terbaru":
        return <WaranTerbaru />;
      case "waran-jatuh-tempo":
        return <WaranJatuhTempo />;
      case "dokumen":
        return <Dokumen />;
      case "dokumen":
        return <Dokumen />;
      default:
        return <NotFound />;
    }
  };

  const navigate = useNavigate();
  const openInNewTab = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };

  const Q1 = () => {
    return (
      <>
        <div class="textCenter   pb-5 ">
          {/* <div class="monsterratBold grey f2r"></div> */}
          <div class="produkMobileContainer monsterratExtraBold mt-3 mx-auto  text-center f275r  blue-text-1 mw90768">
            Apa itu Waran Terstruktur?
          </div>

          <div class="monsterratMedium grey jenisAkunText  f1r mw60 mw90768">
            Waran Terstruktur merupakan instrumen keuangan dan instrumen
            investasi yang diterbitkan oleh perusahaan sekuritas serta dapat
            diperjualbelikan di Bursa Efek Indonesia melalui aplikasi online
            trading masing-masing sekuritas seperti aplikasi KOINS yang dimiliki
            oleh PT. Korea Investment And Sekuritas Indonesia.
          </div>
        </div>
        <div class="bg-biru-muda sahamTextContainer putvscall pt-5 pb-5 text-center rounded-corner mt-5 flex  justify-content-center">
          <div
            class="mx-auto1000"
            style={{ borderRadius: "20px 20px 10px 10px", maxWidth: "24rem" }}
          >
            <div
              class="text-center f2r white monsterratSemiBold"
              style={{
                backgroundColor: "#ca0a09",
                borderRadius: "20px 20px 0px 0px",
              }}
            >
              Put
            </div>

            <div
              style={{
                border: "3px solid #ca0a09",
                borderRadius: "0px 0px 10px 10px",
              }}
            >
              <div class="px-5 f1r grey monsterratSemiBold pt-4 mt-1">
                Right to <span class="fw-800">sell </span> underlying asset at
                future date at <span class="fw-800">predetermined</span> price
              </div>
              <div class="px-5 f09r grey monsterratMedium py-4">
                Increase in value when underlying asset goes <b>down</b>
              </div>
            </div>
          </div>
          <div class="f5r center-vertical block1000 mx-auto1000 monsterratExtraBold blue-text-1 px-5">
            VS
          </div>
          <div
            class="mx-auto1000"
            style={{ borderRadius: "20px 20px 10px 10px", maxWidth: "24rem" }}
          >
            <div
              class="text-center f2r white monsterratSemiBold"
              style={{
                backgroundColor: "#57CB0A",
                borderRadius: "20px 20px 0px 0px",
              }}
            >
              Call
            </div>

            <div
              style={{
                border: "3px solid #57CB0A",
                borderRadius: "0px 0px 10px 10px",
              }}
            >
              <div class="px-5 f1r grey monsterratSemiBold pt-4 mt-1">
                Right to <span class="fw-800">buy </span> underlying asset at
                future date at <span class="fw-800">predetermined</span> price
              </div>
              <div class="px-5 f09r grey monsterratMedium py-4">
                Increase in value when underlying asset goes <b>up</b>
              </div>
            </div>
          </div>
        </div>
        <div class="textCenter   pb-5 ">
          {/* <div class="monsterratBold grey f2r"></div> */}
          <div class="monsterratExtraBold mt-3 mx-auto  f275r  blue-text-1 mw90768">
            Perbedaan
          </div>

          <div class="monsterratMedium grey jenisAkunText  f1r mw60 mw90768">
            <b>Waran Terstruktur</b> dan <b>Waran Perusahaan</b> dari sisi fitur
            produk
          </div>
          <div class="mw80 mw901000 mx-auto">
            <table
              style={{
                border: "2px solid #0271bf",
                verticalAlign: "middle",
                tableLayout: "fixed",
              }}
              class="table f12r table-bordered textCenter monsterratMedium lh25 tableFaqWarrant mt-5"
            >
              <thead class="white" style={{ backgroundColor: "#0271bf" }}>
                <tr>
                  <th class="py-3" style={{ width: "25%" }} scope="col"></th>
                  <th class="py-3" style={{ width: "37.5%" }} scope="col">
                    WARAN TERSTRUKTUR
                  </th>
                  <th class="py-3" style={{ width: "37.5%" }} scope="col">
                    WARAN PERUSAHAAN{" "}
                  </th>
                </tr>
              </thead>
              <tbody
                class="grey monsterratMedium f1r"
                style={{ border: "2px solid #0271bf" }}
              >
                <tr style={{ border: "2px solid #0271bf" }} class="py-3">
                  <td class="py-4">Diterbitkan Oleh</td>
                  <td class="fw700 py-4">Perusahaan Sekuritas (Pihak ke-3)</td>
                  <td class="py-4">Perusahaan Tercatat (Emiten Perusahaan)</td>
                </tr>
                <tr style={{ border: "2px solid #0271bf" }} class="py-3">
                  <td class="py-4">Tujuan</td>
                  <td class="fw700 py-4">
                    Leverage ataupun lindung nilai (hedging) bagi investor
                  </td>
                  <td class="py-4">
                    Pengumpulan dana bagi perusahaan ataupun menjadi bonus bagi
                    investor yang membeli pada saat proses IPO
                  </td>
                </tr>
                <tr style={{ border: "2px solid #0271bf" }} class="py-3">
                  <td class="py-4">
                    Saham Underlying<br></br> (Saham dasar)
                  </td>
                  <td class="fw700 py-4">
                    Saham perusahaan lain, tidak terkait dengan penerbit
                  </td>
                  <td class="py-4">
                    Saham yang dikeluarkan oleh perusahaan tercatat
                  </td>
                </tr>
                <tr style={{ border: "2px solid #0271bf" }} class="py-3">
                  <td class="py-4">Efek Dilusi*</td>
                  <td class="fw700 py-4">Tidak ada</td>
                  <td class="py-4">Menyebabkan dilusi</td>
                </tr>
                <tr style={{ border: "2px solid #0271bf" }} class="py-3">
                  <td class="py-4">Jatuh Tempo</td>
                  <td class="fw700 py-4">2 - 24 Bulan</td>
                  <td class="py-4">
                    Umumnya lebih Panjang, 6 bulan hingga 5 tahun
                  </td>
                </tr>
                <tr style={{ border: "2px solid #0271bf" }} class="py-3">
                  <td class="py-4">Metode Penyelesian</td>
                  <td class="fw700 py-4">Tunai**</td>
                  <td class="py-4">Saham fisik</td>
                </tr>
              </tbody>
            </table>
            <div class="my-5 pb-5">
              <button
                class=" monsterratMedium blue-btn rounded-pill flex  f1r px-4 mb-3"
                style={{ pointerEvents: "none" }}
              >
                Keterangan :
              </button>
              <div
                style={{ maxWidth: 600, textAlign: "left" }}
                class="pt-3 monsterratMedium f1r grey"
              >
                <b>*Efek dilusi</b> adalah berkurangnya porsi kepemilikan saham
                (dalam persentase), karena penambahan lembar saham baru.
              </div>
              <div
                style={{ maxWidth: 600, textAlign: "left" }}
                class="pt-3 monsterratMedium f1r grey"
              >
                <b>**Penyelesaian tunai</b> adalah pada saat tanggal kedaluarsa,
                penyelesaian Waran Terstruktur dalam bentuk tunai (bukan
                penyerahan saham fisik)
              </div>
            </div>
            <div class=" pb-5 ">
              {/* <div class="monsterratBold grey f2r"></div> */}
              <div class="text-center monsterratExtraBold mt-3 mx-auto  f275r  blue-text-1 mw90768">
                Penamaan Waran Terstruktur
              </div>

              <div class="monsterratMedium grey text-center jenisAkunText  f1r mw60 mw90768">
                Dari sisi penamaan, Waran Terstruktur berbeda dengan Waran
                Perusahan dimana pada Waran Terstruktur ada tambahan beberapa
                kode khusus sehingga total kodenya menjadi 10 digit.
              </div>
              <div class="bg-biru-muda sahamTextContainer pt-5 pb-5  rounded-corner mt-5 flex  justify-content-center">
                <div class="px-5 monsterratMedium grey f1r text-left">
                  Sebagai contoh kita gunakan saham{" "}
                  <span class="fw-800">BBCA</span> untuk memahami perbedaan dari
                  sisi penamaan:
                  <div class="my-4"></div>
                  <div class="flex">
                    <div style={{ paddingRight: "20%" }}>
                      <div class="nowrap text-left">
                        Saham dasar (underlying)
                      </div>
                      <div class="nowrap text-left">Waran Perusahaan</div>
                      <div class="nowrap text-left">Waran Terstruktur </div>
                    </div>
                    <div>
                      <div class="nowrap text-left">
                        : BBCA - Bank Central Asia Tbk
                      </div>
                      <div class="nowrap text-left">: BBCA - W</div>
                      <div class="nowrap text-left">: BBCABQCV3A</div>
                    </div>
                  </div>
                  <div class="my-4"></div>
                  Waran Perusahan, biasanya memiliki kode huruf W dibelakang
                  kode sahamnya, sebagai contoh BBCA-W, artinya Waran Perusahaan
                  dari <span class="fw-800">emiten BBCA</span>
                </div>
              </div>
              <div class="sahamTextContainer  grey monsterratMedium f1r">
                Sementara Waran Terstruktur BBCA memiliki 10-digit kode, yaitu
                <span class="fw-800"> BBCABQCV3A</span>, yang terdiri:
                <div>
                  <img class="w90 my-5" src={swCode}></img>
                </div>
              </div>
              <div
                class="flex mw70 mw1001000 monsterratMedium grey mx-auto justify-content-center f12768"
                style={{ fontSize: 20 }}
              >
                <div
                  class="text-left pr1768"
                  style={{
                    paddingRight: "3rem",
                  }}
                >
                  Berikut disamping macam-macam <b>kode waktu</b> Waran
                  Terstruktur:
                  <div class="my-4"></div>
                  Lalu, yang dimaksud dengan angka 3 setelah kode bulan
                  merupakan angka tahun / angka terakhir pada tahun periode
                  Waran Terstruktur berakhir.
                  <div class="my-4"></div> Contoh diatas kode waktunya adalah{" "}
                  <b>V3</b> artinya periodenya akan berakhir pada bulan Oktober
                  20<b>23</b>
                  <div class="my-4"></div>
                  <b>Kode Unik</b> sendiri merupakan satu huruf bebas yang
                  diberikan oleh penerbit Waran Terstruktur tersebut.
                </div>
                <div>
                  <table
                    style={{
                      border: "2px solid #0271bf",
                      maxWidth: 600,
                      tableLayout: "fixed",
                    }}
                    class="table f1r table-bordered textCenter monsterratMedium  tableFaqWarrant w100"
                  >
                    <thead class="white" style={{ backgroundColor: "#0271bf" }}>
                      <tr class="w100">
                        <th class="" style={{ width: "50%" }} scope="col">
                          Bulan
                        </th>
                        <th class="" style={{ width: "50%" }} scope="col">
                          Kode
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      class="grey monsterratMedium f09r"
                      style={{ border: "2px solid #0271bf" }}
                    >
                      <tr style={{ border: "2px solid #0271bf" }} class="">
                        <td class="text-left px-4 pl05768">Januari</td>
                        <td class="">F</td>
                      </tr>
                      <tr style={{ border: "2px solid #0271bf" }} class="">
                        <td class="text-left px-4 pl05768">Februari</td>
                        <td class="">G</td>
                      </tr>
                      <tr style={{ border: "2px solid #0271bf" }} class="">
                        <td class="text-left px-4 pl05768">Maret</td>
                        <td class="">H</td>
                      </tr>
                      <tr style={{ border: "2px solid #0271bf" }} class="">
                        <td class="text-left px-4 pl05768">April</td>
                        <td class="">J</td>
                      </tr>
                      <tr style={{ border: "2px solid #0271bf" }} class="">
                        <td class="text-left px-4 pl05768">Mei</td>
                        <td class="">K</td>
                      </tr>
                      <tr style={{ border: "2px solid #0271bf" }} class="">
                        <td class="text-left px-4 pl05768">Juni</td>
                        <td class="">M</td>
                      </tr>
                      <tr style={{ border: "2px solid #0271bf" }} class="">
                        <td class="text-left px-4 pl05768">Juli</td>
                        <td class="">N</td>
                      </tr>
                      <tr style={{ border: "2px solid #0271bf" }} class="">
                        <td class="text-left px-4 pl05768">Agustus</td>
                        <td class="">Q</td>
                      </tr>
                      <tr style={{ border: "2px solid #0271bf" }} class="">
                        <td class="text-left px-4 pl05768">September</td>
                        <td class="">U</td>
                      </tr>
                      <tr style={{ border: "2px solid #0271bf" }} class="">
                        <td class="text-left px-4 pl05768">Oktober</td>
                        <td class="">V</td>
                      </tr>
                      <tr style={{ border: "2px solid #0271bf" }} class="">
                        <td class="text-left px-4 pl05768">November</td>
                        <td class="">X</td>
                      </tr>
                      <tr style={{ border: "2px solid #0271bf" }} class="">
                        <td class="text-left px-4 pl05768">Desember</td>
                        <td class="">Z</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const WaranTerbaru = () => {
    const [gas, setGas] = useState("kui");
    const [selected, setSelected] = useState(0);
    useEffect(() => {
      console.log("nyu warn", gas);
      const allList = document.getElementsByClassName("tabelPengumumanItem");
      console.log(allList);
      for (let i = 0; i < allList.length; i++) {
        const element = allList[i];

        console.log(element.id);
        if (element.id == `pengumumanList${selected}`) {
          element.classList.add("listActive");
        } else {
          element.classList.remove("listActive");
        }
      }
    });

    const dummyWaranTerbaru = [
      {
        date: "05-11-2024",
        id: 1,
        items: [
          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },
          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },

          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },

          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },

          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },
        ],
      },
      {
        date: "05-11-2024",
        items: [
          {
            exp_date: "07-11-2024",
            name: "xADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },
          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },

          {
            exp_date: "10-11-2024",
            name: "AHDRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },

          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },

          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },
        ],
      },
      {
        date: "03-11-2024",
        items: [
          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },
          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },

          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },

          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },

          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },
        ],
      },
      {
        date: "01-18-2024",
        items: [
          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },
          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },

          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },

          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },

          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },
        ],
      },
      {
        date: "05-11-2024",
        items: [
          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },
          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },

          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },

          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },

          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },
        ],
      },
      {
        date: "05-11-2024",
        items: [
          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },
          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },

          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },

          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },

          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },
        ],
      },
    ];
    return (
      <div class="w100">
        <div class="produkMobileContainer  text-center monsterratExtraBold mt-3 mx-auto  f275r  blue-text-1 mw90768">
          Apa itu Waran Terstruktur?XY
        </div>
        <div class="monsterratMedium grey jenisAkunText text-center  f1r mw60 mw90768">
          Waran Terstruktur merupakan instrumen keuangan dan instrumen investasi
          yang diterbitkan oleh perusahaan sekuritas serta dapat
          diperjualbelikan di Bursa Efek Indonesia melalui aplikasi online
          trading masing-masing sekuritas seperti aplikasi KOINS yang dimiliki
          oleh PT. Korea Investment And Sekuritas Indonesia.
        </div>{" "}
        <div
          class="w80 py-4 mx-auto flex  f1r "
          style={{ marginBottom: "6rem" }}
        >
          <div class="pengumumanTabelWaranList">
            {dummyWaranTerbaru.map((item, i) => {
              return (
                <div
                  id={`pengumumanList${i}`}
                  class="tabelPengumumanItem monsterratSemiBold f1r w100 py-2 pointer"
                  onClick={(e) => {
                    setSelected(i);
                  }}
                >
                  Tanggal Pencatatan : {moment(item.date).format("DD-MMM-YYYY")}
                </div>
              );
            })}
          </div>
          <div class="pengumumanTabelWaran">
            {" "}
            <div class="w90 mx-auto mb-5 pb-5 " style={{ overflow: "auto" }}>
              <table
                id="searchForm"
                className="table  table-striped table-warrant"
                style={{
                  textAlign: "center",
                  borderLeft: "0px solid transparent",
                }}
              >
                <thead
                  style={{
                    border: "none !important  ",
                  }}
                >
                  <tr
                    style={{
                      textAlign: "left",
                      borderLeft: "0px solid transparent",
                      borderRight: "0px solid transparent",
                    }}
                  >
                    <th
                      className=" text-center mx-auto th_waran blue-border monsterratBold blue-text-1 fixedTableCol"
                      style={{
                        backgroundColor: "#eef9ff",
                        borderLeft: "0px solid transparent",
                        borderRight: "0px solid transparent",
                      }}
                    >
                      Nama Waran
                    </th>{" "}
                    <th
                      className=" text-center mx-auto th_waran blue-border monsterratBold blue-text-1"
                      style={{
                        backgroundColor: "#eef9ff",
                        borderRight: "0px solid transparent",
                        borderLeftWidth: 0,
                      }}
                    >
                      <div class="flex center-vertical h100 w100">
                        <span>Saham Dasar</span>
                      </div>
                    </th>{" "}
                    <th
                      className=" text-center mx-auto th_waran blue-border monsterratBold blue-text-1"
                      style={{ backgroundColor: "#eef9ff" }}
                    >
                      <div class="flex center-vertical h100 w100">
                        <span>Tgl. Pencatatan</span>
                      </div>
                    </th>{" "}
                    <th
                      className=" text-center mx-auto th_waran blue-border monsterratBold blue-text-1"
                      style={{ backgroundColor: "#eef9ff" }}
                    >
                      <div class="flex center-vertical h100 w100">
                        <span>Tipe Opsi</span>
                      </div>
                    </th>{" "}
                    <th
                      className=" text-center mx-auto th_waran blue-border monsterratBold blue-text-1"
                      style={{ backgroundColor: "#eef9ff" }}
                    >
                      <div class="flex center-vertical">
                        <span>Harga Pelaksanaan</span>
                      </div>
                    </th>{" "}
                    <th
                      className=" text-center mx-auto th_waran blue-border monsterratBold blue-text-1"
                      style={{ backgroundColor: "#eef9ff" }}
                    >
                      <div class="flex center-vertical">
                        <span>Rasio Pelaksanaan</span>
                      </div>
                    </th>{" "}
                    <th
                      className=" text-center mx-auto th_waran blue-border monsterratBold blue-text-1"
                      style={{ backgroundColor: "#eef9ff" }}
                    >
                      <div class="flex center-vertical">
                        <span>Tanggal kadaluarsa</span>
                      </div>
                    </th>{" "}
                  </tr>
                </thead>
                <tbody id="formData " style={{ fontSize: 12 }}>
                  {dummyWaranTerbaru[selected].items.map((data, i) => {
                    return (
                      <tr className="form_c">
                        <td
                          className=" monsterratSemiBold search-td-h noLeftBorder blue-text-1 fixedTableCol"
                          style={{
                            borderLeft: "1px solid transparent !important",
                          }}
                        >
                          <a>{data.name}</a>
                        </td>
                        <td className=" monsterratSemiBold tdRhb noLeftBorder">
                          {" "}
                          {data.saham_dasar}
                        </td>
                        <td className=" monsterratSemiBold c_one_1 tdRhb">
                          {" "}
                          {data.exp_date}
                        </td>
                        <td className=" monsterratSemiBold c_one_1 tdRhb">
                          {" "}
                          {data.openInNewTab}
                        </td>
                        <td className=" monsterratSemiBold c_one_1 tdRhb">
                          {data.exercise_price}
                        </td>
                        <td className=" monsterratSemiBold c_one_1 tdRhb">
                          {data.rasio}
                        </td>
                        <td className=" monsterratSemiBold c_one_1 tdRhb">
                          {data.exp_date}
                        </td>

                        {/* Repeat the same structure for other table data cells */}
                      </tr>
                    );
                  })}
                  {[[], [], [], []].map((data, i) => {
                    return (
                      <tr className="form_c">
                        <td
                          className=" monsterratSemiBold search-td-h noLeftBorder blue-text-1 fixedTableCol"
                          style={{
                            borderLeft: "1px solid transparent !important",
                          }}
                        >
                          <a>{data.warrant_code}</a>
                        </td>
                        <td className="hidden  monsterratSemiBold tdRhb noLeftBorder">
                          {"|"}
                          {data.issuer_code}
                        </td>
                        <td className=" monsterratSemiBold c_one_1 tdRhb">
                          {" "}
                          {data.base_stock_code}
                        </td>
                        <td className=" monsterratSemiBold c_one_1 tdRhb">
                          {" "}
                          {data.PutOrCall}
                        </td>
                        <td className=" monsterratSemiBold c_one_1 tdRhb">
                          {data.BaseStock_LastPrice}
                        </td>
                        <td className=" monsterratSemiBold c_one_1 tdRhb">
                          {data.exercise_price}
                        </td>
                        <td className=" monsterratSemiBold c_one_1 tdRhb">
                          {data.exercise_ratio}
                        </td>

                        {/* Repeat the same structure for other table data cells */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const Pemberitahuan = () => {
    const [gas, setGas] = useState("kui");
    const [selected, setSelected] = useState(0);
    useEffect(() => {
      console.log("nyu warn", gas);
      const allList = document.getElementsByClassName("tabelPengumumanItem");
      console.log(allList);
      for (let i = 0; i < allList.length; i++) {
        const element = allList[i];

        console.log(element.id);
        if (element.id == `pengumumanList${selected}`) {
          element.classList.add("listActive");
        } else {
          element.classList.remove("listActive");
        }
      }
    });

    const dummyPemberitahuan = [
      {
        date: "05-11-2024",
        title:
          "Informasi Penerbitan Waran Terstruktur di Bursa Efek Indonesia pada tanggal 26 Februari 2024",
        content: `<div data-v-06b38075="" class="col-md pl-md-2 mt-1 p-0 shout-right-bar"><div data-v-06b38075="" class="functionBoxmax" style="padding: 10px 0px;"</div> <div data-v-06b38075=""><div data-v-06b38075="" id="rightText" class="font-size14 footer-h font-weight-bold">Informasi Penerbitan  Waran Terstruktur di Bursa Efek Indonesia pada tanggal 26 Februari 2024</div> <div data-v-06b38075="" id="rightContent" class="mt-5 shout-text"><p>Kami informasikan bahwa berikut ini adalah detail penerbitan Waran Terstruktur ke-18 yang dicatatkan di Bursa Efek Indonesia pada tanggal 26 Februari 2024&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;</p>
        
        <p>Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;ADRODRCM4A<br>
        Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 28/unit<br>
        Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 2650/unit<br>
        Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
        Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;500,000,000<br>
        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
        Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;AMRTDRCM4A<br>
        Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 50/unit<br>
        Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 2600/unit<br>
        Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
        Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;500,000,000<br>
        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
        Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;ANTMDRCM4A<br>
        Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 24/unit<br>
        Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 1500/unit<br>
        Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
        Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;500,000,000<br>
        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
        Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;ASIIDRCM4A<br>
        Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 33/unit<br>
        Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 5500/unit<br>
        Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
        Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;500,000,000<br>
        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
        Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;BBCADRCM4A<br>
        Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 95/unit<br>
        Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 9500/unit<br>
        Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
        Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;200,000,000<br>
        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
        Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;BBNIDRCM4A<br>
        Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 58/unit<br>
        Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 6100/unit<br>
        Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
        Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;200,000,000<br>
        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
        Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;BUKADRCM4A<br>
        Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 15/unit<br>
        Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 160/unit<br>
        Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
        Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;1,000,000,000<br>
        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
        Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;INDFDRCM4A<br>
        Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 31/unit<br>
        Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 6500/unit<br>
        Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
        Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; :&nbsp; &nbsp; &nbsp;500,000,000<br>
        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
        Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;ITMGDRCM4A<br>
        Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 90/unit<br>
        Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 28000/unit<br>
        Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
        Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;200,000,000<br>
        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
        Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;KLBFDRCM4A<br>
        Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 38/unit<br>
        Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 1500/unit<br>
        Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
        Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;500,000,000<br>
        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
        Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;MEDCDRCM4A<br>
        Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 25/unit<br>
        Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 1350/unit<br>
        Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
        Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;500,000,000<br>
        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
        Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;PGASDRCM4A<br>
        Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 19/unit<br>
        Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 1400/unit<br>
        Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
        Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;500,000,000<br>
        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
        Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;UNVRDRCM4A<br>
        Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 36/unit<br>
        Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 2800/unit<br>
        Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
        Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;300,000,000<br>
        &nbsp;</p>
        </div></div></div>`,
      },
      {
        date: "05-11-2024",
        content: `<div data-v-06b38075="" id="rightContent" class="mt-5 shout-text"><p>Berikut adalah pengumuman untuk waran terstruktur AKRADRCH4A, ARTODRCH4A,BBRIDRCH4A, BMRIDRCH4A, BRPTDRCH4A, CPINDRCH4A, EMTKDRCH4A, GOTODRCH4A, HRUMDRCH4A, INCODRCH4A, MDKADRCH4A, PTBADRCH4A, SMGRDRCH4A, TLKMDRCH4A dan UNTRDRCH4A mengenai periode perdagangan terbaru bertepatan dengan Cuti Bersama Hari Suci Nyepi Tahun Baru Saka 1946.</p>

        <p>Periode perdagangan&nbsp;</p>
        
        <table border="1" cellpadding="1" cellspacing="0" style="width: 500px; border-collapse: collapse; border-spacing: 0px;">
          <tbody>
            <tr>
              <td style="padding: 1px; text-align: center;">Kegiatan</td>
              <td style="padding: 1px; text-align: center;">Jadwal Awal</td>
              <td style="padding: 1px; text-align: center;">Jadwal Perubahan</td>
            </tr>
            <tr>
              <td style="padding: 1px; text-align: center;">Periode Perdagangan</td>
              <td style="padding: 1px; text-align: center;">14 Nov 2023 - 11 Mar 2024</td>
              <td style="padding: 1px; text-align: center;">14 Nov 2023 - 7 Mar 2024</td>
            </tr>
          </tbody>
        </table>
        
        <p>&nbsp;</p>
        
        <p>Term Sheet Terbaru&nbsp;<a href="https://www.warrantindonesia.co.id/id/Document" target="_blank">https://www.warrantindonesia.co.id/id/Document</a></p>
        
        <p>&nbsp;</p>
        </div>`,
        title:
          "Perubahan Tanggal Perdagangan Terakhir pada Waran Terstruktur Penerbitan ke-15",
      },
    ];
    return (
      <div class="w100">
        <div class="produkMobileContainer  text-center monsterratExtraBold mt-3 mx-auto  f275r  blue-text-1 mw90768">
          Pemberitahuan
        </div>
        <div class="monsterratMedium grey jenisAkunText text-center  f1r mw60 mw90768">
          Waran Terstruktur merupakan instrumen keuangan dan instrumen investasi
        </div>{" "}
        <div
          class="w80 py-4 mx-auto flex  f1r  mt-5"
          style={{ marginBottom: "6rem" }}
        >
          <div
            class="pengumumanTabelWaranList"
            style={{ maxWidth: 350, textOverflow: "ellipsis" }}
          >
            {dummyPemberitahuan.map((item, i) => {
              return (
                <div
                  id={`pengumumanList${i}`}
                  class="tabelPengumumanItem monsterratSemiBold f1r w100 py-2 pointer"
                  style={{
                    textOverflow: "ellipsis",
                  }}
                  onClick={(e) => {
                    setSelected(i);
                  }}
                >
                  {item.title}
                </div>
              );
            })}
          </div>
          <div class="pengumumanTabelWaran px-5">
            <div class="f12r monsterratSemiBold blue-text-1 mb-5">
              {dummyPemberitahuan[selected].title}
            </div>
            <div
              class="monsterratMedium"
              dangerouslySetInnerHTML={{
                __html: dummyPemberitahuan[selected].content,
              }}
            ></div>
          </div>
        </div>
      </div>
    );
  };
  const WaranJatuhTempo = () => {
    const [gas, setGas] = useState("kui");
    const [selected, setSelected] = useState(0);
    useEffect(() => {
      console.log("nyu warn", gas);
      const allList = document.getElementsByClassName("tabelPengumumanItem");
      console.log(allList);
      for (let i = 0; i < allList.length; i++) {
        const element = allList[i];

        console.log(element.id);
        if (element.id == `pengumumanList${selected}`) {
          element.classList.add("listActive");
        } else {
          element.classList.remove("listActive");
        }
      }
    });

    const dummyJatuhTempo = [
      {
        date: "05-11-2024",
        items: [
          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },
          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },

          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },

          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },

          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },
        ],
      },
      {
        date: "05-11-2024",
        items: [
          {
            exp_date: "07-11-2024",
            name: "xADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },
          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },

          {
            exp_date: "10-11-2024",
            name: "AHDRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },

          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },

          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },
        ],
      },
      {
        date: "03-11-2024",
        items: [
          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },
          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },

          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },

          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },

          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },
        ],
      },
      {
        date: "01-18-2024",
        items: [
          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },
          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },

          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },

          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },

          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },
        ],
      },
      {
        date: "05-11-2024",
        items: [
          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },
          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },

          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },

          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },

          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },
        ],
      },
      {
        date: "05-11-2024",
        items: [
          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },
          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },

          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },

          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },

          {
            exp_date: "10-11-2024",
            name: "ADRODRCM4A",
            saham_dasar: "ADRO",
            opt: "Call",
            exercise_price: "70000",
            rasio: "120000",
          },
        ],
      },
    ];
    return (
      <div class="w100">
        <div class="produkMobileContainer  text-center monsterratExtraBold mt-3 mx-auto  f275r  blue-text-1 mw90768">
          Waran Jatuh Tempo
        </div>
        <div class="monsterratMedium grey jenisAkunText text-center  f1r mw60 mw90768">
          Waran Terstruktur merupakan instrumen keuangan dan instrumen investasi
          yang diterbitkan oleh perusahaan sekuritas serta dapat
          diperjualbelikan di Bursa Efek Indonesia melalui aplikasi online
          trading masing-masing sekuritas seperti aplikasi KOINS yang dimiliki
          oleh PT. Korea Investment And Sekuritas Indonesia.
        </div>{" "}
        <div
          class="w80 py-4 mx-auto flex  f1r "
          style={{ marginBottom: "6rem" }}
        >
          <div class="pengumumanTabelWaranList">
            {dummyJatuhTempo.map((item, i) => {
              return (
                <div
                  id={`pengumumanList${i}`}
                  class="tabelPengumumanItem monsterratSemiBold f1r w100 py-2 pointer"
                  onClick={(e) => {
                    setSelected(i);
                  }}
                >
                  Tanggal Pencatatan : {moment(item.date).format("DD-MMM-YYYY")}
                </div>
              );
            })}
          </div>
          <div class="pengumumanTabelWaran">
            {" "}
            <div class="w90 mx-auto mb-5 pb-5 " style={{ overflow: "auto" }}>
              <table
                id="searchForm"
                className="table  table-striped table-warrant"
                style={{
                  textAlign: "center",
                  borderLeft: "0px solid transparent",
                }}
              >
                <thead
                  style={{
                    border: "none !important  ",
                  }}
                >
                  <tr
                    style={{
                      textAlign: "left",
                      borderLeft: "0px solid transparent",
                      borderRight: "0px solid transparent",
                    }}
                  >
                    <th
                      className=" text-center mx-auto th_waran blue-border monsterratBold blue-text-1 fixedTableCol"
                      style={{
                        backgroundColor: "#eef9ff",
                        borderLeft: "0px solid transparent",
                        borderRight: "0px solid transparent",
                      }}
                    >
                      Nama Waran
                    </th>{" "}
                    <th
                      className=" text-center mx-auto th_waran blue-border monsterratBold blue-text-1"
                      style={{
                        backgroundColor: "#eef9ff",
                        borderRight: "0px solid transparent",
                        borderLeftWidth: 0,
                      }}
                    >
                      <div class="flex center-vertical h100 w100">
                        <span>Saham Dasar</span>
                      </div>
                    </th>{" "}
                    <th
                      className=" text-center mx-auto th_waran blue-border monsterratBold blue-text-1"
                      style={{ backgroundColor: "#eef9ff" }}
                    >
                      <div class="flex center-vertical h100 w100">
                        <span>Tgl. Pencatatan</span>
                      </div>
                    </th>{" "}
                    <th
                      className=" text-center mx-auto th_waran blue-border monsterratBold blue-text-1"
                      style={{ backgroundColor: "#eef9ff" }}
                    >
                      <div class="flex center-vertical h100 w100">
                        <span>Tipe Opsi</span>
                      </div>
                    </th>{" "}
                    <th
                      className=" text-center mx-auto th_waran blue-border monsterratBold blue-text-1"
                      style={{ backgroundColor: "#eef9ff" }}
                    >
                      <div class="flex center-vertical">
                        <span>Harga Pelaksanaan</span>
                      </div>
                    </th>{" "}
                    <th
                      className=" text-center mx-auto th_waran blue-border monsterratBold blue-text-1"
                      style={{ backgroundColor: "#eef9ff" }}
                    >
                      <div class="flex center-vertical">
                        <span>Rasio Pelaksanaan</span>
                      </div>
                    </th>{" "}
                    <th
                      className=" text-center mx-auto th_waran blue-border monsterratBold blue-text-1"
                      style={{ backgroundColor: "#eef9ff" }}
                    >
                      <div class="flex center-vertical">
                        <span>Tanggal kadaluarsa</span>
                      </div>
                    </th>{" "}
                  </tr>
                </thead>
                <tbody id="formData " style={{ fontSize: 12 }}>
                  {dummyJatuhTempo[selected].items.map((data, i) => {
                    return (
                      <tr className="form_c">
                        <td
                          className=" monsterratSemiBold search-td-h noLeftBorder blue-text-1 fixedTableCol"
                          style={{
                            borderLeft: "1px solid transparent !important",
                          }}
                        >
                          <a>{data.name}</a>
                        </td>
                        <td className=" monsterratSemiBold tdRhb noLeftBorder">
                          {" "}
                          {data.saham_dasar}
                        </td>
                        <td className=" monsterratSemiBold c_one_1 tdRhb">
                          {" "}
                          {data.exp_date}
                        </td>
                        <td className=" monsterratSemiBold c_one_1 tdRhb">
                          {" "}
                          {data.openInNewTab}
                        </td>
                        <td className=" monsterratSemiBold c_one_1 tdRhb">
                          {data.exercise_price}
                        </td>
                        <td className=" monsterratSemiBold c_one_1 tdRhb">
                          {data.rasio}
                        </td>
                        <td className=" monsterratSemiBold c_one_1 tdRhb">
                          {data.exp_date}
                        </td>

                        {/* Repeat the same structure for other table data cells */}
                      </tr>
                    );
                  })}
                  {[[], [], [], []].map((data, i) => {
                    return (
                      <tr className="form_c">
                        <td
                          className=" monsterratSemiBold search-td-h noLeftBorder blue-text-1 fixedTableCol"
                          style={{
                            borderLeft: "1px solid transparent !important",
                          }}
                        >
                          <a>{data.warrant_code}</a>
                        </td>
                        <td className="hidden  monsterratSemiBold tdRhb noLeftBorder">
                          {"|"}
                          {data.issuer_code}
                        </td>
                        <td className=" monsterratSemiBold c_one_1 tdRhb">
                          {" "}
                          {data.base_stock_code}
                        </td>
                        <td className=" monsterratSemiBold c_one_1 tdRhb">
                          {" "}
                          {data.PutOrCall}
                        </td>
                        <td className=" monsterratSemiBold c_one_1 tdRhb">
                          {data.BaseStock_LastPrice}
                        </td>
                        <td className=" monsterratSemiBold c_one_1 tdRhb">
                          {data.exercise_price}
                        </td>
                        <td className=" monsterratSemiBold c_one_1 tdRhb">
                          {data.exercise_ratio}
                        </td>

                        {/* Repeat the same structure for other table data cells */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const Dokumen = () => {
    const [gas, setGas] = useState("kui");
    const [selected, setSelected] = useState(0);
    useEffect(() => {
      console.log("nyu warn", gas);
      const allList = document.getElementsByClassName("tabelPengumumanItem");
      console.log(allList);
      for (let i = 0; i < allList.length; i++) {
        const element = allList[i];

        console.log(element.id);
        if (element.id == `pengumumanList${selected}`) {
          element.classList.add("listActive");
        } else {
          element.classList.remove("listActive");
        }
      }
    });

    const dummyDokumen = [
      {
        date: "05-11-2024",
        title:
          "Informasi dok Penerbitan Waran Terstruktur di Bursa Efek Indonesia pada tanggal 26 Februari 2024",
      },
      {
        date: "05-11-2024",

        title:
          "Perubahan Tanggal Perdagangan Terakhir pada Waran Terstruktur Penerbitan ke-15",
      },
    ];
    return (
      <div class="w100">
        <div class="produkMobileContainer  text-center monsterratExtraBold mt-3 mx-auto  f275r  blue-text-1 mw90768">
          Pemberitahuan
        </div>
        <div class="monsterratMedium grey jenisAkunText text-center  f1r mw60 mw90768">
          Waran Terstruktur merupakan instrumen keuangan dan instrumen investasi
        </div>{" "}
        <div
          class="w80 py-4 mx-auto flex  f1r  mt-5"
          style={{ marginBottom: "6rem" }}
        >
          <div
            class="pengumumanTabelWaranList"
            style={{ maxWidth: 350, textOverflow: "ellipsis" }}
          >
            {dummyDokumen.map((item, i) => {
              return (
                <div
                  id={`pengumumanList${i}`}
                  class="tabelPengumumanItem monsterratSemiBold f1r w100 py-2 pointer"
                  style={{
                    textOverflow: "ellipsis",
                  }}
                  onClick={(e) => {
                    setSelected(i);
                  }}
                >
                  {item.title}
                </div>
              );
            })}
          </div>
          <div class="pengumumanTabelWaran px-5">
            <div class="f12r monsterratSemiBold blue-text-1 mb-5">
              {dummyDokumen[selected].title}
            </div>
            <div
              class="monsterratMedium"
              dangerouslySetInnerHTML={{
                __html: dummyDokumen[selected].content,
              }}
            ></div>
          </div>
        </div>
      </div>
    );
  };

  const dummyPemberitahuan = [
    {
      date: "05-11-2024",
      id: 1,
      title:
        "Informasi Penerbitan Waran Terstruktur di Bursa Efek Indonesia pada tanggal 26 Februari 2024",
      content: `<div data-v-06b38075="" class="col-md pl-md-2 mt-1 p-0 shout-right-bar"><div data-v-06b38075="" class="functionBoxmax" style="padding: 10px 0px;"</div> <div data-v-06b38075=""><div data-v-06b38075="" id="rightText" class="font-size14 footer-h font-weight-bold">Informasi Penerbitan  Waran Terstruktur di Bursa Efek Indonesia pada tanggal 26 Februari 2024</div> <div data-v-06b38075="" id="rightContent" class="mt-5 shout-text"><p>Kami informasikan bahwa berikut ini adalah detail penerbitan Waran Terstruktur ke-18 yang dicatatkan di Bursa Efek Indonesia pada tanggal 26 Februari 2024&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;</p>
      
      <p>Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;ADRODRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 28/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 2650/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;500,000,000<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;AMRTDRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 50/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 2600/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;500,000,000<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;ANTMDRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 24/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 1500/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;500,000,000<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;ASIIDRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 33/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 5500/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;500,000,000<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;BBCADRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 95/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 9500/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;200,000,000<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;BBNIDRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 58/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 6100/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;200,000,000<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;BUKADRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 15/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 160/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;1,000,000,000<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;INDFDRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 31/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 6500/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; :&nbsp; &nbsp; &nbsp;500,000,000<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;ITMGDRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 90/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 28000/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;200,000,000<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;KLBFDRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 38/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 1500/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;500,000,000<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;MEDCDRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 25/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 1350/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;500,000,000<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;PGASDRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 19/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 1400/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;500,000,000<br>
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br>
      Kode waran&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;UNVRDRCM4A<br>
      Harga perdana&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 36/unit<br>
      Harga pelaksanaan&nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;IDR 2800/unit<br>
      Tanggal pelaksanaan&nbsp;&nbsp; : &nbsp;&nbsp; &nbsp;26 Juni 2024<br>
      Unit Diterbitkan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;&nbsp; &nbsp;300,000,000<br>
      &nbsp;</p>
      </div></div></div>`,
      category: "penerbitan",
    },
    {
      date: "05-11-2024",
      id: 2,
      content: `<div data-v-06b38075="" id="rightContent" class="mt-5 shout-text"><p>Berikut adalah pengumuman untuk waran terstruktur AKRADRCH4A, ARTODRCH4A,BBRIDRCH4A, BMRIDRCH4A, BRPTDRCH4A, CPINDRCH4A, EMTKDRCH4A, GOTODRCH4A, HRUMDRCH4A, INCODRCH4A, MDKADRCH4A, PTBADRCH4A, SMGRDRCH4A, TLKMDRCH4A dan UNTRDRCH4A mengenai periode perdagangan terbaru bertepatan dengan Cuti Bersama Hari Suci Nyepi Tahun Baru Saka 1946.</p>

      <p>Periode perdagangan&nbsp;</p>
      
      <table border="1" cellpadding="1" cellspacing="0" style="width: 500px; border-collapse: collapse; border-spacing: 0px;">
        <tbody>
          <tr>
            <td style="padding: 1px; text-align: center;">Kegiatan</td>
            <td style="padding: 1px; text-align: center;">Jadwal Awal</td>
            <td style="padding: 1px; text-align: center;">Jadwal Perubahan</td>
          </tr>
          <tr>
            <td style="padding: 1px; text-align: center;">Periode Perdagangan</td>
            <td style="padding: 1px; text-align: center;">14 Nov 2023 - 11 Mar 2024</td>
            <td style="padding: 1px; text-align: center;">14 Nov 2023 - 7 Mar 2024</td>
          </tr>
        </tbody>
      </table>
      
      <p>&nbsp;</p>
      
      <p>Term Sheet Terbaru&nbsp;<a href="https://www.warrantindonesia.co.id/id/Document" target="_blank">https://www.warrantindonesia.co.id/id/Document</a></p>
      
      <p>&nbsp;</p>
      </div>`,
      title:
        "Perubahan Tanggal Perdagangan Terakhir pada Waran Terstruktur Penerbitan ke-15",
      category: "perubahan",
    },
    {
      date: "05-11-2024",
      id: 3,
      content: `<div data-v-06b38075="" id="rightContent" class="mt-5 shout-text"><p>Berikut adalah pengumuman untuk waran terstruktur AKRADRCH4A, ARTODRCH4A,BBRIDRCH4A, BMRIDRCH4A, BRPTDRCH4A, CPINDRCH4A, EMTKDRCH4A, GOTODRCH4A, HRUMDRCH4A, INCODRCH4A, MDKADRCH4A, PTBADRCH4A, SMGRDRCH4A, TLKMDRCH4A dan UNTRDRCH4A mengenai periode perdagangan terbaru bertepatan dengan Cuti Bersama Hari Suci Nyepi Tahun Baru Saka 1946.</p>

      <p>Periode perdagangan&nbsp;</p>
      
      <table border="1" cellpadding="1" cellspacing="0" style="width: 500px; border-collapse: collapse; border-spacing: 0px;">
        <tbody>
          <tr>
            <td style="padding: 1px; text-align: center;">Kegiatan</td>
            <td style="padding: 1px; text-align: center;">Jadwal Awal</td>
            <td style="padding: 1px; text-align: center;">Jadwal Perubahan</td>
          </tr>
          <tr>
            <td style="padding: 1px; text-align: center;">Periode Perdagangan</td>
            <td style="padding: 1px; text-align: center;">14 Nov 2023 - 11 Mar 2024</td>
            <td style="padding: 1px; text-align: center;">14 Nov 2023 - 7 Mar 2024</td>
          </tr>
        </tbody>
      </table>
      
      <p>&nbsp;</p>
      
      <p>Term Sheet Terbaru&nbsp;<a href="https://www.warrantindonesia.co.id/id/Document" target="_blank">https://www.warrantindonesia.co.id/id/Document</a></p>
      
      <p>&nbsp;</p>
      </div>`,
      title:
        "Tanggal Kadaluwarsa Perdagangan Terakhir pada Waran Terstruktur Penerbitan ke-15",
      category: "kadaluwarsa",
    },
    {
      date: "05-11-2024",
      id: 4,
      content: `<div data-v-06b38075="" id="rightContent" class="mt-5 shout-text"><p>Berikut adalah pengumuman untuk waran terstruktur AKRADRCH4A, ARTODRCH4A,BBRIDRCH4A, BMRIDRCH4A, BRPTDRCH4A, CPINDRCH4A, EMTKDRCH4A, GOTODRCH4A, HRUMDRCH4A, INCODRCH4A, MDKADRCH4A, PTBADRCH4A, SMGRDRCH4A, TLKMDRCH4A dan UNTRDRCH4A mengenai periode perdagangan terbaru bertepatan dengan Cuti Bersama Hari Suci Nyepi Tahun Baru Saka 1946.</p>

      <p>Periode perdagangan&nbsp;</p>
      
      <table border="1" cellpadding="1" cellspacing="0" style="width: 500px; border-collapse: collapse; border-spacing: 0px;">
        <tbody>
          <tr>
            <td style="padding: 1px; text-align: center;">Kegiatan</td>
            <td style="padding: 1px; text-align: center;">Jadwal Awal</td>
            <td style="padding: 1px; text-align: center;">Jadwal Perubahan</td>
          </tr>
          <tr>
            <td style="padding: 1px; text-align: center;">Periode Perdagangan</td>
            <td style="padding: 1px; text-align: center;">14 Nov 2023 - 11 Mar 2024</td>
            <td style="padding: 1px; text-align: center;">14 Nov 2023 - 7 Mar 2024</td>
          </tr>
        </tbody>
      </table>
      
      <p>&nbsp;</p>
      
      <p>Term Sheet Terbaru&nbsp;<a href="https://www.warrantindonesia.co.id/id/Document" target="_blank">https://www.warrantindonesia.co.id/id/Document</a></p>
      
      <p>&nbsp;</p>
      </div>`,
      title:
        "Perubahan Tanggal Penyelesaian Perdagangan Terakhir pada Waran Terstruktur Penerbitan ke-15",
      category: "penyelesaian",
    },
    {
      date: "05-11-2024",
      id: 5,
      content: `<div data-v-06b38075="" id="rightContent" class="mt-5 shout-text"><p>Berikut adalah pengumuman untuk waran terstruktur AKRADRCH4A, ARTODRCH4A,BBRIDRCH4A, BMRIDRCH4A, BRPTDRCH4A, CPINDRCH4A, EMTKDRCH4A, GOTODRCH4A, HRUMDRCH4A, INCODRCH4A, MDKADRCH4A, PTBADRCH4A, SMGRDRCH4A, TLKMDRCH4A dan UNTRDRCH4A mengenai periode perdagangan terbaru bertepatan dengan Cuti Bersama Hari Suci Nyepi Tahun Baru Saka 1946.</p>

      <p>Periode perdagangan&nbsp;</p>
      
      <table border="1" cellpadding="1" cellspacing="0" style="width: 500px; border-collapse: collapse; border-spacing: 0px;">
        <tbody>
          <tr>
            <td style="padding: 1px; text-align: center;">Kegiatan</td>
            <td style="padding: 1px; text-align: center;">Jadwal Awal</td>
            <td style="padding: 1px; text-align: center;">Jadwal Perubahan</td>
          </tr>
          <tr>
            <td style="padding: 1px; text-align: center;">Periode Perdagangan</td>
            <td style="padding: 1px; text-align: center;">14 Nov 2023 - 11 Mar 2024</td>
            <td style="padding: 1px; text-align: center;">14 Nov 2023 - 7 Mar 2024</td>
          </tr>
        </tbody>
      </table>
      
      <p>&nbsp;</p>
      
      <p>Term Sheet Terbaru&nbsp;<a href="https://www.warrantindonesia.co.id/id/Document" target="_blank">https://www.warrantindonesia.co.id/id/Document</a></p>
      
      <p>&nbsp;</p>
      </div>`,
      title: "Server Maintenance",
      category: "etc",
    },
  ];

  return (
    <>
      <button
        id="faqBackButton"
        class=" monsterratMedium blue-btn rounded-pill grey flex  f1r px-4 mb-3 center-vertical "
        onClick={(e) => {
          navigate("/produk/waran-terstruktur", {
            replace: true,
            state: { toAnnouncement: true },
          });
        }}
        style={{
          backgroundColor: "transparent",
          border: "2px solid #787878",
          transition: "0s",
        }}
      >
        <img src={faqArrow} class="faqBackArrow center-vertical"></img>Back
      </button>
      <div class="w100">
        <div
          class="produkMobileContainer  text-center monsterratExtraBold mt-3 mx-auto  f275r  blue-text-1 mw90768"
          style={{ textTransform: "capitalize" }}
        >
          {category.title}
        </div>
        <div class="monsterratMedium grey mt-2 mb-2 jenisAkunText text-center  f1r mw60 mw90768"></div>{" "}
        <div
          class="w80 py-4 mx-auto flex  f1r "
          style={{ marginBottom: "6rem" }}
        >
          {/* <div class="pengumumanTabelWaranList">
            {dummyJatuhTempo.map((item, i) => {
              return (
                <div
                  id={`pengumumanList${i}`}
                  class="tabelPengumumanItem monsterratSemiBold f1r w100 py-2 pointer"
                  onClick={(e) => {
                    setSelected(i);
                  }}
                >
                  Tanggal Pencatatan : {moment(item.date).format("DD-MMM-YYYY")}
                </div>
              );
            })}
          </div> */}
          <div class="pengumumanTabelWaran">
            {" "}
            <div class="w90 mx-auto mb-5 pb-5 " style={{ overflow: "auto" }}>
              <table
                id="searchForm"
                className="table  table-striped table-warrant table-pengumuman table-pengumuman "
                style={{
                  textAlign: "center",
                  fontSize: 12,
                }}
              >
                <thead style={{}}>
                  <tr style={{}}>
                    <th
                      className="py-4 white text-center mx-auto  blue-border monsterratMedium  fixedTableCol"
                      style={{
                        backgroundColor: "#0271bf",
                        width: "15%",
                        borderTopLeftRadius: 20,
                      }}
                    >
                      Tanggal Pengumuman
                    </th>{" "}
                    <th
                      className="py-4 white text-center mx-auto  blue-border monsterratMedium  fixedTableCol"
                      style={{
                        backgroundColor: "#0271bf",
                        width: "75%",
                        borderLeft: "1px solid white",
                        borderRight: "1px solid white",
                      }}
                    >
                      Deskripsi
                    </th>{" "}
                    <th
                      className="py-4 white text-center mx-auto  th_waran blue-border monsterratMedium  fixedTableCol"
                      style={{
                        backgroundColor: "#0271bf",
                        width: "15%",
                        borderTopRightRadius: 20,
                        fontSize: 12,
                      }}
                    >
                      Selengkapnya
                    </th>{" "}
                    
                  </tr>
                </thead>
                <tbody id="formData " style={{ fontSize: 12 }}>
                  {data.map((data, i) => {
                    return (
                      <tr className="form_c">
                        <td
                          className=" monsterratMedium py-2 search-td-h noLeftBorder blue-text-1 fixedTableCol "
                          style={{
                            borderRight: "1px solid transparent ",
                            borderLeft: "1px solid white ",
                            color: "black",
                          }}
                        >
                          {data.shout_out_date ? (
                            <> {moment(data.shout_out_date).format("DD-MMM-YYYY")}</>
                          ) : (
                            <span class="hidden px-2 ">|</span>
                          )}
                        </td>
                        <td className="  monsterratMedium py-2 tdRhb noLeftBorder text-left px-5 ">
                          {" "}
                          {data.title ? (
                            data.title
                          ) : (
                            <span class="hidden">|</span>
                          )}
                        </td>
                        <td
                          className=" monsterratMedium py-2 c_one_1 tdRhb center-vertical text-center"
                          style={{
                            borderLeft: "1px solid white",
                          }}
                        >
                          {data.title ? (
                            <div class="rounded pill center-vertical mx-auto">
                              {" "}
                              <button
                                id="faqBackButton"
                                class=" monsterratMedium blue-btn rounded-pill flex  grey   px-2  center-vertical "
                                onClick={(e) => {
                                  navigate(
                                    `/produk/waran-terstruktur/pengumuman/${data.shout_out_type_id}/${data.shout_out_id}`,
                                    {
                                      replace: true,
                                      state: { from: category },
                                    }
                                  );
                                }}
                                style={{
                                  backgroundColor: "transparent",
                                  border: "1px solid #787878",
                                  transition: "0s",
                                }}
                              >
                                <span
                                  style={{ marginRight: "0.3rem" }}
                                  class="mr-1"
                                >
                                  Baca
                                </span>{" "}
                                <img
                                  style={{
                                    marginRight: "0rem",
                                    transform: "rotate(270deg)",
                                    height:'0.55rem'
                                  }}
                                  src={faqArrow}
                                  class="faqBackArrow center-vertical"
                                ></img>
                              </button>
                            </div>
                          ) : (
                            <span class="hidden px-2 f09r">|</span>
                          )}
                        </td>

                        {/* Repeat the same structure for other table data cells */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function BackButton() {
  const [offset, setOffset] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    // console.log(page.substring(1, page.length), page);
    // document.getElementById(page).className +=
    //   " subPathActive";
    console.log(offset, document.documentElement.offsetHeight);

    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, [offset]);
  return (
    <>
      <button
        id="faqBackButton"
        class=" monsterratMedium blue-btn rounded-pill flex  f1r px-4 mb-3 "
        onClick={(e) => {
          navigate("/produk/waran_terstruktur");
        }}
      >
        Keterangan :
      </button>
    </>
  );
}
