import "./style.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import download from "../../assets/download.png";
import moment from "moment";
import {
  getNews,
  setSortedNews,
  setNews,
  setIsDataFetched,
  getPromos,
} from "../../store/action";
import BlogMenu from "../../components/BlogMenu";
import loading from "../../assets/loading.gif";
import search from "../../assets/search.png";
import { host, storageLocation } from "../../config/server";
import {
  setActiveMenu,
  setArticlePage,
  setRnaMenu,
  getResearch,
  setRnaPage,
  setIsSearchActive,
} from "../../store/action";
import promoCIMBSyariah from "../../assets/promoCIMBSyariah.png";
import promoReksadana23 from "../../assets/promoReksadana23.jpg";
import payday_2 from "../../assets/payday_2.png";
import promoETF from "../../assets/promoETF.png";
import promoMargin from "../../assets/promoMargin.png";
import promoSyariah from "../../assets/promoSyariah.jpg";
import idx_islamic_chg from "../../assets/idx_islamic_chg.jpg";
import merdeka78 from "../../assets/merdeka78.jpg";
export default function Blog() {
  const { state } = useLocation();
  let from = "xnxx";
  if (state) {
    from = state.from;
  }
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { page } = useParams();
  const [load, setLoad] = useState(false);
  const [promoList, setPromoList] = useState([]);
  const news = useSelector((state) => state.news);
  const articlePage = useSelector((state) => state.articlePage);
  const isDataFetched = useSelector((state) => state.isDataFetched);
  const sortedNews = useSelector((state) => state.sortedNews);
  const rnaMenu = useSelector((state) => state.rnaMenu);
  const research_list = useSelector((state) => state.research_list);
  const rnaPage = useSelector((state) => state.rnaPage);
  const [isPromoFetched, setIsPromoFetched] = useState(false);
  // const [rnaMenu,setRnaMenu] =  useState('daily')
  const newsTitleFormat = (title) => {
    let result = "";

    for (let i = 0; i < title.length; i++) {
      const element = title[i];
      if (i < 51) {
        result += element;
      }
      if (i == title.length - 1) {
        result += "...";
      }
    }
    // console.log(result);

    return result;
  };

  const rnaPagehandle = (page) => {
    dispatch(setRnaPage(page));
  };
  const responsive = () => {
    if (window.innerWidth > 768) {
      if (page == "artikel") {
        // console.log("sokin", document.getElementById("mainBlogTitle"));
        document.getElementById("mainArticleContainer").style.height =
          window.innerWidth / 80 + "rem";
        document.getElementById("sideImgContainer1").style.height =
          (window.innerWidth / 80) * 0.4427 + "rem";
        document.getElementById("sideImgContainer1").style.width =
          (window.innerWidth / 80) * 0.4427 + "rem";
        document.getElementById("sideImgContainer2").style.height =
          (window.innerWidth / 80) * 0.4427 + "rem";
        document.getElementById("sideImgContainer2").style.width =
          (window.innerWidth / 80) * 0.4427 + "rem";
        // console.log(
        //   document.getElementsByClassName("blogTitle"),
        //   "tayo",
        //   document.getElementsByClassName("subPath")
        // );
        const allTitle = document.getElementsByClassName("blogTitle");
        for (let i = 0; i < allTitle.length; i++) {
          const element = allTitle[i];
          element.style.fontSize = window.innerWidth * 0.00078125 + "rem";
        }
        document.getElementById("mainBlogTitle").style.fontSize =
          window.innerWidth * 0.00096354166666 + "rem";
        const allSmallImg = document.getElementsByClassName(
          "smallImageContainer"
        );
        for (let i = 0; i < allSmallImg.length; i++) {
          const element = allSmallImg[i];
          element.style.height = window.innerWidth * 0.00911458333 + "rem";
        }
      }
      if (page == "promo") {
        const allTitle = document.getElementsByClassName("blogTitle");
        for (let i = 0; i < allTitle.length; i++) {
          const element = allTitle[i];
          element.style.fontSize = window.innerWidth * 0.00078125 + "rem";
        }
        const allThumbnail = document.getElementsByClassName("promoThumbnail")
        for (let i = 0; i < allThumbnail.length; i++) {
          const element = allThumbnail[i];
          element.style.height = window.innerWidth * 0.01302083333333 + "rem"
        }
      }
    }else{
      if (page == "promo") {
     
        const allThumbnail = document.getElementsByClassName("promoThumbnail")
        for (let i = 0; i < allThumbnail.length; i++) {
          const element = allThumbnail[i];
          element.style.height = window.innerWidth * 0.03302083333333 + "rem"
        }
      }
    }
    
  };
  // responsive()
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  const promoListOffline = [
    // {
    //   isOffline: true,
    //   name: "Payday",
    //   thumbnail: payday_2,
    //   title: "Program PAYDAY Promo Cashback 100 Ribu",
    //   slug: "/blog/promo/event_payday_november23",
    //   info: "By Admin | 21/11/2023",
    // },
    // {
    //   isOffline: true,
    //   name: "Payday",
    //   thumbnail: promoReksadana23,
    //   title:
    //     "Program PAYDAY Promo Cashback Bagi Yang Melakukan Transaksi Reksa Dana",
    //   slug: "/blog/promo/event_payday",
    //   info: "By Admin | 23/10/2023",
    // },
    // {
    //   isOffline: true,
    //   name: "CIMB Syariah",
    //   thumbnail: promoCIMBSyariah,
    //   title: "Promo Cashback Bagi Yang Menggunakan RDN CIMB SYARIAH",
    //   slug: "/blog/promo/cimb_syariah",
    //   info: "By Admin | 31/08/2023",
    // },
    // {
    //   isOffline: true,
    //   name: "Giveaway KISI merayakan kemerdekaan 78",
    //   thumbnail: merdeka78,
    //   title: "Giveaway KISI Merayakan Kemerdekaan 78",
    //   slug: "/blog/promo/event_merdeka_78",
    //   info: "By Admin | 16/08/2023",
    // },
    // {
    //   isOffline: true,
    //   name: "Idx Islamic Challenge",
    //   thumbnail: idx_islamic_chg,
    //   title: "IDX Islamic Challenge",
    //   slug: "/blog/promo/idx_islamic_chg",
    //   info: "By Admin | 20/07/2023",
    // },

    // {
    //   isOffline: true,
    //   name: "Promo Syariah",
    //   thumbnail: promoSyariah,
    //   title: "Buka Rekening Syariah",
    //   slug: "/produk/syariah",
    //   info: "By Admin | 22/10/2022",
    // },
    // {
    //   isOffline: true,
    //   name: "Promo Margin",
    //   thumbnail: promoMargin,
    //   title: "Promosi Akun Margin",
    //   slug: "/blog/promo/promo_margin",
    //   info: "By Admin | 22/10/2022",
    // },
    // {
    //   isOffline: true,
    //   name: "Promo ETF",
    //   thumbnail: promoETF,
    //   title: "Promo Exchange Traded Fund",
    //   slug: "/blog/promo/promo_etf",
    //   info: "By Admin | 22/10/2022",
    // },
  ];

  useEffect(() => {
    dispatch(getResearch());
    dispatch(getNews(articlePage));
    dispatch(getPromos()).then((result) => {
      // console.log("gagsag", result);
      setPromoList(result.concat(promoListOffline));
      setIsPromoFetched(true);
      // console.log(promoList);
    });
    // setPromoList(promoListOffline);
    // setIsPromoFetched(true)
    // dispatch(setIsDataFetched(true));
    // console.log(document.getElementById("mainArticleContainer"));
    // document.getElementById("mainArticleContainer").style.height =
    // window.innerWidth / 80 + "rem"
    if (isDataFetched || page == "promo") {
      responsive();
    }
    // console.log( document.getElementsByClassName("subPathSpacer")[0], "xyz");
    if (from) {
      window.scrollTo(0, 1);
      // document.getElementById("blogSpacer").style.height = "10px !Important";
    }
    if (page == "rna") {
      // console.log("cocok");
      window.scrollTo(0, 0);
      // document.getElementById("blogSpacer").style.height = "10px!Important";
    } else {
      window.scrollTo(0, 0);
    }
    dispatch(setActiveMenu("Blog"));

    let cb = function (e) {
      // console.log("event happened");
      // console.log("width: ", window.innerWidth);
      // console.log("height", window.innerHeight);
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
      document.getElementById("mainArticleContainer").style.height =
        window.innerWidth / 80 + "rem";
      responsive();
    };
    // cb()
    // window.onresize = cb;
    window.addEventListener("resize", cb);

    return () => window.removeEventListener("resize", cb);
  }, [page, from, isDataFetched]);
  if (!isDataFetched) {
    // console.log("bbom");
    // dispatch(getNews())
    // .then((news) => {
    //   // console.log(news);
    //   var sortedNews = [].concat(news);
    //   sortedNews.sort((a, b) => {
    //     return b.views - a.views;
    //   });
    //   return dispatch(setSortedNews(sortedNews));
    // })
    // .catch((error) => {
    //   console.log(error);
    // });
  }
  function compareDate(a, b) {
    if (a.date > b.date) {
      return -1;
    }
    if (a.date < b.date) {
      return 1;
    }
    return 0;
  }

  const getNewsHandle = (targetPage) => {
    window.scrollTo(0, 1);
    dispatch(setNews([]));
    dispatch(setArticlePage(targetPage));
    dispatch(getNews(targetPage)).then((res) => {
      // console.log(res, "blogq");
      responsive();
    });
  };
  const goToArticle = (id) => {
    navigate(`/artikel/${id}`);
  };
  const handleSetRnaMenu = (par) => {
    dispatch(setRnaPage(1));
    dispatch(setRnaMenu(par));
  };
  const dateParser = (date) => {
    let result = "";
    for (let i = 9; i >= 0; i--) {
      if (i == 8) {
        result += date[8];
        result += date[9];
      }
      if (i == 7 || i == 4) {
        result += "/";
      }
      if (i == 5) {
        result += date[5];
        result += date[6];
      }
      if (i == 0) {
        result += date[0];
        result += date[1];
        result += date[2];
        result += date[3];
      }
    }
    return result;
  };
  const filterBy = (rna) => {
    // console.log(rna.type.toLowerCase(),rnaMenu);
    if (rna.type) {
      return rna.type.toLowerCase() == rnaMenu;
    } else {
      return rna;
    }
  };
  const RnaTable = () => {
    return (
      <div class=" mh20r ">
        <table
          className="table table-bordered  table-striped dataTable no-footer monsterratMedium  rnaTable"
          id="datatable"
        >
          <thead>
            <tr role="row" class="rnaRow">
              <th
                class="sorting namaRow px-5"
                tabindex="0"
                aria-controls="datatable"
                rowspan="1"
                colspan="1"
                aria-label="NAMA BERKAS: activate to sort column ascending"
              >
                NAMA BERKAS
              </th>
              <th
                class="sorting_desc tanggalRow text-center"
                tabindex="0"
                aria-controls="datatable"
                rowspan="1"
                colspan="1"
                aria-sort="descending"
                aria-label="TANGGAL: activate to sort column ascending"
              >
                TANGGAL
              </th>
              <th
                class="sorting downloadRow text-center"
                tabindex="0"
                aria-controls="datatable"
                rowspan="1"
                colspan="1"
                aria-label="UNDUH: activate to sort column ascending"
              >
                UNDUH
              </th>
            </tr>
          </thead>
          <tbody>
            {/* {JSON.stringify(research_list)} */}
            {research_list
              .sort(compareDate)
              .filter(filterBy)
              .slice((rnaPage - 1) * 10, rnaPage * 10)
              .map((rna, i) => {
                return (
                  <>
                    <tr role="row" class="odd" key={i}>
                      <td class="px-">{rna.title}</td>
                      <td class="sorting_1 text-center">
                        {moment(rna.date).format("DD/MM/YYYY")}
                      </td>
                      <td class="text-center">
                        <a
                          href={`${host}${storageLocation}${
                            JSON.parse(rna.document_file)[0].download_link
                          }`}
                          target="_blank"
                        >
                          <img class="downloadImgRiset" src={download} />
                        </a>
                      </td>
                    </tr>
                  </>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  };
  var x = [1, 2, 3, 4, 5, 6];

  const Article = () => {
    return (
      <div className="articlePageContainer">
        {/* <div class="blogPage flex mt-5">
    <div class="px-4 blogLeft monsterratSemiBold grey w50">Home</div>
    <div class="monsterratSemiBold grey">|</div>
    <div class="px-4  monsterratSemiBold grey">Blog</div>
  </div> */}
        <div class="f25r blue-text-1 monsterratExtraBold my-3 text-center">
          Ada Apa di KOINS?
        </div>
        <div class="artikelSubtitle monsterratMedium grey f1r text-center mx-auto">
          Anda akan mendapatkan informasi mengenai berita pasar, promo, dan
          masih banyak lagi.
        </div>

        {news.length > 0 ? (
          <>
            <div class="topArticleContainer">
              <div>
                <div
                  title={news[0].title}
                  id="mainArticleContainer"
                  class="mainArticleContainer px-4"
                  style={{
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url(${news[0].images})`,
                  }}
                  onClick={() => goToArticle(news[0].news_id)}
                >
                  <div class="mainArticleHeading">
                    <div class="monsterratMedium f065r">{`By IQPlus | ${news[0].days_ago} | ${news[0].subject}`}</div>
                    <div
                      id="mainBlogTitle"
                      title={news[0].title}
                      class="monsterratBold"
                    >
                      {news[0].title}
                    </div>
                  </div>
                </div>
              </div>
              <div class="sideContainer">
                <div
                  title={news[1].title}
                  class="sideArticleContainer "
                  onClick={() => goToArticle(news[1].news_id)}
                >
                  <div
                    id="sideImgContainer1"
                    class="sideImgContainer"
                    style={{
                      backgroundImage: `url(${news[1].images})`,
                      backgroundPosition: "0% 0%",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    {/* <img id="sideImg1" class="sideImg" src={news[1].image}></img> */}
                  </div>
                  <div class="sideNewsTitle center-vertical">
                    <div class="grey blogTitle monsterratMedium">
                      {news[1].title}
                      <div class="monsterratMedium f065r">{`By IQPlus | ${news[1].days_ago} | ${news[1].subject}`}</div>
                    </div>
                  </div>
                </div>
                <div
                  class="sideArticleContainer bottom-vertical-noflex"
                  onClick={() => goToArticle(news[2].news_id)}
                >
                  <div
                    title={news[2].title}
                    id="sideImgContainer2"
                    class="sideImgContainer "
                    style={{
                      backgroundImage: `url(${news[2].images})`,
                      backgroundPosition: "0% 0%",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "bottom",
                    }}
                  >
                    {/* <img id="sideImg1" class="sideImg" src={news[1].image}></img> */}
                  </div>

                  <div
                    style={{ height: "100%" }}
                    class="sideNewsTitle center-vertical titleBottom"
                  >
                    <div class="">
                      <div
                        title={news[2].title}
                        class="grey blogTitle monsterratMedium"
                      >
                        {news[2].title}
                        <div class="monsterratMedium f065r">{`By IQPlus | ${news[2].days_ago} | ${news[2].subject}`}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {windowSize.innerWidth > 768 ? (
              <div class="flex gridNewsContainer">
                {news.map((news, i) => {
                  if (i > 2 && i < 9) {
                    return (
                      <div
                        class="gridArticleWrapper "
                        title={news.title}
                        onClick={() => goToArticle(news.news_id)}
                      >
                        <div
                          class="smallImageContainer"
                          style={{
                            backgroundImage: `url(${news.images})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                          }}
                        ></div>
                        <div class="articleInfoContainer">
                          <div class=" monsterratMedium f075r  grey articleInfo ">
                            <div class="mt-1 monsterratMedium  blogTitle">
                              {newsTitleFormat(news.title)}
                            </div>
                            <div class="monsterratMedium f065r mt-2">{`By IQPlus | ${news.days_ago} | ${news.subject}`}</div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            ) : (
              <div class="flex gridNewsContainer">
                {news.map((news, i) => {
                  if (i > 2 && i < 9) {
                    return (
                      <div
                        class="gridArticleWrapper "
                        title={news.title}
                        onClick={() => goToArticle(news.news_id)}
                      >
                        <div
                          class="smallImageContainer"
                          style={{
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundImage: `url(${news.images})`,
                          }}
                        ></div>
                        <div class="articleInfoContainer">
                          <div class=" monsterratMedium f075r  grey articleInfo ">
                            <div class="mt-1 monsterratMedium  blogTitle">
                              {newsTitleFormat(news.title)}
                            </div>
                            <div class="monsterratMedium f065r mt-2">{`By IQPlus | ${news.days_ago} | ${news.subject}`}</div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            )}
          </>
        ) : (
          <div class="gridArticleWrapper mx-auto">
            <div clas="mx-auto">
              <div
                class="smallImageContainer"
                style={{
                  background: `url(${loading})`,
                  backgroundSize: "auto",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              ></div>
            </div>
            <div clas="mx-auto">
              <div
                class="smallImageContainer"
                style={{
                  background: `url(${loading})`,
                  backgroundSize: "auto",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              ></div>
            </div>
            <div clas="mx-auto">
              <div
                class="smallImageContainer"
                style={{
                  background: `url(${loading})`,
                  backgroundSize: "auto",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              ></div>
            </div>
          </div>
        )}

        {articlePage == 1 ? (
          <div class="text-center mx-auto pt-5" style={{ marginTop: "5rem" }}>
            <button class="numberButton numberButtonDisabled monsterratSemiBold f12r mx-2">
              «
            </button>
            <button class="numberButton numberButtonDisabled monsterratSemiBold f12r mx-2">
              ‹
            </button>
            <span class="mx-2"></span>
            <button class="numberButton numberButtonActive monsterratSemiBold f12r mx-2">
              1
            </button>
            <button
              onClick={() => {
                getNewsHandle(2);
              }}
              class="numberButton monsterratSemiBold f12r mx-2"
            >
              2
            </button>
            <button
              onClick={() => {
                getNewsHandle(3);
              }}
              class="numberButton monsterratSemiBold f12r mx-2"
            >
              3
            </button>
            <span class="mx-2"></span>
            <button
              onClick={() => {
                getNewsHandle(2);
              }}
              class="numberButton monsterratSemiBold f12r mx-2"
            >
              ›
            </button>
            <button class="numberButton monsterratSemiBold f12r numberButtonDisabled mx-2">
              »
            </button>
          </div>
        ) : (
          <div class="text-center mx-auto pt-5" style={{ marginTop: "5rem" }}>
            <button
              onClick={() => {
                getNewsHandle(1);
              }}
              class="numberButton monsterratSemiBold f12r mx-2"
            >
              «
            </button>
            <button
              onClick={() => {
                getNewsHandle(articlePage - 1);
              }}
              class="numberButton monsterratSemiBold f12r mx-2"
            >
              ‹
            </button>
            <span class="mx-2"></span>
            <button
              onClick={() => {
                getNewsHandle(articlePage - 1);
              }}
              class="numberButton  monsterratSemiBold f12r mx-2"
            >
              {articlePage - 1}
            </button>
            <button class="numberButton numberButtonActive monsterratSemiBold f12r mx-2">
              {articlePage}
            </button>
            <button
              onClick={() => {
                getNewsHandle(articlePage + 1);
              }}
              class="numberButton monsterratSemiBold f12r mx-2"
            >
              {articlePage + 1}
            </button>
            <span class="mx-2"></span>
            <button
              onClick={() => {
                getNewsHandle(articlePage + 1);
              }}
              class="numberButton monsterratSemiBold f12r mx-2"
            >
              ›
            </button>
            <button class="numberButton numberButtonDisabled monsterratSemiBold f12r mx-2">
              »
            </button>
          </div>
        )}

        <div style={{ height: "5rem" }}></div>
      </div>
    );
  };

  const ResearchAnalysis = () => {
    return (
      <div>
        <div id="rnaPath" class="subPathWrapperpb-5">
          <div class="subPathContainer appPageLocation">
            <div
              className="subPath rnaSubPath monsterratMedium rounded-pill mx-3"
              id="daily"
              onClick={() => handleSetRnaMenu("daily")}
            >
              Daily Update
            </div>
            <div
              className="subPath rnaSubPath monsterratMedium rounded-pill mx-3"
              id="weekly"
              onClick={() => handleSetRnaMenu("weekly")}
            >
              Research Company
            </div>
            <div
              className="subPath rnaSubPath monsterratMedium rounded-pill mx-3"
              id="monthly"
              onClick={() => handleSetRnaMenu("monthly")}
            >
              Strategy Update
            </div>
          </div>
        </div>
        <div class="w60 mx-auto mt-5">
          <div class="f25r  mb-4 blue-text-1 monsterratExtraBold">
            Riset dan Analisa Bulanan
          </div>
          <div class="monsterratMedium grey f1r">
            Dengan menggunakan teknologi untuk membantu investor
            memvisualisasikan data keuangan, kami memungkinkan pelanggan kami
            untuk menganalisa pasar, mengamati, dan mengelola portofolio mereka
            dengan lebih baik, menemukan saham dan dana baru yang menjanjikan
            untuk dibeli, dan akhirnya membuat keputusan investasi cerdas dan
            tepat waktu. Untuk membantu Anda mempelajari lebih lanjut tentang
            tren saham, kami telah menyediakan dokumen analisis yang bermanfaat.
          </div>
        </div>
        <RnaTable />
        {rnaPage == 1 ? (
          <div class="text-center mx-auto pt-5">
            <button class="numberButton numberButtonDisabled monsterratSemiBold f12r mx-2">
              «
            </button>
            <button class="numberButton numberButtonDisabled monsterratSemiBold f12r mx-2">
              ‹
            </button>
            <span class="mx-2"></span>
            <button class="numberButton numberButtonActive monsterratSemiBold f12r mx-2">
              1
            </button>
            {research_list.sort(compareDate).filter(filterBy).length < 11 ? (
              <>
                <button class="numberButton numberButtonDisabled monsterratSemiBold f12r mx-2">
                  2
                </button>
                <button class="numberButton numberButtonDisabled monsterratSemiBold f12r mx-2">
                  3
                </button>
                <span class="mx-2"></span>
                <button class="numberButton numberButtonDisabled monsterratSemiBold f12r mx-2">
                  ›
                </button>
                <button class="numberButton numberButtonDisabled monsterratSemiBold f12r mx-2">
                  »
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={() => {
                    rnaPagehandle(2);
                  }}
                  class="numberButton monsterratSemiBold f12r mx-2"
                >
                  2
                </button>
                <button
                  onClick={() => {
                    rnaPagehandle(3);
                  }}
                  class="numberButton monsterratSemiBold f12r mx-2"
                >
                  3
                </button>
                <span class="mx-2"></span>
                <button
                  onClick={() => {
                    rnaPagehandle(2);
                  }}
                  class="numberButton monsterratSemiBold f12r mx-2"
                >
                  ›
                </button>
                <button
                  onClick={() =>
                    rnaPagehandle(
                      Math.floor(
                        research_list.sort(compareDate).filter(filterBy)
                          .length /
                          10 +
                          1
                      )
                    )
                  }
                  class="numberButton monsterratSemiBold f12r mx-2"
                >
                  »
                </button>
              </>
            )}
          </div>
        ) : (
          <div class="text-center mx-auto pt-5">
            <button
              onClick={() => {
                rnaPagehandle(1);
              }}
              class="numberButton monsterratSemiBold f12r mx-2"
            >
              «
            </button>
            <button
              onClick={() => {
                rnaPagehandle(rnaPage - 1);
              }}
              class="numberButton monsterratSemiBold f12r mx-2"
            >
              ‹
            </button>
            <span class="mx-2"></span>
            <button
              onClick={() => {
                rnaPagehandle(rnaPage - 1);
              }}
              class="numberButton  monsterratSemiBold f12r mx-2"
            >
              {rnaPage - 1}
            </button>
            <button class="numberButton numberButtonActive monsterratSemiBold f12r mx-2">
              {rnaPage}
            </button>

            {research_list
              .sort(compareDate)
              .filter(filterBy)
              .slice((rnaPage - 1) * 10, rnaPage * 10).length < 10 ? (
              <>
                <button class="numberButton numberButtonDisabled monsterratSemiBold f12r mx-2">
                  {rnaPage + 1}
                </button>
                <span class="mx-2"></span>
                <button class="numberButton numberButtonDisabled monsterratSemiBold f12r mx-2">
                  ›
                </button>
                <button class="numberButton numberButtonDisabled monsterratSemiBold f12r mx-2">
                  »
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={() => {
                    rnaPagehandle(rnaPage + 1);
                  }}
                  class="numberButton monsterratSemiBold f12r mx-2"
                >
                  {rnaPage + 1}
                </button>
                <span class="mx-2"></span>
                <button
                  onClick={() => {
                    rnaPagehandle(rnaPage + 1);
                  }}
                  class="numberButton monsterratSemiBold f12r mx-2"
                >
                  ›
                </button>
                <button
                  onClick={() => {
                    // console.log(Math.floor(research_list));
                    // console.log(
                    //   research_list.sort(compareDate).filter(filterBy).length
                    // );

                    rnaPagehandle(
                      Math.floor(
                        research_list.sort(compareDate).filter(filterBy)
                          .length /
                          10 +
                          1
                      )
                    );
                  }}
                  class="numberButton monsterratSemiBold f12r mx-2"
                >
                  »
                </button>
              </>
            )}
          </div>
        )}
        <div style={{ height: "9vh" }}></div>
      </div>
    );
  };
  const openInNewTab = (url) => {
    console.log(url);
    var win = window.open(url, "_blank");
    win.focus();
  };
  const [imageLoaded, setImageLoaded] = useState({});
  const PromoHome = () => {
    // const promoList = [
    //   {
    //     name: "CIMB Syariah",
    //     thumbnail: promoCIMBSyariah,
    //     text: "Promo Cashback Bagi Yang Menggunakan RDN CIMB SYARIAH",
    //     slug: "/blog/promo/cimb_syariah",
    //     info: "By Admin | 31/08/2023",
    //   },
    //   {
    //     name: "Giveaway KISI merayakan kemerdekaan 78",
    //     thumbnail: merdeka78,
    //     text: "Giveaway KISI Merayakan Kemerdekaan 78",
    //     slug: "/blog/promo/event_merdeka_78",
    //     info: "By Admin | 16/08/2023",
    //   },
    //   {
    //     name: "Idx Islamic Challenge",
    //     thumbnail: idx_islamic_chg,
    //     text: "IDX Islamic Challenge",
    //     slug: "/blog/promo/idx_islamic_chg",
    //     info: "By Admin | 20/07/2023",
    //   },

    //   {
    //     name: "Promo Syariah",
    //     thumbnail: promoSyariah,
    //     text: "Buka Rekening Syariah",
    //     slug: "/produk/syariah",
    //     info: "By Admin | 22/10/2022",
    //   },
    //   {
    //     name: "Promo Margin",
    //     thumbnail: promoMargin,
    //     text: "Promosi Akun Margin",
    //     slug: "/blog/promo/promo_margin",
    //     info: "By Admin | 22/10/2022",
    //   },
    //   {
    //     name: "Promo ETF",
    //     thumbnail: promoETF,
    //     text: "Promo Exchange Traded Fund",
    //     slug: "/blog/promo/promo_etf",
    //     info: "By Admin | 22/10/2022",
    //   },
    // ];

    return (
      <>
        <div class="promoMenuPageContainer">
          <div class="f25r blue-text-1 monsterratExtraBold my-3 text-center">
            Ada Apa di KOINS?
          </div>
          <div class="artikelSubtitle monsterratMedium grey f1r text-center mx-auto">
            Anda akan mendapatkan informasi mengenai berita pasar, promo, dan
            masih banyak lagi.{JSON.stringify(imageLoaded)}
          </div>
          {isPromoFetched ? (
            <div class="promoMenuPageWrapper">
              {promoList.map((promo, i) => {
                return (
                  <div
            
                    class={`promoMenuWrapper`}
                    onClick={(e) => {
                      if (promo.slug) {
                        navigate(`${promo.slug}`);
                      } else {
                        openInNewTab(promo.ext_link);
                      }
                    }}
                  >
                    {promo.isOffline ? (
                      // <img class="promoThumbnail" src={promo.image}></img>
                      <div
                        class="promoThumbnail"
                        style={{ backgroundImage: `url(${promo.thumbnail})` }}
                      ></div>
                    ) : (
                      <div
            
                        class="promoThumbnail"
                        style={{
                          backgroundImage: `url(${
                            host + storageLocation + promo.thumbnail
                          })`,
                        }}
                      ></div>
                    )}
                    <div class="blogTitle promoTitle monsterratMedium grey mt-4">
                      {promo.title}
                    </div>
                    {promo.isOffline ? (
                      <div class="monsterratMedium grey f065r mt-2">
                        {promo.info}
                      </div>
                    ) : (
                      <div class="monsterratMedium grey f065r mt-2">
                        By Admin |{" "}
                        {moment(promo.created_at).format("DD/MM/YYYY")}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          ) : (
            <></>
          )}
          {/* <div class="promoMenuPageWrapper">
            {promoList.map((promo, i) => {
              return (
                <div
                  class="promoMenuWrapper"
                  onClick={(e) => navigate(`${promo.path}`)}
                >
                  <img class="promoThumbnail" src={promo.img}></img>
                  <div class="blogTitle promoTitle monsterratMedium grey mt-4">
                    {promo.text}
                  </div>
                  <div class="monsterratMedium grey f065r mt-2">
                    {promo.info}
                  </div>
                </div>
              );
            })}
          </div> */}
        </div>
      </>
    );
  };
  const BlogContentSwitcher = () => {
    switch (page) {
      case "artikel":
        return Article();
      case "rna":
        return ResearchAnalysis();
      case "promo":
        return PromoHome();
      default:
        break;
    }
  };

  return (
    <div>
      <BlogMenu></BlogMenu>
      {/* {JSON.stringify(news)}
      {JSON.stringify(isDataFetched)} */}
      <div id="blogSpacer" className="subPathSpacer"></div>
      {BlogContentSwitcher()}
      {/* <div class="subPathSpacer"></div> */}
      {/* <div class="subPathWrapper mb-4 pb-5">
        <div class="subPathContainer appPageLocation">
          <div
            className="subPath monsterratMedium rounded-pill mx-3"
            id="artikel"
            onClick={() => navigate("/blog/artikel")}
          >
            Artikel
          </div>
          <div
            className="subPath monsterratMedium rounded-pill mx-3"
            id="rna"
            onClick={() => navigate("/blog/rna")}
          >
            Riset dan Analisis
          </div>
        </div>
      </div> */}
    </div>
  );
}
