import logo from "../../logo.svg";

import PageLocation from "../../components/PageLocation";
import waran1 from "../../assets/waran1.png";
import waran2 from "../../assets/waran2.png";
import waran3 from "../../assets/waran3.png";
import waran4 from "../../assets/waran4.png";
import syariahSertifikatMui from "../../assets/syariahSertifikatMui.pdf";
import manual_order_e_ipo from "../../assets/manual_order_e_ipo.pdf";
import manualPendaftaranEIpo from "../../assets/manualPendaftaranEIpo.pdf";
import koins from "../../assets/koins.png";
import kisiMobile from "../../assets/kisiMobile.png";
import letter from "../../assets/letter.png";
import mail from "../../assets/email.png";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { setActiveMenu } from "../../store/action";
import { useDispatch, useSelector } from "react-redux";
import { server } from "../../config/server";
export function CappingList() {
  const { type } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(type.substring(1, type.length), type);
    // document.getElementById(type).className +=
    //   " subPathActive";
    getData();
    dispatch(setActiveMenu("Produk"));
    switch (type) {
      case "reguler":
        document.getElementById("capping_reguler").className +=
          " subPathActive";
        document.getElementById("capping_margin").className =
          "subPath monsterratMedium rounded-pill mx-3";
        break;

      case "margin":
        // console.log("swituseG");
        document.getElementById("capping_margin").className += " subPathActive";
        document.getElementById("capping_reguler").className =
          "subPath monsterratMedium rounded-pill mx-3";
        break;

      default:
        break;
    }
    if (window.innerWidth > 768) {
      document.getElementById("navBar").style.boxShadow =
        "0px 4px 14px rgb(0 0 0 / 7%)";
    }
  }, [type]);
  const navigate = useNavigate();
  const openInNewTab = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };
  const getData = () => {
    fetch(`${server}/api/v1/capping/list/`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      // body: JSON.stringify(file),
      headers: {
        "Content-Type": "application/json",
        // access_token: localStorage.getItem("access_token"),
      },
    })
      .then((res) => res.json())
      .then(async (data) => {
        if (data.status) {
          console.log("reset", type);
          switch (type) {
            case "reguler":
              setData(data.data["Reguler"]);
              break;
            case "margin":
              setData(data.data["Margin"]);
              break;
          }
        }
        // console.log(data, "promox");
      })

      .catch((err) => {
        // console.log(err);
      });
  };
  return (
    <>
      {type == "reguler" ? (
        <div class="monsterratExtraBold mt-5 mb-4 mx-auto text-center f275r  blue-text-1 mw90768">
          Capping Reguler*
        </div>
      ) : (
        <div class="monsterratExtraBold mt-5 mb-4 mx-auto text-center f275r  blue-text-1 mw90768">
          Capping Margin*
        </div>
      )}
      {/* <div class="monsterratExtraBold mt-5 mb-4 mx-auto text-center f275r  blue-text-1 mw90768">
        List Capping Saham
      </div> */}
      <div
        class="subPathWrapper  pb-5"
        style={{
          paddingTop: "0rem",
        }}
      >
        <div class="subPathContainer appPageLocation">
          <div
            className="subPath monsterratMedium rounded-pill mx-3"
            id="capping_reguler"
            onClick={() => navigate("/capping/reguler")}
          >
            Capping Reguler
          </div>
          <div
            className="subPath monsterratMedium rounded-pill mx-3"
            id="capping_margin"
            onClick={() => navigate("/capping/margin")}
          >
            Capping Margin
          </div>
        </div>
        {/* {JSON.stringify(data)} */}
        <div class="w70 w901000 w851300 mx-auto">
          {type == "reguler" ? (
            <div class="f12r monsterratMedium grey py-5 text-center">
              Merupakan daftar kode saham yang dapat <b>digunakan sebagai
              jaminan limit trading dalam Akun Reguler</b> yang diterbitkan
              PT Korea Investment And Sekuritas Indonesia.
              {/* Daftar emiten (kode saham) yang dapat digunakan sebagai{" "}
              <b>jaminan limit trading dalam akun regular</b> secara persentase
              yang dikeluarkan PT Indo Premier Sekuritas secara periodik. */}
              <div class="f1r py-2">
                *Daftar kode saham dapat berubah sewaktu-waktu sesuai ketentuan
                yang berlaku.
              </div>
            </div>
          ) : (
            <div class="f12r monsterratMedium grey py-5 text-center">
              Merupakan daftar kode saham yang dapat{" "}
              <b>digunakan sebagai jaminan limit trading dalam Akun Margin </b>{" "}
              yang diterbitkan oleh Bursa Efek Indonesia dan disesuaikan oleh PT
              Korea Investment And Sekuritas Indonesia.
              {/* Daftar emiten (kode saham) yang dapat{" "}
              <b> ditransaksikan dan dijaminkan dalam akun margin</b> secara
              persentase yang dikeluarkan Bursa Efek Indonesia secara periodik. */}
              <div class="f1r py-2">
                *Daftar kode saham dapat berubah sewaktu-waktu sesuai ketentuan
                yang berlaku.
              </div>
            </div>
          )}

          <div
            class="flex justify-content-between capping-cont"
            style={{
              flexWrap: "wrap",
            }}
          >
            {data.map((cap, i) => {
              return (
                <div
                  class="capping-item flex justify-content-between mx-5 mb-1"
                  style={{
                    lineHeight: 1.6,
                  }}
                >
                  <div class="f13r monsterratMedium"> {cap.Code}</div>
                  <div class="f13r monsterratMedium">{cap.Capping} %</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
