import logo from '../../logo.svg';
import './style.css';
import React, { useEffect, useState } from "react";
import PageLocation from "../../components/PageLocation";
import strukturOrganisasiDetail from "../../assets/strukturOrganisasiDetail.jpg";
export function StrukturOrganisasi() {
    useEffect(() => {
        window.scrollTo(0, 0);},[])
  return (
    <div >
 <PageLocation left="Home" right="Tentang Kami"/>
<div class="strukturOrganisasiWrapper">
    <div class="strukturOrganisasiContainer">

    <img class="strukturOrganisasiImg"src={strukturOrganisasiDetail}></img>
    </div>
</div>
    </div>
  );
}
