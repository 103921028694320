import "./style.css";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { server } from "../../config/server.js";
import { storageLocation } from "../../config/server";
import faqArrow from "../../assets/faqArrow.png";
import whatsapp from "../../assets/whatsapp.png";
export function CabangById() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    getSalesByBranchId();
    console.log(id);
  }, []);
  const { id } = useParams();
  const [salesList, setSalesList] = useState([]);
  const [branchName, setBranchName] = useState("");
  const getSalesByBranchId = (file) => {
    fetch(server + `/api/v1/about/sales?branch_id=${id}`)
      .then((res) => res.json())
      .then((sales) => {
        console.log(sales);
        setSalesList(sales.data);

        let formatted_name= ''

        
        for (let i = 0; i < sales.data[0].branch_company.branch_name.length; i++) {
            const element = sales.data[0].branch_company.branch_name[i];
            if(element !== ','){
                formatted_name += element 
            }else{
                formatted_name += ' -'
            }
            
        }
        setBranchName(formatted_name);
        // setBranchName(sales.data[0].branch_company.branch_name);
        // openInNewTab(panduan)

        // resolve("success");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <div class="w85 mx-auto mb-5">
        {" "}
        <button
          id=""
          class="backCabang monsterratMedium blue-btn rounded-pill grey flex  f1r px-4 mb-3 center-vertical "
          onClick={(e) => {
            navigate(`/cabang`, {
              replace: true,
              state: { toAnnouncement: true },
            });
          }}
          style={{
            backgroundColor: "transparent",
            border: "2px solid #787878",
            transition: "0s",
            marginTop: "9rem",
          }}
        >
          <img src={faqArrow} class="faqBackArrow center-vertical"></img>Kembali
        </button>
        <div class="f25r monsterratExtraBold blue-text-1 text-center pt-3 pb-5 mb-5">
         <i>Sales</i> {branchName}
        </div>
        <div class="cabangWrapper">
          {salesList.map((b, i) => {
            return (
              <div
                class="eventCard mx-auto pointer mb-5"
                style={{
                  backgroundColor: "white",
                  filter: "drop-shadow(2px 9px 14px #b2a4a4)",
                  height:420
                }}
              >
                <div
                  class="eventPulauImg w100"
                  style={{
                    background: `url(${server}${storageLocation}${b.branch_sales_images_link})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "50% 0%",
                    backgroundColor: "#F3F6F8",
                  }}
                ></div>
                <div
                  class="px-3 pt-3 flex"
                  style={{
                    textAlign: "left",
                    backgroundColor: "white",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div class="f2r blue-text-1 text-center monsterratBold mb-2">
                    {b.branch_sales_nickname}
                  </div>
                  <div class="monsterratMedium grey mb-2 text-center f12r">
                    {b.branch_sales_refferal}
                  </div>

                  <div class="mx-auto mt-3">
                    <a href={`https://wa.me/${b.branch_sales_whatsapp_id}`} target="_blank">
                      {" "}
                      <button
                        class="px-5"
                        style={{
                          backgroundColor: "#60D66A",
                          border: "none",
                          borderRadius:16,
                          paddingTop: 5,
                          paddingBottom:5
                        }}
                      >
                        <img style={{ height: "2rem" }} src={whatsapp}></img>
                        <span class="monsterratMedium white">Hubungi Saya</span>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            );
          })}

          {/* {lokasi == "indonesia" ? (
            <div class="alamat mt-4 mx-3 pt-3 pb-4 px-4 center-vertical text-center">
              <div class="alamatTitle monsterratBlack f2r blue-text-1 comingAddress ">
                Coming Soon
              </div>
            </div>
          ) : null} */}
        </div>
      </div>
    </div>
  );
}
