// import "./style.css";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams,Outlet } from "react-router-dom";
import "./style.css";
import PageLocation from "../../components/PageLocation";
import { Etf } from "./Etf";
import { Margin } from "./Margin";
import { Reksadana } from "./Reksadana";
import { Syariah } from "./Syariah";
import { Saham } from "./Saham";
import { setActiveMenu } from "../../store/action";
import { useDispatch, useSelector } from "react-redux";
export function Products(props) {
  const dispatch = useDispatch();
  const { page } = useParams();
  let DestinationPage;
  useEffect(() => {
    // console.log(page);
    dispatch(setActiveMenu("Produk"));
    // let allSubPath = document.getElementsByClassName("subPath");
    // for (let i = 0; i < allSubPath.length; i++) {
    //   const element = allSubPath[i];
    //   // console.log(element.className);
    //   if (element.className !== page) {
    //     element.className = "subPath monsterratMedium rounded-pill ";
    //   }
    // }
    // document.getElementById(page).className += " subPathActive";
    // switch (page) {
    //     case 'saham':
    //         DestinationPage = <Saham/>
    //         break;
    //         case 'value':

    //         break;
    //         case 'value':

    //         break;
    //         case 'value':

    //         break;
    //         case 'value':

    //         break;

    //     default:
    //         break;
    // }
  }, []);
  const navigate = useNavigate();
  const goTo = (route) => {
    console.log("masuk");
    navigate(route);
  };

  return (
    <div>
      <PageLocation />
      <div class="subPathSpacer subPathProductSpacer"></div>
      <Outlet />
      {/* {page == "saham" ? <Saham /> : null} */}
      {/* {page == "margin" ? <Margin /> : null}
      {page == "etf" ? <Etf /> : null}
      {page == "syariah" ? <Syariah /> : null}
      {page == "reksadana" ? <Reksadana /> : null} */}
    </div>
  );
}
