import "./style.css";
import React, { useEffect, useState, useRef } from "react";

import marginTimeline from "../../assets/marginTimeline.jfif";
import {
  useNavigate,
  useParams,
  Navigate,
  useLocation,
} from "react-router-dom";
export function Disclaimer(props) {
  const discRef = useRef(null);
  const kebRef = useRef(null);
  const infoRef = useRef(null);
  const { state } = useLocation();
  const path = window.location.pathname;
  let page;
  if (state) {
    page = state.page;
  }

  const DisclaimerMargin = () => {
    return (
      <>
        <div class="promoPageContainer">
          <div class="promoPageWrapper">
            <div class="CIMBPageContainer">
              <div class="CIMBPageWrapper">
                <div class="CIMBTextWrapper py-5">
                  <div class="f25r blue-text-1 monsterratExtraBold my-3 text-center">
                    Promo Exchange Traded Fund (ETF)
                  </div>
                  {/* <div class="my-5 f25r nowrap blue-text-1 monsterratExtraBold text-center">
            Syarat & Ketentuan menjadi nasabah ETF
            </div> */}
                  <div class="etfContent f1r monsterratMedium grey">
                    <p>{/* <b>Syarat dan Ketentuan:</b> */}</p>

                    <div class="f15r blue-text-1 monsterratMedium my-3 ">
                      Syarat & Ketentuan
                    </div>

                    <ol>
                      <li class="mt-2">
                        {" "}
                        KISI akan memberikan ETF XKMS sebanyak 1 (satu) lot
                        secara gratis kepada setiap Nasabah individu (retail)
                        yang melakukan pembukaan rekening efek untuk pertama
                        kalinya di KISI.
                      </li>{" "}
                      <li class="mt-2">
                        {" "}
                        Promo ini hanya berlaku bagi Nasabah yang telah
                        melakukan registrasi pada periode Januari 2023 sampai
                        dengan Desember 2023.
                      </li>{" "}
                      <li class="mt-2">
                        {" "}
                        Dalam Proses Registrasi, Calon Nasabah diharapkan untuk
                        dapat menyertakan Identitas Diri, seperti: KTP dan NPWP.
                      </li>{" "}
                      <li class="mt-2">
                        {" "}
                        Bersedia melalukan Verifikasi Data Nasabah, melalui
                        proses Video Call yang jadwal nya dapat disesuaikan oleh
                        Calon Nasabah.
                      </li>{" "}
                      <li class="mt-2"> Fee yang berlaku saat ini :</li>{" "}
                      <li class="mt-2"> KISI Mobile Tersedia di:</li>{" "}
                      <li class="mt-2">
                        {" "}
                        Setelah berhasil melakukan pendaftaran dan melakukan
                        deposit di bulan yang sama. Hanya dengan melakukan
                        minimal deposit IDR 100,000 nasabah akan mendapatkan
                        Promo Free 1 Lot ETF yang akan di distribusikan pada
                        bulan berikutnya.{" "}
                      </li>{" "}
                      <li class="mt-2">
                        {" "}
                        Promo hanya berlaku bagi Nasabah{" "}
                        <b>
                          yang telah melakukan pembukaan rekening efek dan
                          penyetoran awal di bulan yang sama
                        </b>
                        , serta telah{" "}
                        <b>
                          melakukan konfirmasi dengan mengisi dan menyerahkan
                          surat pernyataan serta form nego kepada KISI sebelum
                          tanggal 10 pada bulan berikutnya.
                        </b>
                      </li>{" "}
                      <p class="noList mt-3">
                        Surat Pernyataan dan Form Nego akan dikirimkan dari
                        email hello@kisi.co.id ke email nasabah
                        selambat-lambatnya setiap tanggal 5 dan setiap bulannya.
                        Nasabah pun diharapkan dapat mengembalikan surat
                        penyataan dan form nego yang masing-masing telah di
                        tanda tangani dan dikembalikan melalui email ke
                        hello@kisi.co.id , sebelum tanggal 10 pada bulan
                        tersebut.{" "}
                      </p>
                      <p class="noList mt-3">
                        PT Korea Investment & Sekuritas Indonesia memohon maaf,
                        jika tidak bisa memproses hadiah tersebut jika Nasabah
                        mengembalikan Surat Pernyataan dan Form Nego setelah
                        tanggal 10.
                      </p>
                      <li class="mt-2">
                        KISI akan melakukan pembelian ETF XKMS sebanyak 1 lot di
                        rentang{" "}
                        <b>waktu tanggal 11 sampai dengan tanggal 18 </b>
                        pada bulan yang sama{" "}
                        <b>
                          setelah KISI menerima konfirmasi dari Nasabah serta
                          telah dipenuhinya syarat dan ketentuan sebagaimana
                          disebutkan pada pasal 1, 2 dan 3.
                        </b>
                      </li>
                      <li class="mt-2">
                        Biaya yang timbul sebagai akibat dari proses pemindahan
                        ETF XKMS dari KISI kepada Nasabah sepenuhnya ditanggung
                        oleh KISI. Setelah pemindahan selesai maka biaya
                        transaksi ETF XKMS yang akan timbul di kemudian hari
                        sepenuhnya ditanggung oleh Nasabah.
                      </li>
                    </ol>
                    <br></br>
                    <div class="f15r blue-text-1 monsterratMedium mt-5 ">
                      Disclaimer
                    </div>
                    <ol>
                      <li class="mt-2">
                        Hi #SobatKISI, Aplikasi KISI Mobile dari Korea
                        Investment & Sekuritas Indonesia saat ini hanya dapat
                        melayani pendaftaran Full Online untuk Nasabah Bank BCA
                        & CIMB Bank. Kami memohon maaf untuk nasabah dengan
                        rekening bank selain BCA & CIMB, diharapkan tetap
                        melakukan pendaftaran dengan mengirimkan berkas.
                      </li>
                      <li class="mt-2">
                        Nasabah yang berhak mendapatkan promo ETF hanya nasabah
                        sales online dan ataupun yang melakukan pendaftaran
                        melalui KISI Mobile tanpa menggunakan sales, serta yang
                        memenuhi S&K diatas.
                      </li>
                    </ol>
                    <p></p>
                  </div>
                </div>

                {/* <div class="langkahPendaftaranWrapper langkahEtf rounded-corner bg-biru-muda py-4 mb-4">
                <div class="grey monsterratExtraBold f25r langkahPendaftaranTitle blue-text-1">
                  Tata Cara Pendaftaran Online
                </div>
                <div class="langkahPendaftaranContainer mb-5">
                  <div class="langkahPendaftaran">
                    <div class="monsterratBlack textCenter stepText blue-text-1 grey">
                      Step 1
                    </div>
                    <div class="langkahPendaftaranImgContainer">
                      <img class="langkahPendaftaranImg " src={kisiMobile} />
                    </div>
                    <div class="langkahPendaftaranText grey monsterratMedium">
                      Download KISI Mobile
                    </div>
                  </div>
                  <div class="langkahSpacer monsterratBlack">- - - - - -</div>
                  <div class="langkahPendaftaran  ">
                    <div class="monsterratBlack textCenter stepText blue-text-1 grey">
                      Step 2
                    </div>
                    <div class="langkahPendaftaranImgContainer resume">
                      <img class="langkahPendaftaranImg resume" src={letter} />
                    </div>
                    <div class="langkahPendaftaranText grey monsterratMedium">
                      Ikuti instruksi untuk mendaftar
                    </div>
                  </div>
                  <div class="langkahSpacer monsterratBlack">- - - - - -</div>
                  <div class="langkahPendaftaran ">
                    <div class="monsterratBlack textCenter stepText blue-text-1 grey">
                      Step 3
                    </div>
                    <div class="langkahPendaftaranImgContainer mail ">
                      <img class="langkahPendaftaranImg " src={mail} />
                    </div>
                    <div
                      style={{ width: "200%", marginLeft: "-50%" }}
                      class="langkahPendaftaranText grey monsterratMedium"
                    >
                      Setelah berhasil, maka ID, PIM, & PASS KOINS akan
                      dikirimkan melalui email yang didaftarkan
                    </div>
                  </div>
                  <div class="langkahSpacer monsterratBlack">- - - - - -</div>
                  <div class="langkahPendaftaran">
                    <div class="monsterratBlack textCenter stepText blue-text-1 grey">
                      Step 4
                    </div>
                    <div class="langkahPendaftaranImgContainer">
                      <img class="langkahPendaftaranImg " src={koins} />
                    </div>
                    <div class="langkahPendaftaranText grey monsterratMedium">
                      Download KOINS untuk melakukan Trading
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center mt-5 mb-5">
                <button
                  class="blue-btn f2r rounded-pill monsterratBold downloadButton mx-auto text-center px-4"
                  onClick={() => navigate("/kisimobile")}
                >
                  Pendaftaran Klik Disini
                </button>
              </div> */}

                {/* <div class="CIMBButtonWrapper my-5 pb-5 ">
            <div class="f25r  mb-4 blue-text-1 monsterratExtraBold text-center">
              Appendix
            </div>
            <div class="monsterratMedium grey f1r text-center pt-5">
              <button class="CIMBPdfButton grey f1r px-4 mx-2"   onClick={() => openInNewTab(syariah2017)}>
                POJK No.35/POJK.04/2017
              </button>
              <button class="CIMBPdfButton grey f1r px-4 mx-2"   onClick={() => openInNewTab(syariah2015)}>
              POJK No.17/POJK.04/2015
              </button>
              <button class="CIMBPdfButton grey f1r px-4 mx-2"   onClick={() => openInNewTab(syariah2011)}>
              Fatwa DSN-MUI No.80 tahun 2011
              </button>
              <button class="CIMBPdfButton grey f1r px-4 mx-2"   onClick={() => openInNewTab(syariahSertifikatMui)}>
              Sertifikasi DSN MUI
              </button>
            </div>
          </div> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const GeneralDisclaimer = () => {
    return (
      <>
        <div class="disclaimerPageContainer mt-5 py-4">
          <div
            ref={discRef}
            class="disclaimerContainer rounded-corner bg-biru-muda px-5 py-5 mx-auto mt-5  grey monsterratMedium f1r"
          >
            <div>
        
              <img rel="icon" href="%PUBLIC_URL%/favicon1.png"></img>
            </div>
            <div class="f25r blue-text-1 monsterratExtraBold  pb-1">
              DISCLAIMER
            </div>
            <br></br>
            Informasi yang dipublikasikan di situs ini adalah informasi umum.
            Walaupun kami senantiasa berusaha untuk menjaga kebenaran dan
            keterkinian informasi, kami tidak mewakili dan menjaminkan apapun,
            baik secara langsung maupun tidak, mengenai kelengkapan, ketepatan,
            keandalan, kepantasan maupun ketersediaan informasi sehubungan
            dengan situs web atau informasi, produk, layanan, atau grafik
            terkait, yang terdapat di situs web untuk tujuan apa pun.<br></br>
            <br></br> Perusahaan kami tidak bertanggung jawab atas kerugian atau
            kerusakan termasuk kerusakan langsung atau tidak langsung sehubungan
            dengan penggunaan situs web ini. Perusahaan kami juga tidak
            bertanggung jawab atas informasi yang terkandung dalam situs web
            lain yang terhubung di situs web ini. Nasabah diminta untuk membaca
            dan memahami kebijakan privasi sebelum mengirimkan data, informasi,
            atau instruksi ke KISI.
            <br></br>
            <br></br> KISI akan melakukan upaya terbaik untuk menjaga situs web
            dan membuat perkembangan berkelanjutan untuk pengalaman pengguna
            yang lebih baik. Namun KISI tidak bertanggung jawab apabila situs
            web tersebut tidak tersedia untuk sementara karena masalah teknis di
            luar kendali kami.<br></br> <br></br>PT Korea Investment dan
            Sekuritas Indonesia adalah anggota Bursa Efek Indonesia (BEI) serta
            diatur dan diawasi oleh Otoritas Jasa Keuangan (OJK).
          </div>

          <div
            ref={kebRef}
            class="kebijakanContainer  px-5 py-5 mx-auto mt-5  grey monsterratMedium f1r"
          >
            <div class="f25r blue-text-1 monsterratExtraBold  pb-1">
              KEBIJAKAN PRIVASI
            </div>
            <br></br>
            Kebijakan Privasi berikut ini menjelaskan bagaimana KISI menggunakan
            dan melindungi data dan informasi Nasabah. KISI sangat menghargai
            kepercayaan Nasabah pada perusahaan kami. Kami memahami bawha data
            nasabah merupakan informasi berharga dan KISI berkomitmen untuk
            senantiasa menjaga kepercayaan nasabah dengan melindungi kerahasiaan
            informasi tersebut. <br></br>
            <br></br>Berikut adalah Kebijakan Privasi KISI*. Nasabah diharapkan
            untuk dapat membaca dan memahami ketentuan berikut. <br></br>
            <br></br>1. KISI akan senantiasa menjaga sepenuhnya hak kerahasian
            data Nasabah, yang diketahui oleh KISI, termasuk namun tidak
            terbatas pada data identitas Nasabah, data transaksi Nasabah, data
            keuangan, dan kekayaan Nasabah.<br></br>
            <br></br> 2. KISI berwenang penuh untuk menyampaikan informasi
            mengenai Nasabah beserta aktifitas Rekening Efek Nasabah kepada
            Pihak yang berwenang dan/atau Pegawai KISI untuk keperluan
            operasional KISI, tanpa perlu menyampaikan pemberitahuan kepada atau
            meminta persetujuan terlebih dahulu dari Nasabah.<br></br>
            <br></br> 3. KISI dapat mengungkapkan informasi mengenai Nasabah
            atas Rekening Efek Nasabah sepanjang diperlukan untuk kepentingan
            Pemeriksaan dan/atau penyidikan oleh Bursa, KPEI, KSEI, Otoritas
            Pasar Modal, PPATK, atau oleh Instansi yang mempunyai kewenangan
            atas dasar peraturan perundang-undangan yang berlaku. <br></br>
            <br></br>4. Dalam hal Nasabah memberikan Instruksi kepada KISI untuk
            melakukan penjualan Efek milik Nasabah yang disimpan di Partisipan
            lain, Nasabah dengan ini memberikan kuasa dan kewenangan kepada KISI
            untuk meminta informasi dari Partisipan lain tersebut mengenai
            Nasabah dan Efek milik Nasabah yang diperintahkan oleh Nasabah untuk
            dijual melalui KISI, dan informasi lain yang berhubungan dengan
            Nasabah. Efek yang diperintahkan oleh Nasabah untuk dijual, dalam
            hal Efek milik Nasabah tersebut disimpan di Bank Kustodian dan/atau
            Perusahaan Efek Lain, harus tersedia di KISI paling lambat 1 (satu)
            Hari Bursa setelah transaksi selesai dilaksanakan oleh KISI sebelum
            pukul 12.00 WIB.<br></br>
            <br></br> 5. Nasabah berjanji dan menundukkan diri untuk memberikan
            persetujuan dan kewenangan kepada Partisipan lain tersebut, untuk
            mengungkapkan informasi dan data mengenai Nasabah dan Rekening Efek
            atas nama Nasabah pada Partisipan lain tersebut apabila diminta oleh
            KISI dalam rangka melaksanakan Intruksi jual Nasabah atas Efek yang
            disimpan di Partisipan Lain tersebut.<br></br>
            <br></br> 6. Apabila permintaan informasi dan data mengenai Nasabah
            dan/atau Rekening Efek Nasabah pada angka 6.5 diatas ditolak oleh
            Partisipan lain dimana Nasabah menyimpan Efeknya, maka KISI akan
            menolak untuk melaksanakan Instruksi Nasabah untuk penjualan Efek
            dimaksud. Segala kerugian yang timbul sebagai akibat dari tidak
            dilaksanakannya Instruksi penjualan tersebut menjadi tanggung jawab
            Nasabah dan Nasabah dengan ini membebaskan KISI dari gugatan
            dan/atau tuntutan hukum dan tidak mengajukan gugatan atau tuntutan
            hukum dalam bentuk apapun kepada KISI. <br></br>
            <br></br>7. Nasabah mengizinkan KISI untuk merekam dan/atau
            menyimpan instruksi yang diberikan Nasabah, termasuk pada saat tatap
            muka maupun melalui telepon dan sarana media komunikasi lainnya.
            Nasabah menyetujui bahwa KISI berhak menganggap bahwa Instruksi
            lisan tersebut diberikan oleh pihak yang berhak untuk memberikan
            Instruksi atas dasar Perjanjian dan isi pembicaraan merupakan
            keseluruhan atau bagian dari instruksi yang direkam tersebut adalah
            benar adanya. KISI dan Nasabah menyepakati bahwa hasil rekaman dapat
            dipergunakan sebagai pembuktian apabila terjadi perselisihan antara
            KISI dan Nasabah. <br></br>
            <br></br>8. Kewenangan, persetujuan dan kuasa yang diberikan oleh
            Nasabah kepada KISI di atas tidak dapat ditarik kembali selama
            Nasabah masih memiliki Rekening Efek pada KISI dan pelaksanaan
            kewenangan tersebut dapat dilakukan tanpa diperlukan surat kuasa
            tersendiri.
          </div>
          <div
            ref={infoRef}
            class="informasiContainer px-5 py-5 mx-auto mt-5  grey monsterratMedium f1r"
          >
            <div class="f25r blue-text-1 monsterratExtraBold  pb-1">
              INFORMASI PERDAGANGAN
            </div>
            <br></br>
            <div>
              Informasi adalah Kekuatan! Bisnis kami tidak hanya untuk membeli
              dan menjual produk keuangan, tetapi terutama untuk memberi saran
              kepada pelanggan kami secara profesional dan menawarkan solusi
              keuangan lengkap. Kami menginformasikan strategi perdagangan yang
              kuat yang memungkinkan Anda menangkap momentum dan tren naik.
            </div>
            <div class="informasiPoin  px-5 pt-4 pb-4 ">
              <div class="f2r blue-text-1 monsterratExtraBold  pb-1">
                ANALISIS FUNDAMENTAL
              </div>
              Analisis fundamental adalah salah satu dari dua metodologi utama
              untuk mengevaluasi dan meramalkan pergerakan dalam perdagangan
              saham. Mari kita bicara dengan tim kami untuk melakukan
              perdagangan terbaik apa yang Anda inginkan sebelumnya.
            </div>
            <div class="informasiPoin  px-5 pt-4 pb-4 ">
              <div class="f2r blue-text-1 monsterratExtraBold  pb-1">
                TREN PASAR
              </div>
              Strategi perdagangan saham terbaik berdasarkan analisis teknis:
              Pencarian untuk strategi perdagangan adalah strategi tanpa akhir.
              Dan Kami berkomitmen untuk memperbarui layanan kami untuk
              mencerminkan tren pasar terkini.
            </div>
            <div class="informasiPoin  px-5 pt-4 pb-4 mb-5">
              <div class="f2r blue-text-1 monsterratExtraBold  pb-1">
                STRATEGI MANAJEMEN RESIKO
              </div>
              Seorang trader harus memahami bagaimana mengelola risikonya, dan
              mengatur pesanannya dengan benar. Kami membantu Anda meningkatkan
              manajemen risiko secara instan dan menghindari masalah paling umum
              yang menyebabkan para pedagang kehilangan uang.
            </div>
          </div>
        </div>
      </>
    );
  };
  // switch (page) {
  //   case "disclaimer":
  //     window.scrollTo(0, 110);
  //     //  discRef.current.scrollIntoView({block:'center'})
  //     break;
  //   case "kebijakan_privasi":
  //     window.scrollTo(0, 900);
  //     // kebRef.current.scrollIntoView({block:'nearest'})
  //     break;
  //   case "informasi":
  //     window.scrollTo(0, 2550);
  //     // infoRef.current.scrollIntoView({block:'center'})
  //     break;
  //   case "margin":
  //     return <DisclaimerMargin />;

  //   default:
  //     break;
  // }
  useEffect(() => {
    // console.log(document.documentElement.scrollTop,path);
    switch (page) {
      case "disclaimer":
        window.scrollTo(0, 110);
        //  discRef.current.scrollIntoView({block:'center'})
        break;
      case "kebijakan_privasi":
        window.scrollTo(0, 900);
        // kebRef.current.scrollIntoView({block:'nearest'})
        break;
      case "informasi":
        window.scrollTo(0, 2550);
        // infoRef.current.scrollIntoView({block:'center'})
        break;
      case "margin":
        return <DisclaimerMargin />;

      default:
        break;
    }
    switch (path) {
      case "/privacy":
        window.scrollTo(0, 900);
        //  discRef.current.scrollIntoView({block:'center'})
        break;
     
      default:
        break;
    }
  }, [page, state]);
  return <GeneralDisclaimer />;
}
