import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";

import step1 from "../../assets/step1.png";
import step2 from "../../assets/step2.png";
import step3 from "../../assets/step3.png";
import step4 from "../../assets/step4.png";
import step5 from "../../assets/step5.png";
import step6 from "../../assets/step6.png";
import step7 from "../../assets/step7.png";
import arrow from "../../assets/arrow.png";

import "./style.css";
gsap.registerPlugin(Draggable);

const pictures = [
  {
    source: step1,
    content: {
      date: "<b>telek</b>",
      desc: "Install KISI Mobile di <b>Appstore</b> atau <b>Google Play Store</b> untuk melakukan pendaftaran",
    },
  },
  {
    source: step2,
    content: {
      date: "04.28.2020",
      desc: "Setelah Download silahkan masuk ke aplikasi KISI Mobile.",
    },
  },
  {
    source: step3,
    content: {
      date: "04.28.2020",
      desc: "Pilih <b>DAFTAR</b> untuk kamu yang mau membuka rekening saham",
    },
  },
  {
    source: step4,
    content: {
      date: "04.27.2020",
      desc: "Pilih kategori tipe akun yang sesuai kebutuhan kamu",
    },
  },
  {
    source: step5,
    content: {
      date: "04.23.2020",
      desc: "Lakukan registrasi dan isi data diri kamu",
    },
  },
  {
    source: step6,
    content: {
      date: "04.21.2020",
      desc: "Setelah berhasil melakukan pendaftaran silahkan menikmati fitur KISI Mobile",
    },
  },
  {
    source: step7,
    content: {
      date: "04.21.2020",
      desc: "Setelah berhasil mendaftar, Download KOINS Mobile di Google Play Store atau Applestore",
    },
  },
];

const Slide = ({ imageSource, content, index }) => {
  // var content =content.date;
  // var htmlObject = document.createElement('div');
  // htmlObject.innerHTML = content;
  // htmlObject.getElementById("myDiv").style.marginTop = 2;

  return (
    <div className="appSlide px-2" id={"appSlide" + index}>
      <div className="preview">
        <img
          src={imageSource}
          alt="The Plant"
          draggable="false"
          class="draggableSlideImg"
        />
      </div>
      <div className="infos">
        <div class="appSliderInfoContainer">
          <div class="blue-text-1 monsterratBlack mb-1">STEP {index + 1}</div>
          <div
            class="monsterratMedium stepDesc"
            dangerouslySetInnerHTML={{ __html: content.desc }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export const AppSlider = () => {
  const sliderRef = useRef(null);

  useEffect(() => {
    console.log(sliderRef.current.clientWidth, sliderRef.current.innerWidth);
    Draggable.create(sliderRef.current, {
      type: "x",
      bounds: {
        minX: -sliderRef.current.clientWidth + window.innerWidth * -0.19,
        maxX: 50,
      },
    });
  }, []);

  return (
    <div class="appSliderContainer ">
      <div class="appSpacerLeft  appSpacerHeight ">
        <img class="sliderArrow appSliderMt arrowLeft" src={arrow}></img>
      </div>
      <div class="appSliderWrapper">
        {" "}
        <div id="slider" className="slider" ref={sliderRef}>
          {pictures.map((item, index) => {
            return (
              <Slide
                key={index}
                imageSource={item.source}
                content={item.content}
                index={index}
              ></Slide>
            );
          })}
        </div>
      </div>
      <div class="appSpacerRight  appSpacerHeight ">
        {" "}
        <img class="sliderArrow appSliderMt arrowRight" src={arrow}></img>
      </div>
    </div>
  );
};
