import React, { useEffect, useState } from "react";
import PageLocation from "../../../components/PageLocation";
import etfFee from "../../../assets/etfFee.png";
import playStore from "../../../assets/playStore.png";
import appStore from "../../../assets/appStore.png";
import waranTerstruktur from "../../../assets/waranTerstruktur.png";
import FollowSocmed from "../../../components/FollowSocmed";
import { useNavigate, useLocation } from "react-router-dom";
import Aos from "aos";
import { storageLocation } from "../../../config/server";
import swCode from "../../../assets/swCode.png";
import faqArrow from "../../../assets/faqArrow.png";
import { server } from "../../../config/server";
import moment from "moment";
import { get } from "jquery";
import bondsBanner from "../../../assets/bondsBanner.png";
export function Bonds({}) {
  const { state } = useLocation();
  let toAnnouncement = false;
  const [data, setData] = useState([]);
  const [newSwData, setNewSwData] = useState([]);
  const [expiredSwData, setExpiredSwData] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [offset, setOffset] = useState(0);
  // console.log(state);
  if (state) {
    toAnnouncement = state.toAnnouncement;
  }

  useEffect(() => {
    Aos.init({ duration: 2000 });
    getData();
  }, [sortBy]);
  useEffect(() => {
    // console.log(
    //   document
    //     .getElementById(`tabel_data_waran_wrapper`)
    //     .getBoundingClientRect().width
    // ,window.innerWidth);
    // if (window.innerWidth >= 1660) {
    //   const onScroll = () => setOffset(window.pageYOffset);
    //   // clean up code
    //   window.removeEventListener("scroll", onScroll);
    //   window.addEventListener("scroll", onScroll, { passive: true });
    //   // console.log(
    //   //   document.documentElement.offsetHeight,
    //   //   "hilih",
    //   //   document.getElementById(`data_waran_end`).getBoundingClientRect()
    //   // );
    //   // console.log( document.getElementById(`th_waran_top`), document
    //   // .getElementById(`tabel_data_waran_wrapper`)
    //   // .getBoundingClientRect().width);
    //   document.getElementById(`th_waran_top`).style.width = document
    //     .getElementById(`tabel_data_waran_wrapper`)
    //     .getBoundingClientRect().width;
    //   document.getElementById(`th_waran_top`).style.width = document
    //     .getElementById(`tabel_data_waran_wrapper`)
    //     .getBoundingClientRect().width;
    //   var tableHeadTop = document.getElementById(`th_waran_top`);
    //   var startShow = document
    //     .getElementById(`th_waran_static`)
    //     .getBoundingClientRect();
    //   var navbarH = document
    //     .getElementById(`navBar`)
    //     .getBoundingClientRect().height;
    //   var subpathH = document
    //     .getElementById(`subPath`)
    //     .getBoundingClientRect().height;
    //   var tableEnd = document
    //     .getElementById(`data_waran_end`)
    //     .getBoundingClientRect();
    //   // console.log(startShow, navbarH, subpathH);
    //   tableHeadTop.style.top = `${navbarH + subpathH}px`;
    //   document.getElementById(`th_waran_top`).style.width = `${
    //     document
    //       .getElementById(`tabel_data_waran_wrapper`)
    //       .getBoundingClientRect().width
    //   }px`;
    //   if (
    //     startShow.top <= navbarH + subpathH &&
    //     tableEnd.top >
    //       navbarH +
    //         subpathH +
    //         document.getElementById(`th_waran_static`).getBoundingClientRect()
    //           .height
    //   ) {
    //     tableHeadTop.style.display = "block";
    //     tableHeadTop.style.top = navbarH + subpathH;
    //   } else {
    //     tableHeadTop.style.display = "none";
    //   }
    //   return () => window.removeEventListener("scroll", onScroll);
    //   console.log(document.documentElement.offsetHeight, "hilih");
    // }
  }, [offset]);
  const getData = () => {
    fetch(`${server}/api/v1/fixedincome/list${sortBy}`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      // body: JSON.stringify(file),
      headers: {
        "Content-Type": "application/json",
        // access_token: localStorage.getItem("access_token"),
      },
    })
      .then((res) => res.json())
      .then(async (data) => {
        console.log(data,'okegas');
        setData(data.data.filter((item) => item.active == 1));
        // console.log(data, "promox");
      })

      .catch((err) => {
        // console.log(err);
      });
  };
  const getNewSwData = () => {
    fetch(`${server}/api/v2/swari/termSheet/INTERNAL`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      // body: JSON.stringify(file),
      headers: {
        "Content-Type": "application/json",
        // access_token: localStorage.getItem("access_token"),
      },
    })
      .then((res) => res.json())
      .then(async (data) => {
        let temp = data.data.filter((item) => item.active == 1);
        console.log(temp, "sw_active");
        temp.push([]);

        setNewSwData(temp);
        // console.log(data.data, "kui");
      })

      .catch((err) => {
        // console.log(err);
      });
  };
  const getExpiredSwData = () => {
    fetch(`${server}/api/v2/swari/termSheet/EXPIRED`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      // body: JSON.stringify(file),
      headers: {
        "Content-Type": "application/json",
        // access_token: localStorage.getItem("access_token"),
      },
    })
      .then((res) => res.json())
      .then(async (data) => {
        let temp = data.data.filter((item) => item.active == 1);
        console.log(temp, "sw_active");
        temp.push([]);

        setExpiredSwData(temp);
        console.log(data.data, "kui");
      })

      .catch((err) => {
        // console.log(err);
      });
  };

  const handleSort = (sortby) => {
    setSortBy(sortby);
    getData();
  };
  const dummy = [
    {
      kategori: "Negara",
      kode: "FR0056",
      nama: "Obligasi Negara Republik Indonesia Seri FR0056",
      rating:"AAA(idn)",
      jenis_kupon: "Fixed",
      coupon_rate: "8.38%",
      coupon_payment: "Setiap 6 Bulan",
      expiry: "2026-09-15",
      buy: "103.60%",
      yield: "7.7657%",
      sell: "104.66%",
      yield_sell: "6.8874%",
    },
    {
      kategori: "Negara",
      kode: "FR0056",
      nama: "Obligasi Negara Republik Indonesia Seri FR0056",
      rating:"AAA(idn)",
      jenis_kupon: "Fixed",
      coupon_rate: "8.38%",
      coupon_payment: "Setiap 6 Bulan",
      expiry: "2026-09-15",
      buy: "103.60%",
      yield: "7.7657%",
      sell: "104.66%",
      yield_sell: "6.8874%",
    },
    {
      kategori: "Negara",
      kode: "FR0056",
      nama: "Obligasi Negara Republik Indonesia Seri FR0056",
      rating:"AAA(idn)",
      jenis_kupon: "Fixed",
      coupon_rate: "8.38%",
      coupon_payment: "Setiap 6 Bulan",
      expiry: "2026-09-15",
      buy: "103.60%",
      yield: "7.7657%",
      sell: "104.66%",
      yield_sell: "6.8874%",
    },
    {
      kategori: "Negara",
      kode: "FR0056",
      nama: "Obligasi Negara Republik Indonesia Seri FR0056",
      rating:"AAA(idn)",
      jenis_kupon: "Fixed",
      coupon_rate: "8.38%",
      coupon_payment: "Setiap 6 Bulan",
      expiry: "2026-09-15",
      buy: "103.60%",
      yield: "7.7657%",
      sell: "104.66%",
      yield_sell: "6.8874%",
    },
    {
      kategori: "Negara",
      kode: "FR0056",
      nama: "Obligasi Negara Republik Indonesia Seri FR0056",
      rating:"AAA(idn)",
      jenis_kupon: "Fixed",
      coupon_rate: "8.38%",
      coupon_payment: "Setiap 6 Bulan",
      expiry: "2026-09-15",
      buy: "103.60%",
      yield: "7.7657%",
      sell: "104.66%",
      yield_sell: "6.8874%",
    },
    {
      kategori: "Negara",
      kode: "FR0056",
      nama: "Obligasi Negara Republik Indonesia Seri FR0056",
      rating:"AAA(idn)",
      jenis_kupon: "Fixed",
      coupon_rate: "8.38%",
      coupon_payment: "Setiap 6 Bulan",
      expiry: "2026-09-15",
      buy: "103.60%",
      yield: "7.7657%",
      sell: "104.66%",
      yield_sell: "6.8874%",
    },
    {
      kategori: "Korporasi",
      Kode: "FMFN2B",
      ama: "Obligasi I KB FInansia Multifinance Tahun",
      rating:"AAA(idn)",
      jenis_kupon: "Fixed",
      coupon_rate: "3.38%",
      coupon_payment: "Setiap 3 Bulan",
      expiry: "2026-09-15",
      buy: "101.60%",
      yield: "6.7657%",
      sell: "103.66%",
      yield_sell: "5.8874%",
    },
    {
      kategori: "Negara",
      Kode: "FR0056",
      Nama: "Obligasi Negara Republik Indonesia Seri FR0056",
      rating:"AAA(idn)",
      jenis_kupon: "Fixed",
      coupon_rate: "8.38%",
      coupon_payment: "Setiap 6 Bulan",
      expiry: "2026-09-15",
      buy: "103.60%",
      yield: "7.7657%",
      sell: "104.66%",
      yield_sell: "6.8874%",
    },
    {
      kategori: "Negara",
      Kode: "FR0056",
      Nama: "Obligasi Negara Republik Indonesia Seri FR0056",
      rating:"AAA(idn)",
      jenis_kupon: "Fixed",
      coupon_rate: "8.38%",
      coupon_payment: "Setiap 6 Bulan",
      expiry: "2026-09-15",
      buy: "103.60%",
      yield: "7.7657%",
      sell: "104.66%",
      yield_sell: "6.8874%",
    },
    {
      kategori: "Negara",
      Kode: "FR0056",
      Nama: "Obligasi Negara Republik Indonesia Seri FR0056",
      rating:"AAA(idn)",
      jenis_kupon: "Fixed",
      coupon_rate: "8.38%",
      coupon_payment: "Setiap 6 Bulan",
      expiry: "2026-09-15",
      buy: "103.60%",
      yield: "7.7657%",
      sell: "104.66%",
      yield_sell: "6.8874%",
    },
  ];
  // let filteredData = [];
  // if (data !== null) {
  //   filteredData = data;
  // }
  const navigate = useNavigate();
  const openInNewTab = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };
  const [imageLoaded, setImageLoaded] = useState(false);

  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return "https://play.google.com/store/apps/details?id=id.co.kisi.kisimobile&hl=in&gl=US";
    }

    return "https://apps.apple.com/id/app/kisi-mobile/id1493151464";
  };
  return (
    <div className={`${imageLoaded ? "o1" : "o0"}`}>
      <div class="imageDivWrapper   ">
        {" "}
        <img
          class="w100 pointer"
          onLoad={() => {
            setImageLoaded(true);
          }}
          src={bondsBanner}
          onClick={(e) => navigate("/kisimobile")}
        ></img>
        {/* <img
          onLoad={() => {
            setImageLoaded(true);
          }}
          data-aos="fade-up"
          data-aos-duration="2000"
          src={margin}
          alt="banner image"
          class={`imageZ1 marginImg`}
        />
        <div class="insideWrapperAbsolute centerAbsolute768 sahamImgDiv marginDownloadDiv">
          <div class="downloadButtonGroup">
            <div class=" etfDownloadButtonContainer">
              <button
                class=" monsterratBlack blue-btn rounded-pill koinsDownload downloadButton  f1r px-5"
                onClick={() => navigate("/kisimobile")}
              >
                DOWNLOAD SEKARANG
              </button>

              <div class="flex mobileMarketplaceContainer">
                <div class="  flexStart playStore">
                  <img
                    class="mobileMarketplace"
                    src={playStore}
                    onClick={() =>
                      openInNewTab(
                        "https://play.google.com/store/apps/details?id=id.co.kisi.kisimobile&hl=in&gl=US"
                      )
                    }
                  />
                </div>
                <div class="  flexEnd appStore">
                  <img
                    class="mobileMarketplace"
                    src={appStore}
                    onClick={() =>
                      openInNewTab(
                        "https://apps.apple.com/id/app/kisi-mobile/id1493151464"
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="insideWrapperAbsolute sahamBannerText marginBannerText">
          <div class="sahamBannerTitle monsterratBlack marginBannerTitle">
            <span style={{ color: "#fbb03c" }}>
              PROMO MARGIN <br></br> ACCOUNT
            </span>
            <br></br>
            <div>
              <span class="f9r">12%</span>
              <span class="f15r">/tahun</span>
            </div>
            <div class="f1r marginPersenImg">
              <span>
                <img class="marginCheck" src={marginCheck}></img> Fee Beli 0.13%
                dan Jual 0.23%
              </span>{" "}
              <span>
                <img class="marginCheck" src={marginCheck}></img> 100+ pilihan
                saham
              </span>
              <div>
                {" "}
                <span>
                  <img class="marginCheck" src={marginCheck}></img> Periode
                  promosi Januari s/d Desember 2023
                </span>
              </div>
            </div>

            <p class="sahamBannerSubtitle"></p>
          </div>
        </div> */}
      </div>
      <div class="reksadanaUpperContainer mb-5">
        <div class="monsterratExtraBold  f275r mb-3 blue-text-1">
          Daftar Bonds
        </div>
        {/* <div class="monsterratMedium mt-2 f15r blue-text-1">
          Fund by KISI Mobile
        </div> */}
        <div class="monsterratMedium reksadanaDescriptionTxt grey jenisAkunText mt-3 f1r w80">
          Pilihan Bonds untuk solusi investasi anda
        </div>
        {/* <div class="monsterratMedium grey jenisAkunText mt-2 f1r">
          Untuk dapat melakukan transaksi Reksa Dana silahkan download Aplikasi
          KISI Mobile
        </div> */}
      </div>
      {/* <FollowSocmed /> */}
      <div
        class="w90 mx-auto mb-5 pb-5  "
        id="tabel_data_waran_wrapper"
        style={{ overflow: "auto" }}
      >
        <table
          id="tabel_data_waran_1"
          className="table  table-striped table-warrant"
          style={{ textAlign: "center", borderLeft: "0px solid transparent" }}
        >
          {/* <thead
            id="th_waran_top"
            style={{
              border: "none !important  ",
              position: "fixed",
              display: "none",
              // width: "90%",
              zIndex: 100,
              overflow: "auto",
              cursor: "context-menu",
            }}
          >
            <tr
              className="h1"
              style={{
                borderTop: "1px solid transparent",
              }}
            >
              <th
                className="tableWarrantHeaderFont monsterratBold py-4 blue-text-1"
                colSpan="13"
                style={{
                  borderStyle: "none",
                  backgroundColor: "#eef9ff",
                  borderTopLeftRadius: 20,
                  borderTopRightRadius: 20,
                }}
              >
                Data Waran Terstruktur
              </th>
              <th
                className="tableWarrantHeaderFont monsterratBold py-4 white px-4"
                colSpan="2"
                style={{
                  backgroundColor: "#0067b1",
                  borderBottom: "1px solid white",
                  whiteSpace: "nowrap",
                  borderTopRightRadius: 20,
                }}
              >
                Market Data
              </th>
            </tr>

            <tr
              className="h2 tableWarranFontContent"
              style={{
                textAlign: "left",
                borderLeft: "0px solid transparent",
                borderRight: "0px solid transparent",
              }}
            >
              <th
                className="  monsterratBold  justify-content-around blue-text-1 fixedTableCol"
                style={{
                  backgroundColor: "#eef9ff",
                  borderLeft: "0px solid transparent",
                  borderRight: "1px solid red !important",
                  verticalAlign: "middle",
                }}
              >
                <div
                  class="flex center-vertical  justify-content-between  justify-content-centerjustify-content-around"
                  style={{ verticalAlign: "middle" }}
                >
                  <span>Kategori</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        handleSort("/warrant_code/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        handleSort("/warrant_code/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1  noLeftBorder"
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between  justify-content-center">
                  <span>Kode</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/issuer_code/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/issuer_code/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between  justify-content-center h100 w100">
                  <span>Nama</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/base_stock_code/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/base_stock_code/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Rating</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/PutOrCall/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/PutOrCall/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Jenis Kupon</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/BaseStock_LastPrice/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/BaseStock_LastPrice/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Kupon (% p.a)</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/exercise_price/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/exercise_price/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Pembayaran Kupon</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/exercise_ratio/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/exercise_ratio/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Jatuh Tempo</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        handleSort("/expiry_date/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        handleSort("/expiry_date/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Kami Beli</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/lastTradingDate/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/lastTradingDate/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Yield</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/maturity_days/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/maturity_days/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Kami Jual</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/warrant_sensitivity_ticks/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/warrant_sensitivity_ticks/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Yield</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/effective_gearing/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/effective_gearing/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
            </tr>
          </thead> */}

          <thead
            id="th_waran_static"
            style={{
              border: "none !important  ",
            }}
          >
            {/* <tr
              className="h1"
              style={{
                borderTop: "1px solid transparent",
              }}
            >
              <th
                className="tableWarrantHeaderFont monsterratBold py-4 blue-text-1"
                colSpan="13"
                style={{
                  borderStyle: "none",
                  backgroundColor: "#eef9ff",
                  borderTopLeftRadius: 20,
                  borderTopRightRadius: 20,
                }}
              >
                Data Waran Terstruktur
              </th>
 
            </tr> */}

            <tr
              className="h2 tableWarranFontContent"
              style={{
                textAlign: "left",
                borderLeft: "0px solid transparent",
                borderRight: "0px solid transparent",
              }}
            >
              <th
                className="  monsterratBold  justify-content-around blue-text-1 fixedTableCol"
                style={{
                  backgroundColor: "#eef9ff",
                  borderLeft: "0px solid transparent",
                  borderRight: "1px solid red !important",
                  verticalAlign: "middle",
                }}
              >
                <div
                  class="flex center-vertical  justify-content-between  justify-content-center"
                  style={{ verticalAlign: "middle" }}
                >
                  <span>Kategori</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        handleSort("/bond_category/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        handleSort("/bond_category/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1  noLeftBorder"
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between  justify-content-center">
                  <span>Kode</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/bond_code/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/bond_code/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between  justify-content-center h100 w100">
                  <span>Nama</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/bond_name/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/bond_name/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Rating</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/rating/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/rating/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Jenis Kupon</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/coupon_type/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/coupon_type/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Kupon (% p.a)</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/coupon_rate/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/coupon_rate/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Pembayaran Kupon</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/coupon_payment/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/coupon_payment/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Jatuh Tempo</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        handleSort("/maturity_date/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        handleSort("/maturity_date/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Kami Beli</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/buy_percent/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/buy_percent/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Yield</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/buy_yield/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/buy_yield/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Kami Jual</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/sell_percent/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/sell_percent/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Yield</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/sell_yield/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/sell_yield/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
            </tr>
          </thead>
          <tbody id="formData tableWarrantFontContent">
            {data.map((data, i) => {
              return (
                <tr className="form_c" id={`data_waran_${i}`}>
                  <td
                    className="tableWarranFontContent monsterratSemiBold search-td-h noLeftBorder fixedTableCol"
                    style={{ borderLeft: "1px solid transparent !important" }}
                  >
                    <a>{data.bond_category}</a>
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold tdRhb noLeftBorder">
                    {" "}
                    {data.bond_code}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb" style={{textAlign:'left'}}>
                    {" "}
                    {data.bond_name}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {" "}
                    {data.rating}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.coupon_type}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.coupon_rate}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.coupon_payment}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb nowrap">
                    {data.maturity_date ? (
                      moment(data.maturity_date).format("DD-MMM-YYYY")
                    ) : (
                      <div style={{ color: "transparent" }}>I</div>
                    )}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                  {data.buy_percent}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.buy_yield}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.sell_percent}{" "}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.sell_yield}
                  </td>

                  {/* Repeat the same structure for other table data cells */}
                </tr>
              );
            })}
            {[[]].map((data, i) => {
              return (
                <tr className="form_c" id="data_waran_end">
                  <td
                    className="tableWarranFontContent monsterratSemiBold search-td-h noLeftBorder blue-text-1 fixedTableCol"
                    style={{ borderLeft: "1px solid transparent !important" }}
                  >
                    <a
                      onClick={() =>
                        this.getRicToDetails("KISIDRCJ4A", "KISIDRCJ4A")
                      }
                    >
                      {data.categpry}
                    </a>
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold tdRhb noLeftBorder">
                    {" "}
                    {data.issuer_code}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {" "}
                    {data.base_stock_code}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {" "}
                    {data.PutOrCall}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.BaseStock_LastPrice}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.exercise_price}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.exercise_ratio}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb nowrap">
                    {data.expiry_date ? (
                      moment(data.expiry_date).format("DD-MMM-YYYY")
                    ) : (
                      <div style={{ color: "transparent" }}>I</div>
                    )}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.maturity_days}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.warrant_sensitivity_ticks}{" "}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.effective_gearing}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    
    </div>
  );
}
