// const server = "http://localhost:3001";
import axios from "axios";
// export const server = "http://localhost:8000";
// export const server = "https://kisi.co.id";
// export const server = "";
import { server } from "../config/server";
export function setSlide(payload) {
  return { type: "SET_SLIDE", payload };
}
export function setSortedNews(payload) {
  return { type: "SET_SORTED_NEWS", payload };
}
export function setLogin(payload) {
  return { type: "SET_LOGIN", payload };
}
export function setIsDataFetched(payload) {
  return { type: "SET_IS_DATA_FETCHED", payload };
}
export function setPageAction(payload) {
  return { type: "SET_CURRENT_PAGE", payload };
}
export function setDocuments(payload) {
  return { type: "SET_DOCUMENTS", payload };
}
export function setNewsDetail(payload) {
  return { type: "SET_NEWS_DETAIL", payload };
}
export function setNews(payload) {
  return { type: "SET_NEWS", payload };
}
export function setAccessToken(payload) {
  return { type: "SET_ACCESS_TOKEN", payload };
}
export function setPromos(payload) {
  return { type: "SET_PROMOS", payload };
}
export function setLokasi(payload) {
  return { type: "SET_LOKASI", payload };
}
export function setActiveMenu(payload) {
  return { type: "SET_ACTIVE_MENU", payload };
}
export function setArticlePage(payload) {
  return { type: "SET_ARTICLE_PAGE", payload };
}
export function setRnaMenu(payload) {
  return { type: "SET_RNA_MENU", payload };
}
export function setResearchList(payload) {
  return { type: "SET_RESEARCH_LIST", payload };
}
export function setRnaPage(payload) {
  return { type: "SET_RNA_PAGE", payload };
}

export function setIsSearchActive(payload) {
  return {
    type: "SET_IS_SEARCH_ACTIVE",
    payload,
  };
}
export function getPromos(token) {
  // console.log("masuk store gheXtX");
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      // console.log('gagagagb');
      fetch(`${server}/api/v1/web/promos`, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        // mode: "cors", // no-cors, *cors, same-origin
        // body: JSON.stringify(file),
        headers: {
          "Content-Type": "application/json",
          // access_token: localStorage.getItem("access_token"),
        },
      })
        .then((res) => res.json())
        .then(async (promos) => {
          // console.log(promos,'promox');
          await dispatch(setPromos(promos.data));
          resolve(promos.data)
        
        })
      
        .catch((err) => {
          // console.log(err);
        });
    });
  };
}
export function getNews(page) {
  // console.log("aksi", page, "req");
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      fetch(server + `/api/v1/web/news?limit=10&offset=${page*10 -10}`)
        .then((res) => res.json())
        .then((artikel) => {
          // console.log(artikel.data,'aksion');
          dispatch(setNews(artikel.data));
          dispatch(setIsDataFetched(true));
          resolve(artikel);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  };
}
// export function getNews(page) {
//   // console.log("aksi", page, "req");
//   return function (dispatch) {
//     return new Promise((resolve, reject) => {
//       fetch(server + `/web/artikel_list/${page}`)
//         .then((res) => res.json())
//         .then((artikel) => {
//           dispatch(setNews(artikel));
//           dispatch(setIsDataFetched(true));
//           resolve(artikel);
//         })
//         .catch((err) => {
//           console.log(err);
//           reject(err);
//         });
//     });
//   };
// }

export function getNewsById(id) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      // console.log("byId", id);

      fetch(server + `/api/v1/web/news_content?news_id=${id}`)
        .then((res) => res.json())
        .then((artikel) => {
          console.log(artikel,'aksi');
          resolve(artikel);
        })
        .catch((err) => {
          console.log(err);
        });
    
    });
  };
}

export function getResearch(page) {
  // console.log("aksi69", page);
  return function (dispatch) {
    fetch(server + `/api/v1/web/research_analysis?limit=1200`)
      .then((res) => res.json())
      .then((result) => {
        // console.log(result,'j)X');
        // const sorted = result. sort(function(a, b){return re-b})
        dispatch(setResearchList(result.data));
      });
  };
}
