import "./style.css";
import React, { useEffect, useState } from "react";
import YouTube, { YouTubeProps } from "react-youtube";
import PageLocation from "../../components/PageLocation";
import rocket from "../../assets/rocket.png";
import playStore from "../../assets/playStore.png";
import appStore from "../../assets/appStore.png";
import marketResearch from "../../assets/marketResearch.png";
import fundMall from "../../assets/fundMall.png";
import thumb from "../../assets/thumb.png";
import newsArticles from "../../assets/newsArticles.png";
import education from "../../assets/education.png";
import koins from "../../assets/koins.png";
import kisiMobile from "../../assets/kisiMobile.png";
import videoKisiMobile from "../../assets/videoKisiMobile.mp4";
import letter from "../../assets/letter.png";
import mail from "../../assets/email.png";
import { useNavigate, useParams } from "react-router-dom";
import FollowSocmed from "../../components/FollowSocmed";
import Aos from "aos";
import { setActiveMenu } from "../../store/action";
import { useDispatch, useSelector } from "react-redux";
import manualPengisianKyc from "../../assets/manualPengisianKyc.pdf";
import manualFpreSyariah from "../../assets/manualFpreSyariah.pdf";
import manualRdnCIMB from "../../assets/manualRdnCIMB.pdf";
import manualRdnBCA from "../../assets/manualRdnBCA.pdf";
import manualRdnMandiri from "../../assets/manualRdnMandiri.pdf";
import manualFpreRegulerMargin from "../../assets/manualFpreRegulerMargin.pdf";
export function KisiMobile(props) {
  // let page;
  const dispatch = useDispatch();
  useEffect(() => {
    Aos.init({ duration: 2000 });
    window.scrollTo(0, 0);
    getMobileOperatingSystem();
    const page = window.location.pathname;
    // console.log(page.substring(1, page.length));

    dispatch(setActiveMenu("Produk"));
    document.getElementById(page.substring(1, page.length)).className +=
      " subPathActive";
  }, []);
  const onPlayerReady: YouTubeProps["onReady"] = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };
  const openInNewTab = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };
  const navigate = useNavigate();
  const goTo = (route) => {
    navigate(route);
  };
  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return "https://play.google.com/store/apps/details?id=id.co.kisi.kisimobile&hl=in&gl=US";
    }

    return "https://apps.apple.com/id/app/kisi-mobile/id1493151464";
  };
  const opts = {
    width: "55%",
    height: "560px",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
    id: "videoPlayerKisiMobile",
  };
  return (
    <div>
      <div class="subPathWrapper subPathWrapperMobile mb-5 pb-5">
        <div class="subPathContainer appPageLocation">
          <div
            className="subPath monsterratMedium rounded-pill mx-3"
            id="kisimobile"
            onClick={() => goTo("/kisimobile")}
          >
            KISI MOBILE
          </div>
          <div
            className="subPath monsterratMedium rounded-pill mx-3"
            id="koins"
            onClick={() => goTo("/koins")}
          >
            KOINS
          </div>
        </div>
      </div>
      {/* <PageLocation left="Home" leftPath="/" right="KISI Mobile" /> */}
      <div data-aos="fade-up" data-aos-duration="3000">
        {" "}
        <img class="rocketUpperImg" src={rocket} />
      </div>
      <div class="kisiMobileRegistration">
        <div class="kisiMobileTextContainer kisiAdj">
          <div class="monsterratBlack grey kisiMobileBigText blue-text-1">
            Buka Akun Regular <br></br>dengan Aplikasi <br></br>KISI Mobile
          </div>
          <div class="monsterratMedium grey kisiMobileSmallText">
            Buat rekening saham dan RDN di mana saja dan kapan saja dengan
            menggunakan KISI Mobile. Lebih Mudah dan Cepat!
          </div>
          <div class="downloadButtonGroup">
            <div class=" downloadButtonContainer">
              <button
                class=" monsterratBlack blue-btn rounded-pill koinsDownload downloadButton  f1r"
                onClick={() => openInNewTab(getMobileOperatingSystem())}
              >
                DOWNLOAD SEKARANG
              </button>
              <div class="flex mobileMarketplaceContainer">
                <div class="flex flexEnd appStore">
                  <img
                    class="mobileMarketplace"
                    src={appStore}
                    onClick={() =>
                      openInNewTab(
                        "https://apps.apple.com/id/app/kisi-mobile/id1493151464"
                      )
                    }
                  />
                </div>
                <div class="flex flexStart playStore">
                  <img
                    class="mobileMarketplace"
                    src={playStore}
                    onClick={() =>
                      openInNewTab(
                        "https://play.google.com/store/apps/details?id=id.co.kisi.kisimobile&hl=in&gl=US"
                      )
                    }
                  />
                </div>
              </div>
              {/* <div class="monsterratRegular grey disclaimerText">
                *Selain Nasabah Bank BCA & CIMB Niaga, Hubungi Customer Service
                Kami
              </div> */}
            </div>
          </div>
        </div>
        <div class="rocketImgContainer">
          <img
            class="rocketImg"
            src={rocket}
            data-aos="fade-up"
            data-aos-duration="3000"
          />
        </div>
      </div>
      <div class="secondSectionWrapper">
        {" "}
        <div class="monsterratExtraBold  temukanText mx-auto f25r blue-text-1 textCenter px-4 wide-max-80">
          Hal yang Akan Anda Temukan di Aplikasi KISI Mobile
        </div>
        {/* <div class="grey f1r monsterratMedium mx-auto text-center">
          Yang akan anda temukan di KISI Mobile
        </div> */}
        <div class="kisiMobileSecondSection">
          <div class="kisiMobileFeature">
            <img src={fundMall} class="mobileFeatureImg" />
            <div class="featureText grey monsterratBold blue-text-1">
              Fund Mall
            </div>
          </div>
          <div class="kisiMobileFeature">
            <img src={marketResearch} class="mobileFeatureImg" />
            <div class="featureText grey monsterratBold blue-text-1">
              Market Research
            </div>
          </div>

          <div class="kisiMobileFeature">
            <img src={newsArticles} class="mobileFeatureImg" />
            <div class="featureText grey monsterratBold blue-text-1">
              News Articles
            </div>
          </div>
          <div class="kisiMobileFeature">
            <img src={education} class="mobileFeatureImg" />
            <div class="featureText grey monsterratBold blue-text-1">
              Education
            </div>
          </div>
        </div>
      </div>

      <div class="langkahPendaftaranWrapper rounded-corner bg-biru-muda py-4 my-4">
        <div class="f25r nowrap monsterratExtraBold langkahPendaftaranTitle blue-text-1">
          Tata Cara Pendaftaran Online
        </div>
        <div class="langkahPendaftaranContainer mb-5">
          <div class="langkahPendaftaran">
            <div class="monsterratBlack textCenter stepText blue-text-1 grey">
              Step 1
            </div>
            <div class="langkahPendaftaranImgContainer">
              <img class="langkahPendaftaranImg " src={kisiMobile} />
            </div>
            <div class="langkahPendaftaranText grey monsterratMedium">
              Download KISI Mobile
            </div>
          </div>
          <div class="langkahSpacer monsterratBlack">- - - - - -</div>
          <div class="langkahPendaftaran  ">
            <div class="monsterratBlack textCenter stepText blue-text-1 grey">
              Step 2
            </div>
            <div class="langkahPendaftaranImgContainer resume">
              <img class="langkahPendaftaranImg resume" src={letter} />
            </div>
            <div class="langkahPendaftaranText grey monsterratMedium">
              Ikuti instruksi untuk mendaftar
            </div>
          </div>
          <div class="langkahSpacer monsterratBlack">- - - - - -</div>
          <div class="langkahPendaftaran ">
            <div class="monsterratBlack textCenter stepText blue-text-1 grey">
              Step 3
            </div>
            <div class="langkahPendaftaranImgContainer mail ">
              <img class="langkahPendaftaranImg mail" src={mail} />
            </div>
            <div
              style={{ width: "200%", marginLeft: "-50%" }}
              class="langkahPendaftaranText grey monsterratMedium"
            >
              Setelah berhasil mendaftar, maka ID, PIN, & Password aplikasi
              KOINS akan dikirimkan melalui email yang telah didaftarkan
            </div>
          </div>
          <div class="langkahSpacer monsterratBlack">- - - - - -</div>
          <div class="langkahPendaftaran">
            <div class="monsterratBlack textCenter stepText blue-text-1 grey">
              Step 4
            </div>
            <div class="langkahPendaftaranImgContainer">
              <img class="langkahPendaftaranImg koinsStep" src={koins} />
            </div>
            <div class="langkahPendaftaranText grey monsterratMedium">
              Download KOINS untuk melakukan Trading
            </div>
          </div>
        </div>
      </div>
      <div class="registrationNoteWrapper rounded-corner py-4 mb-5">
        <div class="registrationNote grey monsterratMedium f1r mt-3">
          <b>NOTE:</b> Dalam Proses Registrasi, Calon Nasabah diharapkan untuk
          dapat menyertakan identitas Diri, seperti: KTP dan NPWP.
        </div>
        <div class="registrationNote grey monsterratMedium  f1r mt-4">
          Bersedia melakukan Verifikasi Data Nasabah, melalui Proses Video Call
          yang jadwalnya dapat disesuaikan oleh Calon Nasabah
        </div>
      </div>
      <div class="videoKisiMobileWrapper">
        <div class="monsterratExtraBold grey tutorialTitle blue-text-1">
          Video Tutorial Pembukaan Rekening
        </div>
        <div class="monsterratMedium grey textCenter mt-2">
          <p>Nikmati Kemudahan Bersama KISI Mobile</p>
        </div>
        <div class="videoPlayer py-3">
          <video
            src={videoKisiMobile}
            poster={thumb}
            class="videoKisiMobile"
            width="50%"
            type="video/wmv"
            height="auto"
            controls
          ></video>
          {/* <YouTube
            videoId="w1YDgto5o9A"
            className="videoPlayerKisiMobile"
            onReady={onPlayerReady}
            opts={opts}
          /> */}
        </div>
      </div>

      {/* <div class="monsterratRegular grey textCenter f2r mt-4 mb-5">
        Download buku panduan untuk pendaftaran KISI Mobile
      </div>
      <div class="mx-auto textCenter mb-5">
        <button class="downloadKoinsKisiButton textCenter mx-auto monsterratBlack">
          Download
        </button>
      </div> */}
      {/* <div class="syariahButtonWrapper my-5 pt-2 pb-5 mb-5 mw80 mx-auto">
        <div class="f25r  mb-4 blue-text-1  monsterratExtraBold text-center">
          Manual Book
        </div>
        <div class="monsterratMedium grey block1000 f1r flex text-center pt-1">
          <div class="flex mx-auto block1000">
            <div>
              <button
                class="syariahPdfButton appendixPdfButton grey f1r px-4 mx-2 mt-3"
                onClick={() => openInNewTab(manualPengisianKyc)}
              >
                Pengisian KYC Individu
              </button>
              <button
                class="syariahPdfButton appendixPdfButton grey f1r px-4 mx-2 mt-3"
                onClick={() => openInNewTab(manualFpreRegulerMargin)}
              >
                Pengisian FPRE Akun Reguler dan Akun Margin
              </button>
              <button
                class="syariahPdfButton appendixPdfButton grey f1r px-4 mx-2 mt-3"
                onClick={() => openInNewTab(manualFpreSyariah)}
              >
                Pengisian FPRE Akun Syariah
              </button>
              <button
                class="syariahPdfButton appendixPdfButton grey f1r px-4 mx-2 mt-3"
                onClick={() => openInNewTab(manualRdnCIMB)}
              >
                Pengisian RDN CIMB Individu
              </button>
              <button
                class="syariahPdfButton appendixPdfButton grey f1r px-4 mx-2 mt-3"
                onClick={() => openInNewTab(manualRdnMandiri)}
              >
                Pengisian RDN Bank Mandiri Individu
              </button>
              <button
                class="syariahPdfButton appendixPdfButton grey f1r px-4 mx-2 mt-3"
                onClick={() => openInNewTab(manualRdnBCA)}
              >
                Pengisian RDN Bank BCA Individu
              </button>
            </div>
          </div>
        </div>
      </div> */}
      <FollowSocmed />
      {/* <div class="textCenter monsterratBold grey">Disclaimer </div> */}
      {/* <div class="textCenter typold grey disclaimerText2 mx-auto mb-5">
        Hi #SobatKISI, Aplikasi KISI Mobile dari Korea Investment & Sekuritas
        Indonesia saat ini hanya dapat melayani pendaftaran Full Online untuk
        Nasabah Bank BCA. Kami memohon maaf untuk nasabah dengan rekening bank
        selain BCA, diharapkan tetap dapat melakukan pendaftaran dengan
        mengirimkan berkas.
      </div> */}
      <div></div>
    </div>
  );
}
