import "./style.css";
import React, { useEffect, useState } from "react";
export function NotFound() {
  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);

  return (
    <div>
      <div class="">
        <div class="flex-center position-ref grey mheight80">
          <div class="code">404 </div>
          <div class="message" style={{ padding: "10px" }}>
            Not Found{" "}
          </div>
        </div>
      </div>
    </div>
  );
}
