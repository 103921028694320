import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

const intialState = {
  isDataFetched: false,
  sliderImages: [],
  tes: "",
  slideIndex: 1,
  promos: [],
  news: [],
  accessToken: "XXX",
  sortedNews: [],
  newsDetail: { nama: "" },
  lokasi: "indonesia",
  activeMenu: "Home",
  articlePage: 1,
  rnaMenu: "daily",
  research_list: [],
  rnaPage: 1,
};

function reducer(state = intialState, action) {
  const { type, payload } = action;
  switch (type) {
    case "SET_LOGIN":
      console.log("dad");
      return { ...state, isLogin: payload };
    case "SET_DOCUMENTS":
      return { ...state, documents: payload };
    case "SET_IS_DATA_FETCHED":
      return { ...state, isDataFetched: payload };
    case "SET_CURRENT_PAGE":
      return { ...state, currentPage: payload };
    case "SET_KEYWORD":
      return { ...state, searchKeyword: payload };
    case "SET_SLIDE":
      //   console.log(payload, "tes");
      return { ...state, slideIndex: payload };
    case "SET_SLIDER_IMAGES":
      return { ...state, sliderImages: payload };
    case "SET_PROMOS":
      return { ...state, promos: payload };
    case "SET_NEWS":
      return { ...state, news: payload };
    case "SET_ACCESS_TOKEN":
      return { ...state, accessToken: payload };
    case "SET_SORTED_NEWS":
      return { ...state, sortedNews: payload };
    case "SET_NEWS_DETAIL":
      return { ...state, newsDetail: payload };
    case "SET_LOKASI":
      return { ...state, lokasi: payload };
    case "SET_ACTIVE_MENU":
      return { ...state, activeMenu: payload };
    case "SET_ARTICLE_PAGE":
      return { ...state, articlePage: payload };
    case "SET_RNA_MENU":
      return { ...state, rnaMenu: payload };
    case "SET_RESEARCH_LIST":
      return { ...state, research_list: payload };
    case "SET_RNA_PAGE":
      return { ...state, rnaPage: payload };
    default:
      return state;
  }
}

const store = createStore(reducer, applyMiddleware(thunk));

export default store;
