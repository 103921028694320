import logo from "../../../logo.svg";
import React, { useEffect, useState } from "react";
import "./style.css";
import PageLocation from "../../../components/PageLocation";
import koinsSyariah from "../../../assets/koinsSyariah.png";
import syariah2017 from "../../../assets/syariah2017.pdf";
import syariah2015 from "../../../assets/syariah2015.pdf";
import syariah2011 from "../../../assets/syariah2011.pdf";
import syariahSertifikatMui from "../../../assets/syariahSertifikatMui.pdf";
import koins from "../../../assets/koins.png";
import kisiMobile from "../../../assets/kisiMobile.png";
import letter from "../../../assets/letter.png";
import mail from "../../../assets/email.png";
import syariahImg from "../../../assets/syariahImg.jpg";
import playStore from "../../../assets/playStore.png";
import appStore from "../../../assets/appStore.png";
import Aos from "aos";
import { useNavigate } from "react-router-dom";
import { setActiveMenu } from "../../../store/action";
import { useDispatch, useSelector } from "react-redux";
import manualPengisianKyc from "../../../assets/manualPengisianKyc.pdf";
import manualFpreSyariah from "../../../assets/manualFpreSyariah.pdf";
import manualRdnCIMB from "../../../assets/manualRdnCIMB.pdf";
import manualRdnBCA from "../../../assets/manualRdnBCA.pdf";
import manualRdnMandiri from "../../../assets/manualRdnMandiri.pdf";
export function Syariah() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [imageLoaded, setImageLoaded] = useState(false);
  function openInNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }
  useEffect(() => {
    Aos.init({ duration: 2000 });
    window.scrollTo(0, 0);
    dispatch(setActiveMenu("Produk"));
  }, []);
  return (
    <div className={`${imageLoaded ? "o1" : "o0"}`}>
      {/* <PageLocation left="Home" right="Syariah" subPath="Syariah" /> */}
      <div className={`syariahPageContainer ${imageLoaded ? "o1" : "o0"}`}>
        <div class="syariahPageWrapper">
          {/* <div class="syariahImgContainer">
            <img  src={koinsSyariah}></img>
          </div> */}

          <div
            className={`  syariahBanner productBanner center-vertical syariahImgDivContainer`}
          >
            <div class="kisiMobileTextContainer syariahTextContainer">
              <div class="monsterratBlack nowrap  syariahTitle f3r bannerTitle green-text ">
                Buka<br class="brMobile"></br> Rekening<br></br>Syariah{" "}
                <br class="brMobile"></br> Bersama
              </div>
              <div class="koinsSyariahImgContainer">
                <img class="koinsSyariahImg" src={koinsSyariah} />
              </div>
              <div
                class="monsterratMedium syariahSmallText f12r bannerSubtitle grey kisiMobileSmallText "
                style={{ marginTop: "-1.5rem" }}
              >
                Buka Rekening Syariah Lebih Mudah dan Cepat
                {/* Buat rekening syariah di mana saja dan <br></br>
kapan saja dengan menggunakan KISI Mobile. <br></br>
Lebih Mudah dan Cepat */}
              </div>
              <div class="downloadButtonGroup syariahDownload">
                <div class=" downloadButtonContainer etfDownloadButtonContainer">
                  <button
                    class="thin-btn f1r monsterratBlack blue-btn rounded-pill koinsDownload downloadButton px-5 mt-3 syariahButton "
                    onClick={() => navigate("/kisimobile")}
                  >
                    DOWNLOAD SEKARANG
                  </button>
                  <div class="flex mobileMarketplaceContainer">
                    <div class="flex flexEnd appStore">
                      <img
                        class="mobileMarketplace"
                        src={appStore}
                        onClick={() =>
                          openInNewTab(
                            "https://apps.apple.com/id/app/kisi-mobile/id1493151464"
                          )
                        }
                      />
                    </div>
                    <div class="flex flexStart playStore">
                      <img
                        class="mobileMarketplace"
                        src={playStore}
                        onClick={() =>
                          openInNewTab(
                            "https://play.google.com/store/apps/details?id=id.co.kisi.kisimobile&hl=in&gl=US"
                          )
                        }
                      />
                    </div>
                  </div>
                  {/* <div class="monsterratRegular grey disclaimerText">
                *Selain Nasabah Bank BCA & CIMB Niaga, Hubungi Customer Service
                Kami
              </div> */}
                </div>
              </div>
            </div>

            <img
              class="syariahImg"
              src={syariahImg}
              data-aos="fade-up"
              data-aos-duration="3000"
              onLoad={() => {
                setImageLoaded(true);
              }}
            />
          </div>

          <div class="syariahTextWrapper pb-5 pt-5 mt-5">
            <div class="f25r  mb-4 center768  green-text monsterratExtraBold ">
              Saham Syariah
            </div>
            <div class=" center768 monsterratMedium grey f1r ">
              Saham syariah merupakan efek berbentuk saham yang tidak
              bertentangan dengan prinsip syariah di Pasar Modal. Definisi saham
              dalam konteks saham syariah merujuk kepada definisi saham pada
              umumnya yang diatur dalam undang-undang maupun peraturan OJK
              lainnya. <br></br>Ada dua jenis saham syariah yang diakui di pasar
              modal Indonesia, diantaranya <br></br>
              <br></br>1. Saham yang dinyatakan memenuhi kriteria seleksi saham
              syariah berdasarkan peraturan OJK Nomor 35/POJK.04/2017 tentang
              Kriteria dan Penerbitan Daftar Efek Syariah, <br></br>
              <br></br>2. Saham yang dicatatkan sebagai saham syariah oleh
              emiten atau perusahan publik syariah berdasarkan peraturan OJK no.
              17/POJK.04/2015.<br></br>
              <br></br> Semua saham syariah yang terdapat di pasar modal syariah
              Indonesia, dimasukkan ke dalam Daftar Efek Syariah (DES) yang
              diterbitkan oleh OJK secara berkala pada setiap bulan Mei dan
              November.
            </div>
          </div>
          <div class="syariahTextWrapper  center768 mt-2 pb-5">
            <div class="f25r  mb-4 green-text monsterratExtraBold">
              Sistem Online Trading Syariah (SOTS)
            </div>
            <div class="monsterratMedium grey f1r">
              Sistem online trading syariah (SOTS) adalah system transaksi saham
              syariah secara online yang memenuhi prinsip – prinsip syariah di
              pasar modal. SOTS disertifikasi oleh DSN-MUI karena merupakan
              penjabaran dari fatwa DSN-MUI No. 80 tahun 2011 tentang penerapan
              prinsip syariah dalam mekanisme perdagangan efek bersifat ekuitas
              di Pasar Reguler Bursa Efek. <br></br>
              <br></br>Fitur utama SOTS adalah sebagai berikut: <br></br>
              <ol>
                <li class="mt-2">
                  Hanya saham syariah (dalam indeks ISSI) yang dapat
                  ditransaksikan.{" "}
                </li>

                <li class="mt-2">
                  Transaksi beli saham syariah hanya dapat dilakukan secara
                  tunai (cash-basis transaction).{" "}
                </li>

                <li class="mt-2">
                  Tidak dapat melakukan transaksi jual saham syariah yang belum
                  dimiliki (short selling).{" "}
                </li>

                <li class="mt-2">Portofolio saham syariah terpisah.</li>
              </ol>
            </div>
          </div>
          {/* <div class="syariahTextWrapper mt-5 pb-5">
            <div class="f25r  mb-4 green-text monsterratExtraBold text-center">
            Tata Cara Pembukaan <br></br>Rekening Syariah di KISI Mobile
            </div>
            <div class="monsterratMedium grey f1r text-center">
              Mengikuti tata cara akun regular, hanya pemilihan akunnya oleh
              nasabah adalah akun syariah
            </div>
          </div> */}
          <div class="langkahPendaftaranWrapper langkahSyariah rounded-corner bg-green py-4 mb-4">
            <div class=" f25r nowrap monsterratExtraBold langkahPendaftaranTitle green-text">
              Tata Cara Pendaftaran Online
            </div>
            <div class="langkahPendaftaranContainer mb-5">
              <div class="langkahPendaftaran">
                <div class="monsterratBlack textCenter stepText green-text ">
                  Step 1
                </div>
                <div class="langkahPendaftaranImgContainer">
                  <img class="langkahPendaftaranImg " src={kisiMobile} />
                </div>
                <div class="langkahPendaftaranText grey monsterratMedium">
                  Download KISI Mobile
                </div>
              </div>
              <div class="langkahSpacer monsterratBlack">- - - - - -</div>
              <div class="langkahPendaftaran  ">
                <div class="monsterratBlack textCenter stepText green-text ">
                  Step 2
                </div>
                <div class="langkahPendaftaranImgContainer resume">
                  <img class="langkahPendaftaranImg resume" src={letter} />
                </div>
                <div class="langkahPendaftaranText grey monsterratMedium">
                  Ikuti instruksi untuk mendaftar lalu pilih Akun Syariah
                </div>
              </div>
              <div class="langkahSpacer monsterratBlack">- - - - - -</div>
              <div class="langkahPendaftaran ">
                <div class="monsterratBlack textCenter stepText green-text ">
                  Step 3
                </div>
                <div class="langkahPendaftaranImgContainer mail ">
                  <img class="langkahPendaftaranImg " src={mail} />
                </div>
                <div
                  style={{ width: "200%", marginLeft: "-50%" }}
                  class="langkahPendaftaranText grey monsterratMedium"
                >
                  Setelah berhasil mendaftar, maka User ID, PIN, & Password
                  aplikasi KOINS akan dikirimkan melalui email yang telah
                  didaftarkan
                </div>
              </div>
              <div class="langkahSpacer monsterratBlack">- - - - - -</div>
              <div class="langkahPendaftaran">
                <div class="monsterratBlack textCenter stepText green-text ">
                  Step 4
                </div>
                <div class="langkahPendaftaranImgContainer">
                  <img class="langkahPendaftaranImg " src={koins} />
                </div>
                <div class="langkahPendaftaranText grey monsterratMedium">
                  Download KOINS untuk melakukan Trading
                </div>
              </div>
            </div>
          </div>
          {/* <div class="syariahButtonWrapper my-5 pt-5  mb-2 mw80 mx-auto">
            <div class="f25r  mb-4 green-text  monsterratExtraBold text-center">
              Manual Book
            </div>
            <div class="monsterratMedium grey block1000 f1r flex text-center pt-1">
              <div class="flex mx-auto block1000">
                <div>
                  <button
                    class="syariahPdfButton  grey f1r px-4 mx-2 mt-3"
                    onClick={() => openInNewTab(manualPengisianKyc)}
                  >
                    Pengisian KYC Individu
                  </button>
                  <button
                    class="syariahPdfButton  grey f1r px-4 mx-2 mt-3"
                    onClick={() => openInNewTab(manualFpreSyariah)}
                  >
                    Pengisian FPRE Akun Syariah
                  </button>
                  <button
                    class="syariahPdfButton  grey f1r px-4 mx-2 mt-3"
                    onClick={() => openInNewTab(manualRdnCIMB)}
                  >
                    Pengisian RDN CIMB Individu
                  </button>
                 
                </div>
              </div>
            </div>
          </div> */}
          <div class="syariahButtonWrapper my-5 pb-5 pt-4">
            <div class="f25r  mb-4 green-text monsterratExtraBold text-center">
              Appendix
            </div>
            <div class="monsterratMedium grey block1000 f1r flex text-center pt-1">
              <div class="flex mx-auto block1000">
                {/* <div>
                <button
                  class="syariahPdfButton grey f1r px-4 mx-2"
                  onClick={() => openInNewTab(syariah2017)}
                >
                  POJK No.35/POJK.04/2017
                </button>
              </div>
              <div>
                <button
                  class="syariahPdfButton grey f1r px-4 mx-2"
                  onClick={() => openInNewTab(syariah2015)}
                >
                  POJK No.17/POJK.04/2015
                </button>
              </div>
              <div>
                <button
                  class="syariahPdfButton grey f1r px-4 mx-2"
                  onClick={() => openInNewTab(syariah2011)}
                >
                  Fatwa DSN-MUI No.80 tahun 2011
                </button>
              </div> */}
                <div>
                  <button
                    class="syariahPdfButton grey f1r px-4 mx-2 mt-3"
                    onClick={() => openInNewTab(syariahSertifikatMui)}
                  >
                    Sertifikasi DSN MUI
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
