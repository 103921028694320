import "./style.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import YouTube, { YouTubeProps } from "react-youtube";
import PageLocation from "../../components/PageLocation";
import playStore from "../../assets/playStore.png";
import appStore from "../../assets/appStore.png";
import windowsStore from "../../assets/windowsStore.png";
import orderBooking from "../../assets/orderBooking.png";
import automaticOrder from "../../assets/automaticOrder.png";
import searchStock from "../../assets/searchStock.png";
import realTime from "../../assets/realTime.png";
import KOINS_HTS_setup from "../../assets/KOINS_HTS_setup.exe";
import koins from "../../assets/koins.png";
import kisiMobile from "../../assets/kisiMobile.png";
import resume from "../../assets/resume.png";
import letter from "../../assets/letter.png";
import mail from "../../assets/email.png";
import koinsPage from "../../assets/koinsPage.png";
import FollowSocmed from "../../components/FollowSocmed";
import Aos from "aos";
import koinsTutorial from "../../assets/koinsTutorial.zip";
import { setActiveMenu } from "../../store/action";
import { useDispatch, useSelector } from "react-redux";
import YouTubePlaylist from "@codesweetly/react-youtube-playlist";
export function Koins() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ytApikey = process.env.REACT_APP_YT_API_KEY;
  useEffect(() => {
    // console.log(document.getElementsByClassName("ytp-playlist-menu"), "jeje");
    Aos.init({ duration: 2000 });
    window.scrollTo(0, 0);
    const page = window.location.pathname;
    // console.log(page.substring(1, page.length));
    dispatch(setActiveMenu("Produk"));

    document.getElementById(page.substring(1, page.length)).className +=
      " subPathActive";
  }, []);
  const onPlayerReady: YouTubeProps["onReady"] = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };
  const openInNewTab = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };

  const ytPlaylist = () => {
    // console.log("as");
    // console.log(document.getElementById("ytp-id-22"), "ass");
  };
  const opts = {
    width: "55%",
    height: "640px",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows/i.test(userAgent)) {
      return KOINS_HTS_setup;
    }

    if (/android/i.test(userAgent)) {
      return "https://play.google.com/store/apps/details?id=com.koins.mtsand&hl=in&gl=US";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "https://apps.apple.com/id/app/koins-mobile/id1436459041";
    }

    return "unknown";
  }
  getMobileOperatingSystem();
  return (
    <div>
      {/* top section */}
      <div class="subPathWrapper subPathWrapperMobile mb-5 pb-5">
        <div class="subPathContainer appPageLocation">
          <div
            className="subPath monsterratMedium rounded-pill mx-3"
            id="kisimobile"
            onClick={() => navigate("/kisimobile")}
          >
            KISI MOBILE
          </div>
          <div
            className="subPath monsterratMedium rounded-pill mx-3"
            id="koins"
            onClick={() => navigate("/koins")}
          >
            KOINS 
          </div>
        </div>
      </div>
      <div>
        {" "}
        <img class="koinsUpperImg" src={koinsPage} />
      </div>
      <div class="koinsContainer">
        <div class="container flexEnd ">
          <div class="koinsTextContainer center-vertical">
            <div class="">
              <div class="monsterratBlack blue-text-1 kisiMobileBigText">
                KOINS Aplikasi<br></br> Terpercaya untuk<br></br> Hidup Lebih
                Nyaman
              </div>
              <div class="monsterratMedium grey koinsSmallText">
                Korea Investment And Sekuritas Indonesia menghadirkan platform
                KOINS untuk membantu nasabah berinvestasi di pasar modal, baik
                jual beli-saham, Reksa Dana, Syariah, maupun Surat Berharga
                Negara.
              </div>
              <div class="downloadButtonGroup center768 ">
                <div class=" downloadButtonContainer mx-auto-768">
                  <button
                    class=" monsterratBlack blue-btn rounded-pill koinsDownload downloadButton  f1r"
                    onClick={() => openInNewTab(getMobileOperatingSystem())}
                  >
                    DOWNLOAD SEKARANG
                  </button>
                  <div class="flex koinsMarketplaceContainer">
                    <div class="  appStore koinsStore">
                      <img
                        class="mobileMarketplace"
                        src={appStore}
                        onClick={() =>
                          openInNewTab(
                            "https://apps.apple.com/id/app/koins-mobile/id1436459041"
                          )
                        }
                      />
                    </div>
                    <div class="playStore koinsStore">
                      <img
                        class="mobileMarketplace"
                        src={playStore}
                        onClick={() =>
                          openInNewTab(
                            "https://play.google.com/store/apps/details?id=com.koins.mtsand&hl=in&gl=US"
                          )
                        }
                      />
                    </div>
                    <div class="  windowsStore koinsStore">
                      <img
                        class="mobileMarketplace"
                        src={windowsStore}
                        onClick={() => openInNewTab(KOINS_HTS_setup)}
                      />
                    </div>
                  </div>
                </div>
                {/* <div class="typold grey disclaimerText">
                *Selain Nasabah Bank BCA & CIMB Niaga, Hubungi Customer Service
                Kami
              </div> */}
              </div>
            </div>
          </div>
        </div>
        <div class="koinsImgContainer">
          <img
            class="koinsPageImg"
            src={koinsPage}
            // data-aos="fade-up"
            // data-aos-duration="3000"
          />
        </div>
      </div>
      {/* <div class="textCenter jenisAkunContainer jenisAkunKoins">
        <div class="monsterratBold grey f2r"></div>
        <div class="monsterratExtraBold mt-3 f3r mb-3 blue-text-1 ">Produk & Layanan KOINS</div>
        <div class="monsterratMedium grey jenisAkunText mt-2 f1r">
          Aplikasi Mandiri Sekuritas Online Trading adalah solusi yang
          memudahkan Anda untuk melakukan transaksi efek di mana pun, kapan pun.
          Memiliki 3 jenis akun yang dapat dipilih nasabah yaitu:
        </div>
        <div class="jenisAkunCardContainer ">
        <div class="jenisAkunCardWrapper">
          <div class="jenisAkunCard rounded-corner">
            <div class="f25r monsterratBlack"> 01</div>
            <div class="f25r monsterratExtraBold  blue-text-1">
              {" "}
              Regular
            </div>
            <div class="f1r monsterratMedium grey">
              {" "}
              Minimal Deposit 100rb
            </div>
            <button class="mt-5 pelajariButton rounded-pill  monsterratBold" onClick={()=>{navigate('/kisimobile')}}>
              PELAJARI
            </button>
          </div>
          <div class="jenisAkunCard rounded-corner">
            <div class="f25r monsterratExtraBold"> 02</div>
            <div class="f25r monsterratExtraBold  blue-text-1"> Marjin</div>
            <div class="f1r monsterratMedium  grey">
              {" "}
              Minimal Deposit 200jt
            </div>
            <button class="mt-5 pelajariButton rounded-pill  monsterratBold" onClick={()=>{navigate('/produk/margin')}}>
              PELAJARI
            </button>
          </div>{" "}
          <div class="jenisAkunCard rounded-corner">
            <div class="f25r monsterratExtraBold"> 03</div>
            <div class="f25r monsterratExtraBold  blue-text-1">
              {" "}
              Syariah
            </div>
            <div class="f1r monsterratMedium  grey">
              {" "}
              Minimal Deposit 100rb
            </div>
            <button class="mt-5 pelajariButton rounded-pill  monsterratBold" onClick={()=>{navigate('/produk/syariah')}}>
              PELAJARI
            </button>
          </div>
        </div>
      </div>
      </div> */}

      <div class="nowrap temukanText monsterratExtraBold mx-auto grey langkahPendaftaranTitle textCenter f25r blue-text-1 mt-5">
        Temukan Pengalaman<br></br> Terbaik Anda di KOINS
      </div>
      <div class="kisiMobileSecondSection">
        <div class="kisiMobileFeature">
          <img src={orderBooking} class="mobileFeatureImg" />
          <div class="featureTextKoins blue-text-1 monsterratBold">
            Order Booking
          </div>
        </div>

        <div class="kisiMobileFeature">
          <img src={automaticOrder} class="mobileFeatureImg" />
          <div class="featureTextKoins blue-text-1 monsterratBold">
            Automatic Order
          </div>
        </div>
        <div class="kisiMobileFeature">
          <img src={searchStock} class="mobileFeatureImg" />
          <div class="featureTextKoins blue-text-1 monsterratBold">
            Search Stock
          </div>
        </div>
        <div class="kisiMobileFeature">
          <img src={realTime} class="mobileFeatureImg" />
          <div class="featureTextKoins blue-text-1 monsterratBold">
            Real Time
          </div>
        </div>
      </div>
      <div class="langkahPendaftaranWrapper rounded-corner bg-biru-muda py-4 my-4">
        <div class=" f25r  grey monsterratExtraBold langkahPendaftaranTitle blue-text-1">
        Tata Cara Pendaftaran Online
        </div>
        <div class="langkahPendaftaranContainer mb-5">
          <div class="langkahPendaftaran">
            <div class="monsterratBlack textCenter stepText blue-text-1 grey">
              Step 1
            </div>
            <div class="langkahPendaftaranImgContainer">
              <img class="langkahPendaftaranImg " src={kisiMobile} />
            </div>
            <div class="langkahPendaftaranText grey monsterratMedium">
              Download KISI Mobile
            </div>
          </div>
          <div class="langkahSpacer monsterratBlack">- - - - - -</div>
          <div class="langkahPendaftaran  ">
            <div class="monsterratBlack textCenter stepText blue-text-1 grey">
              Step 2
            </div>
            <div class="langkahPendaftaranImgContainer resume">
              <img class="langkahPendaftaranImg resume" src={letter} />
            </div>
            <div class="langkahPendaftaranText grey monsterratMedium">
              Ikuti instruksi untuk mendaftar
            </div>
          </div>
          <div class="langkahSpacer monsterratBlack">- - - - - -</div>
          <div class="langkahPendaftaran ">
            <div class="monsterratBlack textCenter stepText blue-text-1 grey">
              Step 3
            </div>
            <div class="langkahPendaftaranImgContainer mail ">
              <img class="langkahPendaftaranImg  mail" src={mail} />
            </div>
            <div
              style={{ width: "200%", marginLeft: "-50%" }}
              class="langkahPendaftaranText grey monsterratMedium"
            >
              Setelah berhasil mendaftar, maka ID, PIN, & Password aplikasi
              KOINS akan dikirimkan melalui email yang telah didaftarkan
            </div>
          </div>
          <div class="langkahSpacer monsterratBlack">- - - - - -</div>
          <div class="langkahPendaftaran">
            <div class="monsterratBlack textCenter stepText blue-text-1 grey">
              Step 4
            </div>
            <div class="langkahPendaftaranImgContainer">
              <img class="langkahPendaftaranImg " src={koins} />
            </div>
            <div class="langkahPendaftaranText grey monsterratMedium">
              Download KOINS untuk melakukan Trading
            </div>
          </div>
        </div>
      </div>

      <div class="monsterratBold f2r blue-text-1 tutorialTitle">
        Video Tutorial Penggunaan Aplikasi KOINS
      </div>
      {/* <iframe
        width="50%"
        height="560"
        src="https://www.youtube.com/embed/videoseries?list=PLsb29ECrPaO91JURL6Fd54fYhak3YEzXw"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe> */}
      <div class="monsterratMedium mt-2 f1r grey textCenter videoText">
        <p>Nikmati Kemudahan Bersama KOINS</p>
      </div>
      <div class="iframeKoinsContainer  center-vertical">
        <iframe
          width="100%"
          height={window.innerHeight > 768 ? "auto" : "500px"}
          src="https://www.youtube.com/embed/DY_pBXGdzv4"
          title="KOINS"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          id="yt-koins"
          className="iframe-koins"
        ></iframe>
        <div class="ytOtherContainer">
          <YouTubePlaylist
            onLoad={() => {
              ytPlaylist();
            }}
            apiKey={ytApikey}
            playlistId="PLsb29ECrPaO8isYGaWvLjQxmm4FwjN4AO"
            uniqueName="youtube-playlist-container"
            className="koinsPlaylist"
          />
        </div>
      </div>
      {/* <div class="videoPlayerKoins flex  py-3">
       
        <YouTubePlaylist
      apiKey="AIzaSyCMBV9_vZ-LQiC-TsY81MM2_l9g15dIecs"
      playlistId="PLsb29ECrPaO91JURL6Fd54fYhak3YEzXw"
      uniqueName="youtube-playlist-container"
    />
      </div> */}
      {/* <div class="monsterratRegular grey textCenter mt-4 mb-5 videoText">
        Download buku panduan untuk Penggunaan KOINS
      </div> */}
      {/* <div class="mx-auto textCenter mb-5">
        <button class="downloadKoinsKisiButton textCenter mx-auto monsterratBlack">
          Download
        </button>
      </div> */}
      <div style={{ height: "2rem" }}></div>
      {/* <button
        class="koinsBottomButton mx-auto my-5 monsterratBlack blue-btn rounded-pill koinsDownload downloadButton  f1r px-5"
        onClick={() => openInNewTab(koinsTutorial)}
      >
        Download Buku Panduan Home Trading System (HTS)
      </button> */}
      <FollowSocmed />
      {/* <YouTubePlaylist
      apiKey="AIzaSyCMBV9_vZ-LQiC-TsY81MM2_l9g15dIecs"
      playlistId="PLsb29ECrPaO91JURL6Fd54fYhak3YEzXw"
      uniqueName="youtube-playlist-container"
    /> */}
      {/* <div class="textCenter monsterratBold grey">Disclaimer </div>
      <div class="textCenter monsterratRegular grey disclaimerText2 mx-auto mb-5">
        Hi #SobatKISI, Aplikasi KISI Mobile dari Korea Investment & Sekuritas
        Indonesia saat ini hanya dapat melayani pendaftaran Full Online untuk
        Nasabah Bank BCA. Kami memohon maaf untuk nasabah dengan rekening bank
        selain BCA, diharapkan tetap dapat melakukan pendaftaran dengan
        mengirimkan berkas.
      </div> */}
    </div>
  );
}
