import "./style.css";

import comingSoon from "../../../assets/comingSoon.png";
import PageLocation from "../../../components/PageLocation";
import FollowSocmed from "../../../components/FollowSocmed";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import playStore from "../../../assets/playStore.png";
import appStore from "../../../assets/appStore.png";
import sahamPlane from "../../../assets/sahamPlane.png";
import saham from "../../../assets/saham.jpg";
import sahamMobile from "../../../assets/sahamMobile.jpg";
import Aos from "aos";
import koins from "../../../assets/koins.png";
import kisiMobile from "../../../assets/kisiMobile.png";
import letter from "../../../assets/letter.png";
import mail from "../../../assets/email.png";
import regBanner from "../../../assets/regBanner.png";
import { SmoothScroll } from "../../../components/SmoothScroll";
import { setActiveMenu } from "../../../store/action";
import { useDispatch, useSelector } from "react-redux";
import { server, storageLocation } from "../../../config/server";
import manualPengisianKyc from "../../../assets/manualPengisianKyc.pdf";
import manualFpreRegulerMargin from "../../../assets/manualFpreRegulerMargin.pdf";
import manualRdnCIMB from "../../../assets/manualRdnCIMB.pdf";
import manualRdnBCA from "../../../assets/manualRdnBCA.pdf";
import manualRdnMandiri from "../../../assets/manualRdnMandiri.pdf";

export function Saham() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
    window.scrollTo(0, 0);
  }, []);
  const openInNewTab = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };
  const [imageLoaded, setImageLoaded] = useState(false);
  const navigate = useNavigate();
  return (
    <div
      style={{ marginBottom: "7rem" }}
      className={`${imageLoaded ? "o1" : "o0"}`}
    >
      <div class="imageDivWrapper sahamDivWrapper">
        <img
          class="w100 pointer"
          onLoad={() => {
            setImageLoaded(true);
          }}
          src={regBanner}
          onClick={() => navigate("/kisimobile")}
        ></img>
        {/* {window.innerWidth > 768 ? (
          <img
            onLoad={() => {
              setImageLoaded(true);
            }}
            data-aos="fade-up"
            data-aos-duration="2000"
            src={saham}
            alt="banner image"
            class={`imageZ1`}
          />
        ) : (
          <img
            onLoad={() => {
              setImageLoaded(true);
            }}
            data-aos="fade-up"
            data-aos-duration="2000"
            src={sahamMobile}
            alt="banner image"
            class={`imageZ1`}
          />
        )}

        <div class="insideWrapperAbsolute sahamPlane">
          <img
            class="sahamPlaneImg"
            data-aos="fade-up"
            data-aos-duration="2000"
            src={sahamPlane}
          ></img>
        </div>
        <div class="insideWrapperAbsolute sahamImgDiv centerAbsolute768">
          <div class="downloadButtonGroup">
            <div class=" etfDownloadButtonContainer">
              <button
                class=" monsterratBlack blue-btn rounded-pill koinsDownload downloadButton  f1r px-5"
                onClick={() => navigate("/kisimobile")}
              >
                DOWNLOAD SEKARANG
              </button>

              <div class="flex mobileMarketplaceContainer">
                <div class="  flexStart playStore">
                  <img
                    class="mobileMarketplace"
                    src={playStore}
                    onClick={() =>
                      openInNewTab(
                        "https://play.google.com/store/apps/details?id=id.co.kisi.kisimobile&hl=in&gl=US"
                      )
                    }
                  />
                </div>
                <div class="  flexEnd appStore">
                  <img
                    class="mobileMarketplace"
                    src={appStore}
                    onClick={() =>
                      openInNewTab(
                        "https://apps.apple.com/id/app/kisi-mobile/id1493151464"
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="insideWrapperAbsolute sahamBannerText centerAbsolute768">
          <div class="sahamBannerTitle monsterratBlack">
            Nabung <span style={{ color: "#fbb03c" }}>Saham</span>
            <br></br>
            Gak Pake Ribet
            <p class="sahamBannerSubtitle"></p>
          </div>
          <div class="sahamBannerSubtitle monsterratSemiBold  f13r">
            #WaktumuInvestasimu
          </div>
        </div> */}
      </div>

      {/* <PageLocation subPath="Saham" /> */}
      <div class="textCenter jenisAkunContainer sahamContainer pb-5">
        {/* <div class="monsterratBold grey f2r"></div> */}
        <div class="monsterratExtraBold mt-3 mx-auto  f275r  blue-text-1 mw90768">
          AKUN REGULER
        </div>

        <div class="monsterratMedium grey  px-4 f12r">
          Akun Reguler adalah akun utama yang harus dimiliki oleh Nasabah
          sebelum melakukan pembukaan jenis akun lainnya. Pendaftaran Akun
          Reguler dapat dilakukan melalui aplikasi{" "}
          <b
            class="pointer blue-text-1"
            onClick={() => navigate("/kisimobile")}
          >
            KISI Mobile
          </b>{" "}
          atau{" "}
          <b
            class="pointer blue-text-1"
            onClick={() => openInNewTab("https://registration.kisi.co.id")}
          >
            Web Registration
          </b>
          . Kemudian Nasabah dapat <i>log in</i> ke aplikasi{" "}
          <b class="pointer blue-text-1" onClick={() => navigate("/koins")}>
            KOINS HTS/MTS
          </b>{" "}
          dan bertransaksi semua jenis saham yang diperdagangkan di Bursa Efek
          Indonesia (BEI).
        </div>
        {/* <div class="monsterratExtraBold mt-3 f275r  blue-text-1 ">
          Capital Market
        </div>
        <div class="monsterratMedium f15r blue-text-1">Brokerage</div>
        <div class="monsterratMedium grey jenisAkunText  f12r">
          Transaksi Saham dan Turunannya secara mandiri (Online) melalui
          aplikasi terpercaya dan melalui Tenaga Penjualan (Sales offline)
          secara Real Time.
        </div> */}
        {/* <div class="monsterratMedium grey  jenisAkunText  f12r ">
          Memiliki 3 jenis akun yang dapat dipilih nasabah yaitu:
        </div> */}
        {/* <div class="jenisAkunCardContainer ">
          <div class="jenisAkunCardWrapper">
            <div class="jenisAkunCard rounded-corner">

              <div class="f25r monsterratExtraBold  blue-text-1"> Reguler</div>
              <div class="f12r monsterratMedium grey">
                {" "}
                Minimal Deposit 100rb
              </div>
              <button
                class="mt-5 pelajariButton rounded-pill  monsterratBold"
                onClick={() => {
                  navigate("/kisimobile");
                }}
              >
                PELAJARI
              </button>
            </div>
            <div class="jenisAkunCard rounded-corner">

              <div class="f25r monsterratExtraBold  blue-text-1"> Margin</div>
              <div class="f12r monsterratMedium  grey">
                {" "}
                Minimal Deposit 200jt
              </div>
              <button
                class="mt-5 pelajariButton rounded-pill  monsterratBold"
                onClick={() => {
                  navigate("/produk/margin");
                }}
              >
                PELAJARI
              </button>
            </div>{" "}
            <div class="jenisAkunCard rounded-corner">

              <div class="f25r monsterratExtraBold  blue-text-1"> Syariah</div>
              <div class="f12r monsterratMedium  grey">
                {" "}
                Minimal Deposit 100rb
              </div>
              <button
                class="mt-5 pelajariButton rounded-pill  monsterratBold"
                onClick={() => {
                  navigate("/produk/syariah");
                }}
              >
                PELAJARI
              </button>
            </div>
          </div>
        </div> */}
      </div>

      {/* <div class="sahamTableContainer monsterratReguler">
        <table class="table sahamTable">
          <thead>
            <tr>
              <th class=" sahamTableHead f15r monsterratReguler" scope="col">
                Individu (Online)
              </th>
              <th class=" sahamTableHead f15r monsterratReguler" scope="col">
                Tenaga Penjualan (Sales Offline)
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="sahamTableContent monsterratMedium">
                Akses penuh untuk melakukan input order dan mencari data
                keuangan yang dibutuhkan
              </td>
              <td class="sahamTableContent monsterratMedium">
                Tenaga Penjualan siap melayani nasabah pada hari bursa untuk
                melakukan input order atau layanan lain terkait akun nasabah
              </td>
            </tr>
            <tr>
              <td class="sahamTableContent monsterratMedium">
                Fee (biaya) Transaksi kompetitif sesuai kesepakatan
              </td>
              <td class="sahamTableContent monsterratMedium">
                Fee (biaya) Transaksi kompetitif sesuai kesepakatan
              </td>
            </tr>
            <tr>
              <td class="sahamTableContent monsterratMedium">
                Layanan Edukasi gratis terkait penggunaan sistem, mekanisme
                transaksi, analisa yang diperlukan
              </td>
              <td class="sahamTableContent monsterratMedium">
                Tenaga Penjualan siap memberikan konsultasi apabila diperlukan
              </td>
            </tr>
            <tr>
              <td class="sahamTableContent monsterratMedium tableBottom">
                Layanan Customer Centre di nomor 150 350 pada hari Bursa pk
                08.00 - 16.00 WIB dan Galeri di 28 lokasi seluruh Indonesia siap
                membantu memberikan Pelayanan
              </td>
              <td class="sahamTableContent monsterratMedium tableBottom">
                {" "}
                Layanan Customer Centre di nomor 150 350 pada hari Bursa pk
                08.00 - 16.00 WIB dan Galeri di 28 lokasi seluruh Indonesia siap
                membantu memberikan Pelayanan
              </td>
            </tr>
          </tbody>
        </table>
      </div> */}
      {/* <div class="bg-biru-muda sahamTextContainer pt-4 pb-5 text-center rounded-corner mt-5">
        <div class="monsterratExtraBold f275r  blue-text-1 ">
          Minimal Deposit
        </div>

        <div class="monsterratMedium grey mt-3 f12r  mw90 mx-auto">
          Minimal deposit awal untuk pembukaan Akun Reguler adalah Rp. 100.000
        </div>
      </div> */}

      {/* <div class="monsterratExtraBold nowrap  mx-auto f275r  ilustrasiText pb-3 mb-3 blue-text-1  text-center lh14">
        Ilustrasi Akun Margin<br class="brWeb"></br> Dibandingkan
        <br class="brMobile"></br> dengan Rekening Reguler dalam<br></br>{" "}
        penggunaan trading limit dan waktunya
      </div> */}
      <div class="sahamTextContainer px-2">
        <ul class=" f12r grey  monsterratMedium lh2">
          <li>Tidak ada minimum deposit dana.</li>
          <li>
            <i>Fee</i> transaksi beli 0,13% dan jual 0,23%.
          </li>
          <li>
            Penggunaan <i>Trading Limit</i> ((<i>Cash</i> T+2 +{" "}
            <i>Liquidity Value</i> - <i>Order Buy</i> ) x <i>Stock Multiple*</i>{" "}
            )<br></br>Keterangan : <br></br>
            <i>Cash</i> T+2 : dana yang tersedia pada T+2 portofolio. <br></br>
            <i>Liquidity Value</i> : nilai <i>liquidity value</i> pada
            portofolio. <br></br>
            <i>Stock Multiple</i> : ketentuan angka perkalian.
          </li>
          <li>
            Bunga penggunaan <i>Trading Limit</i> 36% per tahun.
          </li>
          <li>
            Pada T+3 dikenakan <i>Suspend Buy</i> dan T+4 dan dikenakan{" "}
            <i>Forced Sell</i>.
          </li>
          <li>
            <b
              class="blue-text-1 pointer"
              onClick={(e) => {
                // openInNewTab(
                //   `${server}${storageLocation}announcement/Regular%20List%20Limit%20Multiple%20Juli%202024.xlsx`
                // );
                navigate('/capping/reguler')
              }}
            >
              Klik untuk melihat
            </b>{" "}
            <i>List Capping</i> saham.
          </li>
        </ul>
        <div class="monsterratExtraBold my-5 mx-auto  f2r text-center blue-text-1 mw90768">
          Tabel <i>Stock Multiple</i>
        </div>
        <table class="table f12r table-bordered textCenter monsterratMedium lh25">
          <thead>
            <tr>
              <th scope="col">Capping</th>
              <th scope="col">
                {" "}
                <i>Multiple</i> (Penggunaan Fasilitas Margin)
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>75%</th>
              <td>4.0 x</td>
            </tr>
            <tr>
              <th>70%</th>
              <td>3.3 x</td>
            </tr>
            <tr>
              <th>60%</th>
              <td>2.5 x</td>
            </tr>
            <tr>
              <th>50%</th>
              <td>2.0 x</td>
            </tr>
            <tr>
              <th>35%</th>
              <td>1.5 x</td>
            </tr>
            <tr>
              <th>10%</th>
              <td>1.0 x</td>
            </tr>
          </tbody>
        </table>
        {/* <div class="monsterratExtraBold poinText f275r  mx-auto py-4 my-4 blue-text-1  text-center lh14">
          Point (+/-) <br class="brMobile"></br>ketetapan standard<br></br> dari
          Akun Margin dan <br class="brMobile"></br>Akun Reguler
        </div>
        <table class="table f12r table-bordered textCenter monsterratMedium lh25">
          <thead>
            <tr>
              <th scope="col">Ketentuan</th>
              <th scope="col">Akun Reguler</th>
              <th scope="col">Akun Margin</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                <i>Fee</i> Transaksi
              </th>
              <td>
                Promo <i>Buy</i> 0,13% / <i>Sell</i> 0,23%{" "}
              </td>
              <td>
                Promo <i>Buy</i> 0,13% / <i>Sell</i> 0,23%
              </td>
            </tr>
            <tr>
              <th>
                <i>Minimum deposit</i> / Aset
              </th>
              <td>-</td>
              <td>Rp. 200,000,000</td>
            </tr>
            <tr>
              <th scope="row">
                Nominal penggunaan margin dan jangka waktu penggunaan
              </th>
              <td>
                3x <i>Cash</i> (Berdasarkan <i>Capping Ratio</i> Saham) dan
                maksimal waktu penggunaan T+4
              </td>
              <td>
                2x <i>Cash</i> (Berdasarkan <i>Capping Ratio</i> Saham) dan
                maksimal waktu penggunaan <i>unlimited</i>
              </td>
            </tr>

            <tr>
              <th scope="row">
                <i>Interest</i> / Bunga penggunaan margin
              </th>
              <td>0,1%/hari atau 36%/tahun</td>
              <td>sampai 0,04%/hari atau sampai 12%/tahun</td>
            </tr>
            <tr>
              <th scope="row">
                <i>Stock List</i> / Saham yang bisa di perdagangkan
              </th>
              <td>Seluruh saham yang listing di bursa</td>
              <td>
                Stock List/ Saham yang bisa di perdagangkan; (Terbatas) 107*
                saham margin perbulan Maret 2023
              </td>
            </tr>
            <tr>
              <th scope="row">
                <i>Forced Sell</i>
              </th>
              <td>
                <i>Suspend buy</i> T+3 dan <i>Forced Sell</i> T+4
              </td>
              <td>
                Apabila <i>collateral ratio</i> >= 65% selama tiga hari dan/atau
                >=80%
              </td>
            </tr>
          </tbody>
        </table> */}
      </div>
      {/* <div class="langkahPendaftaranWrapper rounded-corner bg-biru-muda py-4 my-4 mb-5">
        <div class="f25r nowrap monsterratExtraBold langkahPendaftaranTitle blue-text-1">
          Tata Cara Pendaftaran Online
        </div>
        <div class="langkahPendaftaranContainer mb-5">
          <div class="langkahPendaftaran">
            <div class="monsterratBlack textCenter stepText blue-text-1 grey">
              Step 1
            </div>
            <div class="langkahPendaftaranImgContainer">
              <img class="langkahPendaftaranImg " src={kisiMobile} />
            </div>
            <div class="langkahPendaftaranText grey monsterratMedium">
              Download KISI Mobile
            </div>
          </div>
          <div class="langkahSpacer monsterratBlack">- - - - - -</div>
          <div class="langkahPendaftaran  ">
            <div class="monsterratBlack textCenter stepText blue-text-1 grey">
              Step 2
            </div>
            <div class="langkahPendaftaranImgContainer resume">
              <img class="langkahPendaftaranImg resume" src={letter} />
            </div>
            <div class="langkahPendaftaranText grey monsterratMedium">
              Ikuti instruksi untuk mendaftar lalu pilih Akun Reguler
            </div>
          </div>
          <div class="langkahSpacer monsterratBlack">- - - - - -</div>
          <div class="langkahPendaftaran ">
            <div class="monsterratBlack textCenter stepText blue-text-1 grey">
              Step 3
            </div>
            <div class="langkahPendaftaranImgContainer mail ">
              <img class="langkahPendaftaranImg " src={mail} />
            </div>
            <div
              style={{ width: "200%", marginLeft: "-50%" }}
              class="langkahPendaftaranText grey monsterratMedium"
            >
              Setelah berhasil mendaftar, maka User ID, PIN, & Password aplikasi
              KOINS akan dikirimkan melalui email yang telah didaftarkan
            </div>
          </div>
          <div class="langkahSpacer monsterratBlack">- - - - - -</div>
          <div class="langkahPendaftaran">
            <div class="monsterratBlack textCenter stepText blue-text-1 grey">
              Step 4
            </div>
            <div class="langkahPendaftaranImgContainer">
              <img class="langkahPendaftaranImg " src={koins} />
            </div>
            <div class="langkahPendaftaranText grey monsterratMedium">
              Download KOINS untuk melakukan Trading
            </div>
          </div>
        </div>
      </div> */}
      {/* <div class="syariahButtonWrapper my-5 pt-5 pb-5 mb-5 mw80 mx-auto">
        <div class="f25r  mb-4 blue-text-1 monsterratExtraBold text-center">
          Manual Book
        </div>
        <div class="monsterratMedium grey block1000 f1r flex text-center pt-1">
          <div class="flex mx-auto block1000">
            <div>
              <button
                class="syariahPdfButton appendixPdfButton grey f1r px-4 mx-2 mt-3"
                onClick={() => openInNewTab(manualPengisianKyc)}
              >
                Pengisian KYC Individu
              </button>
              <button
                class="syariahPdfButton appendixPdfButton grey f1r px-4 mx-2 mt-3"
                onClick={() => openInNewTab(manualFpreRegulerMargin)}
              >
                Pengisian FPRE Akun Reguler dan Akun Margin
              </button>
              <button
                class="syariahPdfButton appendixPdfButton grey f1r px-4 mx-2 mt-3"
                onClick={() => openInNewTab(manualRdnCIMB)}
              >
                Pengisian RDN CIMB Individu
              </button>
              <button
                class="syariahPdfButton appendixPdfButton grey f1r px-4 mx-2 mt-3"
                onClick={() => openInNewTab(manualRdnMandiri)}
              >
                Pengisian RDN Bank Mandiri Individu
              </button>
              <button
                class="syariahPdfButton appendixPdfButton grey f1r px-4 mx-2 mt-3"
                onClick={() => openInNewTab(manualRdnBCA)}
              >
                Pengisian RDN Bank BCA Individu
              </button>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div class="sahamTextContainer bg-biru-muda rounded-corner">
        <div class="sahamTextWrapper py-5">
          <div class=" sahamText rounded-corner mx-2  ">
            <div class=" text-center blue-text-1 monsterratExtraBold sahamTextTitle f15r">
              Individu (Online)
            </div>
            <ul>
              {" "}
              <li class="sahamTableContent monsterratMedium">
                Akses penuh untuk melakukan input order dan mencari data
                keuangan yang dibutuhkan
              </li>
              <li class="sahamTableContent monsterratMedium">
                Fee (biaya) Transaksi kompetitif sesuai kesepakatan
              </li>
              <li class="sahamTableContent monsterratMedium">
                Layanan Edukasi gratis terkait penggunaan sistem, mekanisme
                transaksi, analisa yang diperlukan
              </li>
              <li class="sahamTableContent monsterratMedium ">
                Layanan Customer Centre di nomor 150 350 pada hari Bursa pk
                08.00 - 16.00 WIB dan Galeri di 28 lokasi seluruh Indonesia siap
                membantu memberikan Pelayanan
              </li>
            </ul>
          </div>
          <div class="sahamText rounded-corner mx-2  ">
            <div class="text-center blue-text-1 monsterratExtraBold sahamTextTitle f15r">
              Tenaga Penjualan (Sales Offline)
            </div>
            <ul>
              <li class="sahamTableContent monsterratMedium">
                Tenaga Penjualan siap melayani nasabah pada hari bursa untuk
                melakukan input order atau layanan lain terkait akun nasabah
              </li>
              <li class="sahamTableContent monsterratMedium">
                Fee (biaya) Transaksi kompetitif sesuai kesepakatan
              </li>
              <li class="sahamTableContent monsterratMedium">
                Tenaga Penjualan siap memberikan konsultasi apabila diperlukan
              </li>
              <li class="sahamTableContent monsterratMedium ">
                Layanan Customer Centre di nomor 150 350 pada hari Bursa pk
                08.00 - 16.00 WIB dan Galeri di 28 lokasi seluruh Indonesia siap
                membantu memberikan Pelayanan
              </li>
            </ul>
          </div>
        </div>
      </div> */}
      {/* <FollowSocmed /> */}
    </div>
  );
}
