import { useNavigate, useSearchParams } from "react-router-dom";
import "./style.css";
import { server } from "../../config/server";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import {
  getNews,
  setSortedNews,
  getNewsById,
  setNewsDetail,
} from "../../store/action";
import BlogMenu from "../../components/BlogMenu";
import loading from "../../assets/loading.gif";
import { useParams } from "react-router-dom";
import fbLogo from "../../assets/fb.ico";
import instagramLogo from "../../assets/instagram.ico";
import twitterLogo from "../../assets/twitter.ico";
import linkedinLogo from "../../assets/linkedin.ico";
import tiktokLogo from "../../assets/tiktok.ico";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
export function ConfirmDelete() {
  let { destinationUrl, tokenConfirm } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sortedNews = useSelector((state) => state.sortedNews);
  const news = useSelector((state) => state.news);
  const newsDetail = useSelector((state) => state.newsDetail);
  const [localNewsDetail, setLocalNewsDetail] = useState({});

  const [searchParams, setSearchParams] = useSearchParams();
  // const [tokenParams, setTokenParams] = useSearchParams();
  // const [confirmTokenParams, setConfirmTokenParams] = useSearchParams();

  const destination = searchParams.get("act");
  const token = searchParams.get("key");
  const confirmToken = searchParams.get("confirm");

  const MySwal = withReactContent(Swal);
  // const newsDetail = useSelector((state) => state.newsDetail);

  useEffect(() => {
    window.scrollTo(0, 1);
    let params = queryString.parse(window.location.pathname);
    console.log(destination, token, confirmToken);
    // console.log("aku preman", page);
    const body = document.getElementsByTagName("BODY")[0];
    body.style.marginTop = `0px`;
    // setIsChecked(false);
    switch (destination) {
      case "deleting-acc-kisimobile":
        confirmModal();
        break;

      default:
        resultModal("Invalid Access Link");
        break;
    }

    // document.getElementById("navBar").style.boxShadow =
    // "0px 4px 14px rgb(0 0 0 / 7%)";
    // loadPage();
  }, []);
  function openInNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }

  
  const confirmModal = (msg) => {
    const modal = MySwal.mixin({
      // toast: true,
      position: "center",
      showConfirmButton: true,
      toast: true,
      target: document.getElementById("deleteDiv"),
      html: (
        <>
          <div style={{zIndex:"10000"}}class="f1r monsterratMedium text-center">
            Are you sure to delete your Account ?
          </div>
          <div class="text-center">
            <button
              class="pelajariButton pelajariFaqButton rounded-pill  monsterratBold mx-2 mt-4"
              onClick={(e) => {
                executeDelete();
              }}
            >
              Yes
            </button>
            <button
              class="pelajariButton pelajariFaqButton rounded-pill  monsterratBold mx-2 mt-4"
              onClick={(e) => {
                navigate("/");
                MySwal.close();
              }}
            >
              No
            </button>
          </div>
        </>
      ),
    });
    return modal.fire({
      showClass: {
        backdrop: "swal2-noanimation", // disable backdrop animation
        popup: "animated fadeInDown faster",
        icon: "animated heartBeat delay-1s",
      },
      showClass: {
        backdrop: "swal2-noanimation", // disable backdrop animation
        popup: "animated fadeInDown faster",
        icon: "animated heartBeat delay-1s",
      },
    });
  };
  const resultModal = (msg) => {
    const modal = MySwal.mixin({
      toast: true,
      position: "center",
      showConfirmButton: true,
      toast: true,
      html: (
        <>
          <div class="f1r monsterratMedium text-center">{msg}</div>
          <div class="text-center">
            <button
              class="pelajariButton pelajariFaqButton rounded-pill  monsterratBold mx-auto mt-4"
              onClick={(e) => {
                navigate("/");
                MySwal.close();
              }}
            >
              Ok
            </button>
          </div>
        </>
      ),
    });
    return modal.fire({
      showClass: {
        backdrop: "swal2-noanimation", // disable backdrop animation
        popup: "animated fadeInDown faster",
        icon: "animated heartBeat delay-1s",
      },
      showClass: {
        backdrop: "swal2-noanimation", // disable backdrop animation
        popup: "animated fadeInDown faster",
        icon: "animated heartBeat delay-1s",
      },
    });
  };
  const loadingModal = (msg) => {
    const modal = MySwal.mixin({
      // toast: true,
      position: "center",
      showConfirmButton: true,
      toast: true,
      target: document.getElementById("deleteDiv"),
      html: (
        <>
          <div class="f1r monsterratMedium text-center">
            <img
              style={{ maxHeight: "100px", maxWidth: "100px" }}
              src={loading}
            />
          </div>
        </>
      ),
    });
    return modal.fire({
      showClass: {
        backdrop: "swal2-noanimation", // disable backdrop animation
        popup: "animated fadeInDown faster",
        icon: "animated heartBeat delay-1s",
      },
      showClass: {
        backdrop: "swal2-noanimation", // disable backdrop animation
        popup: "animated fadeInDown faster",
        icon: "animated heartBeat delay-1s",
      },
    });
  };
  const executeDelete = () => {
    loadingModal();
    const body = {
      destination,
      token,
      confirmToken,
    };
    console.log(body, "delete suw");
    fetch(`${server}/delete-acc-kisimobile-confirmation`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      headers: {
        "content-type": "application/json",

        // "Access-Control-Allow-Origin": "http://localhost:3000",
        // "Access-Control-Allow-Credentials": true,
        // "Access-Control-Allow-Origin": "localhost:3000",
        // "Access-Control-Allow-Methods":"POST"
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if(response.status){

          resultModal(response.data.message);
        }else{
         throw response
        }
      })
      .catch((err) => {
        
        resultModal(err.data.message);
        // console.log(err,'eytoy');
      });
  };
  const ArticleContent = () => {
    if (localNewsDetail.id !== undefined) {
      return (
        <div class="newsContentContainer f13r  monsterratRegular grey">
          <pre class="formattedArticleContent monsterratMedium">
            {" "}
            {localNewsDetail.content}
          </pre>
        </div>
      );
    }
  };
  return (
    <>
      <div
        id="deleteDiv"
        style={{
          height: "100vh",
          width: "100vw",
          // position: "absolute",
          zIndex: "9000",
          // backgroundColor: "red",
        }}
      ></div>
    </>
  );
}
