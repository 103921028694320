import "./style.css";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import comingSoon from "../../assets/comingSoon.png";
import SignatureCanvas from "react-signature-canvas";
import kisiLogo from "../../assets/logo.svg";
import Swal from "sweetalert2";
import { kisimobileServer } from "../../config/server"
import withReactContent from "sweetalert2-react-content";
export function Signature() {
  const { token } = useParams();
  let sigPad = {};
  const clear = () => {
    sigPad.clear();
  };
  const MySwal = withReactContent(Swal);
  const [name, setName] = useState("");
  const [spouseName, setSpouseName] = useState("");
  const [pin, setPin] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const checkHandler = (e) => {
    // console.log(e.target.checked);
    setIsChecked(e.target.checked);
  };
  const handleConfirm = (e) => {
    // console.log(sigPad);
    e.preventDefault();
    if (!sigPad.isEmpty()) {
      fetch(`${kisimobileServer}/upload-data-extend-sp`, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
          "Content-Type": "application/json",
          //   token: localStorage.getItem("access_token"),
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          sign: sigPad.toDataURL("image/png"),
          pin: pin,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
  

          if (response.success) {
            resultModal(response.message);
            setPin("");
            sigPad.clear();
          } else {
            resultModal(response.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      resultModal("Please Input Signature");
    }
  };

  const resultModal = (msg) => {
    const modal = MySwal.mixin({
      // toast: true,
      position: "center",
      showConfirmButton: false,
      toast: true,
      html: (
        <>
          <div class="f1r monsterratMedium">{msg}</div>
          <div class="text-center">
            <button
              class="pelajariButton pelajariFaqButton rounded-pill  monsterratBold mx-auto mt-4"
              onClick={(e) => {
                MySwal.close();
              }}
            >
              Ok
            </button>
          </div>
        </>
      ),
    });
    return modal.fire({
      showClass: {
        backdrop: "swal2-noanimation", // disable backdrop animation
        popup: "animated fadeInDown faster",
        icon: "animated heartBeat delay-1s",
      },
      showClass: {
        backdrop: "swal2-noanimation", // disable backdrop animation
        popup: "animated fadeInDown faster",
        icon: "animated heartBeat delay-1s",
      },
    });
  };
  useEffect(() => {
    // console.log(token, "aku preman");
    const body = document.getElementsByTagName("BODY")[0]
    body.style.marginTop = `0px`
    fetch(`${kisimobileServer}/data-extend-sp`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      headers: {
        "Content-Type": "application/json",
        //   token: localStorage.getItem("access_token"),
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response, "respek br");

        setName(response.data.fullname);
        setSpouseName(response.data.spouse_name);
      })
      .catch((err) => {
        console.log(err);
      });
    // console.log("asw");
    // window.scrollTo(0, 0);
  }, [token]);
  const navigate = useNavigate();
  const openInNewTab = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };
  
  return (
    <div>
      <div class="wrapper mheight90" style={{ height: "auto" }}>
        <header
          class="main-header bg-primary center"
          style={{ padding: "5px" }}
        >
          <img
         
            src={kisiLogo}
            style={{maxHeight:'3rem',padding:'0.33rem'}}
          />
        </header>
        <div id="content" class="content">
          <div id="signature-pad" class="box box-primary">
            <div class="box-header bg-info">
              <h5 class="text-header" style={{ marginBottom: "0px" }}>
                <strong>
                  Your spouse ask you for your signature for completeness of
                  margin account opening documents at PT Korea Investment dan
                  Sekuritas Indonesia
                </strong>
              </h5>
            </div>
            <form
              action="https://kisi.co.id/signature-store"
              method="POST"
              onSubmit={(e) => handleConfirm(e)}
            >
              <div class="box-body bg-info">
                <div class="form-group center-vertical">
                  <label class="col-sm-2 control-label ">Name</label>
                  <div class="col-sm-10 input-name mb-10">
                    <input
                      type="text"
                      disabled
                      class="form-control signForm"
                      id="namex"
                      name="namex"
                      value={name}
                    />
                    {/* <input type="text" hidden="" id="id" name="id" value="{token}"/> */}
                    {/* <input type="text" hidden="" id="sign" name="sign"/>
                            <input type="text" hidden="" id="name" name="name" value=""/> */}
                  </div>
                </div>
                <div class="form-group center-vertical">
                  <label class="col-sm-2 control-label">Spouse Name</label>
                  <div class="col-sm-10 input-name_sp mb-10">
                    <input
                      type="text"
                      disabled
                      class="form-control signForm"
                      id="name_spx"
                      name="name_spx"
                      value={spouseName}
                    />
                    {/* <input
                      type="text"
                      hidden=""
                      id="name_sp"
                      name="name_sp"
                      value=""
                    /> */}
                  </div>
                </div>
                <div
                  class="form-group col-sm-12 input-sign text-center"
                  style={{ "margin-bottom": "0px" }}
                >
                  <SignatureCanvas
                    penColor="black"
                    ref={(ref) => {
                      sigPad = ref;
                    }}
                    backgroundColor="white"
                    canvasProps={{
                      // width: "80%",
                      // height: 200,
                      className: "sigCanvas signBox",
                    }}
                  />
                  {/* <canvas style={{"width:100%;height:150px;border:1px solid #dde0e6;margin-top:10px,margin-bottom:0px !important"></canvas> */}
                </div>
                <div class="text-center mb-10" style={{ fontSize: "14px" }}>
                  Sign above
                </div>
                <div class="form-group center-vertical">
                  <label class="col-sm-2 control-label center-vertical">
                    PIN
                  </label>
                  <div class="col-sm-10 input-pin">
                    <input
                      type="text"
                      class="form-control signForm"
                      id="pin"
                      name="pin"
                      value={pin}
                      onChange={(e) => {
                        setPin(e.target.value);
                      }}
                      required
                    />
                  </div>
                </div>
                <div class="form-check input-flexCheckDefault">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    name="flexCheckDefault"
                    checked={isChecked}
                    onChange={(e) => checkHandler(e)}
                    required
                  />
                  <span class="text-desc f085r" for="flexCheckDefault">
                    I hereby declare that I have read, understood, and agree to
                    the content of the statement{" "}
                    <a
                      href="https://kisi.co.id/disclaimer-margin"
                      style={{
                        textDecoration: "none",
                        color: "rgb(60, 141, 188)",
                      }}
                      class="koreaGroupLink"
                      onClick={(e) => {
                        e.preventDefault();
                        openInNewTab("/disclaimer-margin");
                      }}
                    >
                      Read Statement
                    </a>
                  </span>
                </div>
              </div>
              <div class="box-footer center">
                <button
                  type="button"
                  class="btn btn-info btn-sm signBtn mx-1 clearBtn"
                  data-action="clear"
                  onClick={() => {
                    clear();
                  }}
                >
                  Clear
                </button>

                {isChecked ? (
                  <button
                    type="submit"
                    class="btn btn-primary btn-sm signBtn mx-1 saveBtn"
                    // data-action="save"
                    // onClick={(e) => handleConfirm(e)}
                  >
                    Save
                  </button>
                ) : (
                  <button
                    disabled
                    type="button"
                    class="btn btn-primary btn-sm signBtn mx-1 saveBtn"
                    data-action="save"
                  >
                    Save
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
