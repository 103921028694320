import { useNavigate, useSearchParams } from "react-router-dom";
import "./style.css";
import { server } from "../../config/server";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import {
  getNews,
  setSortedNews,
  getNewsById,
  setNewsDetail,
} from "../../store/action";
import BlogMenu from "../../components/BlogMenu";
import loading from "../../assets/loading.gif";
import { useParams } from "react-router-dom";
import fbLogo from "../../assets/fb.ico";
import instagramLogo from "../../assets/instagram.ico";
import twitterLogo from "../../assets/twitter.ico";
import linkedinLogo from "../../assets/linkedin.ico";
import tiktokLogo from "../../assets/tiktok.ico";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
export function KisiMobileDelete() {
  let { destinationUrl, tokenConfirm } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sortedNews = useSelector((state) => state.sortedNews);
  const news = useSelector((state) => state.news);
  const newsDetail = useSelector((state) => state.newsDetail);
  const [localNewsDetail, setLocalNewsDetail] = useState({});

  const [searchParams, setSearchParams] = useSearchParams();
  // const [tokenParams, setTokenParams] = useSearchParams();
  // const [confirmTokenParams, setConfirmTokenParams] = useSearchParams();

  const destination = searchParams.get("act");
  const token = searchParams.get("key");
  const confirmToken = searchParams.get("confirm");

  const MySwal = withReactContent(Swal);
  // const newsDetail = useSelector((state) => state.newsDetail);

  useEffect(() => {
    window.scrollTo(0, 1);
    let params = queryString.parse(window.location.pathname);
    // console.log(destination, token, confirmToken);
    // console.log("aku preman", page);
    // const body = document.getElementsByTagName("BODY")[0];
    // body.style.marginTop = `0px`;
    // setIsChecked(false);
    // switch (destination) {
    //   case "deleting-acc-kisimobile":
    //     confirmModal();
    //     break;

    //   default:
    //     resultModal("Invalid Access Link");
    //     break;
    // }

    // document.getElementById("navBar").style.boxShadow =
    // "0px 4px 14px rgb(0 0 0 / 7%)";
    // loadPage();
  }, []);
  function openInNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }
  // const loadPage = () => {
  //   dispatch(setNewsDetail({}));

  //   if (sortedNews.length === 0) {
  //     console.log("masuk if no news");
  //     dispatch(getNews())
  //       .then((news) => {
  //         var sortedNews = [].concat(news);
  //         sortedNews.sort((a, b) => {
  //           return b.views - a.views;
  //         });
  //         dispatch(setSortedNews(sortedNews));
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }
  //   let isArticleAvailable = false;
  //   for (let i = 0; i < news.length; i++) {
  //     if (news[i].id === Number(articleId)) {
  //       dispatch(setNewsDetail(news[i]));
  //       setLocalNewsDetail(news[i]);
  //     }
  //   }

  //   if (newsDetail.id == undefined) {
  //     dispatch(getNewsById(articleId))
  //       .then((result) => {
  //         if (result.error) {
  //           throw result;
  //         } else {
  //           // setNewsDetail(result); //
  //           dispatch({ type: "SET_NEWS_DETAIL", payload: result });
  //           setLocalNewsDetail(result);
  //         }
  //       })
  //       .catch((error) => {
  //         navigate("/404");
  //       });
  //   }
  // };

  const resultModal = (msg) => {
    const modal = MySwal.mixin({
      // toast: true,
      position: "center",
      // showConfirmButton: true,
      // backdrop: false,
      allowOutsideClick: false,
      html: (
        <>
          <div class="f1r monsterratMedium py-2   text-center">{msg}</div>
          <div class="text-center">
            <button
              class="pelajariButton pelajariFaqButton rounded-pill  monsterratBold mx-auto mt-4"
              onClick={(e) => {
                MySwal.close();
              }}
            >
              Ok
            </button>
          </div>
        </>
      ),
    });
    return modal.fire({
      showClass: {
        backdrop: "swal2-noanimation", // disable backdrop animation
        popup: "animated fadeInDown faster",
        icon: "animated heartBeat delay-1s",
      },
      showClass: {
        backdrop: "swal2-noanimation", // disable backdrop animation
        popup: "animated fadeInDown faster",
        icon: "animated heartBeat delay-1s",
      },
    });
  };
  const loadingModal = (msg) => {
    const modal = MySwal.mixin({
      // toast: true,
      position: "center",
      showConfirmButton: true,
      toast: true,
      target: document.getElementById("deleteDiv"),
      html: (
        <>
          <div class="f1r monsterratMedium text-center">
            <img
              style={{ maxHeight: "100px", maxWidth: "100px" }}
              src={loading}
            />
          </div>
        </>
      ),
    });
    return modal.fire({
      showClass: {
        backdrop: "swal2-noanimation", // disable backdrop animation
        popup: "animated fadeInDown faster",
        icon: "animated heartBeat delay-1s",
      },
      showClass: {
        backdrop: "swal2-noanimation", // disable backdrop animation
        popup: "animated fadeInDown faster",
        icon: "animated heartBeat delay-1s",
      },
    });
  };
  const executeDelete = async (e) => {
    e.preventDefault();
    // loadingModal();
    const email = document.getElementById("delete-email").value;
    const password = document.getElementById("delete-password").value;
    const body = await JSON.stringify({ email: email, password: password });
    // const body= {email,password}
    // console.log(body, "delete suw");
    fetch(`${server}/delete-acc-kisimobile`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      headers: new Headers({ "content-type": "application/json" }),
      body: body,
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        // resultModal(response, "SEKUY");

        if (!response.status) {
          throw response;
        } else {
          resultModal(response.data.message);
          navigate("/");
        }
      })
      .catch((err) => {
        resultModal(err.data.message);
        console.log(err, "eytoy");
      });
  };

  return (
    <>
      <div
        class="pageContainer"
        style={{
          minHeight: "50vh",
          width: "100vw",
          // position: "absolute",
          marginTop: "8rem",
          zIndex: "9000",
          // backgroundColor: "red",
        }}
      >
        <div class="bg-biru-muda sahamTextContainer pb-5 text-center rounded-corner mt-5 pt-5">
          <div class="monsterratExtraBold f2r mb-5  blue-text-1 ">
            Delete KISI Mobile Account Confirmation
          </div>
          <div class="monsterratMedium grey mt-3 f12r  mw90 mx-auto">
            <form
              onSubmit={(e) => {
                executeDelete(e);
              }}
            >
              <div class=" deleteAccForm mb-3">
                <label for="exampleInputEmail1" class="center-vertical">
                  Email address
                </label>
                <input
                  required
                  type="email"
                  class="form-control"
                  id="delete-email"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                />
                {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
              </div>
              <div class=" deleteAccForm mb-3">
                <label for="exampleInputPassword1" class="center-vertical">
                  Password
                </label>
                <input
                  required
                  type="password"
                  class="form-control"
                  id="delete-password"
                  placeholder="Password"
                />
              </div>
              {/* <div class="form-check ">
    <input type="checkbox" class="form-check-input " id="exampleCheck1"/>
    <label class="form-check-label" for="exampleCheck1">Check me out</label>
  </div> */}
              <button
                class="pelajariButton pelajariFaqButton rounded-pill  monsterratBold mx-auto mt-4"
                type="submit"
              >
                Ok
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
