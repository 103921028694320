import logo from "../../logo.svg";
import "./style.css";
import PageLocation from "../../components/PageLocation";
import waran1 from "../../assets/waran1.png";
import waran2 from "../../assets/waran2.png";
import waran3 from "../../assets/waran3.png";
import waran4 from "../../assets/waran4.png";
import syariahSertifikatMui from "../../assets/syariahSertifikatMui.pdf";
import manual_order_e_ipo from "../../assets/manual_order_e_ipo.pdf";
import manualPendaftaranEIpo from "../../assets/manualPendaftaranEIpo.pdf";
import koins from "../../assets/koins.png";
import kisiMobile from "../../assets/kisiMobile.png";
import letter from "../../assets/letter.png";
import mail from "../../assets/email.png";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { setActiveMenu } from "../../store/action";
import { useDispatch, useSelector } from "react-redux";
export function Layanan() {
  const { page } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    // console.log(page.substring(1, page.length), page);
    // document.getElementById(page).className +=
    //   " subPathActive";
    dispatch(setActiveMenu("Produk"));
    switch (page) {
      case "e_ipo":
        document.getElementById("e_ipo").className += " subPathActive";
        document.getElementById("waran_terstruktur").className =
          "subPath monsterratMedium rounded-pill mx-3";
        break;

      case "waran_terstruktur":
        // console.log("swituseG");
        document.getElementById("waran_terstruktur").className +=
          " subPathActive";
        document.getElementById("e_ipo").className =
          "subPath monsterratMedium rounded-pill mx-3";
        break;

      default:
        break;
    }
  }, [page]);
  const navigate = useNavigate();
  const openInNewTab = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };
  return (
    <>
      <div class="subPathWrapper  pb-5">
        <div class="subPathContainer appPageLocation">
          <div
            className="subPath monsterratMedium rounded-pill mx-3"
            id="e_ipo"
            onClick={() => navigate("/layanan/e_ipo")}
          >
            E-IPO
          </div>
          <div
            className="subPath monsterratMedium rounded-pill mx-3"
            id="waran_terstruktur"
            onClick={() => navigate("/layanan/waran_terstruktur")}
          >
            WARAN TERSTRUKTUR
          </div>
        </div>
      </div>
      {page === "e_ipo" ? (
        <div
          class="disclaimerPageContainer py-4"
          style={{ marginBottom: "6rem" }}
        >
          <div class="disclaimerContainer rounded-corner bg-biru-muda px-5 py-5 mx-auto mt-5  grey monsterratMedium f1r">
            <div class="f25r blue-text-1 monsterratExtraBold center768 pb-1">
              Prosedur Pesanan Saham E-IPO
            </div>
            <div class="f15r monsterratMedium blue-text-1">
              Prosedur Pesanan Saham Melalui e-ipo.co.id:
            </div>
            <br></br>
            <div class="eIpoText">
              <ol>
                <li>
                  Nasabah masuk ke link{" "}
                  <a
                    class="blue-text-1"
                    onClick={(e) => openInNewTab(e, "https://www.e-ipo.co.id")}
                    href="https://www.e-ipo.co.id"
                  >
                    https://www.e-ipo.co.id{" "}
                  </a>{" "}
                  dari IDX, Pilih PT Korea Investment And Sekuritas Indonesia
                  (selanjutnya disebut “KISI”) untuk pendaftaran akun e-ipo
                  saham. Selanjutnya data Nasabah akan diverifikasi oleh <i>Team</i>{' '}
                  CRO KISI untuk mendapat persetujuan.
                </li>
                <br></br>
                <li>
                  IDX akan memberikan informasi bilamana ada penawaran IPO ke
                  sistem e-ipo.co.id.
                </li>
                <br></br>
                <li>
                  Pada masa Penawaran Awal (<i>Bookbuilding</i> ) : Apabila
                  harga pesanan dibawah Harga Pembentukan, maka pesanan Nasabah
                  akan dibatalkan (<i>dropped</i>) secara <i>system</i> e-ipo.co.id,
                  namun bila harga pesanan Nasabah lebih tinggi, maka pesanan
                  Nasabah diteruskan dengan Harga Pembentukan (Harga IPO).
                  <br></br> KISI berhak tidak menyampaikan keikutsertaan
                  Nasabah dalam <i>Bookbuilding&nbsp;</i>
                  bilamana pesanan Nasabah tidak didukung dengan Dana dan/atau
                  Efek yang mencukupi, senilai pesanan di Rekening Efek Nasabah.
                </li>
                <br></br>
                <li>
                  Nasabah menyampaikan <b>pesanan IPO</b> melalui e-ipo.co.id{" "}
                  <b>
                    paling lambat pada pukul 11. 30 WIB di Hari Terakhir
                    Penawaran Umum ("H") atau mengikuti jam penawaran sesuai
                    <i>prospectus</i> bila ditentukan kurang dari jam 11.30
                  </b>
                  .<br></br> Perlu diperhatikan juga Nasabah WAJIB “
                  <i>confirm</i> telah membaca <i>prospectus</i>”, bila tidak,
                  maka pesanan akan dibatalkan (<i>dropped</i>) secara otomatis
                  oleh sistem e-ipo.co.id.<br></br> KISI sarankan, Nasabah
                  secara mandiri dan berkala memeriksa status Nasabah di
                  e-ipo.co.id.
                </li>
                <br></br>
                <li>
                  KISI akan proses pesanan Nasabah mulai H-2 dari Hari Terakhir
                  Penarawan Umum dengan mendebit dana Nasabah di RDN sebesar
                  pesanan IPO, tanpa harus konfirmasi lagi ke nasabah.{" "}
                  <b>
                    Mengingat dana di Rekening Efek Nasabah (selanjutnya disebut
                    "RDN") juga digunakan untuk penyelesaian transaksi saham di
                    pasar sekunder, maka Nasabah wajib menyediakan dana untuk
                    penyelesaian transaksi pasar sekunder dan pesanan IPO
                  </b>
                  .
                </li>
                <br></br>
                <li>
                  Bilamana Nasabah bermaksud BATAL, maka Nasabah harus
                  mengirimkan instruksi BATAL ke :{" "}
                  <a class="blue-text-1" href="mailto: cs@kisi.co.id">
                    cs@kisi.co.id
                  </a>
                  . Nasabah tidak dapat beranggapan bahwa dengan tidak melakukan
                  konfirmasi telah membaca final prospektus (
                  <i>Unconfirmed Final Prospectus</i>), akan batal dengan
                  sendirinya.<br></br> KISI mendapatkan konfirmasi{" "}
                  <i>Unconfirmed Final Prospectus</i> pada Hari Terakhir
                  Penawaran Umum pada pukul 16:00 WIB. Nasabah akan mengalami
                  risiko: Dananya ditarik untuk e-IPO, <i>Limit trading</i> Nasabah di
                  pasar sekunder berkurang, Risiko tidak dapat menyelesaikan
                  transaksi di pasar sekunder (<i>SUSPEND BUY</i>,{" "}
                  <i>FORCE SELL</i> dan Denda Keterlambatan) dan Pengembalian
                  dananya pada tanggal <i>Refund</i> IPO.
                </li>
                <br></br>
                <li>
                  <b>
                    Dana untuk pesanan IPO wajib tersedia dalam RDN paling
                    lambat pada pukul 11.30 WIB di Hari Terakhir Penawaran Umum
                    ("H")
                  </b>{" "}
                  , jika dana tidak tersedia maka pesanan IPO dianggap batal.
                  <br></br> KISI tidak wajib menghubungi Nasabah untuk
                  mengonfirmasi ketersediaan dana dalam RDN dan KISI tidak
                  mengirimkan email <i>Reminder</i> untuk ketersediaan dana
                  pemesanan IPO pada Hari Terakhir Penawaran Umum.
                </li>
                <br></br>
                <li>
                  Instruksi perubahan jumlah pesanan dan/atau pembatalan pesanan
                  diinformasikan ke{" "}
                  <a class="blue-text-1" href="mailto: cs@kisi.co.id">
                    cs@kisi.co.id
                  </a>{" "}
                  paling lambat jam 09.30 WIB untuk IPO berakhir jam 10.00 atau
                  jam 11.00 WIB untuk IPO yang berakhir lebih dari jam 10.00
                  WIB.
                </li>
                <br></br>
              </ol>
              {/* <ol>
                <li class="mt-3">Nasabah wajib klik <i><b>"Investor has already read the prospectus"</b></i></li>
                <li class="mt-3">Jika nasabah akan melakukan pembatalan pesanan, Instruksi pembatalan dikirimkan ke email: <a href = "mailto: cs@kisi.co.id">cs@kisi.co.id</a></li>
                <li class="mt-3">Nasabah aktif melakukan pengecekan pada status pesanan. Jika belum <i>approved</i>, info ke email: <a href = "mailto: cs@kisi.co.id">cs@kisi.co.id</a></li>
                <li class="mt-3">Pesanan Saham e-IPO dan ketersediaan Dana pembelian Saham e-IPO berakhir dan harus diterima pada pukul 11:30 WIB. Jika melewati pukul tersebut dianggap batal.</li>
              </ol> */}
            </div>
          </div>
          {/* <div class="syariahButtonWrapper my-5 pt-5">
            <div class="f25r  mb-4 blue-text-1 monsterratExtraBold text-center">
            Manual Book
            </div>
            <div class="monsterratMedium grey block1000 f1r flex text-center pt-1">
              <div class="flex mx-auto block1000">
         
                <div>
                <button
                    class="syariahPdfButton appendixPdfButton grey f1r px-4 mx-2"
                    onClick={() => openInNewTab(manualPendaftaranEIpo)}
                  >
                    Manual Book Pendaftaran e-IPO
                  </button>
                  <button
                    class="syariahPdfButton appendixPdfButton grey f1r px-4 mx-2"
                    onClick={() => openInNewTab(manual_order_e_ipo)}
                  >
                    Manual Book Order e-IPO
                  </button>
           
                </div>
              </div>
            </div>
          </div> */}
        </div>
      ) : null}
      {page === "waran_terstruktur" ? (
        <div class="disclaimerPageContainer py-4 mb-5">
          <div class="disclaimerContainer rounded-corner bg-biru-muda px-5 py-5 mx-auto mt-5  grey monsterratMedium f1r">
            <div class="f25r blue-text-1 monsterratExtraBold  center768 pb-1">
              WARAN TERSTRUKTUR
            </div>

            <br></br>
            <div class="eIpoText">
              <p>
                Kami menginformasikan bahwa nasabah PT Korea Investment And
                Sekuritas Indonesia dapat melakukan transaksi Waran Terstruktur
                melalui aplikasi KOINS.
              </p>
              <p>
                Cara bertranksaksi Waran Terstruktur di KOINS?
                <ol>
                  <li><i>Log in</i> di Aplikasi KOINS</li>
                  <li> <i>Home</i> KOINS -> <i>Order</i> </li>
                  <li>
                    Masukkan kode Waran Terstruktur misal ADRODRCM3A,
                    BBRIDRCM3A,UNVRDRCM3A, jumlah yang ingin dijual-beli dan
                    klik <i>submit</i>.
                  </li>
                </ol>
              </p>
              <p>
                Itulah cara bertransaksi Waran Terstruktur pada aplikasi KOINS.
                Pertimbangkan harga saham yang menjadi <i>underlying</i> Waran
                Terstruktur saat beli dan/atau jual, harga tebus serta harga
                Waran Terstruktur itu sendiri.
              </p>
              <p>
                Waran Terstruktur adalah Efek yang diterbitkan oleh Perusahaan
                Efek yang memberikan hak kepada Investor Pasar Modal untuk
                membeli dan/atau menjual <i>Underlying</i> <i>Securities</i>{" "}
                pada harga dan waktu yang sudah ditentukan.
              </p>
              <p>
                <i>Underlying</i> <i>Securities</i> adalah Efek yang menjadi
                dasar transaksi waran terstruktur, dalam hal ini adalah saham
                konstituen indeks IDX30 yang ditetapkan oleh Bursa Efek
                Indonesia.
              </p>
              <div class="tabelWaran">
                Spesifikasi Waran Terstruktur :
                <table class="table table-bordered grey monsterratMedium">
                  <thead>
                    <tr>
                      <th scope="col">No.</th>
                      <th scope="col">
               
                          Kriteria
               
                      </th>
                      <th scope="col">Keterangan</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td scope="row">1</td>
                      <td>Kode Efek</td>
                      <td>
                        <p>
                          [<i>Underlying</i>][2 huruf kode Perusahaan
                          Penerbit][Jenis Waran Terstruktur = C/P][Bulan jatuh
                          tempo][Tahun jatuh tempo][Kode unik]
                        </p>
                        <p>
                          Contoh: ABCDXXCF2A <i><i>Call Warrant</i></i> ABCD diterbitkan oleh
                          XX sekuritas dengan jatuh tempo pada tanggal 28
                          Januari 2022
                        </p>
                        <div>
                          Kode Bulan Jatuh Tempo Waran Terstruktur
                          <table class="table table-bordered grey monsterratMedium">
                            <thead>
                              <tr>
                                <th scope="col">Month</th>
                                <th scope="col">Month Code</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td scope="row">Januari</td>
                                <td>F</td>
                              </tr>
                              <tr>
                                <td scope="row">Februari</td>
                                <td>G</td>
                              </tr>
                              <tr>
                                <td scope="row">Maret</td>
                                <td>H</td>
                              </tr>
                              <tr>
                                <td scope="row">April</td>
                                <td>J</td>
                              </tr>
                              <tr>
                                <td scope="row">Mei</td>
                                <td>K</td>
                              </tr>
                              <tr>
                                <td scope="row">Juni</td>
                                <td>M</td>
                              </tr>
                              <tr>
                                <td scope="row">Juli</td>
                                <td>N</td>
                              </tr>
                              <tr>
                                <td scope="row">Agustus</td>
                                <td>Q</td>
                              </tr>
                              <tr>
                                <td scope="row">September</td>
                                <td>U</td>
                              </tr>
                              <tr>
                                <td scope="row">Oktober</td>
                                <td>V</td>
                              </tr>
                              <tr>
                                <td scope="row">November</td>
                                <td>X</td>
                              </tr>
                              <tr>
                                <td scope="row">Desember</td>
                                <td>Z</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">2</td>
                      <td>
                        <i>
                          <i>Underlying</i>
                        </i>
                      </td>
                      <td>Saham konstituen Indeks Efek IDX30</td>
                    </tr>
                    <tr>
                      <td scope="row">3</td>
                      <td>Fraksi Harga</td>
                      <td>
                        Mengikuti fraksi harga Efek bersifat Ekuitas (Peraturan
                        Bursa Nomor II-A tentang perdagangan Efek bersifat
                        Ekuitas)
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">4</td>
                      <td><i>Exercise</i></td>
                      <td>
                        <i>Exercise</i> Waran Terstruktur dilakukan secara otomatis
                        ketika <i>in-the-money</i> pada tanggal jatuh tempo
                        secara tunai (<i>European style-cash settlement</i>)
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">5</td>
                      <td>Perhitungan harga penyelesaian</td>
                      <td>
                        Rata-rata harga penutupan{" "}
                        <i>
                          <i>underlying</i>
                        </i>{" "}
                        selama 5 hari sebelum jatuh tempo Waran Terstruktur
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">6</td>
                      <td>Periode penyelesaian </td>
                      <td>
                        Perdagangan di Pasar Sekunder: T+2<br></br>Perdagangan
                        hak (<i><i>exercise</i></i>): T+3
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">7</td>
                      <td>Jatuh Tempo Waran Terstruktur</td>
                      <td>2 Bulan sampai 2 Tahun</td>
                    </tr>
                    <tr>
                      <td scope="row">8</td>
                      <td>Waktu Perdagangan</td>
                      <td>
                        Mengikuti jam perdagangan Efek bersifat Ekuitas
                        (Peraturan Bursa Nomor II-A tentang perdagangan Efek
                        bersifat Ekuitas)
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">9</td>
                      <td>Batasan Perubahan Harga</td>
                      <td>
                        Tidak terdapat batasan perubahan harga maksimum untuk
                        Waran Terstruktur
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">10</td>
                      <td><i>Auto Rejection</i></td>
                      <td>
                        Sama atau melebihi harga perdagangan terakhir{" "}
                        <i>
                          <i>underlying</i>{' '}<i>securities</i>
                        </i>
                        -nya
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">11</td>
                      <td>Hari Perdagangan Terakhir</td>
                      <td>
                        3 (tiga) hari bursa sebelum tanggal jatuh tempo, untuk
                        perdagangan Waran Terstruktur di Pasar Reguler dan Pasar
                        Negosiasi;<br></br>1 (satu) hari bursa sebelum tanggal
                        jatuh tempo , untuk perdagangan Waran Terstruktur di
                        Pasar Tunai.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p>
                Tipe Waran Terstruktur
                <ol>
                  <li>
                    <i><i>Call Warrant</i></i><br></br>
                    Investor memiliki Hak untuk Membeli <i>underlying</i>{" "}
                    <i>securities</i> (Saham) pada harga dan jangka waktu
                    tertentu.
                  </li>
                  <li>
                    <i><i>Put Warrant</i></i><br></br>
                    Investor memiliki Hak untuk Menjual <i>underlying</i>{" "}
                    <i>securities</i> (Saham) pada harga dan jangka waktu
                    tertentu.
                  </li>
                </ol>
              </p>
              <p>
                Manfaat Waran Terstruktur
                <ol type="a">
                  <li>
                    <i>Leverage</i><br></br>
                    Dengan modal relatif kecil, dapat memiliki hak untuk membeli
                    (Call) atau menjual (Put) <i>underlying</i>{" "}
                    <i>securities</i> pada harga dan tanggal tertentu, sehingga
                    persentase potensi keuntungan Waran Terstruktur cenderung
                    lebih besar dari pada <i>underlying</i>{" "}
                    <i>securities</i>-nya, begitupun sebaliknya.
                  </li>
                  <li>
                    Potensi Keuntungan dan Kerugian<br></br>
                    Potensi keuntungan yang akan diperoleh investor tidak
                    terbatas, tergantung dari pergerakan harga <i>underlying</i>{" "}
                    <i>securities</i>. Sedangkan maksimum potensi kerugian yang
                    dialami hanya sebatas harga Waran Terstruktur.
                  </li>
                  <li>
                    Sarana Lindung Nilai Investasi<br></br> <i>Put Warrant</i> dapat
                    digunakan sebagai sarana lindung nilai dengan mengunci harga
                    jual <i>underlying</i>{" "}<i>securities</i>. Ketika harga{" "}
                    <i>underlying</i> turun, Investor dapat mendapatkan
                    keuntungan dari perdagangan dan/atau <i>exercise</i> <i>Put Warrant</i>.
                  </li>
                  <li>
                    {" "}
                    Likuiditas Tinggi<br></br> Waran Terstruktur biasanya
                    mempunyai likuiditas yang tinggi karena Perusahaan Penerbit/Perusahaan Efek wajib menjadi <i>Liquidity Provider</i> dan dapat
                    menunjuk Anggota Bursa lain sebagai <i>Liquidity Provider</i> (jika
                    ada).
                  </li>
                </ol>
              </p>
              <div class="tabelWaran">
                <span>Perbedaan Saham dan Waran Terstruktur</span>
                <table class="table table-bordered grey monsterratMedium">
                  <thead>
                    <tr>
                      <th scope="col" rowspan="2">
                        No.
                      </th>
                      <th scope="col" rowspan="2">
                        Faktor Pembanding
                      </th>
                      <th scope="col" colspan="2">
                        Perbedaan
                      </th>
                    </tr>
                    <tr>
                      <th scope="col">Saham</th>
                      <th scope="col" class="bg-green">
                        Waran Terstruktur
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td scope="row">1</td>
                      <td>Hak Kepemilikan</td>
                      <td>
                        Jika investor membeli saham, maka investor menjadi salah
                        satu. Investor juga berhak mengikuti Rapat Umum Pemegang
                        Saham{" "}
                      </td>
                      <td>
                        Waran berbeda dengan saham karena waran ini merupakan
                        produk derivatif saham. Jadi Investor yang memiliki
                        waran, tidak mendapatkan dividen dan tidak bisa
                        mengikuti RUPS.
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">2</td>
                      <td>Harga</td>
                      <td>
                        Harga saham di BEI akan mengikuti mekanisme pasar dengan
                        ketentuan batas atas atau <i>Auto Rejection</i> Atas
                        (ARA) dan batas tertentu.
                      </td>
                      <td>
                        tidak berlaku pada harga waran terstruktur. Harga waran
                        terstruktur dapat naik dan turun tanpa adanya batas
                        perubahan harga maksimum untuk waran terstruktur. Namun{" "}
                        <i>Auto Rejection</i> waran terstruktur terbatas hingga
                        harga waran yang diperdagangkan lebih besar atau sama
                        dengan harga saham aset dasarnya.
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">3</td>
                      <td>Jatuh Tempo</td>
                      <td>
                        Saham tidak memiliki aturan jatuh tempo atau masa
                        berlaku. Jadi Investor bebas untuk memiliki saham
                        tersebut tanpa jangka waktu.
                      </td>
                      <td>
                        Sedangkan untuk waran terstruktur ada jatuh temponya.
                        Periode jatuh tempo untuk jenis waran terstruktur ini
                        bisa berbeda-beda antara 2 hingga 24 bulan. Pada saat
                        jatuh tempo, jika waran terstruktur mempunyai keuntungan
                        (<i>in the money</i>), maka KSEI akan mentransfer dana
                        tunai secara otomatis ke rekening investor sebesar
                        keuntungan
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <p>
                  Risiko Waran Terstruktur
                  <ol>
                    <li>
                      Risiko Pasar<br></br>
                      Harga pasar waran terstruktur dipengaruhi salah satunya
                      oleh pergerakan harga aset dasarnya. Waran terstruktur
                      juga memiliki risiko premium dimana harga pada pasar
                      sekunder mungkin diperdagangkan pada diskon (atau premium,
                      sebagaimana berlaku) dibandingkan estimasi nilai wajarnya.
                    </li>
                    <li>
                      Risiko Kredit dan Penerbit<br></br>
                      Hal ini timbul dari kegagalan penerbit Waran Terstruktur
                      dalam memenuhi kewajiban finansial maupun kontrak yang
                      sudah disepakati sebelumnya. Risiko lain yang perlu
                      diperhatikan terkait penerbit adalah adanya risiko hukum,
                      peraturan, kepatuhan, operasional, strategis dan reputasi
                      penerbit waran terstruktur.
                    </li>
                    <li>
                      <i>Delisting</i>
                      <br></br>
                      Tidak ada jaminan adanya pasar aktif untuk waran
                      terstruktur setelah pencatatannya di BEI. Apabila saham
                      yang menjadi aset dasar waran terstruktur dihentikan
                      sementara (suspensi) oleh BEI, maka perdagangan waran
                      terstruktur juga dapat dihentikan sementara. Apabila
                      terjadi penghapusan pencatatan emiten (<i>delisting</i>) dari
                      bursa sebelum Waran Terstruktur berakhir, maka Waran
                      Terstruktur tersebut akan kehilangan nilai pada waktu yang
                      masih tersisa.
                    </li>
                  </ol>
                </p>
                <p>
                  Faktor-faktor yang dapat mempengaruhi harga waran terstruktur:
                  <ol>
                    <li>
                      HARGA <i>UNDERLYING</i>
                      <br></br>
                      Naiknya harga <i>underlying</i> <i>securities</i> akan
                      menyebabkan harga <i>Call Warrant</i> naik karena kemungkinan
                      waran tersebut jatuh tempo dalam kondisi <i>In The Money</i> akan
                      meningkat, sementara harga <i>Put Warrant</i> akan turun karena
                      kemungkinan waran tersebut jatuh tempo dalam kondisi <i>In
                      The Money</i> akan semakin kecil. Sebaliknya, ketika harga{" "}
                      <i>underlying</i>{" "}
                      <i>securities</i> turun, maka harga <i>Call Warrant</i> akan
                      turun dan harga <i>Put Warrant</i> akan naik.
                    </li>

                    <li>
                      <i>EXERCISE PRICE</i>
                      <br></br>
                      <i>Call Warrant</i> dengan harga <i>exercise</i> yang tinggi memiliki
                      kemungkinan yang lebih kecil untuk jatuh tempo dalam
                      kondisi <i>In The Money</i>, begitupun sebaliknya. Oleh karena
                      itu, harga <i>Call Warrant</i> dengan <i>underlying</i>{" "}
                      <i>securities</i> dan tanggal jatuh tempo yang sama namun
                      dengan harga <i>exercise</i> yang lebih tinggi akan lebih murah
                      dibandingkan dengan <i>Call Warrant</i> dengan harga <i>exercise</i>{' '}
                      yang lebih rendah. Berbanding terbalik dengan <i>Call
                      Warrant</i>, harga <i>Put Warrant</i> dengan <i>underlying</i>{" "}
                      <i>securities</i> dan tanggal jatuh tempo yang sama namun
                      dengan harga <i>exercise</i> yang lebih tinggi akan lebih mahal
                      dibandingkan dengan <i>Put Warrant</i> dengan harga <i>exercise</i> yang
                      lebih rendah.
                    </li>

                    <li>
                      VOLATILITAS <i>UNDERLYING</i>
                      <br></br>
                      Volatilitas <i>underlying</i> adalah salah satu faktor
                      penentu harga Waran Terstruktur. Volatilitas historis
                      mencerminkan fluktuasi harga dari harga <i>underlying</i>{" "}
                      dalam jangka waktu tertentu di masa lalu. <i>Implied
                      volatility</i> adalah ekspektasi terhadap fluktuasi harga{" "}
                      <i>underlying</i> atas masa berlaku Waran Terstruktur.
                      Semakin tinggi <i>implied volatility</i> aset <i>underlying</i>,
                      maka semakin tinggi risiko fluktuasi harga{" "}
                      <i>underlying</i> sehingga, semakin besar kemungkinan
                      Waran Terstruktur tersebut jatuh tempo dalam kondisi <i>In
                      The Money</i> dan investor dapat mendapatkan laba yang lebih
                      tinggi. Harga Waran Terstruktur dengan <i>implied volatility</i>{' '}
                      yang tinggi akan semakin mahal, baik untuk <i>Call Warrant</i>{' '}
                      maupun <i>Put Warrant</i>. Kondisi sebaliknya juga berlaku
                      apabila ekspektasi pasar atas fluktuasi harga
                      <i>underlying</i> cenderung stabil.
                    </li>
                    <li>
                      <i>LIFESPAN</i> WARAN TERSTRUKTUR<br></br> Apabila
                      lifespan atau masa berlaku Waran Terstruktur semakin lama,
                      maka harga Waran Terstruktur akan semakin tinggi. Hal
                      tersebut disebabkan kemungkinan harga <i>underlying</i>{" "}
                      akan bergerak menuju harga yang diharapkan investor Waran
                      Terstruktur pada saat jatuh tempo semakin tinggi.
                    </li>
                    <li>
                 <i>     DIVIDEND YIELD UNDERLYING</i>
                      <br></br>
                      Meskipun <i>dividend yield</i> <i>underlying</i> tidak
                      berpengaruh secara langsung terhadap harga Waran
                      Terstruktur, <i>dividend yield</i> <i>underlying</i> sangat erat
                      kaitannya dengan harga{' '}
                      <i>underlying</i>-nya. Semakin tinggi <i>dividend yield</i>{" "}
                      <i>underlying</i>, maka akan semakin rendah harga{" "}
                      <i>underlying</i> tersebut pada periode ex-div. Perubahan
                      harga <i>underlying</i> akan berdampak ke turunnya harga
                      Waran Terstruktur. Namun dalam praktiknya, <i>dividend yield</i>{" "}
                      <i>underlying</i> kemungkinan telah dimasukkan ke dalam
                      harga Waran Terstruktur oleh Perusahaan Penerbit pada saat
                      penerbitan. Oleh karena itu, <i>dividend yield</i>{" "}
                      <i>underlying</i> cenderung memiliki dampak terbatas pada
                      harga Waran Terstruktur pada tanggal ex-dividen.
                    </li>
                    <li>
                      SUKU BUNGA <br></br>Suku bunga mempengaruhi harga Waran
                      Terstruktur melalui <i>holding cost</i> pembelian{" "}
                      <i>underlying</i>{' '}
                      untuk melindungi nilai Waran Terstruktur yang dijual.
                      Kenaikan tingkat suku bunga akan menyebabkan kenaikan
                      harga <i>underlying</i> <i>securities</i>-nya, sehingga
                      harga <i>Call Warrant</i> akan naik. Begitupun sebaliknya.
                    </li>
                  </ol>
                </p>
                <p>
                  Pembelian Waran Terstruktur
                  <ol>
                    <li>
                      {" "}
                      Pasar Perdana<br></br> Pembelian Waran Terstruktur di
                      Pasar Perdana dapat dilakukan melalui Penerbit dan/atau
                      <i>Selling Agent</i> pada periode Penawaran Umum.{" "}
                    </li>
                    <li>
                      {" "}
                      Pasar Sekunder<br></br> Pembelian Waran Terstruktur di
                      Pasar Sekunder dapat dilakukan melalui Seluruh Anggota
                      Bursa di Bursa Efek Indonesia. Tidak diperlukan pembukaan
                      Rekening baru dalam transaksi produk Waran Terstruktur.
                    </li>
                  </ol>
                </p>
              </div>
              <div>
                <p>
                  <b>Pelaksanaan Hak Saat Jatuh Tempo Waran Terstruktur</b>
                </p>
                <img class="waranImg" src={waran1}></img>
                <p>
                  <b>Simulasi Perdagangan Waran Terstruktur</b>
                </p>
                <img class="waranImg" src={waran2}></img>
                <p>
                  <b>Simulasi Jatuh Tempo</b>
                </p>
                <img class="waranImg" src={waran3}></img>
                <img class="waranImg" src={waran4}></img>
                <br></br>
                <br></br>
                <p>
                  Disclaimer<br></br>
                  Segala materi yang disediakan di sini hanya terbatas untuk
                  pemberian informasi dan bukan merupakan penawaran, permintaan
                  penawaran, saran, atau rekomendasi untuk melaksanakan suatu
                  transaksi. Segala hal yang berkaitan dengan diterimanya
                  dan/atau digunakannya informasi dalam artikel ini merupakan
                  tanggung jawab pribadi atas segala risiko yang mungkin timbul.
                  Investor wajib setiap saat untuk membaca dan memahami produk
                  untuk membuat penilaian risiko investor sendiri serta
                  mendapatkan konsultasi dari pihak profesional (jika
                  diperlukan) sebelum berinvestasi.
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
