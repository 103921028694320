import "./style.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPromos, getNews, getSliderImage } from "../../store/action";
import PageLocation from "../../components/PageLocation";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import profileImg from "../../assets/profile.png";
import location from "../../assets/location.png";
import phone from "../../assets/phone.png";

import lokasiGlobal from "../../assets/lokasiGlobal.png";
import atlas from "../../assets/atlas.png";
import { setLokasi } from "../../store/action";
export function Lokasi(props) {
  let { page } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lokasi = useSelector((state) => state.lokasi);
  const [current, setCurrent] = useState("indonesia");
  useEffect(() => {
    // window.scrollTo(0, 0);
    // console.log("use");
    switch (lokasi) {
      case "indonesia":
        document.getElementById("buttonLokasiIndonesia").className +=
          " buttonProfileActive";
        document.getElementById("buttonLokasiGlobal").className =
          "profileButton monsterratMedium rounded-pill grey ";
        break;

      case "global":
        // console.log("swituseG");
        document.getElementById("buttonLokasiGlobal").className +=
          " buttonProfileActive";
        document.getElementById("buttonLokasiIndonesia").className =
          "profileButton monsterratMedium rounded-pill grey ";
        break;

      default:
        break;
    }

    // console.log(page);
    // console.log([{ nama: "gugun" }, { nama: "kodir" }]);
    // const alamat = document.getElementsByClassName("branchAddress");
    // console.log(alamat[0].innerHTML);
    // let temp = [];
    // for (let i = 0; i < alamat.length; i++) {
    //   const element = alamat[i];
    //   // document.getElementsByClassName("branchCity")[i]
    //   let obj = {
    //     kota: document.getElementsByClassName("branchCity")[i].innerHTML,
    //     alamat: element.innerHTML,
    //   };
    //   temp.push(obj);
    // }
    // console.log(temp);
  }, [lokasi]);
  const changePage = (route) => {
    // window.scrollTo(0, 0);
    // props.lokasiDetail=route
    console.log(route);
    // setCurrent(route)
    dispatch(setLokasi(route));
    // navigate("/tentangkami/lokasi");
    // return <Navigate to="/tentangkami/
  };
  const LokasiBlueImage = () => {
    return (
      <>
        {" "}
        <div class="tentangKamiImageContainer">
          <img class="tentangKamiImage" src={profileImg} />
          <svg xmlns="http://www.w3.org/2000/svg">
            <filter id="svgTint">
              <feColorMatrix
                type="matrix"
                values="0.01568627450980392 0.01568627450980392 0.01568627450980392  0 0 0.07450980392156863 0.07450980392156863 0.07450980392156863  0 0 0.24705882352941178 0.24705882352941178 0.24705882352941178  0 0  0 0 0 1 0"
              />
            </filter>
          </svg>
        </div>
        {page == "indonesia" ? (
          <div class="imgText">
            <div class="imgTextBig ">
              Jaringan<br></br>Indonesia
            </div>

            <div class="f1r monsterratMedium">
              Korea Investment And Sekuritas Indonesia
            </div>
            <div class="mt-4">
              <button class="squareWhiteButton monsterratMedium">
                More Details
              </button>
            </div>
          </div>
        ) : (
          <div class="imgText">
            <div class="imgTextBig f35r">
              Global<br></br>Network
            </div>
            <div class="f1r monsterratMedium">
              Korea Investment And Sekuritas Indonesia
            </div>
            <div class="mt-4">
              <button class="squareWhiteButton monsterratMedium">
                More Details
              </button>
            </div>
          </div>
        )}
        {/* <div class="imgText">
          <div class="imgTextBig f35r">
            Jaringan<br></br>Indonesia
          </div>
          <div class="f1r monsterratMedium">
            Korea Investment And Sekuritas Indonesia
          </div>
          <div class="mt-4">
            <button class="squareWhiteButton monsterratMedium">
              More Details
            </button>
          </div>
        </div> */}
      </>
    );
  };

  // const ProfileImage = () => {
  //   return (
  //     <>
  //       {" "}
  //       <div class="tentangKamiImageContainer">
  //         <img class="tentangKamiImage" src={profileImg2} />
  //         {/* <svg xmlns="http://www.w3.org/2000/svg">
  //       <filter id="svgTint">
  //         <feColorMatrix
  //           type="matrix"
  //           values="0.01568627450980392 0.01568627450980392 0.01568627450980392  0 0 0.07450980392156863 0.07450980392156863 0.07450980392156863  0 0 0.24705882352941178 0.24705882352941178 0.24705882352941178  0 0  0 0 0 1 0"
  //         />
  //       </filter>
  //     </svg> */}
  //       </div>
  //     </>
  //   );
  // };
  const ProfileButtons = () => {
    return (
      <div class="profileButtonContainer">
        <div class="profileButtonWrapper">
          <div class="f3r blue-text-1 monsterratExtraBold mb-4">
            Lokasi Kami{lokasi}
          </div>
          <button
            id="buttonLokasiIndonesia"
            class="profileButton monsterratMedium rounded-pill grey "
            onClick={(e) => changePage("indonesia")}
          >
            Indonesia
          </button>
          <button
            id="buttonLokasiGlobal"
            class="profileButton monsterratMedium rounded-pill grey "
            onClick={(e) => changePage("global")}
          >
            Global Network
          </button>
        </div>
      </div>
    );
  };
  const Alamat = () => {
    return (
      <div class="alamatContainer ">
        <div class="alamatWrapper">
          {lokasi == "indonesia"
            ? addressDataIndonesia.map((address, i) => {
                let noTelp = "";
                if (address.no_telp.length == 0) {
                  noTelp = "-";
                } else {
                  for (let i = 0; i < address.no_telp.length; i++) {
                    const element = address.no_telp[i];
                    if (i !== 0 && i !== address.no_telp.length) {
                      noTelp += ` | `;
                    }
                    noTelp += element;
                  }
                }

                return (
                  <div class="alamat mt-4 mx-3 pt-3 pb-4 px-4">
                    <div class="alamatTitle monsterratBlack f2r blue-text-1">
                      {address.kota}
                    </div>
                    <div class="grey mt-2 addressDetails f075r monsterratMedium fw-500">
                      <div class="grey pinContainer">
                        <img class="locationImg" src={location} />
                      </div>
                      <div>{address.alamat}</div>
                    </div>
                    <div class="alamatSpacer mt-3"></div>
                    {/* <div class="mt-3  mb-2 monsterratBold f075r">
                      Telepon
                    </div> */}
                    <div
                      // dangerouslySetInnerHTML={{ __html: noTelp }}
                      class="lokasiTelp grey monsterratMedium f075r fw500 flex"
                    >
                      <div class="grey pinContainer center-vertical">
                        <img class="phoneImg" src={phone} />
                      </div>
                      <div class="center-vertical">
                        <a
                          className="text-none grey"
                          style={{ textDecoration: "none !important" }}
                          href={`tel:${address.no_telp}`}
                        >
                          {noTelp}
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })
            : addressGlobal.map((address, i) => {
                let noTelp = "";
                for (let i = 0; i < address.no_telp.length; i++) {
                  const element = address.no_telp[i];
                  if (i !== 0 && i !== address.no_telp.length) {
                    noTelp += `&nbsp;&nbsp;|&nbsp;&nbsp;`;
                  }
                  noTelp += element;
                }

                return (
                  <div class="alamat mt-4 mx-3 pt-3 pb-4 px-4">
                    <div class="alamatTitle monsterratBlack f2r blue-text-1">
                      {address.kota}
                    </div>
                    {/* <div class="grey mt-1 mb-2 monsterratBold f075r">
                      Alamat :
                    </div> */}
                    {/* <div
                     
                      class="grey addressDetails f075r monsterratMedium fw-500"
                    >
                      {}
                    </div> */}
                    <div class="grey mt-2 addressDetails f075r monsterratMedium fw-500">
                      <div class="grey pinContainer">
                        <img class="locationImg" src={location} />
                      </div>
                      <div
                        dangerouslySetInnerHTML={{ __html: address.alamat }}
                      ></div>
                    </div>
                    <div class="alamatSpacer mt-3"></div>
                    {/* <div class="mt-3  mb-2 monsterratBold f075r">
                      Telepon
                    </div> */}
                    {/* <div
                      dangerouslySetInnerHTML={{ __html: noTelp }}
                      class="lokasiTelp grey monsterratMedium f075r fw500"
                    ></div> */}
                    <div
                      // dangerouslySetInnerHTML={{ __html: noTelp }}
                      class="lokasiTelp grey monsterratMedium f075r fw500 flex"
                    >
                      <div class="grey pinContainer center-vertical">
                        <img class="phoneImg" src={phone} />
                      </div>
                      <div class="center-vertical">
                        <a
                          className="text-none grey"
                          style={{ textDecoration: "none !important" }}
                          href={`tel:${address.no_telp}`}
                        >
                          {noTelp}
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
          {/* {lokasi == "indonesia" ? (
            <div class="alamat mt-4 mx-3 pt-3 pb-4 px-4 center-vertical text-center">
              <div class="alamatTitle monsterratBlack f2r blue-text-1 comingAddress ">
                Coming Soon
              </div>
            </div>
          ) : null} */}
        </div>
      </div>
    );
  };
  let addressDataIndonesia = [
    {
      kota: "Headquarter",
      alamat:
        "Equity Tower Lt.9 Unit A, SCBD Lot.9 JI. Jend Sudirman Kav.52-56, Jakarta 12190, Indonesia",
      no_telp: ["021-2991-1911"],
    },
    // {
    //   kota: "Kelapa Gading - Jakarta",
    //   alamat:
    //     "Rukan Boulevard Arta Gading (Gading Kirana) Blok A7C no.7, Kelapa Gading, Jakarta Utara, DKI Jakarta 14240",
    //   no_telp: ["021-2991-1877"],
    // },
    // {
    //   kota: "Surabaya",
    //   alamat:
    //     "Graha Bumi Surabaya Suite 604 Jl. Basuki Rahmat No.106-128, Embong Kallasin, Kec. genteng, Kota SBY, Jawa Timur 60271",
    //   no_telp: ["081277328389", "081234537199"],
    // },
    // {
    //   kota: "Bandung",
    //   alamat: "Next Space by Union Spce, Jalan Trunojoyo No.11 Bandung",
    //   no_telp: ["082219775974"],
    // },
    // {
    //   kota: "Batam",
    //   alamat:
    //     "Komplek Mahkota Raya Blok A, No. 11, Jl. Raja H. Fisabilillah - Batam Center, Batam, 29456, Kepulauan Riau",
    //   no_telp: [],
    // },
    // {
    //   kota: "Medan",
    //   alamat:
    //     "Jl. Ir. H. Juanda Baru No A5-A6, Ps. Merah Barat, Kec. Medan Kota, Kota Medan, Sumatera Utara 20214",
    //   no_telp: ["081414990430", "081370328164"],
    // },
    // {
    //   kota: "Jambi",
    //   alamat:
    //     "Jl Kolonel Abun Jani No. 11A dan 11B, Kel. Selamat, Kec. Telanaipura, Jambi, 36129",
    //   no_telp: ["0895622099820"],
    // },
    // {
    //   kota: "Pekanbaru",
    //   alamat:
    //     "Ruko Sudirman Business Central Block B1, Jl. Jend Sudirman, Kel. Tangkerang Selatan, Kec. Bukit Raya, Kota Pekanbaru",
    //   no_telp: ["081266802323"],
    // },
    // {
    //   kota: "Bengkulu",
    //   alamat:
    //     "Jl. Asahan, No. 18 Kel. Padang Harapan Kec. Gading Cempaka Kota Bengkulu Prov. Bengkulu, 38225",
    //   no_telp: ["082177417816", "085368574799"],
    // },
    // {
    //   kota: "Makassar",
    //   alamat:
    //     "Jl. A.P. Pettarani No. 9, Kelurahan Sinri Jala, Kecamatan Panakkukang, Kota Makassar, Sulawesi Selatan",
    //   no_telp: ["081524520390", "085341709774"],
    // },
    // {
    //   kota: "Gorontalo",
    //   alamat:
    //     "Jl. Muchlis Rahim, Desa Panggulo Barat, Kecamatan Botupingge, Kabupaten Bone Balango, Provinsi Gorontalo 96112.",
    //   no_telp: [],
    // },
    // {
    //   kota: "Manado",
    //   alamat:
    //     "Ruko Mega Bright Blok 1E1 No.3 Komp. Mega Mas - Jl. Piere Tendean Boulevard Manado, Sulawesi Utara",
    //   no_telp: ["081340249297"],
    // },
    // {
    //   kota: "Banjarmasin",
    //   alamat:
    //     "Jl. Ahmad Yani KM 5, RT. 003/RW. 001, Pemurus Baru, Banjarmasin Selatan, Banjarmasin, Kalimantan Selatan 70248",
    //   no_telp: ["085348184870", "085820224746"],
    // },
    // {
    //   kota: "Pontianak",
    //   alamat:
    //     "Komplek Central Perdana No. A2-A3, 78124 Jl. Perdana, Parit Tokaya,Kota Pontianak, Kalimantan Barat 78115",
    //   no_telp: ["0852 4522 8899"],
    // },

    // {
    //   kota: "Palangkaraya",
    //   alamat:
    //     "Jl. RTA Milono Km 2.5, Kel. Menteng Kec. Jekan Raya, Palangkaraya 73111",
    //   no_telp: ["082140013249"],
    // },

    // {
    //   kota: "Bali",
    //   alamat: "Jl. Cok Agung Tresna No.163 Renon, Denpasar 80239",
    //   no_telp: [],
    // },

    // {
    //   kota: "Solo",
    //   alamat:
    //     "Surakarta-Jl. Mr. Muh Yamin No. 54 Jayengan, Kel. Jayengan, Kec. Serengan, Kota Surakarta, Provinsi Jawa Tengah",
    //   no_telp: [],
    // },
    // {
    //   kota: "Puri - Jakarta",
    //   alamat:
    //     "JL. PURI KENCANA BLOK M8-1i, Kec. Kembangan, Daerah Khusus Ibukota Jakarta 11610",
    //   no_telp: [],
    // },
    // {
    //   kota: "Pangkal Pinang",
    //   alamat:
    //     "Pasar Padi, Girimaya, Pangkal Pinang City, Bangka Belitung Islands 33684",
    //   no_telp: [],
    // },
  ];
  let addressGlobal = [
    {
      kota: "Headquarter Global",
      alamat: "88, Uisadang-daero, Yeongdeungpo-Gu, Seoul,Korea",
      no_telp: ["02-3276-6400"],
    },

    {
      kota: "Singapore",
      alamat:
        "KIARA ADVISORS <br/>1 Raffles Place, #43-04 One Raffles Place, Singapore 048616",
      no_telp: ["65-6501-5623"],
    },
    {
      kota: "Singapore",
      alamat:
        "KOREA INVESTMENT & SECURITIES SINGAPORE PTE. LTD (KIS SINGAPORE)<br/> 1 Raffles Place, #43-04 One Raffles Place, Singapore 048616",
      no_telp: ["65-6501-5600"],
    },
    {
      kota: "Singapore",
      alamat:
        "KOREA INVESTMENT PARTNERS SOUTHEAST ASIA PTE. LTD<br/>  65 Chulia Street #49-04 OCBC Centre, Singapore",
      no_telp: ["65-6228-0490"],
    },
    {
      kota: "U.K",
      alamat:
        "KOREA INVESTMENT & SECURITIES EUROPE LIMITED (KIS EUROPE) <br/> 30 Crown Place, 10th Floor, London EC2A 4EB UK",
      no_telp: ["44-207-065-2760"],
    },
    {
      kota: "U.S.A",
      alamat:
        "KOREA INVESTMENT & SECURITIES US, lnc. (KIS US)<br/> 1350 Avenue of the Americas, Suite 1610, New York, NY10019",
      no_telp: ["1-646-891-5163"],
    },
    {
      kota: "U.S.A",
      alamat:
        "KOREA INVESTMENT & SECURITIES AMERICA, INC (KIS AMERICA) <br/> 1350 Avenue of the America Suite 1110 New York, NY 10019",
      no_telp: ["1-212-314-0681"],
    },
    {
      kota: "U.S.A",
      alamat:
        "KOREA INVESTMENT PARTNERS USA <br/>333 West El Camino Real, Sunnyvale, CA 94087, USA",
      no_telp: ["1-650-542-9676"],
    },
    {
      kota: "Hong Kong",
      alamat:
        "KOREA INVESTMENT & SECURITIES ASIA LIMITED (KIS ASIA)<br/> Suite 3716-19 Jardine House 1 Connaught Place, Central Hong Kong",
      no_telp: ["852-2530-8900"],
    },
    {
      kota: "China",
      alamat:
        "BEIJING REPRESENTATIVE OFFICE OF KOREA INVESTMENT & SECURITIES CO.,LTD<br/> 31th Floor China World Office 1, No.1 Jianguomenwai Avenue, Chaoyang District, Beijing, .China",
      no_telp: ["86-10-8571-2802"],
    },
    {
      kota: "China",
      alamat:
        "TRUE FRIEND INVESTMENT ADVISORY CO.,LTD<br/> 31th Floor China World Office 1, No.1 Jianguomenwai Avenue, Chaoyang District, Beijing, China",
      no_telp: ["86-10-8571-2802"],
    },
    {
      kota: "China",
      alamat:
        "SHANGHAI REPRESENTATIVE OFFICE OF KOREAINVESTMENT MANAGEMENT CO., LTD.<br/> No64T50, 64F, Shanghai World Financial Center, 100 Century Avenue, Pudong New Area Shanghai 200120, China",
      no_telp: ["86-21-6877-6880"],
    },
    {
      kota: "China",
      alamat:
        "KOREA INVESTMENT PARTNERS (SHANGHAI) CO., LTD<br/> RM4102, 41F, United Plaza, No.1468 Nanjing West Road, JingAn District, Shanghai, China",
      no_telp: ["86-21-6212-2667"],
    },
    {
      kota: "China",
      alamat:
        "BEIJING REPRESENTATIVE OFFICE, KOREAINVESTMENT PARTNERS CO., LTD. <br/> 31/F. China World Office 1, No.1 Jian Guo Men Wai Avenue, Beijing, China",
      no_telp: ["86-10-6505-9793"],
    },
    {
      kota: "China",
      alamat:
        "CHENGDU REPRESENTATIVE OFFICE OF KOREAINVESTMENT PARTNERS CO., LTD. <br/> RM1004, 10F, Building 2A, No.200 Tianfu 5th St., Chengdu Hi-Tech Development Zone, Chengdu, Sichuan, China",
      no_telp: ["86-134-3895-0619"],
    },
    {
      kota: "China",
      alamat:
        "GUANGZHOU REPRESENTATIVE OFFICE OF KOREA INVESTMENT PARTNERS CO., LTD. <br/> No.1004, C1 Innovation Building, Huangpu District, Guangzhou, China",
      no_telp: ["86-159-1310-7807"],
    },
    {
      kota: "Vietnam",
      alamat:
        "KOREA INVESTMENT & SECURITIES VIETNAM SECURITIES CORPORATION <br/>3th Floor, Maritime Bank Tower, 180-192, Nguyen Cong Tru, District1, HCMC, Vietnam",
      no_telp: ["84-28-3194-8585"],
    },
    {
      kota: "Vietnam",
      alamat:
        "KIM VIETNAM FUND MANAGEMENT CO., LTD <br/>25 Nguyen Co Thach, An Loi Dong Ward, District2, Ho Chi Minh, Vietnam",
      no_telp: ["84-28-3824-2220"],
    },

    {
      kota: "Indonesia",
      alamat:
        "PT KOREA INVESTMENT & SEKURITAS INDONESIA <br/> Equity Tower Lt.9 Unit A, SCBD Lot.9 JI. Jend Sudirman Kav.52-56, Jakarta 12190, Indonesia",
      no_telp: ["62-21-2991-1888"],
    },
    {
      kota: "Indonesia",
      alamat:
        "PT KISI ASSET MANAGEMENT  <br/> Equity Tower Lt.9 Unit A, SCBD Lot.9 JI. Jend Sudirman Kav.52-56, Jakarta 12190, Indonesia",
      no_telp: ["62-21-2991-1808"],
    },
  ];

  return (
    <div>
      {/* {page == "indonesia" ? (
        <PageLocation left="Tentang Kami" leftPath="/" right="Indonesia" />
      ) : null}
      {page == "global" ? (
        <PageLocation left="Tentang Kami" leftPath="/" right="Global Network" />
      ) : null}
      {page == "profil_perusahaan" ? (
        <PageLocation
          left="Tentang Kami"
          leftPath="/"
          right="Profil Perusahaan"
        />
      ) : null} */}

      <div class="lokasiIndonesiaContainer">
        {/* <LokasiBlueImage />
        {page == "indonesia" || page == "global" ? (
          <LokasiBlueImage />
        ) : (
          <ProfileImage />
        )} */}
        <div class="profileButtonContainer">
          <div class="profileButtonWrapper">
            <div class="f3r blue-text-1 monsterratExtraBold mb-4">
              Lokasi Kami
            </div>
            <button
              id="buttonLokasiIndonesia"
              class="profileButton monsterratMedium rounded-pill grey "
              onClick={(e) => changePage("indonesia")}
            >
              Indonesia
            </button>
            <button
              id="buttonLokasiGlobal"
              class="profileButton monsterratMedium rounded-pill grey "
              onClick={(e) => changePage("global")}
            >
              Global Network
            </button>
          </div>
        </div>
        <div class="lokasiGlobalImgContainer">
          <img class="lokasiGlobalImg" src={atlas} />
        </div>

        {page !== "profil_perusahaan" ? <Alamat /> : null}
      </div>
      <div style={{ height: "7rem" }}></div>
      <div id="main-image"></div>
    </div>
  );
}
