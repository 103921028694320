import logo from "../../logo.svg";
import "./style.css";
import idx_islamic_chg from "../../assets/idx_islamic_chg.jpg";
import merdeka78 from "../../assets/merdeka78.jpg";
import CIMB from "../../assets/CIMB.jpg";
import koins from "../../assets/koins.png";
import loading from "../../assets/loading.gif";
import marginTimeline from "../../assets/marginTimeline.jfif";
import kisiMobile from "../../assets/kisiMobile.png";
import letter from "../../assets/letter.png";
import mail from "../../assets/email.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import promoMarginMain from "../../assets/promoMarginMain.jpg";
import promoEtfMain from "../../assets/promoEtfMain.jpg";
import promoCIMBSyariah from "../../assets/promoCIMBSyariah.png";
import promoCIMBSyariahMobile from "../../assets/promoCIMBSyariahMobile.png";
import etfFee from "../../assets/etfFee.png";
import playStore from "../../assets/playStore.png";
import appStore from "../../assets/appStore.png";
import BlogMenu from "../../components/BlogMenu";
import marginCheck from "../../assets/marginCheck.png";
import regAccInterest from "../../assets/regAccInterest.png";
import marginAccInterest from "../../assets/marginAccInterest.png";
import { getPromos, } from "../../store/action";
import promoReksadana23 from "../../assets/promoReksadana23.jpg";
import { NotFound } from "../NotFound";
import { host, storageLocation } from "../../config/server";
import { server } from "../../config/server";
import payday_2 from "../../assets/payday_2.png";
export function Promo() {
  const dispatch = useDispatch();
  const { page } = useParams();
  const [promoListFromContent, setPromoListFromContent] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [content, setContent] = useState();
  const params = useLocation();
  const currentPath = params.pathname;
  const [imageLoaded, setImageLoaded] = useState(false);
  const getContent = () => {
    fetch(server + `/api/v1/web/promos?slug=${page}`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      headers: {
        "Content-Type": "application/json",
        access_token: localStorage.getItem("access_token"),
      },
    })
      .then((res) => res.json())
      .then(async (result) => {
        console.log(result, "hasil");
        if (result.data.length > 0) {
          setContent(result.data[0]);
        }
        setIsDataFetched(true);
        // console.log(result);
        // console.log(result);
        // await dispatch(setSelectedData(result));
        // const structuredData = await tableDataStructurer(
        //   result,
        //   selectDetails
        // );
        // const { dataToSet, columnToSet } = structuredData;
        // // console.log(dataToSet, "loped", columnToSet);
        // await dispatch(setTableColumns(columnToSet));
        // await dispatch(setTableData(dataToSet));
        // resolve(result);
      })
      .catch((err) => {
        setIsDataFetched(true);
        console.log(err);
      });
  };

  useEffect(() => {
    // console.log("asw", isDataFetched, promoListFromContent);
    window.scrollTo(0, 0);
    getContent();
  }, [page]);
  const navigate = useNavigate();
  const openInNewTab = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };

  const PromoEtf = () => {
    return (
      <div class="promoPageContainer">
        <div class="promoPageWrapper">
          <div class="CIMBPageContainer">
            <div class="CIMBPageWrapper">
              <div class="etfContainer productBanner etfPromoBanner">
                <div class="etfTextContainer etfPromoTextContainer">
                  <div class="etfTextWrapper ">
                    <div class="monsterratBlack f4r bannerTitle grey  nowrap blue-text-1">
                      Exchange<br></br>Traded<br></br>Fund (ETF)
                      <br class="brMobile"></br>
                    </div>
                    {/* <div
                  class="monsterratBlack f4r bannerTitle grey  blue-text-1"
               
                >
                  1 LOT / ETF
                </div> */}
                    <div class="monsterratMedium bannerSubtitle  mt-4 grey kisiMobileSmallText f12r">
                      PT Korea Investment And Sekuritas Indonesia<br></br>{" "}
                      sebagai Dealer Partisipan
                    </div>

                    <div class="downloadButtonGroup etfDownloadGroup etfPromoDownload">
                      <div class="mx-auto etfDownloadButtonContainer etfPromoDownloadButtonContainer">
                        <button
                          class=" monsterratBlack blue-btn rounded-pill koinsDownload downloadButton  f1r px-5"
                          onClick={() => navigate("/kisimobile")}
                        >
                          DOWNLOAD SEKARANG
                        </button>

                        <div class="flex mobileMarketplaceContainer">
                          <div class="  flexEnd appStore">
                            <img
                              class="mobileMarketplace"
                              src={appStore}
                              onClick={() =>
                                openInNewTab(
                                  "https://apps.apple.com/id/app/kisi-mobile/id1493151464"
                                )
                              }
                            />
                          </div>
                          <div class="  flexStart playStore">
                            <img
                              class="mobileMarketplace"
                              src={playStore}
                              onClick={() =>
                                openInNewTab(
                                  "https://play.google.com/store/apps/details?id=id.co.kisi.kisimobile&hl=in&gl=US"
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div class="monsterratRegular grey disclaimerText mt-5 none-mobile">
                        *Selain Nasabah Bank BCA & CIMB Niaga, Hubungi Customer
                        Service Kami
                      </div>
                    </div>
                  </div>
                </div>

                <div class="etfImgContainer">
                  <img
                    class="etfImg etfImgPromo"
                    src={promoEtfMain}
                    // onLoad={() => {
                    //   setImageLoaded(true);
                    // }}
                    // data-aos="fade-up"
                    // data-aos-duration="3000"
                  />
                </div>
              </div>
              <div class="CIMBTextWrapper py-5">
                <div class="f25r blue-text-1 monsterratExtraBold my-3 text-center">
                  Promo Exchange Traded Fund (ETF)
                </div>
                {/* <div class="my-5 f25r nowrap blue-text-1 monsterratExtraBold text-center">
            Syarat & Ketentuan menjadi nasabah ETF
            </div> */}
                <div class="etfContent f1r monsterratMedium grey">
                  <p>{/* <b>Syarat dan Ketentuan:</b> */}</p>

                  <div class="f15r blue-text-1 monsterratMedium my-3 ">
                    Syarat & Ketentuan
                  </div>

                  <ol>
                    <li class="mt-2">
                      {" "}
                      KISI akan memberikan ETF XKMS sebanyak 1 (satu) lot secara
                      gratis kepada setiap Nasabah individu (retail) yang
                      melakukan pembukaan rekening efek untuk pertama kalinya di
                      KISI.
                    </li>{" "}
                    <li class="mt-2">
                      {" "}
                      Promo ini hanya berlaku bagi Nasabah yang telah melakukan
                      registrasi pada periode Januari 2024 sampai dengan
                      Desember 2024.
                    </li>{" "}
                    <li class="mt-2">
                      {" "}
                      Dalam Proses Registrasi, Calon Nasabah diharapkan untuk
                      dapat menyertakan Identitas Diri, seperti: KTP dan NPWP.
                    </li>{" "}
                    <li class="mt-2">
                      {" "}
                      Bersedia melalukan Verifikasi Data Nasabah, melalui proses
                      Video Call yang jadwal nya dapat disesuaikan oleh Calon
                      Nasabah.
                    </li>{" "}
                    <li class="mt-2"> Fee yang berlaku saat ini :</li>{" "}
                    <div>
                      <img src={etfFee} />
                    </div>
                    <li class="mt-2"> KISI Mobile Tersedia di:</li>{" "}
                    <div class="flex my-4">
                      <div>
                        <img
                          class="downloadImg mb-2"
                          src={playStore}
                          onClick={() =>
                            openInNewTab(
                              "https://play.google.com/store/apps/details?id=id.co.kisi.kisimobile&hl=in&gl=US"
                            )
                          }
                        />
                      </div>
                      <div>
                        <img
                          onClick={() =>
                            openInNewTab(
                              "https://apps.apple.com/id/app/kisi-mobile/id1493151464"
                            )
                          }
                          class="downloadImg mb-2"
                          src={appStore}
                        />
                      </div>
                    </div>
                    <li class="mt-2">
                      {" "}
                      Setelah berhasil melakukan pendaftaran dan melakukan
                      deposit di bulan yang sama. Hanya dengan melakukan minimal
                      deposit IDR 100,000 nasabah akan mendapatkan Promo Free 1
                      Lot ETF yang akan di distribusikan pada bulan berikutnya.{" "}
                    </li>{" "}
                    <li class="mt-2">
                      {" "}
                      Promo hanya berlaku bagi Nasabah{" "}
                      <b>
                        yang telah melakukan pembukaan rekening efek dan
                        penyetoran awal di bulan yang sama
                      </b>
                      , serta telah{" "}
                      <b>
                        melakukan konfirmasi dengan mengisi dan menyerahkan
                        surat pernyataan serta form nego kepada KISI sebelum
                        tanggal 10 pada bulan berikutnya.
                      </b>
                    </li>{" "}
                    <p class="noList mt-3">
                      Surat Pernyataan dan Form Nego akan dikirimkan dari email
                      hello@kisi.co.id ke email nasabah selambat-lambatnya
                      setiap tanggal 5 dan setiap bulannya. Nasabah pun
                      diharapkan dapat mengembalikan surat penyataan dan form
                      nego yang masing-masing telah di tanda tangani dan
                      dikembalikan melalui email ke hello@kisi.co.id , sebelum
                      tanggal 10 pada bulan tersebut.{" "}
                    </p>
                    <p class="noList mt-3">
                      PT Korea Investment & Sekuritas Indonesia memohon maaf,
                      jika tidak bisa memproses hadiah tersebut jika Nasabah
                      mengembalikan Surat Pernyataan dan Form Nego setelah
                      tanggal 10.
                    </p>
                    <li class="mt-2">
                      KISI akan melakukan pembelian ETF XKMS sebanyak 1 lot di
                      rentang <b>waktu tanggal 11 sampai dengan tanggal 18 </b>
                      pada bulan yang sama{" "}
                      <b>
                        setelah KISI menerima konfirmasi dari Nasabah serta
                        telah dipenuhinya syarat dan ketentuan sebagaimana
                        disebutkan pada pasal 1, 2 dan 3.
                      </b>
                    </li>
                    <li class="mt-2">
                      Biaya yang timbul sebagai akibat dari proses pemindahan
                      ETF XKMS dari KISI kepada Nasabah sepenuhnya ditanggung
                      oleh KISI. Setelah pemindahan selesai maka biaya transaksi
                      ETF XKMS yang akan timbul di kemudian hari sepenuhnya
                      ditanggung oleh Nasabah.
                    </li>
                  </ol>
                  <br></br>
                  <div class="f15r blue-text-1 monsterratMedium mt-5 ">
                    Disclaimer
                  </div>
                  <ol>
                    <li class="mt-2">
                      Hi #SobatKISI, Aplikasi KISI Mobile dari Korea Investment
                      & Sekuritas Indonesia saat ini hanya dapat melayani
                      pendaftaran Full Online untuk Nasabah Bank BCA & CIMB
                      Bank. Kami memohon maaf untuk nasabah dengan rekening bank
                      selain BCA & CIMB, diharapkan tetap melakukan pendaftaran
                      dengan mengirimkan berkas.
                    </li>
                    <li class="mt-2">
                      Nasabah yang berhak mendapatkan promo ETF hanya nasabah
                      sales online dan ataupun yang melakukan pendaftaran
                      melalui KISI Mobile tanpa menggunakan sales, serta yang
                      memenuhi S&K diatas.
                    </li>
                  </ol>
                  <p></p>
                </div>
              </div>

              {/* <div class="langkahPendaftaranWrapper langkahEtf rounded-corner bg-biru-muda py-4 mb-4">
                <div class="grey monsterratExtraBold f25r langkahPendaftaranTitle blue-text-1">
                  Tata Cara Pendaftaran Online
                </div>
                <div class="langkahPendaftaranContainer mb-5">
                  <div class="langkahPendaftaran">
                    <div class="monsterratBlack textCenter stepText blue-text-1 grey">
                      Step 1
                    </div>
                    <div class="langkahPendaftaranImgContainer">
                      <img class="langkahPendaftaranImg " src={kisiMobile} />
                    </div>
                    <div class="langkahPendaftaranText grey monsterratMedium">
                      Download KISI Mobile
                    </div>
                  </div>
                  <div class="langkahSpacer monsterratBlack">- - - - - -</div>
                  <div class="langkahPendaftaran  ">
                    <div class="monsterratBlack textCenter stepText blue-text-1 grey">
                      Step 2
                    </div>
                    <div class="langkahPendaftaranImgContainer resume">
                      <img class="langkahPendaftaranImg resume" src={letter} />
                    </div>
                    <div class="langkahPendaftaranText grey monsterratMedium">
                      Ikuti instruksi untuk mendaftar
                    </div>
                  </div>
                  <div class="langkahSpacer monsterratBlack">- - - - - -</div>
                  <div class="langkahPendaftaran ">
                    <div class="monsterratBlack textCenter stepText blue-text-1 grey">
                      Step 3
                    </div>
                    <div class="langkahPendaftaranImgContainer mail ">
                      <img class="langkahPendaftaranImg " src={mail} />
                    </div>
                    <div
                      style={{ width: "200%", marginLeft: "-50%" }}
                      class="langkahPendaftaranText grey monsterratMedium"
                    >
                      Setelah berhasil, maka ID, PIM, & PASS KOINS akan
                      dikirimkan melalui email yang didaftarkan
                    </div>
                  </div>
                  <div class="langkahSpacer monsterratBlack">- - - - - -</div>
                  <div class="langkahPendaftaran">
                    <div class="monsterratBlack textCenter stepText blue-text-1 grey">
                      Step 4
                    </div>
                    <div class="langkahPendaftaranImgContainer">
                      <img class="langkahPendaftaranImg " src={koins} />
                    </div>
                    <div class="langkahPendaftaranText grey monsterratMedium">
                      Download KOINS untuk melakukan Trading
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center mt-5 mb-5">
                <button
                  class="blue-btn f2r rounded-pill monsterratBold downloadButton mx-auto text-center px-4"
                  onClick={() => navigate("/kisimobile")}
                >
                  Pendaftaran Klik Disini
                </button>
              </div> */}

              {/* <div class="CIMBButtonWrapper my-5 pb-5 ">
            <div class="f25r  mb-4 blue-text-1 monsterratExtraBold text-center">
              Appendix
            </div>
            <div class="monsterratMedium grey f1r text-center pt-5">
              <button class="CIMBPdfButton grey f1r px-4 mx-2"   onClick={() => openInNewTab(syariah2017)}>
                POJK No.35/POJK.04/2017
              </button>
              <button class="CIMBPdfButton grey f1r px-4 mx-2"   onClick={() => openInNewTab(syariah2015)}>
              POJK No.17/POJK.04/2015
              </button>
              <button class="CIMBPdfButton grey f1r px-4 mx-2"   onClick={() => openInNewTab(syariah2011)}>
              Fatwa DSN-MUI No.80 tahun 2011
              </button>
              <button class="CIMBPdfButton grey f1r px-4 mx-2"   onClick={() => openInNewTab(syariahSertifikatMui)}>
              Sertifikasi DSN MUI
              </button>
            </div>
          </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const PromoMargin = () => {
    return (
      <div class="promoPageContainer promoMarginContainer">
        <div class="promoPageWrapper">
          <div class="CIMBPageContainer">
            <div class="CIMBPageWrapper">
              <div class="etfContainer productBanner etfPromoBanner marginPromoBanner">
                <div class="sahamBannerText marginPromoText">
                  <div class="sahamBannerTitle monsterratBlack marginBannerTitle">
                    <span style={{ color: "#fbb03c" }}>
                      PROMO MARGIN <br></br> ACCOUNT
                    </span>
                    <br></br>
                    <div>
                      <span class="f9r">12%</span>
                      <span class="f15r">/tahun</span>
                    </div>
                    <div class="f1r marginPersenImg">
                      <span>
                        <img class="marginCheck" src={marginCheck}></img> Fee
                        Beli 0.13% dan Jual 0.23%
                      </span>{" "}
                      <span>
                        <img class="marginCheck" src={marginCheck}></img> 100+
                        pilihan saham
                      </span>
                      <div>
                        {" "}
                        <span>
                          <img class="marginCheck" src={marginCheck}></img>{" "}
                          Periode promosi Januari s/d Desember 2024
                        </span>
                      </div>
                    </div>

                    <p class="sahamBannerSubtitle"></p>
                  </div>
                  <div class="downloadButtonGroup etfPromoDownload etfDownloadGroup">
                    <div class="mx-auto etfDownloadButtonContainer etfPromoDownloadButtonContainer">
                      <button
                        class=" monsterratBlack blue-btn rounded-pill koinsDownload downloadButton  f1r px-5"
                        onClick={() => navigate("/kisimobile")}
                      >
                        DOWNLOAD SEKARANG
                      </button>

                      <div
                        class="flex mobileMarketplaceContainer marginPromoMarketplace"
                        style={{ marginTop: "-1rem" }}
                      >
                        <div class="  flexEnd appStore">
                          <img
                            class="mobileMarketplace"
                            src={appStore}
                            onClick={() =>
                              openInNewTab(
                                "https://apps.apple.com/id/app/kisi-mobile/id1493151464"
                              )
                            }
                          />
                        </div>
                        <div class="  flexStart playStore">
                          <img
                            class="mobileMarketplace"
                            src={playStore}
                            onClick={() =>
                              openInNewTab(
                                "https://play.google.com/store/apps/details?id=id.co.kisi.kisimobile&hl=in&gl=US"
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div class="monsterratRegular grey disclaimerText mt-5 none-mobile">
                      *Selain Nasabah Bank BCA & CIMB Niaga, Hubungi Customer
                      Service Kami
                    </div>
                  </div>
                </div>

                <div class="etfImgContainer">
                  <img
                    class="etfImg marginPromoImg"
                    src={promoMarginMain}
                    // onLoad={() => {
                    //   setImageLoaded(true);
                    // }}
                    // data-aos="fade-up"
                    // data-aos-duration="3000"
                  />
                </div>
              </div>
              <div class="CIMBTextWrapper py-5">
                <div class="f25r blue-text-1 monsterratExtraBold mt-3 mb-5 text-center">
                  Promo Margin
                </div>
                {/* <div class="my-5 f25r nowrap blue-text-1 monsterratExtraBold text-center">
            Syarat & Ketentuan menjadi nasabah ETF
            </div> */}
                <div class="grey f12r lh2 monsterratMedium ">
                  <p>
                    Nikmati promo{" "}
                    <span class="blue-text-1">
                      <i>#PalingCuanseIndonesia</i>
                    </span>
                    , untuk setiap pembukaan Akun Margin, bagi nasabah
                    perorangan/retail hingga{" "}
                    <b>8.8% per tahun (syarat & ketentuan berlaku)</b> .
                    {/* <b> Akun Margin </b>
                    merupakan akun khusus yang di peruntukkan untuk nasabah yang
                    ingin menggunakan fasilitas margin dalam tempo waktu yang
                    fleksibel dan bunga yang jauh lebih murah dibanding Akun
                    Regular. Nikmati promo fleksibel <i>Margin Rate</i> setiap
                    pembukaan Akun Margin, bagi nasabah perorangan/<i>retail</i>{" "}
                    hingga <b>12% per tahun (syarat & ketentuan berlaku).</b> */}
                  </p>
                  <div>
                    <b>Syarat & Ketentuan</b>
                    <ol class="syaratMargin">
                      {/*
                      <li>
                        Minimal deposit Rp 200,000,000 atau{" "}
                        <i>collateral ratio</i> saham
                      </li> */}
                      <li>
                        Promosi berlangsung dari 
                        <b>Juni 2024 - Desember 2024</b>
                      </li>
                      <li>
                        Promo berlaku untuk nasabah baru Rekening Efek Margin di
                        PT Korea Investment & Sekuritas Indonesia (KISI)
                      </li>{" "}
                      <li>
                        Nikmati Pemindahbukuan Margin dari sekuritas lain dengan
                        gratis biaya, bagi nasabah yang memiliki Akun Marjin di
                        sekuritas lain
                      </li>
                      <li>
                        Nikmati promo bunga marjin dengan:
                        <ul>
                          <li>
                            3 bulan sejak pembukaan akun dengan bunga marjin
                            8.8% per tahun
                          </li>
                          <li>
                            3 bulan berikut nya dengan bunga marjin 8.8% per
                            tahun, jika total nilai transaksi selama 3 bulan
                            mencapai total fasilitas margin yang digunakan. Jika
                            total nilai transaksinya tidak mencapai total
                            fasilitas margin yang digunakan, maka biaya bunga
                            marjin berubah menjadi 10% per tahun
                          </li>
                        </ul>
                      </li>
                      <li>
                        Pihak Korea Investment And Sekuritas Indonesia berhak
                        melakukan pembatalan dan/atau mengubah dan/atau menolak
                        penggunaan promo, berdasarkan penilaian Korea Investment
                        And Sekuritas Indonesia, bilamana terjadi:
                        <ul>
                          <li>Pelanggaran terhadap syarat dan ketentuan;</li>
                          <li>
                            Melanggar Undang-Undang Pasar Modal atau ketentuan
                            lainnya yang berlaku
                          </li>
                        </ul>
                      </li>
                      <li>
                        Nasabah menyatakan setuju dengan syarat dan ketentuan
                        yang ditentukan oleh Korea Investment And Sekuritas
                        Indonesia beserta perubahannya dari waktu ke waktu
                      </li>
                    </ol>
                  </div>
                  <br></br>
                  {/* <table class="table table-striped table-bordered textCenter monsterratMedium">
                    <thead>
                      <tr>
                        <th scope="col">Nilai Liquiditas Portofolio</th>
                        <th scope="col">
                          <i>
                            <i>Margin Rate (p.a)</i>
                          </i>
                        </th>
                        <th scope="col">Available Stock (*bulan Maret 2023)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">>= 85%</th>
                        <th>12%</th>
                        <th>19</th>
                      </tr>
                      <tr>
                        <th scope="row">{"85%< , >=65%"}</th>
                        <th>14%</th>
                        <th>37</th>
                      </tr>
                      <tr>
                        <th scope="row">{"65%< , >=45%"}</th>
                        <th>16%</th>
                        <th>42</th>
                      </tr>

                      <tr>
                        <th scope="row">{"45%<"}</th>
                        <th>18%</th>
                        <th>9</th>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td colspan="3">Total= 107*</td>
                      </tr>
                    </tbody>
                  </table>
                  <br></br>
                  <p>
                    Tingkat <i>Margin Rate</i> nasabah ditentukan berdasarkan
                    tingkat likuiditas saham (tidak menghitung cash) marjin di
                    portofolio nasabah dengan perhitungan berikut ini:
                  </p>

                  <div class="rumus">
                    <div class="flex flexEnd mx-5 my-auto">
                      Nilai Likuiditas Portofolio
                    </div>
                    <div class="my-auto">=</div>
                    <div class="divided">
                      <div>
                        <i>Liquid Value (Capping Value)</i>
                      </div>
                      <div>
                        <hr class="solid" />
                      </div>
                      <div>
                        <i>Market Value</i>
                      </div>
                    </div>
                  </div>
                  <ol>
                    <li>
                      Bunga yang belum terbayarkan oleh nasabah akan
                      diakumulasikan pada bulan berikutnya, dan nasabah akan
                      dikenakan <i>Margin Rate</i> bulan sebelumnya
                    </li>
                    <li>
                      Perubahan <i>Margin Rate</i> akan dilakukan setiap awal
                      bulan tanpa pemberitahuan terlebih dahulu kepada nasabah
                    </li>
                    <li>
                      Jika terdapat perbedaan perhitungan maka akan diberlakukan
                      perhitungan oleh PT Korea Investment And Sekuritas
                      Indonesia
                    </li>
                    <li>
                      Sesuai keputusan Perusahaan, PT Korea Investment And
                      Sekuritas Indonesia dapat merubah periode promo yang
                      pemberitahuannya akan disampaikan secara tertulis, 1
                      (Satu) minggu sebelum periode promo berakhir
                    </li>
                    <li>
                      Untuk informasi lebih lanjut, Anda dapat menghubungi sales
                      Anda atau e-Mail ke:{" "}
                      <u>
                        <i>koins@kisi.co.id</i>
                      </u>
                    </li>
                  </ol>

                  <br></br>
                  <p>
                    <b>
                      Berikut ilustrasi Akun Marjin, jika dibandingkan dengan
                      Akun Regular dalam penggunaan <i>trading limit</i> dan
                      waktunya:
                    </b>
                  </p>
                  <br></br>
                  <p>
                    <img src={regAccInterest} class="marginAccImg"></img>
                  </p> */}
                  {/* <p>
                    Apabila nasabah menggunakan Akun Regular dalam penggunaan
                    layanan
                    <i> overdraft</i> (atau Pinjaman Trading) melebihi dana
                    cash, perhitungan <i>settlement</i> akan diberlakukan
                    seperti diatas dan dikenakan bunga 0,1% / hari dengan
                    periode pinjaman tidak boleh lebih dari T+4 / 4 (Empat)
                    hari, dan pada saat T+4 akan dilakukan <i>force sell</i>{" "}
                    (jual paksa), apabila nasabah tidak <i>top up</i> dana
                    sesuai <i>outstanding </i> yang digunakan + bunga.
                  </p>
                  <p>
                    <img src={marginTimeline} class="marginAccImg" />
                  </p>
                  <br></br>
                  <p>
                    Apabila nasabah menggunakan Rekening Marjin dalam penggunaan
                    fasilitas Marjin (atau Pinjaman Trading) melebihi dana cash
                    perhitungan settlement seperti diatas hanya akan dikenakan
                    bunga 0,04% / hari atau *12% / tahun dan periode pinjaman{" "}
                    <i>Unlimited Time</i>, serta Tidak ada <i>force sell</i>{" "}
                    (jual paksa) *berdasarkan waktu penggunaan.
                  </p>
                  <p>
                    <div class="monsterratExtraBold poinText f275r  mx-auto py-4 my-4 blue-text-1  text-center lh14">
                      Point (+/-) <br class="brMobile"></br>ketetapan standard
                      <br></br> dari Akun Marjin dan <br class="brMobile"></br>
                      Akun Regular
                    </div>
                  </p>
                  <br class="brdiv"></br>
                  <br></br>

                  <table class="table f12r table-bordered textCenter monsterratMedium lh25">
                    <thead>
                      <tr>
                        <th scope="col">Ketentuan</th>
                        <th scope="col">Akun Regular</th>
                        <th scope="col">Akun Marjin</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Fee Transaksi</th>
                        <td>
                          Promo <i>Buy</i> 0,13% / <i>Sell</i> 0,23%{" "}
                        </td>
                        <td>
                          Promo <i>Buy</i> 0,13% / <i>Sell</i> 0,23%
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <i>Minimum deposit</i> / aset
                        </th>
                        <td>Rp. 100,000</td>
                        <td>Rp. 200,000,000</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          Nominal penggunaan marjin dan jangka waktu penggunaan
                        </th>
                        <td>
                          3x <i>Cash</i> (Berdasarkan <i>Capping Ratio</i>{" "}
                          Saham) dan maksimal waktu penggunaan T+4
                        </td>
                        <td>
                          2x <i>Cash</i> (Berdasarkan <i>Capping Ratio</i>{" "}
                          Saham) dan maksimal waktu penggunaan unlimited
                        </td>
                      </tr>

                      <tr>
                        <th scope="row">
                          <i>Interest</i> / bunga penggunaan marjin
                        </th>
                        <td>0,1%/hari atau 36%/tahun</td>
                        <td>sampai 0,04%/hari atau sampai 12%/tahun</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <i>Stock List</i> / saham yang bisa di perdagangkan
                        </th>
                        <td>Seluruh saham yang listing di bursa</td>
                        <td>
                          Stock List/saham yang bisa di perdagangkan; (Terbatas)
                          107* saham margin perbulan Maret 2023
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <i>Force Sell</i>
                        </th>
                        <td>
                          Suspen <i>buy</i> T+3 dan <i>Forced Sell</i> T+4
                        </td>
                        <td>
                          Apabila <i>collateral ratio</i> >= 65% selama tiga
                          hari dan/atau >=80%
                        </td>
                      </tr>
                    </tbody>
                  </table> */}
                </div>
              </div>

              {/* <div class="langkahPendaftaranWrapper langkahEtf rounded-corner bg-biru-muda py-4 mb-4">
                <div class="grey monsterratExtraBold f25r langkahPendaftaranTitle blue-text-1">
                  Tata Cara Pendaftaran Online
                </div>
                <div class="langkahPendaftaranContainer mb-5">
                  <div class="langkahPendaftaran">
                    <div class="monsterratBlack textCenter stepText blue-text-1 grey">
                      Step 1
                    </div>
                    <div class="langkahPendaftaranImgContainer">
                      <img class="langkahPendaftaranImg " src={kisiMobile} />
                    </div>
                    <div class="langkahPendaftaranText grey monsterratMedium">
                      Download KISI Mobile
                    </div>
                  </div>
                  <div class="langkahSpacer monsterratBlack">- - - - - -</div>
                  <div class="langkahPendaftaran  ">
                    <div class="monsterratBlack textCenter stepText blue-text-1 grey">
                      Step 2
                    </div>
                    <div class="langkahPendaftaranImgContainer resume">
                      <img class="langkahPendaftaranImg resume" src={letter} />
                    </div>
                    <div class="langkahPendaftaranText grey monsterratMedium">
                      Ikuti instruksi untuk mendaftar
                    </div>
                  </div>
                  <div class="langkahSpacer monsterratBlack">- - - - - -</div>
                  <div class="langkahPendaftaran ">
                    <div class="monsterratBlack textCenter stepText blue-text-1 grey">
                      Step 3
                    </div>
                    <div class="langkahPendaftaranImgContainer mail ">
                      <img class="langkahPendaftaranImg " src={mail} />
                    </div>
                    <div
                      style={{ width: "200%", marginLeft: "-50%" }}
                      class="langkahPendaftaranText grey monsterratMedium"
                    >
                      Setelah berhasil, maka ID, PIM, & PASS KOINS akan
                      dikirimkan melalui email yang didaftarkan
                    </div>
                  </div>
                  <div class="langkahSpacer monsterratBlack">- - - - - -</div>
                  <div class="langkahPendaftaran">
                    <div class="monsterratBlack textCenter stepText blue-text-1 grey">
                      Step 4
                    </div>
                    <div class="langkahPendaftaranImgContainer">
                      <img class="langkahPendaftaranImg " src={koins} />
                    </div>
                    <div class="langkahPendaftaranText grey monsterratMedium">
                      Download KOINS untuk melakukan Trading
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center mt-5 mb-5">
                <button
                  class="blue-btn f2r rounded-pill monsterratBold downloadButton mx-auto text-center px-4"
                  onClick={() => navigate("/kisimobile")}
                >
                  Pendaftaran Klik Disini
                </button>
              </div> */}

              {/* <div class="CIMBButtonWrapper my-5 pb-5 ">
            <div class="f25r  mb-4 blue-text-1 monsterratExtraBold text-center">
              Appendix
            </div>
            <div class="monsterratMedium grey f1r text-center pt-5">
              <button class="CIMBPdfButton grey f1r px-4 mx-2"   onClick={() => openInNewTab(syariah2017)}>
                POJK No.35/POJK.04/2017
              </button>
              <button class="CIMBPdfButton grey f1r px-4 mx-2"   onClick={() => openInNewTab(syariah2015)}>
              POJK No.17/POJK.04/2015
              </button>
              <button class="CIMBPdfButton grey f1r px-4 mx-2"   onClick={() => openInNewTab(syariah2011)}>
              Fatwa DSN-MUI No.80 tahun 2011
              </button>
              <button class="CIMBPdfButton grey f1r px-4 mx-2"   onClick={() => openInNewTab(syariahSertifikatMui)}>
              Sertifikasi DSN MUI
              </button>
            </div>
          </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const PromoCIMBSyariah = () => {
    return (
      <div class="promoPageContainer">
        <div class="promoPageWrapper">
          <div class="CIMBPageContainer">
            <div class="CIMBPageWrapper">
              <div class="CIMBTextWrapper py-5">
                {window.innerWidth <= 768 ? (
                  <img
                    class="w100 mb-5 mx-auto text-center"
                    src={promoCIMBSyariahMobile}
                  ></img>
                ) : (
                  <img class="w100 mb-5" src={promoCIMBSyariah}></img>
                )}
                {/* <img class="w100 mb-5" src={promoCIMBSyariah}></img> */}
                <div class="f25r  monsterratExtraBold my-5 text-center green-text mb-5 pb-5">
                  Promo Cashback Bagi Yang Menggunakan RDN CIMB SYARIAH
                </div>
                {/* <div class="my-5 f25r nowrap blue-text-1 monsterratExtraBold text-center">
          Syarat & Ketentuan menjadi nasabah ETF
          </div> */}
                <div class="etfContent f1r monsterratMedium grey">
                  <p>{/* <b>Syarat dan Ketentuan:</b> */}</p>

                  <div class="f15r green-text monsterratMedium my-3 ">
                    Detail Program:
                  </div>
                  <div class="f1r monsterratMedium my-3 ">
                    Kerjasama antara KISI dan CIMB Niaga Syariah bahwa akan
                    diberikan kepada 100 pendaftar pertama cashback RDN sebesar
                    100.000
                  </div>
                  <br></br>
                  <div class="f15r green-text monsterratMedium my-3 ">
                    Periode Program:
                  </div>
                  <div class="f1r monsterratMedium my-3 ">
                    1 – 30 September 2023
                  </div>
                  <br></br>
                  <div class="f15r green-text monsterratMedium my-3 ">
                    Syarat & Ketentuan berlaku:
                  </div>
                  <div class="f1r monsterratMedium my-3 ">
                    <ol>
                      <li class="mt-2">
                        Program khusus diberikan kepada pengguna baru KOINS
                        Syariah yang menggunakan RDN CIMB Niaga Syariah.
                      </li>
                      <li class="mt-2">
                        {" "}
                        Promo cashback hanya diberikan kepada satu orang/akun,
                        tidak berlaku kelipatan.
                      </li>
                      <li class="mt-2">
                        Periode dimulai dari tanggal 1 s/d 30 September 2023
                        (berdasarkan pada tanggal akun aktif nasabah).
                      </li>
                      <li class="mt-2">
                        Kuota terbatas hanya untuk 100 orang pertama.{" "}
                      </li>
                      <li class="mt-2">
                        {" "}
                        Dapatkan Promo Cashback Rp100.000 dari CIMB Niaga
                        Syariah.
                      </li>
                      <li class="mt-2">
                        Sistem yang digunakan adalah yang tercepat mendaftar
                        yang akan dilihat melalui waktu akun nasabah aktif
                        (berdasarkan hari, jam, menit, dan detik) yang lebih
                        awal.
                      </li>
                      <li class="mt-2">
                        Tidak ada minimum deposit untuk Promo Cashback RDN CIMB
                        Niaga Syariah.
                      </li>
                      <li class="mt-2">
                        Nasabah yang berhak mendapatkan promo cashback RDN
                        Rp100.000 adalah nasabah sales online dan yang melakukan
                        pendaftaran melalui KISI Mobile tanpa menggunakan sales,
                        serta memenuhi S&K diatas
                      </li>
                    </ol>
                  </div>
                  <br></br>

                  <p></p>
                </div>
                <div class="langkahPendaftaranWrapper mt-5 langkahEtf rounded-corner bg-green py-4 mb-4">
                  <div class=" monsterratExtraBold f25r langkahPendaftaranTitle green-text">
                    Tata Cara Pendaftaran Online
                  </div>
                  <div class="langkahPendaftaranContainer mb-5">
                    <div class="langkahPendaftaran">
                      <div class="monsterratBlack textCenter stepText green-text">
                        Step 1
                      </div>
                      <div class="langkahPendaftaranImgContainer">
                        <img class="langkahPendaftaranImg " src={kisiMobile} />
                      </div>
                      <div class="langkahPendaftaranText grey monsterratMedium">
                        Download KISI Mobile
                      </div>
                    </div>
                    <div class="langkahSpacer monsterratBlack">- - - - - -</div>
                    <div class="langkahPendaftaran  ">
                      <div class="monsterratBlack textCenter stepText green-text">
                        Step 2
                      </div>
                      <div class="langkahPendaftaranImgContainer resume">
                        <img
                          class="langkahPendaftaranImg resume"
                          src={letter}
                        />
                      </div>
                      <div class="langkahPendaftaranText grey monsterratMedium">
                        Ikuti instruksi untuk mendaftar
                      </div>
                    </div>
                    <div class="langkahSpacer monsterratBlack">- - - - - -</div>
                    <div class="langkahPendaftaran ">
                      <div class="monsterratBlack textCenter stepText green-text">
                        Step 3
                      </div>
                      <div class="langkahPendaftaranImgContainer mail ">
                        <img class="langkahPendaftaranImg " src={mail} />
                      </div>
                      <div
                        style={{ width: "200%", marginLeft: "-50%" }}
                        class="langkahPendaftaranText grey monsterratMedium"
                      >
                        Setelah berhasil, maka ID, PIM, & PASS KOINS akan
                        dikirimkan melalui email yang didaftarkan
                      </div>
                    </div>
                    <div class="langkahSpacer monsterratBlack">- - - - - -</div>
                    <div class="langkahPendaftaran">
                      <div class="monsterratBlack textCenter stepText green-text">
                        Step 4
                      </div>
                      <div class="langkahPendaftaranImgContainer">
                        <img class="langkahPendaftaranImg " src={koins} />
                      </div>
                      <div class="langkahPendaftaranText grey monsterratMedium">
                        Download KOINS untuk melakukan Trading
                      </div>
                    </div>
                  </div>
                </div>
                <br></br>
                {/* <div class="f1r monsterratMedium my-3 grey mt-5">Note</div>

                <div class="f1r monsterratMedium my-3 grey">
                  {" "}
                  Nasabah yang berhak mendapatkan promo cashback RDN Rp100.000
                  hanya nasabah sales online dan ataupun yang melakukan
                  pendaftaran melalui KISI Mobile tanpa menggunakan sales, serta
                  yang memenuhi S&K diatas.
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const PromoSwitcher = () => {
    switch (page) {
      // case "promo_etf":
      //   return PromoEtf();
      //   break;
      // case "promo_margin":
      //   return PromoMargin();
      //   break;
      // case "cimb_syariah":
      //   return PromoCIMBSyariah();
      //   break;
      // case "idx_islamic_chg":
      //   return PromoIdxIslamic();
      //   break;
      // case "event_merdeka_78":
      //   return PromoMerdeka78();
      //   break;
      // case "event_payday":
      //   return PromoPayday();
      // case "event_payday_november23":
      //   return PromoPayday2();
      //   break;
      default:
        return PromoContent();
        break;
    }
  };
  const PromoIdxIslamic = () => {
    return (
      <div class="promoPageContainer">
        <div class="promoPageWrapper">
          <div class="CIMBPageContainer">
            <div class="CIMBPageWrapper">
              <div class="CIMBTextWrapper py-5">
                <img class="w100 mb-5" src={idx_islamic_chg}></img>
                <div class="f25r  monsterratExtraBold my-5 text-center blue-text-1 mb-5 pb-5">
                  IDX ISLAMIC CHALLENGE 2023<br></br>
                  <p></p>
                  Program Penghargaan Investor Syariah Teraktif
                </div>
                {/* <div class="my-5 f25r nowrap blue-text-1 monsterratExtraBold text-center">
          Syarat & Ketentuan menjadi nasabah ETF
          </div> */}
                <div class="etfContent f1r monsterratMedium grey">
                  <p>{/* <b>Syarat dan Ketentuan:</b> */}</p>

                  <div class="f15r blue-text-1 monsterratMedium my-3 ">
                    Deskripsi Program
                  </div>
                  <div class="f1r monsterratMedium my-3 ">
                    IDX Islamic Challenge merupakan Program Bursa Efek Indonesia
                    yang bertujuan untuk meningkatkan aktivitas Investor Syariah
                    dalam bertransaksi di Pasar Modal. Investor syariah
                    ditantang untuk melakukan melakukan sejumlah transaksi jual
                    dan beli saham syariah pada satu periode tertentu.
                  </div>

                  <div class="f15r blue-text-1 monsterratMedium my-3 ">
                    Periode Program
                  </div>
                  <div class="f1r monsterratMedium my-3 ">
                    Periode 1&emsp; : &emsp;April - Juni 2023 <br></br>
                    Periode 2&emsp; : &emsp;Juli - September 2023
                  </div>

                  <div class="f15r blue-text-1 monsterratMedium my-3 ">
                    Kategori Peserta
                  </div>
                  <div class="f1r monsterratMedium my-3 ">
                    Seluruh nasabah syariah Individu yang memiliki akun syariah
                    di KISI
                  </div>
                  <div class="f15r blue-text-1 monsterratMedium my-3 ">
                    Hadiah
                  </div>
                  <div class="f1r monsterratMedium my-3 ">
                    <ul>
                      <li class="mt-2">
                        <span
                          style={{ width: "350px", display: "inline-block" }}
                        >
                          Best Investor
                        </span>
                        Rp 15.000.000
                      </li>
                    </ul>
                  </div>
                  <div class="f1r monsterratBold">Pemenang Setiap Periode</div>
                  <div class="f1r monsterratMedium my-3 ">
                    <ul>
                      <li class="mt-2">
                        <span
                          style={{ width: "350px", display: "inline-block" }}
                        >
                          Peringkat&emsp;1
                        </span>
                        Rp&emsp;3.000.000
                      </li>
                      <li class="mt-2">
                        <span
                          style={{ width: "350px", display: "inline-block" }}
                        >
                          Peringkat&emsp;2
                        </span>
                        Rp&emsp;2.500.000
                      </li>
                      <li class="mt-2">
                        <span
                          style={{ width: "350px", display: "inline-block" }}
                        >
                          Peringkat&emsp;3
                        </span>
                        Rp&emsp;2.000.000
                      </li>
                    </ul>
                  </div>

                  <div class="f15r blue-text-1 monsterratMedium my-3 ">
                    Syarat dan Ketentuan
                  </div>
                  <div class="f1r monsterratMedium my-3 ">
                    <ul>
                      <li class="mt-2">
                        Program dapat diikuti oleh investor syariah yang
                        memiliki rekening saham syariah di Korea Investment And
                        Sekuritas Indonesia. Apabila belum memiliki rekening
                        saham syariah, dapat melakukan pembukaan rekening saham
                        syariah terlebih dulu
                      </li>
                      <li class="mt-2">
                        Peserta harus melakukan transaksi jual dan beli saham
                        syariah di rekening saham syariah minimal 12 kali untuk
                        berpartisipasi dalam program
                      </li>
                      <li class="mt-2">
                        Peserta yang telah mengikuti program pada periode 1
                        dapat diikutsertakan kembali di periode ke-2 dengan
                        ketentuan bahwa transaksi yang sudah dilakukan di
                        periode 1 tidak diperhitungkan di periode 2
                      </li>
                      <li class="mt-2">
                        Peserta tidak diperkenankan mendaftar ke lebih dari 1
                        sekuritas
                      </li>
                      <li class="mt-2">
                        Peserta bersedia mengikuti keseluruhan aturan yang
                        ditetapkan oleh Bursa Efek Indonesia dan Korea
                        Investment And Sekuritas Indonesia yang telah disebutkan
                        diatas
                      </li>
                      <li class="mt-2">
                        Penilaian Best Investor dan Peringkat 1 - 3 pada setiap
                        periode dinilai berdasarkan Return On Investment (ROI)
                      </li>
                      <li class="mt-2">
                        Pengumuman pemenang Best Investor akan diumumkan oleh
                        IDX Islamic pada akhir periode program
                      </li>
                      <li class="mt-2">
                        Pemenang peringkat 1 - 3 di setiap periode akan
                        ditentukan dan diumumkan oleh Korea Investment And
                        Sekuritas Indonesia dengan rincian timeline sebagai
                        berikut:
                      </li>
                    </ul>
                  </div>
                  <div class="f15r blue-text-1 monsterratMedium my-3 ">
                    Timeline Kegiatan
                  </div>
                  <div class="f1r monsterratMedium my-3 ">
                    <ul>
                      <li class="mt-2">
                        Start Program Periode 2 : 1 Juli 2023
                      </li>
                      <li class="mt-2">
                        End Program Periode 2 : 30 September 2023
                      </li>
                      <li class="mt-2">
                        Pengumuman Pemenang Periode 2 : 5 Oktober 2023
                      </li>
                      <li class="mt-2">
                        Distribusi Hadiah kepada Pemenang Periode 2 : 5-10
                        Oktober 2023 (menunggu konfirmasi IDX Islamic)
                      </li>
                    </ul>
                  </div>
                  <div class="f15r blue-text-1 monsterratMedium my-3 ">
                    Note
                  </div>
                  <div class="f1r monsterratMedium my-3 ">
                    <ul style={{ listStyleType: "none" }}>
                      <li class="mt-2">
                        * Pemenang best investor akan ditentukan dan diumumkan
                        oleh BEI pada akhir periode program
                      </li>
                      <li class="mt-2">
                        * Pemenang setiap periode akan ditentukan dan diumumkan
                        oleh Korea Investment And Sekuritas setiap periodenya
                      </li>
                      <li class="mt-2">
                        <p>* Metode Perhitungan ROI</p>
                        <div class="rumus" style={{ display: "flex" }}>
                          <div class="flex flexEnd mx-5 my-auto">ROI</div>
                          <div class="my-auto">&emsp;=&emsp;</div>
                          <div class="divided">
                            <div>
                              <i>Total Return</i>
                            </div>
                            <div>
                              <hr class="solid" />
                            </div>
                            <div>
                              <i>Total Investment</i>
                            </div>
                          </div>
                          <div class="my-auto">&emsp;X 100%&emsp;</div>
                        </div>
                      </li>
                      <br></br>
                      Total Return : Realized Gain + Cash Dividend<br></br>
                      Total Investment : Nilai Uang (Cash) yang dikonversikan
                      menjadi saham terhitung saat mendaftar hingga akhir
                      periode kegiatan. sehingga terjadinya withdraw atau top up
                      RDN tidak akan berpengaruh pada perhitungan ROI.
                    </ul>
                  </div>

                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const PromoMerdeka78 = () => {
    return (
      <div class="promoPageContainer">
        <div class="promoPageWrapper">
          <div class="CIMBPageContainer">
            <div class="CIMBPageWrapper">
              <div class="CIMBTextWrapper py-5">
                <img class="w100 mb-5" src={merdeka78}></img>
                <div class="f25r  monsterratExtraBold my-5 text-center blue-text-1 mb-5 pb-5">
                  GIVEAWAY KISI MERAYAKAN KEMERDEKAAN 78<br></br>
                  <p></p>
                </div>
                {/* <div class="my-5 f25r nowrap blue-text-1 monsterratExtraBold text-center">
          Syarat & Ketentuan menjadi nasabah ETF
          </div> */}
                <div class="etfContent f1r monsterratMedium grey">
                  <p>{/* <b>Syarat dan Ketentuan:</b> */}</p>
                  <div class="f1r monsterratMedium my-3 ">
                    Kami dengan bangga mengumumkan bahwa dalam rangka
                    Memperingati Hari Ulang Tahun (HUT) Kemerdekaan Republik
                    Indonesia (RI) pada tanggal 17 Agustus 2023 yang ke 78
                    tahun, perusahaan kami akan menggelar Program Giveaway
                    Kemerdekaan yang menarik dan menghibur! Ini adalah
                    kesempatan bagi kami untuk berbagi kegembiraan dan semangat
                    kemerdekaan bersama Anda.
                  </div>
                  <div class="f15r blue-text-1 monsterratMedium my-3 ">
                    Deskripsi Program
                  </div>
                  <div class="f1r monsterratMedium my-3 ">
                    Share momen Anda seputar kemerdekaan di feed instagram
                    pribadi Anda sertakan caption dan jangan lupa tag akun
                    Instagram{" "}
                    <span
                      style={{ cursor: "pointer" }}
                      class="blue-text-1"
                      onClick={() => {
                        openInNewTab(
                          "https://www.instagram.com/koreainvestment.id/"
                        );
                      }}
                    >
                      @koreainvestment.id{" "}
                    </span>{" "}
                    setelah itu Tag 3 temen Anda sertakan hashtag{" "}
                    <span class="blue-text-1 monsterratBold">
                      #GiveawayKISI #merdeka78 #GIVEAWAYKISIKEMERDEKAAN78
                    </span>
                  </div>
                  <div class="f15r blue-text-1 monsterratMedium my-3 ">
                    Periode Program
                  </div>
                  <div class="f1r monsterratMedium my-3 ">
                    <ul>
                      <li>Program dimulai pada tanggal 17-25 Agustus 2023</li>
                      <li>Pemilihan pemenang pada tanggal 28 Agustus 2023</li>
                      <li>
                        Pengumuman pemenang di Instagram pada tanggal 29 Agustus
                        2023
                      </li>
                      <li>Distribusi 30 Agustus -5 September 2023</li>
                    </ul>
                  </div>
                  <div class="f15r blue-text-1 monsterratMedium my-3 ">
                    Syarat & Ketentuan
                  </div>
                  Cara Partisipasi Giveaway Kemerdekaan 78 :
                  <ul>
                    <li>
                      Wajib Nasabah KOINS (jika non-nasabah diharuskan mendaftar
                      terlebih dahulu)
                    </li>
                    <li>
                      Pemilihan pemenang akan diumumkan di Instagram{" "}
                      <span
                        style={{ cursor: "pointer" }}
                        class="blue-text-1"
                        onClick={() => {
                          openInNewTab(
                            "https://www.instagram.com/koreainvestment.id/"
                          );
                        }}
                      >
                        @koreainvestment.id{" "}
                      </span>{" "}
                      pada tanggal 29 Agustus 2023
                    </li>
                    <li>
                      Wajib Follow akun Instagram{" "}
                      <span
                        style={{ cursor: "pointer" }}
                        class="blue-text-1"
                        onClick={() => {
                          openInNewTab(
                            "https://www.instagram.com/koreainvestment.id/"
                          );
                        }}
                      >
                        @koreainvestment.id{" "}
                      </span>{" "}
                    </li>
                    <li>
                      Like postingan Feed Giveaway Kemerdekaan Instagram{" "}
                      <span
                        style={{ cursor: "pointer" }}
                        class="blue-text-1"
                        onClick={() => {
                          openInNewTab(
                            "https://www.instagram.com/koreainvestment.id/"
                          );
                        }}
                      >
                        @koreainvestment.id{" "}
                      </span>{" "}
                    </li>
                    <li>Pastikan akun Instagram Anda tidak di private</li>
                    <li>
                      Setelah itu jangan lupa untuk comment postingan Feed
                      Giveaway Kemerdekaan di kolom komentar{" "}
                      <span
                        style={{ cursor: "pointer" }}
                        class="blue-text-1"
                        onClick={() => {
                          openInNewTab(
                            "https://www.instagram.com/koreainvestment.id/"
                          );
                        }}
                      >
                        @koreainvestment.id{" "}
                      </span>{" "}
                      dengan kata “DONE” setelah melakukan tugas yang diberikan
                    </li>
                    <li>
                      100 momen terbaik akan diberikan hadiah Voucher Gopay
                      sebesar Rp 78.000 dari KISI
                    </li>

                    <li>
                      KISI berhak secara sepihak membatalkan kemenangan peserta,
                      jika terdapat indikasi kecurangan, penipuan, dan
                      ketidakpatuhan yang dapat merugikan KISI dan peserta
                      lainnya
                    </li>
                    <li>
                      Pemenang wajib memberikan bukti screenshot code client,
                      E-mail & nomor telepon yang terdaftar di KISI
                    </li>
                  </ul>
                  <br></br>
                  <div class="f1r monsterratMedium my-3 ">
                    Hormat kami,<br></br>
                    PT Korea Investment And Sekuritas Indonesia
                  </div>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const PromoPayday = () => {
    return (
      <div class="promoPageContainer">
        <div class="promoPageWrapper">
          <div class="CIMBPageContainer">
            <div class="CIMBPageWrapper">
              <div class="CIMBTextWrapper py-5">
                <img class="w100 mb-5" src={promoReksadana23}></img>
                <div class="f25r  monsterratExtraBold my-5 text-center blue-text-1 mb-5 pb-5">
                  Program PAYDAY promo <i>Cashback</i> Bagi Yang Melakukan
                  Transaksi Reksa Dana
                  <p></p>
                </div>
                {/* <div class="my-5 f25r nowrap blue-text-1 monsterratExtraBold text-center">
          Syarat & Ketentuan menjadi nasabah ETF
          </div> */}
                <div class="etfContent f1r monsterratMedium grey">
                  <p>{/* <b>Syarat dan Ketentuan:</b> */}</p>
                  <div class="f1r monsterratMedium my-3 ">
                    {/* Kami dengan bangga mengumumkan bahwa dalam rangka
                      Memperingati Hari Ulang Tahun (HUT) Kemerdekaan Republik
                      Indonesia (RI) pada tanggal 17 Agustus 2023 yang ke 78
                      tahun, perusahaan kami akan menggelar Program Giveaway
                      Kemerdekaan yang menarik dan menghibur! Ini adalah
                      kesempatan bagi kami untuk berbagi kegembiraan dan semangat
                      kemerdekaan bersama Anda. */}
                  </div>
                  <div class="f15r blue-text-1 monsterratMedium my-3 ">
                    Detail Program:
                  </div>
                  <div class="f1r monsterratMedium my-3 ">
                    PAYDAY adalah Program <i>Cashback</i> Reksa Dana yang hanya
                    diberikan kepada 50 orang Nasabah pertama berupa{" "}
                    <i>Cashback</i>
                    produk Reksa Dana senilai Rp200.000,-
                  </div>
                  <div class="f15r blue-text-1 monsterratMedium my-3 ">
                    Periode Program:
                  </div>
                  <div class="f1r monsterratMedium my-3 ">
                    23 Oktober – 31 Oktober 2023
                  </div>
                  <div class="f15r blue-text-1 monsterratMedium my-3 ">
                    Syarat & Ketentuan berlaku:
                  </div>
                  <ul>
                    <li>
                      Kegiatan promosi ini berlaku untuk Nasabah PT Korea
                      Investment And Sekuritas Indonesia (“KISI”) dan sudah
                      memiliki nomor IFUA (<i>Investor Fund Unit Account</i>);
                    </li>
                    <li>
                      Program <i>cashback</i> hanya diberikan kepada 50 orang
                      Nasabah pertama yang melakukan transaksi pada periode
                      tersebut dan hanya berlaku untuk satu nama per akun, tidak
                      berlaku kelipatan;
                    </li>
                    <li>
                      Periode promosi dimulai dari tanggal 23 Oktober – 31
                      Oktober 2023;
                    </li>
                    <li>
                      Setiap Nasabah yang melakukan transaksi Reksadana melalui
                      KISI Mobile pada periode tersebut akan mendapatkan
                      <i>cashback </i> berupa produk Reksadana yang sama senilai
                      Rp200.000,- (dua ratus ribu Rupiah);
                    </li>

                    <li>
                      Nasabah yang berhak mendapatkan cashback, adalah nasabah
                      yang melakukan transaksi pembelian (<i>subscription</i>)
                      minimal senilai Rp100.000,- (seratus ribu Rupiah) dan
                      tidak melakukan penjualan sebelum tanggal periode
                      pendistribusian <i>cashback</i>;
                    </li>
                    <li>
                      Periode pendistribusian <i>cashback</i> dimulai dari
                      tanggal 02 – 03 November 2023;
                    </li>

                    <li>
                      Kepemilikan atas Reksadana yang dimiliki, baik dari
                      pembelian sendiri dan program <i>cashback</i> dapat
                      dilihat pada menu portofolio di KISI Mobile;
                    </li>
                    <li>
                      Transaksi reksadana KISI hanya dilakukan melalui aplikasi
                      KISI Mobile, KISI tidak bertanggung jawab atas kerugian
                      Nasabah atas transaksi yang dilakukan di luar aplikasi
                      KISI Mobile.
                    </li>
                  </ul>
                  <br></br>
                  <div class="f1r monsterratMedium my-3 ">
                    Hormat kami,<br></br>
                    PT Korea Investment And Sekuritas Indonesia
                  </div>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const PromoPayday2 = () => {
    return (
      <div class="promoPageContainer">
        <div class="promoPageWrapper">
          <div class="CIMBPageContainer">
            <div class="CIMBPageWrapper">
              <div class="CIMBTextWrapper py-5">
                <img class="w100 mb-5" src={payday_2}></img>
                <div class="f25r  monsterratExtraBold my-5 text-center blue-text-1 mb-5 pb-5">
                  Program PAYDAY Promo <i>Cashback</i> 100 Ribu
                  <p></p>
                </div>
                {/* <div class="my-5 f25r nowrap blue-text-1 monsterratExtraBold text-center">
          Syarat & Ketentuan menjadi nasabah ETF
          </div> */}
                <div class="etfContent f1r monsterratMedium grey">
                  <p>{/* <b>Syarat dan Ketentuan:</b> */}</p>
                  <div class="f1r monsterratMedium my-3 ">
                    {/* Kami dengan bangga mengumumkan bahwa dalam rangka
                      Memperingati Hari Ulang Tahun (HUT) Kemerdekaan Republik
                      Indonesia (RI) pada tanggal 17 Agustus 2023 yang ke 78
                      tahun, perusahaan kami akan menggelar Program Giveaway
                      Kemerdekaan yang menarik dan menghibur! Ini adalah
                      kesempatan bagi kami untuk berbagi kegembiraan dan semangat
                      kemerdekaan bersama Anda. */}
                  </div>
                  <div class="f15r blue-text-1 monsterratMedium my-3 ">
                    Detail Program:
                  </div>
                  <div class="f1r monsterratMedium my-3 ">
                    PAYDAY adalah Program <i>Cashback</i> Reksa Dana yang hanya
                    diberikan kepada nasabah yang melakukan transaksi Reksa Dana
                    melalui KISI Mobile senilai Rp100.000,-
                  </div>
                  <div class="f15r blue-text-1 monsterratMedium my-3 ">
                    Periode Program:
                  </div>
                  <div class="f1r monsterratMedium my-3 ">
                    23 November– 30 November 2023
                  </div>
                  <div class="f15r blue-text-1 monsterratMedium my-3 ">
                    Syarat & Ketentuan berlaku:
                  </div>
                  <ul>
                    <li>
                      Kegiatan promosi ini berlaku untuk Nasabah PT Korea
                      Investment And Sekuritas Indonesia (“KISI”) dan sudah
                      memiliki nomor IFUA (<i>Investor Fund Unit Account</i>);
                    </li>
                    <li>
                      Program <i>cashback</i> hanya diberikan kepada seluruh
                      nasabah yang melakukan transaksi pada periode tersebut dan
                      hanya berlaku untuk satu nama per akun, tidak berlaku
                      kelipatan;
                    </li>
                    <li>
                      Periode promosi dimulai dari tanggal 23 November – 30
                      November 2023;
                    </li>
                    <li>
                      Setiap nasabah yang melakukan transaksi Reksa Dana melalui
                      KISI Mobile pada periode tersebut akan mendapatkan
                      <i>cashback</i> berupa produk Reksa Dana yang sama senilai
                      Rp.100.000,- (seratus ribu Rupiah);
                    </li>

                    <li>
                      Nasabah yang berhak mendapatkan <i>cashback</i>, adalah
                      nasabah yang melakukan transaksi pembelian (
                      <i>subscription</i>) minimal senilai Rp.100.000,- (seratus
                      ribu Rupiah) dan tidak melakukan penjualan sebelum tanggal
                      periode pendistribusian <i>cashback</i>;
                    </li>
                    <li>
                      Periode pendistribusian cashback dimulai dari tanggal 04 –
                      05 Desember 2023;
                    </li>

                    <li>
                      Kepemilikan atas Reksadana yang dimiliki, baik dari
                      pembelian sendiri dan program <i>cashback</i> dapat
                      dilihat pada menu portofolio di KISI Mobile;
                    </li>
                    <li>
                      Transaksi reksadana KISI hanya dilakukan melalui aplikasi
                      KISI Mobile, KISI tidak bertanggung jawab atas kerugian
                      Nasabah atas transaksi yang dilakukan di luar aplikasi
                      KISI Mobile.
                    </li>
                  </ul>
                  <br></br>
                  <div class="f1r monsterratMedium my-3 ">
                    Hormat kami,<br></br>
                    PT Korea Investment And Sekuritas Indonesia
                  </div>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const PromoContent = () => {
    console.log(isDataFetched, "huk");

    if (content) {
      return (
        <div class={`promoPageContainer ${imageLoaded ? "o1" : "o0"}`}>
          <div class="promoPageWrapper">
            <div class="CIMBPageContainer">
              <div class="CIMBPageWrapper">
                <div class="CIMBTextWrapper py-5">
                  <img
                    onLoad={() => {
                      setImageLoaded(true);
                    }}
                    class="w100 mb-5"
                    src={host + storageLocation + content.image}
                  ></img>
                  {}
                  <div class="f25r  monsterratExtraBold my-5 text-center blue-text-1 mb-5 pb-5">
                    {content.title}
                    <p></p>
                  </div>
                  {/* <div class="my-5 f25r nowrap blue-text-1 monsterratExtraBold text-center">
            Syarat & Ketentuan menjadi nasabah ETF
            </div> */}
                  <div
                    class="etfContent f1r monsterratMedium grey"
                    dangerouslySetInnerHTML={{ __html: content.content }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      if (isDataFetched) {
        return <NotFound />;
      } else {
        return (
          <div class="w100 text-center center-vertical" style={{ height: 700 }}>
            {/* {JSON.stringify(isDataFetched)} */}
            <img class="mx-auto center-vertical" src={loading}></img>
          </div>
        );
      }
    }

    // return (
    //   <div class="promoPageContainer">

    //     <div class="promoPageWrapper">
    //       <div class="CIMBPageContainer">
    //         <div class="CIMBPageWrapper">
    //           <div class="CIMBTextWrapper py-5">
    //             <img class="w100 mb-5" src={merdeka78}></img>
    //             <div class="f25r  monsterratExtraBold my-5 text-center blue-text-1 mb-5 pb-5">
    //               GIVEAWAY KISI MERAYAKAN KEMERDEKAAN 78<br></br>
    //               <p></p>
    //             </div>
    //             {page} {currentPath}
    //             {/* <div class="my-5 f25r nowrap blue-text-1 monsterratExtraBold text-center">
    //       Syarat & Ketentuan menjadi nasabah ETF
    //       </div> */}
    //             <div class="etfContent f1r monsterratMedium grey">
    //               <p>{/* <b>Syarat dan Ketentuan:</b> */}</p>
    //               <div class="f1r monsterratMedium my-3 ">
    //                 Kami dengan bangga mengumumkan bahwa dalam rangka
    //                 Memperingati Hari Ulang Tahun (HUT) Kemerdekaan Republik
    //                 Indonesia (RI) pada tanggal 17 Agustus 2023 yang ke 78
    //                 tahun, perusahaan kami akan menggelar Program Giveaway
    //                 Kemerdekaan yang menarik dan menghibur! Ini adalah
    //                 kesempatan bagi kami untuk berbagi kegembiraan dan semangat
    //                 kemerdekaan bersama Anda.
    //               </div>
    //               <div class="f15r blue-text-1 monsterratMedium my-3 ">
    //                 Deskripsi Program
    //               </div>
    //               <div class="f1r monsterratMedium my-3 ">
    //                 Share momen Anda seputar kemerdekaan di feed instagram
    //                 pribadi Anda sertakan caption dan jangan lupa tag akun
    //                 Instagram{" "}
    //                 <span
    //                   style={{ cursor: "pointer" }}
    //                   class="blue-text-1"
    //                   onClick={() => {
    //                     openInNewTab(
    //                       "https://www.instagram.com/koreainvestment.id/"
    //                     );
    //                   }}
    //                 >
    //                   @koreainvestment.id{" "}
    //                 </span>{" "}
    //                 setelah itu Tag 3 temen Anda sertakan hashtag{" "}
    //                 <span class="blue-text-1 monsterratBold">
    //                   #GiveawayKISI #merdeka78 #GIVEAWAYKISIKEMERDEKAAN78
    //                 </span>
    //               </div>
    //               <div class="f15r blue-text-1 monsterratMedium my-3 ">
    //                 Periode Program
    //               </div>
    //               <div class="f1r monsterratMedium my-3 ">
    //                 <ul>
    //                   <li>Program dimulai pada tanggal 17-25 Agustus 2023</li>
    //                   <li>Pemilihan pemenang pada tanggal 28 Agustus 2023</li>
    //                   <li>
    //                     Pengumuman pemenang di Instagram pada tanggal 29 Agustus
    //                     2023
    //                   </li>
    //                   <li>Distribusi 30 Agustus -5 September 2023</li>
    //                 </ul>
    //               </div>
    //               <div class="f15r blue-text-1 monsterratMedium my-3 ">
    //                 Syarat & Ketentuan
    //               </div>
    //               Cara Partisipasi Giveaway Kemerdekaan 78 :
    //               <ul>
    //                 <li>
    //                   Wajib Nasabah KOINS (jika non-nasabah diharuskan mendaftar
    //                   terlebih dahulu)
    //                 </li>
    //                 <li>
    //                   Pemilihan pemenang akan diumumkan di Instagram{" "}
    //                   <span
    //                     style={{ cursor: "pointer" }}
    //                     class="blue-text-1"
    //                     onClick={() => {
    //                       openInNewTab(
    //                         "https://www.instagram.com/koreainvestment.id/"
    //                       );
    //                     }}
    //                   >
    //                     @koreainvestment.id{" "}
    //                   </span>{" "}
    //                   pada tanggal 29 Agustus 2023
    //                 </li>
    //                 <li>
    //                   Wajib Follow akun Instagram{" "}
    //                   <span
    //                     style={{ cursor: "pointer" }}
    //                     class="blue-text-1"
    //                     onClick={() => {
    //                       openInNewTab(
    //                         "https://www.instagram.com/koreainvestment.id/"
    //                       );
    //                     }}
    //                   >
    //                     @koreainvestment.id{" "}
    //                   </span>{" "}
    //                 </li>
    //                 <li>
    //                   Like postingan Feed Giveaway Kemerdekaan Instagram{" "}
    //                   <span
    //                     style={{ cursor: "pointer" }}
    //                     class="blue-text-1"
    //                     onClick={() => {
    //                       openInNewTab(
    //                         "https://www.instagram.com/koreainvestment.id/"
    //                       );
    //                     }}
    //                   >
    //                     @koreainvestment.id{" "}
    //                   </span>{" "}
    //                 </li>
    //                 <li>Pastikan akun Instagram Anda tidak di private</li>
    //                 <li>
    //                   Setelah itu jangan lupa untuk comment postingan Feed
    //                   Giveaway Kemerdekaan di kolom komentar{" "}
    //                   <span
    //                     style={{ cursor: "pointer" }}
    //                     class="blue-text-1"
    //                     onClick={() => {
    //                       openInNewTab(
    //                         "https://www.instagram.com/koreainvestment.id/"
    //                       );
    //                     }}
    //                   >
    //                     @koreainvestment.id{" "}
    //                   </span>{" "}
    //                   dengan kata “DONE” setelah melakukan tugas yang diberikan
    //                 </li>
    //                 <li>
    //                   100 momen terbaik akan diberikan hadiah Voucher Gopay
    //                   sebesar Rp 78.000 dari KISI
    //                 </li>

    //                 <li>
    //                   KISI berhak secara sepihak membatalkan kemenangan peserta,
    //                   jika terdapat indikasi kecurangan, penipuan, dan
    //                   ketidakpatuhan yang dapat merugikan KISI dan peserta
    //                   lainnya
    //                 </li>
    //                 <li>
    //                   Pemenang wajib memberikan bukti screenshot code client,
    //                   E-mail & nomor telepon yang terdaftar di KISI
    //                 </li>
    //               </ul>
    //               <br></br>
    //               <div class="f1r monsterratMedium my-3 ">
    //                 Hormat kami,<br></br>
    //                 PT Korea Investment And Sekuritas Indonesia
    //               </div>
    //               <p></p>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // );
  };
  return (
    <>
      <BlogMenu from="artikelById"></BlogMenu>
      {/* {page === "promo_cimb" ? (
        <div>
          <div class="CIMBPageContainer">
            <div class="CIMBPageWrapper">
              <div class="CIMBImgContainer">
                <img class="CIMBImg" src={CIMB}></img>
              </div>

              <div class="CIMBTextWrapper py-5">
                <div class="my-5 f25r nowrap blue-text-1 monsterratExtraBold text-center">
                  Buruan Buka rekening saham di KOINS<br></br> dengan RDN Bank
                  CIMB Niaga bisa <br></br>dapat cashback 100rb + 1 Lot ETF!
                </div>
                <div class="f15r  pt-5 mb-4 blue-text-1 monsterratMedium ">
                  Syarat & Ketentuan berlaku:
                </div>
                <div class="monsterratMedium grey f1r">
                  <ol>
                    <li class="mt-2">
                      Khusus untuk pengguna baru KOINS yang menggunakan RDN CIMB
                      NIAGA.
                    </li>
                    <br></br>
                    <li class="mt-2">
                      Promo Cashback hanya untuk 1 akun tidak berlaku kelipatan.
                    </li>
                    <br></br>
                    <li class="mt-2">
                      Periode 09 s/d 31 Januari 2023 (berdasarkan tanggal
                      pendaftaran oleh Nasabah).
                    </li>
                    <br></br>
                    <li class="mt-2">
                      Kuota terbatas hanya untuk 125 orang pertama.
                    </li>
                    <br></br>
                    <li class="mt-2">
                      Dapatkan Promo Cashback Rp100.000 dari CIMB Niaga + 1 Lot
                      ETF dari Korea Investment And Sekuritas Indonesia.
                    </li>
                    <br></br>
                    <li class="mt-2">
                      Tidak ada minimum deposit untuk Promo Cashback RDN CIMB
                      Niaga.
                    </li>
                    <br></br>
                    <li class="mt-2">
                      Sistem yang digunakan adalah yang tercepat mendaftar yang
                      akan dilihat melalui waktu (berdasarkan hari, jam, menit,
                      dan detik) yang lebih awal.
                    </li>
                    <br></br>
                    <li class="mt-2">
                      Jadwal Distribusi Promo Cashback<br></br>
                      <br></br> Nasabah akan mendapatkan cashback Rp100.000 pada
                      RDN CIMB nasabah yang telah terdaftar <br></br>
                      <br></br>- Tanggal pendaftaran oleh nasabah : Tanggal 09
                      Januari hingga tanggal 31 Januari 2023. <br></br>
                      <br></br>- Pendistribusian Cashback : Tanggal 07 Februari
                      2023.
                    </li>
                    <br></br>
                    <li class="mt-2">
                      Jadwal Distribusi 1 Lot ETF <br></br>
                      <br></br>Nasabah akan dikirimkan formulir Surat Pernyataan
                      dan Form Nego melalui email hello@kisi.co.id ke email
                      nasabah yang terdaftar. <br></br>
                      <br></br>Nasabah diharuskan untuk mengembalikan Surat
                      Penyataan dan Form Nego yang telah ditandatangani dan
                      dikirim ke email hello@kisi.co.id sebelum tanggal yang
                      ditetapkan sebagai berikut. <br></br>
                      <br></br>- Pendistribusian formulir Surat Pernyataan dan
                      Form Nego oleh KISI : Tanggal 07 Februari 2023. <br></br>
                      <br></br>- Batas waktu pengembalian formulir yang sudah
                      ditandatangani oleh Nasabah : Tanggal 13 Februari 2023.{" "}
                      <br></br>
                      <br></br>- Pendistribusian 1 Lot ETF oleh KISI : Tanggal
                      15 Februari 2023.<br></br>
                      <br></br>
                      <br></br>
                      Gampang banget kan, yuk buruan daftar! <br></br>
                      <br></br>Klik link dibawah ini untuk melakukan pendaftaran
                    </li>
                  </ol>
                </div>
              </div>
              <div class="langkahPendaftaranWrapper rounded-corner bg-biru-muda py-4 my-4">
                <div class="grey monsterratExtraBold f25r langkahPendaftaranTitle blue-text-1">
                  3 Langkah Mudah Menggunakan KOINS
                </div>
                <div class="langkahPendaftaranContainer mb-5">
                  <div class="langkahPendaftaran">
                    <div class="monsterratBlack textCenter stepText blue-text-1 grey">
                      Step 1
                    </div>
                    <div class="langkahPendaftaranImgContainer">
                      <img class="langkahPendaftaranImg " src={kisiMobile} />
                    </div>
                    <div class="langkahPendaftaranText grey monsterratMedium">
                      Download KISI Mobile
                    </div>
                  </div>
                  <div class="langkahSpacer monsterratBlack">- - - - - -</div>
                  <div class="langkahPendaftaran  ">
                    <div class="monsterratBlack textCenter stepText blue-text-1 grey">
                      Step 2
                    </div>
                    <div class="langkahPendaftaranImgContainer resume">
                      <img class="langkahPendaftaranImg resume" src={letter} />
                    </div>
                    <div class="langkahPendaftaranText grey monsterratMedium">
                      Ikuti instruksi untuk mendaftar
                    </div>
                  </div>
                  <div class="langkahSpacer monsterratBlack">- - - - - -</div>
                  <div class="langkahPendaftaran ">
                    <div class="monsterratBlack textCenter stepText blue-text-1 grey">
                      Step 3
                    </div>
                    <div class="langkahPendaftaranImgContainer mail ">
                      <img class="langkahPendaftaranImg " src={mail} />
                    </div>
                    <div
                      style={{ width: "200%", marginLeft: "-50%" }}
                      class="langkahPendaftaranText grey monsterratMedium"
                    >
                      Setelah berhasil, maka ID, PIM, & PASS KOINS akan
                      dikirimkan melalui email yang didaftarkan
                    </div>
                  </div>
                  <div class="langkahSpacer monsterratBlack">- - - - - -</div>
                  <div class="langkahPendaftaran">
                    <div class="monsterratBlack textCenter stepText blue-text-1 grey">
                      Step 4
                    </div>
                    <div class="langkahPendaftaranImgContainer">
                      <img class="langkahPendaftaranImg " src={koins} />
                    </div>
                    <div class="langkahPendaftaranText grey monsterratMedium">
                      Download KOINS untuk melakukan Trading
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center mt-5 mb-5">
                <button
                  class="blue-btn f2r rounded-pill monsterratBold downloadButton mx-auto text-center px-4"
                  onClick={() => navigate("/kisimobile")}
                >
                  Pendaftaran Klik Disini
                </button>
              </div>

              <div class="CIMBTextWrapper mt-5 pb-5 py-5">
                <div class="monsterratMedium grey f1r ">
                  Note <br></br>
                  <br></br>- Nasabah yang berhak mendapatkan promo cashback
                  Rp100.000 & 1 Lot ETF hanya nasabah sales online dan ataupun
                  yang melakukan pendaftaran melalui KISI Mobile tanpa
                  menggunakan sales, serta yang memenuhi S&K diatas.<br></br>
                  <br></br> - Promosi ini tidak termasuk "promotion 1 Lot ETF"
                  (tidak digandakan)
                </div>
              </div>

            </div>
          </div>
        </div>
      ) : null} */}

      {PromoSwitcher()}
    </>
  );
}
