import logo from "../../logo.svg";
import "swiper/css/effect-fade";
import CIMBMobile from "../../assets/CIMBMobile.jpg";
import thirdImageMobile from "../../assets/thirdImageMobile.jpg";
import sliderMobile from "../../assets/sliderMobile.jpg";
// import promoEtfMobile from "../../assets/promoEtfMobile.jpg";
import step1 from "../../assets/step1.png";
import step2 from "../../assets/step2.png";
import step3 from "../../assets/step3.png";
import step4 from "../../assets/step4.png";
import step5 from "../../assets/step5.png";
import step6 from "../../assets/step6.png";
import step7 from "../../assets/step7.png";
import testi1 from "../../assets/testi1.png";
import testi2 from "../../assets/testi2.png";
import testi3 from "../../assets/testi3.png";
import testi4 from "../../assets/testi4.png";
import testi5 from "../../assets/testi5.png";
import arrow from "../../assets/arrow.png";
import "./style.css";
import promoSyariah from "../../assets/promoSyariah.jpg";
import promoETF from "../../assets/promoETF.png";
import promoReksadana from "../../assets/promoReksadana.jpg";
import payday_2 from "../../assets/payday_2.png";
import kisipoin from "../../assets/kisipoin.jpg";
import kisipoinMobile from "../../assets/kisipoinMobile.jpg";
import promoMargin from "../../assets/promoMargin.png";
import promoCIMBSyariah from "../../assets/promoCIMBSyariah.png";
import promoEtfMobile from "../../assets/promoEtfMobile.png";
import promoMarginMobile from "../../assets/promoMarginMobile.png";
import promoCIMBSyariahMobile from "../../assets/promoCIMBSyariahMobile.png";
import bannerGif from "../../assets/banner.gif";
import { Link } from "react-router-dom";
import Aos from "aos";
import koins from "../../assets/koins.png";
import faqArrow from "../../assets/faqArrow.png";
import kisiTalk from "../../assets/kisiTalk.png";
import slider from "../../assets/slider.png";
import koinsHp from "../../assets/koinsHp.png";
import kisiMobileHp from "../../assets/kisiMobileHp.png";
import ngobrolKoins from "../../assets/ngobrolKoins.png";
import landing from "../../assets/landing.png";
import aman from "../../assets/aman.png";
import promoEtf from "../../assets/promoEtf.jpg";
//promo
import { server } from "../../config/server.js";
import promo1 from "../../assets/promo1.jpeg";
import promo2 from "../../assets/promo2.jpeg";
import promo3 from "../../assets/promo3.jpeg";

import YouTube, { YouTubeProps } from "react-youtube";
import biayaImg from "../../assets/biayaImg.png";
import integritas from "../../assets/integritas.png";
import profesional from "../../assets/profesional.png";
import inovasi from "../../assets/inovasi.png";
import thirdImage from "../../assets/thirdImage.jpg";
import playStore from "../../assets/playStore.png";
import appStore from "../../assets/appStore.png";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPromos,
  getNews,
  getSliders,
  login,
  setAccessToken,
  setNews,
  setActiveMenu,
} from "../../store/action";
import { useNavigate } from "react-router-dom";
import DownloadBanner from "../../components/DownloadBanner";
import FollowSocmed from "../../components/FollowSocmed";
import "aos/dist/aos.css";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { AppSlider } from "../../components/AppSlider";
import { TestiSlider } from "../../components/TestiSlider";
import ngobrol1 from "../../assets/ngobrol1.jfif";
import ngobrol2 from "../../assets/ngobrol2.jfif";
import ngobrol3 from "../../assets/ngobrol3.jfif";
import ngobrol4 from "../../assets/ngobrol4.jfif";
import ngobrol5 from "../../assets/ngobrol5.jfif";

import { useProgressiveImage } from "../../helpers/ImgLazyLoad";
import YouTubePlaylist from "@codesweetly/react-youtube-playlist";
import { host, storageLocation } from "../../config/server";
export function HomePage() {
  const dispatch = useDispatch();
  const loaded = useProgressiveImage(landing);
  const [isSliderLoaded, setIsSliderLoaded] = useState(false);
  const sliderImages = useSelector((state) => state.sliderImages);
  const promos = useSelector((state) => state.promos);
  const news = useSelector((state) => state.news);
  const accessToken = useSelector((state) => state.accessToken);
  const [swiper, setSwiper] = useState(<Swiper />);
  const [swiper2, setSwiper2] = useState(<Swiper />);
  const [swiper3, setSwiper3] = useState(<Swiper />);
  const [swiper4, setSwiper4] = useState(<Swiper />);
  const [slideIndex, setSlideIndex] = useState(0);
  const [slideIndex2, setSlideIndex2] = useState(0);
  const [slideIndex3, setSlideIndex3] = useState(1);
  const [sliderArr, setSliderArr] = useState([]);
  const [eventArr, setEventArr] = useState([]);
  const navigate = useNavigate();
  const ytApikey = process.env.REACT_APP_YT_API_KEY;
  const onPlayerReady: YouTubeProps["onReady"] = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };
  // setInterval(swiper.slideNext(), 5000);
  let opts = {
    // width: "55%",
    // height: "560px",
    className: "kisiTalkIframe",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      width: 1080,
      height: 1920,
    },
  };

  const swiperSlideTo = (i) => {
    // setSlideIndex(i);
    swiper.slideTo(i);
  };
  const swiperNgobrolSlideTo = (i) => {
    swiper2.slideTo(i);
  };

  const steps = [
    {
      source: step1,
      content: {
        date: "04.28.2020",
        desc: "Install KISI Mobile melalui <b>App Store</b> atau <b>Google Play Store</b> untuk melakukan pendaftaran",
      },
    },
    {
      source: step2,
      content: {
        date: "04.28.2020",
        desc: "Setelah Download silahkan masuk ke aplikasi KISI Mobile.",
      },
    },
    {
      source: step3,
      content: {
        date: "04.28.2020",
        desc: "Pilih <b>DAFTAR</b> untuk Anda yang mau membuka rekening saham",
      },
    },
    {
      source: step4,
      content: {
        date: "04.27.2020",
        desc: "Pilih kategori tipe akun yang sesuai kebutuhan Anda",
      },
    },
    {
      source: step5,
      content: {
        date: "04.23.2020",
        desc: "Lakukan registrasi dan isi data diri Anda",
      },
    },
    {
      source: step6,
      content: {
        date: "04.21.2020",
        desc: "Setelah berhasil melakukan pendaftaran silahkan menikmati fitur KISI Mobile",
      },
    },
    {
      source: step7,
      content: {
        date: "04.21.2020",
        desc: "Setelah berhasil mendaftar, Download KOINS Mobile di <b>Google Play Store</b> atau <b>App Store</b>",
      },
    },
  ];

  const eventArrOffline = [
    {
      isOffline: true,
      image: promo1,
      link_event: "https://www.instagram.com/reel/Ch64PjYpNl9/",
    },
    {
      isOffline: true,
      image: promo2,
      link_event: "https://www.instagram.com/reel/CjAZe84phEV/",
    },

    {
      isOffline: true,
      image: promo3,
      link_event: "https://www.instagram.com/reel/CjsgalnJMRc/",
    },

    {
      isOffline: true,
      image: ngobrol1,
      link_event: "https://www.instagram.com/reel/Cl09-t_JWns/",
    },

    {
      isOffline: true,
      image: ngobrol2,
      link_event: "https://www.instagram.com/reel/ClYUGHGpHG0/",
    },

    {
      isOffline: true,
      image: ngobrol3,
      link_event: "https://www.instagram.com/reel/CkxtCfJJfo1/",
    },

    {
      isOffline: true,
      image: ngobrol4,
      link_event: "https://www.instagram.com/reel/Cmbme89Pk3S/",
    },

    {
      isOffline: true,
      image: ngobrol5,
      link_event: "https://www.instagram.com/reel/CicXGXApov0/",
    },
  ];

  function getWindowDimensions() {
    let windowDimension;
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  const openInNewTab = (url) => {
    // console.log(url);
    var win = window.open(url, "_blank");
    win.focus();
  };

  const arrowHeight = () => {
    let appSpacer = document.getElementsByClassName("langkahSpacerApp");
    let spacerHeight = document.getElementById("slide_langkah_0").offsetHeight;
    for (let i = 0; i < appSpacer.length; i++) {
      const element = appSpacer[i];
      element.style.height = `${spacerHeight}px`;
    }
  };
  let offlineSlider = [

    // {
    //   isOffline: true,
    //   image: payday_2,
    //   navigate: "/blog/promo/event_payday_november23",
    // },
    { isOffline: true, image: promoReksadana,navigate: "/kisimobile" },
    // {
    //   isOffline: true,
    //   image: promoCIMBSyariah,
    //   navigate: "/blog/promo/cimb_syariah",
    // },
    // {
    //   isOffline: true,
    //   image: merdeka78,
    //   navigate: "/blog/promo/event_merdeka_78",
    // },
    // {
    //   isOffline: true,
    //   image: idx_islamic_chg,
    //   navigate: "/blog/promo/idx_islamic_chg",
    // },
    { isOffline: true, image: promoETF, navigate: "/koins" },
    // {
    //   isOffline: true,
    //   image: promoMargin,
    //   navigate: "/blog/promo/promo_margin",
    // },
   // { isOffline: true, image: slider, ext_link: "https://registration.kisi.co.id" },
  ];
  // let sliderArr = [
  //   { image: promoCIMBSyariah, navigate: "/blog/promo/cimb_syariah" },
  //   { image: merdeka78, navigate: "/blog/promo/event_merdeka_78" },
  //   { image: idx_islamic_chg, navigate: "/blog/promo/idx_islamic_chg" },
  //   { image: promoETF, navigate: "/blog/promo/promo_etf" },
  //   { image: promoMargin, navigate: "/blog/promo/promo_margin" },
  //   { image: slider, navigate: "/kisimobile" },
  // ];

  useEffect(() => {
    // console.log(ytApikey);
    if (sliderArr.length == 0) {
      // console.log("kosong");
      dispatch(getPromos())
        .then(async (result) => {
          // console.log("hasil", result);
          await setSliderArr(result.concat(offlineSlider));
          setIsSliderLoaded(true);
          // console.log(offlineSlider.concat(result));
          // console.log(sliderArr);
          // console.log(sliderArr, "arai");
        })
        .catch((err) => {});
    }

    getEvents();

    // setSliderArr(offlineSlider);
    // if (window.innerWidth <= 768) {
    //   setSliderArr([
    //     { isOffline: true, image: kisipoin, ext_link: "https://www.kisipoin.com" },
    //     { isOffline: true, image: payday_2,navigate:"/blog/promo/event_payday_november23" },
    //     { isOffline: true, image: promoReksadana },
    //     { image: promoEtfMobile, navigate: "/promo/promo_etf" },
    //     { image: promoMarginMobile, navigate: "/blog/promo/promo_margin" },
    //     { image: sliderMobile, navigate: "/kisimobile" },
    //   ]);
    //   setIsSliderLoaded(true);
    // }
    // setIsSliderLoaded(true);
    //     const container = document.querySelector('.iframe-container')
    // const video = container.querySelector('iframe')
    // const videoAspectRatio = video.clientWidth / video.clientHeight

    // video.style.height = `${container.clientHeight}px`
    // video.style.width = `${container.clientWidth * videoAspectRatio}px`

    Aos.init({ duration: 2000 });
    window.scrollTo(0, 0);
    // dispatch(getNews());

    dispatch(setActiveMenu("Home"));

    // console.log(window.innerWidth, "xax");
    // let slideIndicator = document.getElementById("slide" + slideIndex);
    // if(slideIndicator){
    //   document.getElementById('slide'+slideIndex).className += " slideIndicatorActive";
    // }
    if (window.innerWidth <= 768) {
      // console.log("1200");
      opts.width = "90%";
      // console.log(opts);

      for (let i = 0; i < eventArr.length; i++) {
        const element = eventArr[i];
        for (const key in element) {
          // console.log(key);
        }
      }
    }
  }, []);
  const getEvents = () => {
    // console.log('gagagagb');
    fetch(`${server}/api/v1/web/events`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      // body: JSON.stringify(file),
      headers: {
        "Content-Type": "application/json",
        // access_token: localStorage.getItem("access_token"),
      },
    })
      .then((res) => res.json())
      .then(async (events) => {
        // console.log(events, "promox");
        setEventArr(events.data.concat(eventArrOffline));
      })

      .catch((err) => {
        // console.log(err);
      });
  };
  const goTo = (route) => {
    navigate(route);
  };
  async function showDivs(n) {
    var i;
    var x = document.getElementsByClassName("slides");
    if (n > x.length) {
      setSlideIndex(1);
    }
    if (n < 1) {
      setSlideIndex(x.length);
    }
    for (i = 0; i < x.length; i++) {
      x[i].style.display = "none";
    }
    x[slideIndex - 1].style.display = "block";
    // setSlideIndex(n);
  }
  const testi = [
    {
      source: testi1,
      content: {
        date: "04.29.2020",
        desc: "Behind the leaves. ",
      },
    },
    {
      source: testi2,
      content: {
        date: "04.28.2020",
        desc: "Minimal eucalyptus leaves",
      },
    },
    {
      source: testi3,
      content: {
        date: "04.28.2020",
        desc: "Rubber Plant",
      },
    },
    {
      source: testi4,
      content: {
        date: "04.27.2020",
        desc: "Person holding leaf plant",
      },
    },
    {
      source: testi5,
      content: {
        date: "04.23.2020",
        desc: "Green leafed plant photography",
      },
    },
  ];
  const langkahPrev = (e) => {
    swiper3.slidePrev();
  };
  const langkahNext = (e) => {
    swiper3.slideNext();
  };
  const testiPrev = (e) => {
    swiper4.slidePrev();
  };
  const testiNext = (e) => {
    // console.log("xnxx");
    swiper4.slideNext();
  };
  const slideChange = (e) => {
    setSlideIndex(e.realIndex);
  };
  const ngobrolSlideChange = (e) => {
    setSlideIndex2(e.realIndex);
  };
  const slideChangeLangkah = (e) => {
    setSlideIndex3(e.realIndex);
  };
  return (
    <div className="homePage">
      {/* {JSON.stringify(sliderArr.length)} */}  
      <div
        className={`landingContainer ${loaded ? "o1" : "o0"}`}
        style={{ backgroundImage: `url(${landing})` }}
        // onLoad={() => {
        //   setImageLoaded(true);
        // }}
      >
        <div className="landingContent">
        <div className="landingButtonContainer mt-4 none-desktop" style={{paddingBottom:'1.2rem'}}>
            <button
              className="landingButton monsterratBlack rounded-pill px-4 py-1 nowrap"
              onClick={() => goTo("/kisimobile")}
            >
           Pendaftaran Melalui KISI Mobile
            </button>
            <button
              className="landingButton monsterratBlack rounded-pill px-4 py-1 nowrap landingB2"
              onClick={() => openInNewTab("https://registration.kisi.co.id")}
            >
           Pendaftaran Melalui Web Registration
            </button>
          </div>
          <div className="monsterratBlack white landingBigText nowrap">
            {" "}
            Berinvestasi <br></br> Menjadi Lebih{" "}
            {window.offsetWidth > 768 ? null : <br className="brMobile"></br>}
            Mudah
          </div>
          <div className="monsterratMedium nowrap white landingSmallText mt-3">
          Semuanya <i>Full Online!</i>
          </div>
          <div className="landingButtonContainer mt-4 none-mobile">
            <button
              className="landingButton monsterratBlack rounded-pill px-4 py-1 nowrap"
              onClick={() => goTo("/kisimobile")}
            >
           Pendaftaran Melalui KISI Mobile
            </button>
            <button
              className="landingButton monsterratBlack rounded-pill px-4 py-1 nowrap landingB2"
              onClick={() => openInNewTab("https://registration.kisi.co.id")}
            >
           Pendaftaran Melalui Web Registration
            </button>
          </div>
        </div>
      </div>
      <div className="kenapaWrapper">
        <div className="kenapaRightContainer">
          <div className="monsterratExtraBold grey kenapaBigText textCenter blue-text-1">
            <>Kenapa Memilih Kami?</>
            <div className="f12r monsterratMedium grey mx-auto text-center">
              Empat hal yang Kami berikan untuk Anda
            </div>
          </div>

          <div className="kenapaImageWrapper">
            <div className="kenapaImageContainer  textCenter">
              <div className="kenapaSmallWrapper  kenapaLeft">
                <img className="kenapaImg" src={aman} />
                <p className="kenapaSmallText monsterratBold">Aman</p>
                <div className="reksadanaDescriptionContainer descriptionHome">
                  <div className="jago-syariah triangle"></div>
                  <div className="reksadanaDescription py-3 px-4">
                    <div className="blue-text-1 f12r monsterratSemiBold">
                      Aman
                    </div>
                    <div className="f09r grey monsterratMedium">
                      Untuk mencapai keamanan yang optimal, kami mengadopsi
                      strategi dan praktik yang efektif dalam mencegah,
                      mendeteksi, dan merespon terhadap berbagai ancaman. Ini
                      mencakup penerapan kebijakan keamanan informasi yang
                      ketat, seperti perlindungan data dan privasi, pemantauan
                      kegiatan karyawan, dan penegakan standar keamanan IT yang
                      ketat.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="kenapaImageContainer  textCenter">
              <div className="kenapaSmallWrapper kenapaRight">
                <img className="kenapaImg" src={inovasi} />
                <p className="kenapaSmallText monsterratBold">Inovasi</p>
                <div className="reksadanaDescriptionContainer descriptionHome">
                  <div className="jago-syariah triangle"></div>
                  <div className="reksadanaDescription py-3 px-4">
                    <div className="blue-text-1 f12r monsterratSemiBold">
                      Inovasi
                    </div>
                    <div className="f09r grey monsterratMedium">
                      Kami terus melakukan strategi inovasi yang tepat dan
                      memastikan bahwa setiap inovasi yang diadopsi mematuhi
                      persyaratan hukum, etika, dan privasi yang berlaku. Kami
                      mempertimbangkan juga risiko yang mungkin terjadi terkait
                      dengan inovasi, seperti risiko keamanan cyber dan risiko
                      reputasi, serta memastikan bahwa mereka memenuhi standar
                      pengawasan dan regulasi yang berlaku.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="kenapaImageContainer kenapaBottom textCenter">
              <div className="kenapaSmallWrapper  kenapaLeft ">
                <img className="kenapaImg" src={integritas} />
                <p className="kenapaSmallText monsterratBold">Integritas</p>
                <div className="reksadanaDescriptionContainer descriptionHome">
                  <div className="jago-syariah triangle"></div>
                  <div className="reksadanaDescription py-3 px-4">
                    <div className="blue-text-1 f12r monsterratSemiBold">
                      Integritas
                    </div>
                    <div className="f09r grey monsterratMedium">
                      Kami terus berusaha dalam menjaga kerahasiaan informasi,
                      menghindari konflik kepentingan, serta mematuhi semua
                      peraturan dan persyaratan hukum yang berlaku. Kami terus
                      berusaha mengembangkan sistem pengawasan internal yang
                      efektif dan melakukan audit secara teratur untuk
                      memastikan kepatuhan terhadap standar integritas yang
                      telah ditetapkan.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="kenapaImageContainer  kenapaBottom textCenter">
              <div className="kenapaSmallWrapper kenapaRight">
                {" "}
                <img className="kenapaImg" src={profesional} />
                <p className="kenapaSmallText monsterratBold">Profesional</p>
                <div className="reksadanaDescriptionContainer descriptionHome">
                  <div className="jago-syariah triangle"></div>
                  <div className="reksadanaDescription py-3 px-4">
                    <div className="blue-text-1 f12r monsterratSemiBold">
                      Profesional
                    </div>
                    <div className="f09r grey monsterratMedium">
                      Kami terus mengembangkan dan memelihara hubungan yang baik
                      dengan nasabah, pemangku kepentingan, dan anggota tim
                      lainnya. Hal ini mencakup kemampuan untuk berkomunikasi
                      secara efektif, menunjukkan sikap yang ramah dan
                      menghargai orang lain, serta berkontribusi dalam membangun
                      lingkungan kerja yang inklusif dan kolaboratif.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flashNewsSliderContainer textCenter">
        <div className="f25r grey monsterratExtraBold blue-text-1">
          Informasi Terkini
        </div>
        <div className="sliderSmall monsterratMedium grey">
          Jangan sampai terlewatkan informasi terbaru dari PT Korea Investment
          And Sekuritas Indonesia!
        </div>
        <div className="swiperContainer">
          <Swiper
            speed={500}
            effect="crossfade"
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={1}
            // loop={true}
            // spaceBetween={500}
            // coverflowEffect={{
            //   rotate: 50,
            //   stretch: 0,
            //   depth: 100,
            //   modifier: 1,
            //   slideShadows: false,
            // }}
            fadeEffect={{
              crossFade: true, // enables slides to cross fade
            }}
            autoplay={{
              delay: "5000",
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            modules={[Autoplay]}
            pagination={true}
            className="mySwiper"
            onTransitionEnd={(e) => slideChange(e)}
            // navigation={true}
            onSwiper={(swiper) => setSwiper(swiper)}
          >
          
            {isSliderLoaded
              ? sliderArr.map((slide, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <div className="slideContainer">
                        <div className="slideFlashWrapper">
                          {slide.isOffline ? (
                            <img
                              onClick={() => {
                                if (slide.navigate) {
                                  navigate(slide.navigate);
                                } else if (slide.ext_link) {
                                  openInNewTab(slide.ext_link);
                                }
                              }}
                              className="slide-image  slider-image"
                              src={slide.image}
                              alt=""
                            />
                          ) : (
                            <>
                              <img
                                onClick={() => {
                                  if (slide.slug) {
                                    navigate("blog/promo/" + slide.slug);
                                  }else{
                                    openInNewTab(slide.ext_link);
                                  }
                                }}
                                className="slide-image  slider-image"
                                src={`${host}${storageLocation}${slide.image}`}
                                alt=""
                              />
                              {/* {slide.slug}KEHED */}
                            </>
                          )}
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })
              : null}
            <div className="flex mt-5 swiperPaginationDiv">
              <div className="flex mx-auto">
                {" "}
                {sliderArr.map((slide, i) => {
                  return (
                    <div
                      key={i}
                      onClick={(e) => swiperSlideTo(i)}
                      id={"slide" + i}
                      className={
                        +slideIndex == i
                          ? "slideIndicator rounded-pill mx-1 slideIndicatorActive"
                          : "slideIndicator rounded-pill mx-1"
                      }
                    ></div>
                  );
                })}
              </div>
            </div>
          </Swiper>
        </div>
      </div>

      <div className="biayaTransaksiContainer">
        <div className="biayaTransaksiWrapper rounded-corner">
          <div className="biayaTitleContainer">
            <div className="py-3 monsterratExtraBold biayaTitle blue-text-1">
              Biaya Transaksi
            </div>
          </div>
          <div className="biayaTransaksiSpacerCenter center-vertical">
            {" "}
            <div className="biayaTransaksiGridContainer ">
              <div className="biayaTransaksiGrid">
                <div className="monsterratMedium biayaSubtitle grey f15r">
                  Fee Beli
                </div>
                <div className="biayaPersen">0,13%</div>
              </div>
              <div>
                <div className="monsterratMedium biayaSubtitle grey f15r">
                  Fee Jual
                </div>
                <div className="biayaPersen">0,23%</div>
              </div>
            </div>
          </div>

          <div className="biayaBottomSection grey">
            <div className="bottomText">
              <span className="monsterratBlack">Keterangan:</span> <br></br>
              <span className="monsterratMedium">
                Biaya transaksi di atas yaitu bersifat promosi, untuk informasi
                lebih lanjut silakan menghubungi <i>Call Center</i> kami.
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="appText">
        <div className="monsterratExtraBold large grey  px-4 blue-text-1 mx-auto textCenter mw90768 f2r mt-5">
          Nikmati Kemudahan Dalam Berinvestasi
        </div>
        <div className="monsterratMedium f12r grey appHeaderText">
          Dua aplikasi yang memiliki fungsi yang berbeda
        </div>
      </div>

      <div className="appHomeContainer textCenter">
        {" "}
        <div className="appHome">
          <div className="center-vertical flexEnd appTextWrapper">
            <div className="appTextContainer textCenter bg-biru-muda rounded-corner  ">
              <div className=" pt-5 monsterratMedium  blue-text-1  appSubtitleDiv">
                <h5 className="appSubtitle">
                  Berinvestasi Menjadi Lebih Mudah
                </h5>
              </div>
              <div className=" pt-3 pb-2 mb-2 monsterratBold blue-text-1 appTextDiv">
                <h2 className="monsterratExtraBold">Download Aplikasi KOINS</h2>
                <div className="monsterratMedium f1r grey px-2 appCaption f12r">
                  Aplikasi KOINS memudahkan Anda dalam melakukan transaksi saham
                </div>
              </div>
              <div className="selengkapnyaButtonDiv py-3 mb-4">
                <button
                  className="selengkapnyaButton monsterratMedium py-1 px-5 "
                  onClick={(e) => goTo("/koins")}
                >
                  Selengkapnya
                </button>
              </div>
            </div>
          </div>
          <div className="appHomeImgContainer center-vertical">
            <img src={koinsHp} className="appImgHp" />
          </div>
        </div>
      </div>
      <div className="appHomeContainer textCenter appContainer2">
        <div className="appHome app2nd">
          <div className="appHomeImgContainer appHomeImg2 center-vertical ">
            <img src={kisiMobileHp} className="appImgHp" />
          </div>
          <div className="center-vertical flexEnd appTextWrapper appTextWrapper2">
            <div className="appTextContainer textCenter bg-biru-muda rounded-corner  textContainer2">
              <div className=" pt-5 monsterratMedium  blue-text-1  appSubtitleDiv">
                <h5 className="appSubtitle">
                  Membuka Rekening Menjadi Lebih Mudah
                </h5>
              </div>
              <div className="pt-3 pb-2 mb-2 monsterratBold blue-text-1 appTextDiv">
                <h2 className="monsterratExtraBold">
                  Download Aplikasi KISI Mobile
                </h2>
                <div className="monsterratMedium f1r grey px-2 appCaption f12r">
                  Memudahkan Anda dalam melakukan pendaftaran dan dapatkan
                  informasi seputar pasar modal
                </div>
              </div>
              <div className="selengkapnyaButtonDiv py-3 mb-4">
                <button
                  className="selengkapnyaButton monsterratMedium py-1 px-5 "
                  onClick={(e) => goTo("/kisimobile")}
                >
                  Selengkapnya
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="ngobrolBarengContainer">
        <div className="ngobrolbarengWrapper bg-biru-muda rounded-corner">
          <div className="ngobrolUpperWrapper">
            <div className="ngobrolImgContainer center-vertical">
              <img className="ngobrolImg" src={ngobrolKoins} />
            </div>
            <div className="ngobrolText grey monsterratMedium">
              <div>
                Program Ngobrol Bareng KOINS bertujuan untuk memudahkan Anda
                dalam memahami dan mengerti konsep investasi. Program ini
                menghadirkan berbagai ahli di bidang Pasar Modal sehingga tidak
                ada alasan untuk tidak berinvestasi dan tidak mengikuti program
                ini. Yuk, jangan sampai ketinggalan!
              </div>
            </div>
          </div>
          <div className="swiperContainerNgobrol ">
            <Swiper
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={false}
              slidesPerView={3}
              loop={true}
              coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: false,
              }}
              breakpoints={{
                1024: {
                  slidesPerView: 3,
                },
                768: {
                  slidesPerView: 2,
                },
                200: {
                  slidesPerView: 1,
                },
              }}
              autoplay={{
                delay: "5000",
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              modules={[Autoplay]}
              pagination={true}
              className="swiperNgobrol"
              onTransitionEnd={(e) => ngobrolSlideChange(e)}
              // navigation={true}
              onSwiper={(swiper) => setSwiper2(swiper)}
            >
              {eventArr.map((slide, i) => {
                return (
                  <SwiperSlide key={i}>
                    <div className="ngobrolSlideContainer">
                     {slide.isOffline ?  <img
                        className="slide-image-ngobrol"
                        src={slide.image}
                        alt=""
                        onClick={() => {
                          openInNewTab(slide.link_event);
                        }}
                      />: <img
                      className="slide-image-ngobrol"
                      src={host+storageLocation+slide.image}
                      alt=""
                      onClick={() => {
                        openInNewTab(slide.link_event);
                      }}
                    />}
                    </div>
                  </SwiperSlide>
                );
              })}
              <div className="flex mt-5 swiperPaginationDiv2">
                <div className="flex mx-auto ngobrolControl">
                  {window.innerWidth > 768
                    ? eventArr.map((slide, i) => {
                        return (
                          <div
                            key={i}
                            onClick={(e) => swiperNgobrolSlideTo(i + 3)}
                            id={"slide" + i}
                            className={
                              slideIndex2 == i
                                ? "ngobrolSlideIndicator  mx-1 rounded-pill ngobrolSlideIndicatorActive my-auto"
                                : "ngobrolSlideIndicator  mx-1 rounded-pill my-auto"
                            }
                          ></div>
                        );
                      })
                    : null}
                </div>
              </div>
            </Swiper>
          </div>
        </div>
      </div> */}
      <div className="kisiTalkContainer">
        <div className="kisiTalkWrapper">
          <img src={kisiTalk} className="kisiTalkImg" />
          <div className="monsterratMedium grey mt-2 mb-4 f12r mw70768 mx-auto">
            Program KISI TALK untuk Anda yang ingin belajar dari ahlinya.
            {/* {JSON.stringify(eventArr)} */}
          </div>
          <div className="iframeKoinsContainer  center-vertical iframeHome">
            <iframe
              width="100%"
              height={window.innerHeight > 768 ? "auto" : "500px"}
              src="https://www.youtube.com/embed/9I7NjzDhI5o"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              id="yt-koins"
              className="iframe-koins iframe-main-playlist"
            ></iframe>

            <div className="ytOtherContainer">
              <YouTubePlaylist
                apiKey={ytApikey}
                playlistId="PLsb29ECrPaO-xW2Rz40Z9SFo8V4nx18RJ"
                uniqueName="youtube-playlist-container"
                className="koinsPlaylist"
              />
            </div>
          </div>
        </div>
      </div>
      {/* slider section */}
      <div className="sliderContainer">
        {/* <Slider sliderSettings={sliderSettings} /> */}
      </div>

      <div className="appSliderContianer">
        {" "}
        <div className="mx-auto monsterratExtraBold mw80768 f2r grey textCenter  blue-text-1">
          Cara Membuka<br className="brMobile"></br> Akun Reguler
        </div>
        <div className="monsterratMedium mw70768 mx-auto text-center grey f12r mb-5 pb-4 langkahText">
          Langkah-langkah membuka akun melalui Aplikasi KISI Mobile
        </div>
        <div className="swiperContainer flex">
          <div className="appSpacerLeft  appSpacerHeight langkahSpacerApp center-vertical">
            <img
              className="sliderArrow  arrowLeft"
              onClick={(e) => langkahPrev(e)}
              src={arrow}
            ></img>
          </div>
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={false}
            slidesPerView={3}
            // loop={true}

            initialSlide={0}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: false,
            }}
            // autoplay={{
            //   delay: "5000",
            //   disableOnInteraction: false,
            //   pauseOnMouseEnter: true,
            // }}
            // allowSlidePrev={slideIndex3 == 1 ? false:true}
            modules={[Autoplay]}
            breakpoints={{
              1024: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 2,
              },
              200: {
                slidesPerView: 1,
              },
            }}
            pagination={true}
            className="swiperLangkah "
            onTransitionEnd={(e) => slideChangeLangkah(e)}
            // navigation={true}
            onSwiper={(swiper) => setSwiper3(swiper)}
          >
            {steps.map((slide, i) => {
              return (
                <SwiperSlide
                  key={i}
                  //  allowSlidePrev={false}
                >
                  <div className="slideWrapper">
                    <div
                      className="slideContainerLangkah"
                      id={`slide_langkah_${i}`}
                    >
                      <img
                        onLoad={() => arrowHeight()}
                        className="slideLangkahImg"
                        onClick={() => {
                          if (slide.navigate) {
                            navigate(slide.navigate);
                          }
                        }}
                        src={slide.source}
                        alt=""
                      />
                    </div>
                    <div className="infos">
                      <div className="appSliderInfoContainer">
                        <div className="blue-text-1 monsterratBlack mb-1">
                          STEP {i + 1}
                        </div>
                        <div
                          className="monsterratMedium stepDesc"
                          dangerouslySetInnerHTML={{
                            __html: slide.content.desc,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div className="appSpacerRight  appSpacerHeight langkahSpacerApp center-vertical">
            {" "}
            <img
              className="sliderArrow  arrowRight"
              onClick={(e) => langkahNext(e)}
              src={arrow}
            ></img>
          </div>
        </div>
      </div>
      <div className="TestiSliderContianer">
        {" "}
        <div className="mx-auto monsterratExtraBold mt-5 pt-5 f2r grey textCenter blue-text-1">
          Apa Kata Mereka?
        </div>{" "}
        <div
          className="mx-auto f12r monsterratMedium grey textCenter mb-5 sliderSmall"
          style={{ width: "90%" }}
        >
          Kami telah dipercaya oleh ribuan pengguna dari berbagai kalangan
        </div>{" "}
        <div className="swiperContainer flex">
          <div className="appSpacerLeft center-vertical appSpacerHeight te testiSpacer">
            <img
              className="sliderArrow testiSpacer  arrowLeft"
              onClick={(e) => testiPrev(e)}
              src={arrow}
            ></img>
          </div>
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={false}
            slidesPerView={3}
            // loop={true}

            initialSlide={1}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: false,
            }}
            breakpoints={{
              1024: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 3,
              },
              200: {
                slidesPerView: 1,
              },
            }}
            // autoplay={{
            //   delay: "5000",
            //   disableOnInteraction: false,
            //   pauseOnMouseEnter: true,
            // }}
            // allowSlidePrev={slideIndex3 == 1 ? false:true}
            modules={[Autoplay]}
            pagination={true}
            className="swiperLangkah swiperTesti"
            onTransitionEnd={(e) => slideChangeLangkah(e)}
            // navigation={true}
            onSwiper={(swiper) => setSwiper4(swiper)}
          >
            {testi.map((slide, i) => {
              return (
                <SwiperSlide
                  key={i}
                  // allowSlidePrev={false}
                >
                  <div className="slideWrapper">
                    <div className="slideContainerLangkah">
                      <img
                        className="slideLangkahImg"
                        onClick={() => {
                          if (slide.navigate) {
                            navigate(slide.navigate);
                          }
                        }}
                        src={slide.source}
                        alt=""
                      />
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div className="appSpacerRight center-vertical appSpacerHeight te testiSpacer">
            {" "}
            <img
              className="sliderArrow testiSpacer  arrowRight"
              onClick={(e) => testiNext(e)}
              src={arrow}
            ></img>
          </div>
        </div>
      </div>
      <div className="faqDiv">
        <div className="faqText">
          <div className="monsterratExtraBold f2r mx-auto  mw70768 grey textCenter blue-text-1 f2r">
            Daftar Pertanyaan yang Sering Diajukan
          </div>
          <div className="monsterratMedium f12r  mw70768 grey textCenter mt-2 mb-4 sliderSmall">
            Pertanyaan yang sering ditanyakan kepada kami
          </div>
        </div>
        <div className="faqContainer">
          <div className="faqWrapper">
            <div className="faqTitle ">
              <div className="center-vertical">
                <div className=" monsterratBold grey faqQuestion">
                  Bagaimana cara membuka rekening?
                </div>
              </div>
              <button className="faqIndicator">
                <span></span>
                <span></span>
              </button>
            </div>
            <div className="faqAnswerContainer flex">
              <div className="faqAnswer monsterratMedium grey ">
                <div className="flex faq2">
                  <div className="w70 faqWithButton">
                    Menyiapkan dokumen berupa E-KTP yang telah terverifikasi
                    (DUKCAPIL), Nomor Rekening Bank Pribadi, NPWP (jika ada).
                    Kemudian <i>download</i> Aplikasi KISI Mobile di Play Store
                    atau App Store, setelah itu ikuti instruksi registrasi
                    sampai pendaftaran berhasil.
                  </div>
                  <div className="center-vertical pelajariFaq">
                    <button
                      className="pelajariButton pelajariFaqButton rounded-pill  monsterratBold"
                      onClick={() => {
                        navigate("/kisimobile");
                      }}
                    >
                      PELAJARI
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="faqContainer">
          <div className="faqWrapper">
            <div className="faqTitle ">
              <div className="">
                <div className=" monsterratBold grey faqQuestion">
                  Apa yang harus dilakukan jika gagal dalam pendaftaran?
                </div>
              </div>
              <button className="faqIndicator">
                <span></span>
                <span></span>
              </button>
            </div>
            <div className="faqAnswerContainer">
              <div className="faqAnswer monsterratMedium grey">
                Anda bisa menghubungi Call Center kami ke nomor WhatsApp
                Official Business Account kami{" "}
                <a
                  href="https://wa.me/628151881911"
                  className="text-none blue-text-1"
                  target="_blank"
                >
                  +62 815-1881-911
                </a>{" "}
                (verified/centang biru) atau email ke{" "}
                <a
                  className="text-none blue-text-1"
                  href="mailto:cc@kisi.co.id"
                >
                  cc@kisi.co.id
                </a>
                . Pesan Anda akan dibalas pada saat jam kerja bursa, yaitu hari
                Senin s.d. Jumat, jam 08.30 s.d. 17.00 WIB.
              </div>
            </div>
          </div>
        </div>
        <div className="faqContainer">
          <div className="faqWrapper">
            <div className="faqTitle ">
              <div className="">
                <div className=" monsterratBold grey faqQuestion">
                  Berapa minimal deposit?
                </div>
              </div>
              <button className="faqIndicator">
                <span></span>
                <span></span>
              </button>
            </div>
            <div className="faqAnswerContainer">
              <div className="faqAnswer monsterratMedium grey flex faq2">
                <div className="w70 faqWithButton">
                  <ul>
                    {/* <li className="flex pt-2 center-vertical">
                      <li className=" ">
                        Reguler Akun Minimal Deposit Rp100 ribu
                      </li>{" "}
                    </li>
                    <li className="flex pt-2 center-vertical">
                      <li className="">
                        {" "}
                        Syariah Akun Minimal Deposit Rp100 ribu
                      </li>{" "}
                    </li> */}
                    <li className="flex pt-2 center-vertical">
                      <li className="">
                        {" "}
                        Margin Akun Minimal Deposit Rp200 juta{" "}
                      </li>{" "}
                    </li>
                  </ul>
                </div>
                <div className="center-vertical pelajariFaq">
                  <button
                    className="pelajariButton pelajariFaqButton rounded-pill  monsterratBold"
                    onClick={() => {
                      navigate("/produk/saham");
                    }}
                  >
                    PELAJARI
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="faqContainer">
          <div className="faqWrapper">
            <div className="faqTitle ">
              <div className="">
                <div className=" monsterratBold grey faqQuestion">
                  Apakah Korea Investment And Sekuritas Indonesia aman?
                </div>
              </div>
              <button className="faqIndicator">
                <span></span>
                <span></span>
              </button>
            </div>
            <div className="faqAnswerContainer">
              <div className="faqAnswer monsterratMedium grey">
                Kami Korea Investment And Sekuritas Indonesia telah terdaftar &
                diawasi oleh &nbsp;
                <b
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    openInNewTab(
                      "https://www.ojk.go.id/id/kanal/pasar-modal/data-dan-statistik/data-perusahaan-efek/Default.aspx"
                    );
                  }}
                >
                  Otoritas Jasa Keuangan (OJK).
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FollowSocmed />
    </div>
  );
}
