import "./style.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import superhero from "../../assets/superhero.png";
import strukturOrganisasiDetail from "../../assets/strukturOrganisasiDetail.jpg";

import ijinUsaha from "../../assets/ijinUsaha.png";
import visiMisi from "../../assets/visiMisi.png";
import ijinUsahaDocument from "../../assets/ijinUsahaDocument.pdf";
import piagamDireksi from "../../assets/piagamDireksi.pdf";
import piagamKomisaris from "../../assets/piagamKomisaris.pdf";
import piagamKepatuhan from "../../assets/piagamKepatuhan.pdf";
import pedomanGood from "../../assets/pedomanGood.pdf";
import pedomanManRisk from "../../assets/pedomanManRisk.pdf";
import download from "../../assets/download.png";
import kodeEtikDocument from "../../assets/kodeEtikDocument.pdf";
import ceo from "../../assets/ceo.png";
import visiBg from "../../assets/visiBg.jpg";
import laporan2020 from "../../assets/laporan2020.pdf";
import DownloadBanner from "../../components/DownloadBanner";
import PageLocation from "../../components/PageLocation";
import YouTube, { YouTubeProps } from "react-youtube";
import { useNavigate, useParams } from "react-router-dom";
import { LaporanTahunan } from "../LaporanTahunan";
import { Lokasi } from "../Lokasi";
import sangyup from "../../assets/sangyup.png";
import jongin from "../../assets/jongin.png";
import monang from "../../assets/monang.png";
import kyounghun from "../../assets/kyounghun.png";
import anSongIl from "../../assets/anSongIl.png";
import feryantoBoentaran from "../../assets/feryantoBoentaran.png";
import yongWonCho from "../../assets/yongWonCho.png";
import { setActiveMenu } from "../../store/action";
import Aos from "aos";
export function TentangKami(props) {
  let { page } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onPlayerReady: YouTubeProps["onReady"] = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };
  const [pimpinanPage, setPimpinanPage] = useState("direksi");
  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init({ duration: 2000 });
    if (page == "karir") {
      window.scrollTo(0, 0);
    }
    dispatch(setActiveMenu("tentangKami"));
    let allButton = document.getElementsByClassName("aboutButton");
    for (let i = 0; i < allButton.length; i++) {
      const element = allButton[i];
      // console.log(element.className);
      if (element.className !== page) {
        element.className =
          "aboutButton monsterratMedium rounded-pill f1r panduanSubPath mx-4 px-5";
      }
    }
    document.getElementById(page).className += " aboutButtonActive";

    if (document.getElementById(pimpinanPage)) {
      document.getElementById(pimpinanPage).className += " buttonProfileActive";
    }
    // if (document.getElementById(pimpinanPage)) {
    //   switch (pimpinanPage) {
    //     case "direksi":
    //       document.getElementById("direksi").className +=
    //         " buttonProfileActive";
    //       document.getElementById("komisaris").className =
    //         "profileButton monsterratMedium rounded-pill grey ";
    //       break;

    //     case "komisaris":
    //       console.log("swituseG");
    //       document.getElementById("komisaris").className +=
    //         " buttonProfileActive";
    //       document.getElementById("direksi").className =
    //         "profileButton monsterratMedium rounded-pill grey ";
    //       break;

    //     default:
    //       break;
    //   }
    // }

    // console.log([{ nama: "gugun" }, { nama: "kodir" }]);
    // const alamat = document.getElementsByClassName("branchAddress");
    // console.log(alamat[0].innerHTML);
    // let temp = [];
    // for (let i = 0; i < alamat.length; i++) {
    //   const element = alamat[i];
    //   // document.getElementsByClassName("branchCity")[i]
    //   let obj = {
    //     kota: document.getElementsByClassName("branchCity")[i].innerHTML,
    //     alamat: element.innerHTML,
    //   };
    //   temp.push(obj);
    // }
    // console.log(temp);
  }, [page]);
  const goTo = (route) => {
    // console.log("masuk");
    navigate(route);
  };
  function openInNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }
  const opts = {
    height: "720",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  const handleSelect = (value) => {
    // console.log(value);
    const parsed = JSON.parse(value);

    if (parsed.action == "navigate") {
      navigate(parsed.value);
    } else {
      switch (parsed.value) {
        case "Kode Etik":
          return openInNewTab(kodeEtikDocument);
        case "Laporan Tahunan":
          return openInNewTab(laporan2020);
        case "Izin Usaha":
          return openInNewTab(ijinUsahaDocument);
        case "Piagam Dewan Komisaris":
          return openInNewTab(piagamKomisaris);
        case "Piagam Dewan Direksi":
          return openInNewTab(piagamDireksi);
        case "Piagam Kepatuhan":
          return openInNewTab(piagamKepatuhan);
        case "Pedoman Manajemen Resiko":
          return openInNewTab(pedomanManRisk);
        case "Pedoman Good Corporate Governance":
          return openInNewTab(pedomanGood);
        default:
          break;
      }
    }
  };
  const Timeline = () => {
    return (
      <>
        <div class="timelineContainer">
          <div class="grey monsterratExtraBold profileTitle f25r blue-text-1 text-center">
            Perjalanan Tentang <br></br> PT. Korea Investment And{" "}
            <br class="brMobile"></br>Sekuritas Indonesia
          </div>
          <div class="timelineCircle"></div>
          <div class="timelineWrapper flex">
            <div class="timelineLeft">
              <div class="yearLeftWrapper"></div>
              <div class="yearLeftWrapper flex halfLeft">
                <div class=" px-4 monsterratBold blue-text-1 f25r flowFlexDiv   center-vertical">
                  1989
                  <div></div>
                </div>
                <div class=" px-4 monsterratBold blue-text-1 f13r flowFlexContainerSpacer timelineHalfSpacer"></div>
              </div>
              <div class="yearLeftWrapper  timelineLeftDescription grey f1r monsterratMedium">
                Pendirian&nbsp;PT. <b>Amcol Securindo</b>
              </div>
              <div class="yearLeftWrapper"></div>
              <div class="yearLeftWrapper"></div>
              <div class="yearLeftWrapper flex halfLeft">
                <div class=" px-4 monsterratBold blue-text-1 f25r flowFlexDiv   center-vertical">
                  1996
                  <div></div>
                </div>
                <div class=" px-4 monsterratBold blue-text-1 f13r flowFlexContainerSpacer timelineHalfSpacer"></div>
              </div>
              <div class="yearLeftWrapper  timelineLeftDescription grey f1r monsterratMedium">
                Mengganti nama menjadi&nbsp;PT. <b>Danpac Sekuritas</b>
              </div>
              <div class="yearLeftWrapper"></div>
              <div class="yearLeftWrapper"></div>
              <div class="yearLeftWrapper flex halfLeft">
                <div class=" px-4 monsterratBold blue-text-1 f25r flowFlexDiv   center-vertical">
                  2006
                  <div></div>
                </div>
                <div class=" px-4 monsterratBold blue-text-1 f13r flowFlexContainerSpacer timelineHalfSpacer"></div>
              </div>
              <div class="yearLeftWrapper  timelineLeftDescription grey f1r monsterratMedium">
                Meluncurkan aplikasi trading&nbsp;<b>BQ Online</b>
              </div>
              <div class="yearLeftWrapper"></div>
              <div class="yearLeftWrapper"></div>
              <div class="yearLeftWrapper flex halfLeft">
                <div class=" px-4 monsterratBold blue-text-1 f25r flowFlexDiv   center-vertical">
                  2018
                  <div></div>
                </div>
                <div class=" px-4 monsterratBold blue-text-1 f13r flowFlexContainerSpacer timelineHalfSpacer"></div>
              </div>
              <div class="text-right yearLeftWrapper  timelineLeftDescription grey f1r monsterratMedium">
                <div>
                  <b>
                    Korea Investment & Securities Co. Ltd<br></br>
                  </b>
                  mengakuisisi 75% saham danpac sekuritas<br></br> perubahan
                  nama menjadi <br></br>
                  <b>Korea Investment And Sekuritas Indonesia (KISI)</b>
                </div>
              </div>
              <div class="yearLeftWrapper"></div>
              <div class="yearLeftWrapper"></div>
              <div class="yearLeftWrapper flex halfLeft">
                <div class=" px-4 monsterratBold blue-text-1 f25r flowFlexDiv   center-vertical">
                  2019
                  <div></div>
                </div>
                <div class=" px-4 monsterratBold blue-text-1 f13r flowFlexContainerSpacer timelineHalfSpacer"></div>
              </div>
              <div class="text-right yearLeftWrapper  timelineLeftDescription grey f1r monsterratMedium">
                <div>
                  Pembentukan<br></br>
                  <b>KISI Asset Management (KISI-AM)</b>
                </div>
              </div>
            </div>
            <div class="yearLeftWrapper"></div>{" "}
            <div class="yearLeftWrapper"></div>
            <div class="timelineRight">
              <div class="yearLeftWrapper"></div>
              <div class="yearLeftWrapper"></div>
              <div class="yearLeftWrapper"></div>
              <div class="yearLeftWrapper flex ">
                <div class=" px-4 monsterratBold blue-text-1 f13r flowFlexContainerSpacer timelineHalfSpacer"></div>
                <div class=" px-4 monsterratBold blue-text-1 f25r flowFlexDiv   center-vertical">
                  1994
                </div>
              </div>
              <div class="yearLeftWrapper timelineRightDescription grey f1r monsterratMedium">
                Mengganti nama menjadi&nbsp;PT. <b>Gading Sekuritas</b>
              </div>
              <div class="yearLeftWrapper"></div>
              <div class="yearLeftWrapper"></div>
              <div class="yearLeftWrapper flex ">
                <div class=" px-4 monsterratBold blue-text-1 f13r flowFlexContainerSpacer timelineHalfSpacer"></div>
                <div class=" px-4 monsterratBold blue-text-1 f25r flowFlexDiv   center-vertical">
                  2000
                </div>
              </div>
              <div class="yearLeftWrapper   timelineRightDescription grey f1r monsterratMedium">
                Menerima formulir izin penjamin emisi dari<br></br>
                <b>Bapepam/LK</b>
              </div>
              <div class="yearLeftWrapper"></div>
              <div class="yearLeftWrapper"></div>
              <div class="yearLeftWrapper flex ">
                <div class=" px-4 monsterratBold blue-text-1 f13r flowFlexContainerSpacer timelineHalfSpacer"></div>
                <div class=" px-4 monsterratBold blue-text-1 f25r flowFlexDiv   center-vertical">
                  2014
                </div>
              </div>
              <div class="yearLeftWrapper   timelineRightDescription grey f1r monsterratMedium">
                <b>Korea Investment & Securities Co. Ltd</b>
                <br></br>
                membuka kantor perwakilan di Indonesia
              </div>
              <div class="yearLeftWrapper"></div>
              <div class="yearLeftWrapper"></div>
              <div class="yearLeftWrapper flex ">
                <div class=" px-4 monsterratBold blue-text-1 f13r flowFlexContainerSpacer timelineHalfSpacer"></div>
                <div class=" px-4 monsterratBold blue-text-1 f25r flowFlexDiv   center-vertical">
                  2019
                </div>
              </div>
              <div class="yearLeftWrapper   timelineRightDescription grey f1r monsterratMedium">
                Meluncurkan aplikasi <b>KOINS</b> untuk melakukan
                <br></br>
                transaksi jual beli saham secara online
              </div>
              <div class="yearLeftWrapper"></div>
              <div class="yearLeftWrapper"></div>
              <div class="yearLeftWrapper flex ">
                <div class=" px-4 monsterratBold blue-text-1 f13r flowFlexContainerSpacer timelineHalfSpacer"></div>
                <div class=" px-4 monsterratBold blue-text-1 f25r flowFlexDiv   center-vertical">
                  2022
                </div>
              </div>
              <div class="yearLeftWrapper   timelineRightDescription grey f1r monsterratMedium">
                Suntikan Modal Baru <b>MKBD > IDR 700bn</b>
              </div>
              <div class="yearLeftWrapper"></div>
            </div>
          </div>
          <div class="timelineCircle"></div>
        </div>
        <div class="grey monsterratExtraBold titleMobile profileTitle f25r blue-text-1 text-center">
          Perjalanan Tentang <br></br>PT. Korea Investment And{" "}
          <br class="brMobile"></br>Sekuritas Indonesia
        </div>
        <div class="timelineMobileContainer">
          <div class="w100">
            <div class="timelineCircleMobile"></div>
            <div class="timelineMobileLeft"></div>
            <div class="timelineCircleMobile"></div>
          </div>
          <div class="timelineMobileRight">
            <div class="mobileHalfSpacer"></div>
            <div class="mobileBranchAndYear">
              <div class="h100">
                <div class="mobileBranch"></div>
              </div>

              <div class="center-vertical monsterratExtraBold blue-text-1 f3r mx-3">
                1989
              </div>
            </div>
            <div class="mobileTimelineText monsterratMedium f09r grey">
              {" "}
              Pendirian&nbsp;PT. <b>Amcol Securindo</b>
            </div>
            <div class="mobileBranchAndYear">
              <div class="h100">
                <div class="mobileBranch"></div>
              </div>

              <div class="center-vertical monsterratExtraBold blue-text-1 f3r mx-3">
                1994
              </div>
            </div>
            <div class="mobileTimelineText monsterratMedium f09r grey">
              {" "}
              Mengganti nama menjadi&nbsp;PT. <b>Gading Sekuritas</b>
            </div>{" "}
            <div class="mobileBranchAndYear">
              <div class="h100">
                <div class="mobileBranch"></div>
              </div>

              <div class="center-vertical monsterratExtraBold blue-text-1 f3r mx-3">
                1996
              </div>
            </div>
            <div class="mobileTimelineText monsterratMedium f09r grey">
              {" "}
              Mengganti nama menjadi&nbsp;PT. <b>Danpac Sekuritas</b>
            </div>{" "}
            <div class="mobileBranchAndYear">
              <div class="h100">
                <div class="mobileBranch"></div>
              </div>

              <div class="center-vertical monsterratExtraBold blue-text-1 f3r mx-3">
                2000
              </div>
            </div>
            <div class="mobileTimelineText monsterratMedium f09r grey">
              {" "}
              Menerima formulir izin penjamin emisi dari<br></br>
              <b>Bapepam/LK</b>
            </div>{" "}
            <div class="mobileBranchAndYear">
              <div class="h100">
                <div class="mobileBranch"></div>
              </div>

              <div class="center-vertical monsterratExtraBold blue-text-1 f3r mx-3">
                2006
              </div>
            </div>
            <div class="mobileTimelineText monsterratMedium f09r grey">
              {" "}
              Meluncurkan aplikasi trading&nbsp;<b>BQ Online</b>
            </div>{" "}
            <div class="mobileBranchAndYear">
              <div class="h100">
                <div class="mobileBranch"></div>
              </div>

              <div class="center-vertical monsterratExtraBold blue-text-1 f3r mx-3">
                2014
              </div>
            </div>
            <div class="mobileTimelineText monsterratMedium f09r grey">
              {" "}
              <b>Korea Investment & Securities Co. Ltd</b>
              <br></br>
              membuka kantor perwakilan di Indonesia
            </div>{" "}
            <div class="mobileBranchAndYear">
              <div class="h100">
                <div class="mobileBranch"></div>
              </div>

              <div class="center-vertical monsterratExtraBold blue-text-1 f3r mx-3">
                2018
              </div>
            </div>
            <div class="mobileTimelineText monsterratMedium f09r grey">
              {" "}
              <b>
                Korea Investment & Securities Co. Ltd<br></br>
              </b>
              mengakuisisi 75% saham danpac sekuritas<br></br> perubahan nama
              menjadi <br></br>
              <b>Korea Investment And Sekuritas Indonesia (KISI)</b>
            </div>{" "}
            <div class="mobileBranchAndYear">
              <div class="h100">
                <div class="mobileBranch"></div>
              </div>

              <div class="center-vertical monsterratExtraBold blue-text-1 f3r mx-3">
                2019
              </div>
            </div>
            <div class="mobileTimelineText monsterratMedium f09r grey">
              {" "}
              meluncurkan aplikasi <b>KOINS</b> untuk melakukan
              <br></br>
              transaksi jual beli saham secara online
            </div>
            <div class="mobileBranchAndYear">
              <div class="h100">
                <div class="mobileBranch"></div>
              </div>

              <div class="center-vertical monsterratExtraBold blue-text-1 f3r mx-3">
                2019
              </div>
            </div>
            <div class="mobileTimelineText monsterratMedium f09r grey">
              Pembentukan<br></br>
              <b>KISI Asset Management (KISI-AM)</b>
            </div>
            <div class="mobileBranchAndYear">
              <div class="h100">
                <div class="mobileBranch"></div>
              </div>

              <div class="center-vertical monsterratExtraBold blue-text-1 f3r mx-3">
                2022
              </div>
            </div>
            <div class="mobileHalfSpacer monsterratMedium f09r grey">
              Suntikan Modal Baru <b>MKBD > IDR 700bn</b>
            </div>
          </div>
        </div>
      </>
    );
  };
  const StrukturOrganisasi = () => {
    window.scrollTo(0, 0);
    return (
      <div class="strukturOrganisasiWrapper">
        <div class="strukturOrganisasiContainer">
          <img
            class="strukturOrganisasiImg"
            src={strukturOrganisasiDetail}
          ></img>
        </div>
      </div>
    );
  };
  const changePage = (route) => {
    // console.log(route);
    // window.scrollTo(0, 0);
    navigate(route);
    // return <Navigate to="/tentangkami/global" />;
  };
  const TentangKamiButtons = () => {
    return (
      <div class="aboutButtonContainer">
        <div class="aboutButtonWrapper2 justify-content-center ">
          <div></div>
          <button
            id="profile"
            class="aboutButton monsterratMedium rounded-pill f1r"
            onClick={(e) => changePage("/tentangkami/profile")}
          >
            Profil Perusahaan
          </button>
          <button
            id="lokasi"
            class="aboutButton monsterratMedium rounded-pill f1r"
            onClick={(e) => changePage("/tentangkami/lokasi")}
          >
            Lokasi
          </button>
          <button
            id="karir"
            class="aboutButton monsterratMedium rounded-pill f1r"
            onClick={(e) => changePage("/tentangkami/karir")}
          >
            Karir
          </button>
          <button
            id="struktur_organisasi"
            class="aboutButton monsterratMedium rounded-pill f1r"
            onClick={(e) => changePage("/tentangkami/struktur_organisasi")}
          >
            Struktur Organisasi
          </button>
        </div>
        <div class="aboutButtonWrapper">
          <button
            id=""
            class="aboutButton monsterratMedium rounded-pill f1r"
            onClick={() => openInNewTab(ijinUsahaDocument)}
          >
            <div>Izin Usaha</div>

            <img class="downloadImgAbout" src={download} />
          </button>
          <button
            id="kode_etik"
            class="aboutButton monsterratMedium rounded-pill f1r"
            onClick={() => openInNewTab(kodeEtikDocument)}
          >
            Kode Etik
            <img class="downloadImgAbout" src={download} />
          </button>
          <button
            id="laporan_tahunan"
            class="aboutButton monsterratMedium rounded-pill f1r"
            onClick={() => openInNewTab(laporan2020)}
          >
            Laporan Tahunan
            <img class="downloadImgAbout" src={download} />
          </button>
          <button
            id=""
            className=" monsterratMedium  f1r piagamButton piagamBottom"
          >
            <div class="aboutMenuHover center-vertical">
              <div class="piagamText">
                Piagam <img class="downloadImgAbout" src={download} />
              </div>
            </div>
            <div class="piagamButtonHidden">
              <div
                class="tentangSubmenu"
                onClick={() => openInNewTab(piagamKomisaris)}
              >
                Piagam Dewan Komisaris
                <img class="downloadImgAbout" src={download} />
              </div>
              <div
                class="tentangSubmenu"
                onClick={() => openInNewTab(piagamDireksi)}
              >
                Piagam Dewan Direksi
                <img class="downloadImgAbout" src={download} />
              </div>
              <div
                class="tentangSubmenu"
                onClick={() => openInNewTab(piagamKepatuhan)}
              >
                Piagam Kepatuhan
                <img class="downloadImgAbout" src={download} />
              </div>
            </div>
            <div></div>
          </button>
          <button
            id="pedoman"
            className=" monsterratMedium  f1r piagamButton pedomanButton "
            onClick={() => openInNewTab(piagamDireksi)}
          >
            <div></div>
            <div class="piagamText">
              Pedoman <img class="downloadImgAbout" src={download} />
            </div>
            <div class="piagamButtonHidden">
              <div
                class="tentangSubmenu"
                onClick={() => openInNewTab(pedomanManRisk)}
              >
                Pedoman Manajemen Resiko
                <img class="downloadImgAbout" src={download} />
              </div>
              <div
                class="tentangSubmenu"
                onClick={() => openInNewTab(pedomanGood)}
              >
                Pedoman Good Corporate Governance
                <img class="downloadImgAbout" src={download} />
              </div>
            </div>
          </button>
        </div>
      </div>
    );
  };
  const Komisaris = () => {
    return (
      <div class="pimpinanTab">
        <div class="flex pimpinanTabWrapper">
          <div class="w100 mx-auto pimpinanTabWrapper">
            <div class="pimpinanWrapper">
              {/* <div class="pimpinanImgContainer mx-auto center-vertical">
                <img src={yongWonCho} class="pimpinanImg"></img>
              </div>
              <div class="blue-text-1 f13r monsterratBlack text-center">
                Yong Won Cho
              </div>

              <div class="grey f085r monsterratMedium text-center mt-1">
                Independent Commissioner
              </div> */}
              <div class="pimpinanImgContainer mx-auto center-vertical">
                <img src={sangyup} class="pimpinanImg"></img>
              </div>
              <div class="blue-text-1 f13r monsterratBlack text-center">
                Song Sangyup
              </div>

              <div class="grey f085r monsterratMedium text-center mt-1">
                President Commisioner
              </div>
            </div>
          </div>
        </div>
        <div class="flex blockMobile my-5 py-5">
          <div class="w50 wauto768 pimpinanWrapper flex flexEnd">
            <div class="pimpinanWrapper pimpinanLeft">
              <div class="pimpinanImgContainer mx-auto center-vertical">
                <img src={feryantoBoentaran} class="pimpinanImg"></img>
              </div>
              <div class="blue-text-1 f13r monsterratBlack text-center">
                Feryanto Boentaran
              </div>

              <div class="grey f085r monsterratMedium text-center mt-1">
                Commissioner
              </div>
              {/* <div class="pimpinanImgContainer mx-auto center-vertical">
                <img src={yongWonCho} class="pimpinanImg"></img>
              </div>
              <div class="blue-text-1 f13r monsterratBlack text-center">
                Yong Won Cho
              </div>

              <div class="grey f085r monsterratMedium text-center mt-1">
                Independent Commissioner
              </div> */}
            </div>
          </div>
          <div class="w50 wauto768 pimpinanWrapper flex flexStart">
            <div class="pimpinanWrapper pimpinanRight">
              <div class="pimpinanImgContainer mx-auto center-vertical">
                <img src={yongWonCho} class="pimpinanImg"></img>
              </div>
              <div class="blue-text-1 f13r monsterratBlack text-center">
                Yong Won Cho
              </div>

              <div class="grey f085r monsterratMedium text-center mt-1">
                Independent Commissioner
              </div>
            </div>
          </div>

          {/* <div class="w100 mx-auto pimpinanTabWrapper">
            <div class="pimpinanWrapper">
              <div class="pimpinanImgContainer mx-auto center-vertical">
                <img src={yongWonCho} class="pimpinanImg"></img>
              </div>
              <div class="blue-text-1 f13r monsterratBlack text-center">
                Yong Won Cho
              </div>

              <div class="grey f085r monsterratMedium text-center mt-1">
                Independent Commissioner
              </div>
            </div>
          </div> */}
        </div>
      </div>
    );
  };

  const Direksi = () => {
    return (
      <div class="pimpinanTab">
        <div class="flex pimpinanTabWrapper">
          <div class="w100 mx-auto pimpinanTabWrapper">
            <div class="pimpinanWrapper">
              <div class="pimpinanImgContainer mx-auto center-vertical">
                <img src={kyounghun} class="pimpinanImg"></img>
              </div>
              <div class="blue-text-1 f13r monsterratBlack text-center">
                Kyoung Hun Nam
              </div>

              <div class="grey f085r monsterratMedium text-center mt-1">
                President Director
              </div>
            </div>
          </div>
          {/* <div class="w50 pimpinanWrapper flex flexEnd">
            <div class="pimpinanWrapper pimpinanLeft">
              <div class="pimpinanImgContainer mx-auto center-vertical">
                <img src={sangyup} class="pimpinanImg"></img>
              </div>
              <div class="blue-text-1 f13r monsterratBlack text-center">
                Song Sangyup
              </div>

              <div class="grey f085r monsterratMedium text-center mt-1">
                President Director
              </div>
            </div>
          </div>
          <div class="w50 pimpinanWrapper flex flexStart">
            <div class="pimpinanWrapper pimpinanRight">
              <div class="pimpinanImgContainer mx-auto center-vertical">
                <img src={kyounghun} class="pimpinanImg"></img>
              </div>
              <div class="blue-text-1 f13r monsterratBlack text-center">
                Kyoung Hun Nam
              </div>

              <div class="grey f085r monsterratMedium text-center mt-1">
                Director
              </div>
            </div>
          </div> */}
        </div>
        <div class="flex my-5 py-5 pimpinanTabWrapper">
          <div class="w50 wauto768 pimpinanWrapper flex flexEnd">
            <div class="pimpinanWrapper pimpinanLeft">
              <div class="pimpinanImgContainer mx-auto center-vertical">
                <img src={monang} class="pimpinanImg"></img>
              </div>
              <div class="blue-text-1 f13r monsterratBlack text-center">
                Ir. Monang Silalahi
              </div>

              <div class="grey f085r monsterratMedium text-center mt-1">
                Director
              </div>
            </div>
          </div>
          <div class="w50 wauto768 pimpinanWrapper flex flexStart">
            <div class="pimpinanWrapper pimpinanRight">
              <div class="pimpinanImgContainer mx-auto center-vertical">
                <img src={jongin} class="pimpinanImg"></img>
              </div>
              <div class="blue-text-1 f13r monsterratBlack text-center">
                Jong In Hong
              </div>

              <div class="grey f085r monsterratMedium text-center mt-1">
                Director
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const tentangList = [
    {
      name: "Profil Perusahaan",
      id: "profile",
      type: "navigation",
      path: "/tentangkami/profile",
    },
    {
      name: "Lokasi",
      id: "lokasi",
      type: "navigation",
      path: "/tentangkami/lokasi",
    },
    {
      name: "Karir",
      id: "karir",
      type: "navigation",
      path: "/tentangkami/karir",
    },
    // {
    //   name: "Struktur Organisasi",
    //   id: "struktur_organisasi",
    //   type: "navigation",
    //   path: "/tentangkami/struktur_organisasi",
    // },
    // {
    //   name: "Izin Usaha",
    //   id: "syariah",
    //   type: "download",
    //   path: "/produk/syariah",
    // },
    // {
    //   name: "Kode Etik",
    //   id: "syariah",
    //   type: "download",
    //   path: "/produk/syariah",
    // },
    // {
    //   name: "Laporan Tahunan",
    //   id: "syariah",
    //   type: "download",
    //   path: "/produk/syariah",
    // },
    // {
    //   name: "Piagam",
    //   id: "syariah",
    //   type: "Download",
    //   path: "/produk/syariah",
    //   sub: [
    //     { name: "Piagam Dewan Komisaris", doc: piagamKomisaris },
    //     { name: "Piagam Dewan Direksi", doc: piagamDireksi },
    //     { name: "Piagam Kepatuhan", doc: piagamKepatuhan },
    //   ],
    // },
    // {
    //   name: "Pedoman",
    //   id: "syariah",
    //   type: "Download",
    //   path: "/produk/syariah",
    //   sub: [
    //     { name: "Pedoman Manajemen Resiko", doc: pedomanManRisk },
    //     { name: "Pedoman Good Corporate Governance", doc: pedomanGood },
    //   ],
    // },
  ];

  const handlePimpinanButton = (category) => {
    setPimpinanPage(category);
    if (document.getElementById(category)) {
      switch (category) {
        case "direksi":
          document.getElementById("direksi").className +=
            " buttonProfileActive";
          document.getElementById("komisaris").className =
            "profileButton monsterratMedium rounded-pill grey ";
          break;

        case "komisaris":
          // console.log("swituseG");
          document.getElementById("komisaris").className +=
            " buttonProfileActive";
          document.getElementById("direksi").className =
            "profileButton monsterratMedium rounded-pill grey ";
          break;

        default:
          break;
      }
    }
  };

  return (
    <div class="">
      <div class="videoPlayerWrapper">
        {/* <div class="videoPlayer pb-5">
          <YouTube videoId="vLHFzBZwrpE" opts={opts} onReady={onPlayerReady} />
        </div> */}
        <div class="video-container video-container-tentang">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/vLHFzBZwrpE"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            className="tentangVideo"
          ></iframe>
        </div>
      </div>
      <div class="aboutButtonContainer">
        <div
          class="aboutButtonWrapper2 flex"
          style={{ justifyContent: "center" }}
        >
          <div></div>
          <button
            id="profile"
            class="aboutButton monsterratMedium subPath rounded-pill f1r px-4"
            onClick={(e) => changePage("/tentangkami/profile")}
          >
            Profil Perusahaan
          </button>
          <button
            id="lokasi"
            class="aboutButton monsterratMedium subPath rounded-pill f1r px-4"
            onClick={(e) => changePage("/tentangkami/lokasi")}
          >
            Lokasi
          </button>
          <button
            id="karir"
            class="aboutButton monsterratMedium subPath rounded-pill f1r px-4"
            onClick={(e) => changePage("/tentangkami/karir")}
          >
            Karir
          </button>
          {/* <button
            id="struktur_organisasi"
            class="aboutButton monsterratMedium rounded-pill f1r"
            onClick={(e) => changePage("/tentangkami/struktur_organisasi")}
          >
            Struktur Organisasi
          </button> */}
        </div>
        <div class="aboutButtonWrapper">
          {/* <button
            id=""
            class="aboutButton monsterratMedium rounded-pill f1r"
            onClick={() => openInNewTab(ijinUsahaDocument)}
          >
            <div>Izin Usaha</div>

            <img class="downloadImgAbout" src={download} />
          </button> */}
          {/* <button
            id="kode_etik"
            class="aboutButton monsterratMedium rounded-pill f1r"
            onClick={() => openInNewTab(kodeEtikDocument)}
          >
            Kode Etik
            <img class="downloadImgAbout" src={download} />
          </button> */}
          {/* <button
            id="laporan_tahunan"
            class="aboutButton monsterratMedium rounded-pill f1r"
            onClick={() => openInNewTab(laporan2020)}
          >
            Laporan Tahunan
            <img class="downloadImgAbout" src={download} />
          </button> */}
          {/* <button
            id=""
            className=" monsterratMedium  f1r piagamButton piagamBottom"
          >
            <div class="aboutMenuHover center-vertical">
              <div class="piagamText">
                Piagam <img class="downloadImgAbout" src={download} />
              </div>
            </div>
            <div class="piagamButtonHidden">
              <div
                class="tentangSubmenu"
                onClick={() => openInNewTab(piagamKomisaris)}
              >
                Piagam Dewan Komisaris
                <img class="downloadImgAbout" src={download} />
              </div>
              <div
                class="tentangSubmenu"
                onClick={() => openInNewTab(piagamDireksi)}
              >
                Piagam Dewan Direksi
                <img class="downloadImgAbout" src={download} />
              </div>
              <div
                class="tentangSubmenu"
                onClick={() => openInNewTab(piagamKepatuhan)}
              >
                Piagam Kepatuhan
                <img class="downloadImgAbout" src={download} />
              </div>
            </div>
            <div></div>
          </button> */}
          {/* <button
            id="pedoman"
            className=" monsterratMedium  f1r piagamButton pedomanButton "
            onClick={() => openInNewTab(piagamDireksi)}
          >
            <div></div>
            <div class="piagamText">
              Pedoman <img class="downloadImgAbout" src={download} />
            </div>
            <div class="piagamButtonHidden">
              <div
                class="tentangSubmenu"
                onClick={() => openInNewTab(pedomanManRisk)}
              >
                Pedoman Manajemen resiko
                <img class="downloadImgAbout" src={download} />
              </div>
              <div
                class="tentangSubmenu"
                onClick={() => openInNewTab(pedomanGood)}
              >
                Pedoman Good Corporate Governance
                <img class="downloadImgAbout" src={download} />
              </div>
            </div>
          </button> */}
        </div>
      </div>
      <div class="tentangSelectContainer">
        <select
          onChange={(e) => handleSelect(e.target.value)}
          class="form-select subPathSelect subPathSelectAbout monsterratMedium f12r"
          aria-label="Default select example"
        >
          {tentangList.map((tentang, i) => {
            if (page == tentang.id) {
              return (
                <option
                  selected
                  class="subPathOption"
                  value={JSON.stringify({
                    action: "navigate",
                    value: tentang.path,
                  })}
                >
                  {tentang.name}
                </option>
              );
            }
            if (tentang.sub) {
              return (
                <optgroup label={tentang.name}>
                  {tentang.sub.map((sub, i) => {
                    return (
                      <option
                        style={{ backgroundImage: `url(${download})` }}
                        class="subPathOption optionDownload"
                        value={JSON.stringify({
                          action: "download",
                          value: sub.name,
                        })}
                      >
                        {sub.name} &darr;
                      </option>
                    );
                  })}
                </optgroup>
              );
            }
            if (tentang.type == "download") {
              return (
                <option
                  class="subPathOption"
                  value={JSON.stringify({
                    action: "download",
                    value: tentang.name,
                  })}
                >
                  {tentang.name} &darr;
                </option>
              );
            } else {
              return (
                <option
                  class="subPathOption"
                  value={JSON.stringify({
                    action: "navigate",
                    value: tentang.path,
                  })}
                >
                  {tentang.name}
                </option>
              );
            }
          })}
        </select>
      </div>
      {page == "profile" ? (
        <div>
          <div class="profileTextWrapper">
            <div class="grey monsterratExtraBold profileTitle f25r blue-text-1 text-center">
              Cerita Tentang <br></br>Korea Investment And
              <br class="brMobile"></br> Sekuritas Indonesia
            </div>
            {/* <div class="profileSubtitle monsterratMedium grey">
          PT KOREA INVESTMENT AND SEKURITAS INDONESIA (“KISI”) (“Perusahaan”)
          mulai beroperasi pada tahun 2018 setelah diakuisisinya
        </div>
        <div class="profileSubtitle monsterratMedium grey">
          PT DANPAC SEKURITAS (“DANPAC”) oleh KOREA INVESTMENT AND SECURITIES
          CO., LTD (“KIS”).
        </div> */}

            <div class=" profileArticleContainer monsterratMedium grey f1r">
              <div class="ceritaImgContainer center-vertical">
                <img
                  src={superhero}
                  class="superheroImg"
                  data-aos="fade-up"
                  data-aos-duration="3500"
                />
              </div>
              <div class="profileArticleText greyText monsterratMedium">
                <p class="">
                  PT KOREA INVESTMENT AND SEKURITAS INDONESIA (KISI) mulai
                  beroperasi pada tahun 2018 setelah diakuisisinya PT DANPAC
                  SEKURITAS (DANPAC) oleh KOREA INVESTMENT AND SECURITIES CO.,
                  LTD (KIS).
                </p>
                <p></p>
                <p class="">
                  Perusahaan didirikan pada tanggal 17 Juni 1989 dengan nama PT
                  Amcol Securindo, dan pada tanggal 21 Juni 1994 pertama diubah
                  menjadi PT Gading Sekuritas, dan diubah kembali namanya pada
                  tanggal 13 September 1996 menjadi PT DANPAC SEKURITAS.
                  <p></p>
                  <p class="">
                    Pada tanggal 26 Juni 2018, DANPAC berhasil diakuisisi oleh
                    Korea Investment And Securities Co., Ltd (KIS) yang kemudian
                    namanya diubah kembali menjadi PT Korea Investment And
                    Sekuritas Indonesia (KISI). KISI memiliki izin usaha
                    Perantara Pedagang Efek (PPE), Penjamin Emisi Efek (PEE),
                    dan Agen Penjual Efek Reksa Dana (APERD) yang diterbitkan
                    oleh Otoritas Jasa Keuangan. KISI juga merupakan anggota
                    dari PT Bursa Efek Indonesia (BEI) dengan memiliki kode BQ.
                  </p>
                </p>
                <p></p>

                <p>
                  KIS sendiri didirikan pada tahun 1974 dengan nama Korea
                  Investment Trust Management & Securities Co., Ltd. Perusahaan
                  tersebut merupakan perusahaan investment trust pertama di
                  Korea dan menjadi pemimpin di dalam industrinya secara cepat.
                  Pada Juni 2013, Korea Investment Trust Management & Securities
                  Co., Ltd mengubah namanya menjadi Korea Investment &
                  Securities Co., Ltd dan pada Juni 2005 mengakuisisi Dongwon
                  Securities Co., Ltd. Dongwon Securities Co. Ltd didirikan pada
                  1968 dan merupakan salah satu anggota Dongwon Group,
                  perusahaan perikanan terbesar di Korea Selatan.
                </p>
                <p></p>
                <p class="">
                  KIS merupakan pemain besar dalam industri asset management
                  sementara Dongwon Securities merupakan salah satu pemain utama
                  dalam broker saham, investment banking, dan derivative
                  trading. Setelah merger, perusahaan tersebut secara cepat
                  menempati peringkat pertama di Korea untuk IPO underwriting.
                  Sejak 20 Juni 2018, KIS merupakan salah satu perusahaan
                  sekuritas besar di Korea yang memiliki 87 kantor cabang, 7
                  perusahaan di luar negeri, 2 kantor representatif di luar
                  negeri dan mempekerjakan 2,509 karyawan, serta mengelola aset
                  senilai US$32 milyar. Dengan keahlian KIS dan pengalaman lokal
                  yang terakumulasi, KISI mempunyai target untuk menjadi
                  perusahaan sekuritas terbaik di Indonesia.
                </p>
                <p></p>
                <p></p>
              </div>
            </div>
          </div>
          <div class="visiMisiContainer">
            {/* <div class="visiMisibgContainer">
            <img class="visiBgImg imageZ1" src={visiBg}></img>
          </div> */}
            <div class="visiMisiWrapper">
              <div class="visiMisiImgContainer center-vertical">
                <img class="visiMisiImg" src={visiMisi} />
              </div>
              <div class="visiMisiContent center-vertical">
                <div>
                  <div class="visiContainer">
                    <div class="f21r orangeText monsterratExtraBold">VISI</div>
                    <p class="monsterratMedium f11r white">
                      Membangun Perusahaan Induk Keuangan Terkemuka di Indonesia
                      yang berfungsi sebagai{" "}
                      <i>Hub of Southeast Asia Business.</i>
                    </p>
                  </div>
                  <div class="misiContainer ">
                    <div class="f21r orangeText monsterratExtraBold">MISI</div>
                    <div class="misiWrapper">
                      <div class="misiLeft">
                        <div class="monsterratMedium f11r white">
                          <b>Pertumbuhan yang Transparan dan Berkelanjutan </b>
                          <br></br>didorong oleh keseluruhan potensi
                          perekonomian Indonesia dan evolusi pasar modal lokal
                          <br></br>
                          <br></br>
                        </div>
                      </div>
                      <div class="misiRight">
                        <div class="monsterratMedium f11r white">
                          <b>Tumbuh Bersama </b>
                          <br></br>berkomitmen penuh untuk mematuhi dan
                          berpartisipasi dengan kebijakan regulasi untuk
                          pertumbuhan yang saling menguntungkan
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Timeline />

          <div class="pimpinanSection">
            <div class="grey monsterratExtraBold profileTitle f25r blue-text-1 text-center">
              Para Pimpinan <br></br>Korea Investment And{" "}
              <br class="brMobile"></br>Sekuritas Indonesia
            </div>
            <div class="pimpinanWrapper ">
              <div class="pimpinanImgContainer mx-auto center-vertical">
                <img
                  style={{
                    filter: "grayscale(100%)",
                  }}
                  src={ceo}
                  class="pimpinanImg"
                ></img>
              </div>
              <div class="blue-text-1 f13r  monsterratBlack text-center">
                Kim Sung-hwan
              </div>

              <div class="grey f085r mx-auto mw80768 monsterratMedium text-center mt-1">
                Chief Executive Officer of Korea Investment And Securities Co.,
                Ltd.
              </div>
            </div>
            <div class="profileButtonContainer">
              <div class="profileButtonWrapper">
                <button
                  id="direksi"
                  class="profileButton monsterratMedium rounded-pill grey "
                  onClick={(e) => handlePimpinanButton("direksi")}
                >
                  Dewan Direksi
                </button>
                <button
                  id="komisaris"
                  class="profileButton monsterratMedium rounded-pill grey "
                  onClick={(e) => handlePimpinanButton("komisaris")}
                >
                  Dewan Komisaris
                </button>
              </div>
            </div>

            {pimpinanPage == "direksi" ? <Direksi /> : <Komisaris />}
          </div>
        </div>
      ) : null}
      {/* {page == "struktur_organisasi" ? <StrukturOrganisasi /> : null}
      {page == "laporan_tahunan" ? <LaporanTahunan /> : null} */}
      {page == "karir" ? <div style={{ height: "60vh" }}></div> : null}
      {page == "lokasi" ? (
        <Lokasi lokasiDetail="indonesia" source="about" />
      ) : null}

      <div style={{ height: "7vh" }}></div>
    </div>
  );
}
